import { FC, useCallback, useEffect } from "react";

import NegativeOpinionForm from "web/Pages/HelpCenterArticle/Shared/Footer/NegativeOpinionForm";
import HelpCenterArticleOpinionOptions from "web/Pages/HelpCenterArticle/Shared/Footer/OpinionOptions";
import useHelpCenterArticleOpinion from "web/Pages/HelpCenterArticle/Shared/useOpinion";
import { IHelpCenterArticleWithId } from "web/Pages/HelpCenterArticle/helpCenterArticle";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";
import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import { useAppContext } from "web/context/app";

import classes from "./footer.scss";

interface IHelpCenterArticleMobileFooterProps {
  id: IHelpCenterArticleWithId["id"];
}

const HelpCenterArticleMobileFooter: FC<
  IHelpCenterArticleMobileFooterProps
> = ({ id }) => {
  const { modal } = useAppContext();
  const { dispatch } = modal;

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  const { isParamLangEn } = useLangFromParams();

  const { message, isLoading, error, postOpinion } =
    useHelpCenterArticleOpinion(id, closeModal);

  useEffect(() => {
    if (error) dispatch({ type: ModalActionTypes.RESET });
  }, [error]);

  const showModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: (
        <NegativeOpinionForm
          isLoading={isLoading}
          onPostOpinion={postOpinion}
        />
      ),
    });
  }, [dispatch]);

  const handlePostOpinion = () => {
    postOpinion({ opinion: 1 });
    dispatch({ type: ModalActionTypes.RESET });
  };

  const contentContainer = (() => {
    switch (true) {
      case !!error: {
        console.error(error);
        if (error?.responseMessage) {
          return (
            <p className={classes.responseMessage}>{error.responseMessage}</p>
          );
        }
        return <ErrorComponent />;
      }
      case isLoading: {
        return <Loading />;
      }
      default: {
        return (
          <HelpCenterArticleOpinionOptions
            onClickPositive={handlePostOpinion}
            onClickNegative={showModal}
          />
        );
      }
    }
  })();

  return (
    <footer>
      <div className={classes.content}>
        {!message && (
          <div className={classes.title}>
            {isParamLangEn
              ? "Was the answer helpful?"
              : __("Czy odpowiedź była pomocna?")}
          </div>
        )}
        {message ? (
          <p className={classes.responseMessage}>{__(message)}</p>
        ) : (
          contentContainer
        )}
      </div>
    </footer>
  );
};

export default HelpCenterArticleMobileFooter;
