/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useFormikContext } from "formik";
import { FC } from "react";

import type { LocationField } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import __ from "web/Layout/Translations";

import GoogleSearch from "./GoogleSearch";
import classes from "./localization.scss";

interface IProductLocalizationMobileProps {
  field: LocationField;
}

const ProductLocalizationMobile: FC<IProductLocalizationMobileProps> = ({
  field,
}) => {
  const { values } = useFormikContext<Record<string, string>>();
  const locationValue = values && values[field.name];

  return (
    <div className={classes.root}>
      <div className={classes.fieldset}>
        <strong className={classes.legend}>{__("Lokalizacja")}</strong>
        <GoogleSearch
          name={field.name}
          placeholder={field.placeholder}
          // @ts-ignore
          initialValue={locationValue || ""}
        />
      </div>
    </div>
  );
};

export default ProductLocalizationMobile;
