/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  availableLanguages,
  defaultLanguage,
} from "web/Layout/Translations/LanguageWrapper";

import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";

const baseQueryMSI = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders: (headers, { extra }) => {
    const storage = new BrowserPersistence();
    // @ts-ignore
    const token = extra?.token ?? storage.getItem(storageNames.tokenAccess);

    const languageKey =
      storage.getItem(storageNames.language) ||
      window.localStorage.getItem(storageNames.language) ||
      defaultLanguage;

    const language = availableLanguages.find((availableLanguage) => {
      return (
        availableLanguage.key === languageKey ||
        availableLanguage.code === languageKey
      );
    });

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    if (!headers.has("Content-Type")) {
      headers.set("Content-Type", "application/json");
    }

    if (!headers.has("Accept")) {
      headers.set("Accept", "application/json");
    }

    headers.set("Content-language", language?.code || "");

    return headers;
  },
});

export default baseQueryMSI;
