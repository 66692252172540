import { createSlice } from "@reduxjs/toolkit";

import { Error } from "web/api/apiRequestTypes";

import type { Nullable } from "web/types/Utils";

import { getFilteredBenefitGroups } from "./benefitGroupsThunks";

interface IBenefitGroup {
  [key: string]: { price: Nullable<number> };
}

interface IFilterBenefitGroupsState {
  isLoading: boolean;
  cache: {
    [key: string]: IBenefitGroup;
  };
  benefitGroups: IBenefitGroup;
  error: Nullable<Error>;
}

const initialState: IFilterBenefitGroupsState = {
  benefitGroups: {},
  isLoading: false,
  cache: {},
  error: null,
};

export const filterBenefitGroupsSlice = createSlice({
  name: "benefitGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFilteredBenefitGroups.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getFilteredBenefitGroups.fulfilled, (state, action) => {
      const { payload } = action;
      const { cacheKey } = payload;

      const cacheData = cacheKey
        ? { ...state.cache, [cacheKey]: payload.benefitGroups }
        : { ...state.cache };

      return {
        ...state,
        isLoading: false,
        benefitGroups: payload.benefitGroups,
        cache: cacheData,
      };
    });
  },
});

export default filterBenefitGroupsSlice.reducer;
