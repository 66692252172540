import { FC, PropsWithChildren } from "react";

import classes from "./section.scss";

interface ICheckoutTourismDomesticSectionProps {
  className?: string;
  title?: string;
  subtitle?: string;
}

const CheckoutTourismDomesticSection: FC<
  PropsWithChildren<ICheckoutTourismDomesticSectionProps>
> = ({ className = "", children, title = "", subtitle = "" }) => {
  return children || title || subtitle ? (
    <section className={className || classes.root}>
      {title ? <h2 className={classes.title}>{title}</h2> : null}
      {subtitle ? (
        <strong className={classes.subtitle}>{subtitle}</strong>
      ) : null}
      {children}
    </section>
  ) : null;
};

export default CheckoutTourismDomesticSection;
