import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { ICategoryCount } from "web/types/CategoryCount";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const categoryCountsAdapter =
  createEntityAdapter<ICachedItem<ICategoryCount>>();

const categoryCountsSlice = createSlice({
  name: "categoryCounts",
  initialState: categoryCountsAdapter.getInitialState(),
  reducers: {
    addCategoryCountsEntries: categoryCountsAdapter.addMany,
    removeCategoryCountsEntries: categoryCountsAdapter.removeMany,
  },
});

export const { addCategoryCountsEntries, removeCategoryCountsEntries } =
  categoryCountsSlice.actions;

export const { selectEntities } = categoryCountsAdapter.getSelectors<RootState>(
  (state) => state.categoryCounts
);

export const categoryCountsSliceName = categoryCountsSlice.name;

export default categoryCountsSlice.reducer;
