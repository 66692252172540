/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable react/no-array-index-key */
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  useFormikContext,
} from "formik";
import { FC, useEffect } from "react";

import PlusMinusInput from "web/Pages/Tourism/DomesticTourismForm/PlusMinusInput";
import Child from "web/Pages/Tourism/DomesticTourismForm/Room/Child";

import __ from "web/Layout/Translations";

import { names } from "../../domesticTourismFormFields";
import classes from "./people.scss";

interface IProductRoomPeopleMobileProps {
  index: number;
  onChange?: (value: number) => void;
}

const ProductRoomPeopleMobile: FC<IProductRoomPeopleMobileProps> = ({
  index: roomIndex,
  onChange = () => {},
}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    // @ts-ignore
    onChange(values[names.rooms][roomIndex]);
  }, [values]);

  // @ts-ignore
  const changeAdultsValue = (value: number, field, form) => {
    form.getFieldHelpers(field.name).setValue(value);
  };

  const changeChildrenValue = (
    value: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    // @ts-ignore
    const difference = value - values[names.rooms][roomIndex].children.length;
    // @ts-ignore
    if (value > values[names.rooms][roomIndex].children.length) {
      Array(difference)
        .fill({ age: 1 })
        .map((kido) => arrayHelpers.push(kido));
    }
    // @ts-ignore
    if (value < values[names.rooms][roomIndex].children.length) {
      // @ts-ignore
      arrayHelpers.remove();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {__("Pokój")} {roomIndex + 1}
      </div>
      <div className={classes.persons}>
        <div className={classes.adults}>
          <div className={classes.label}>
            {__("Dorośli")}
            <sup>(18+)</sup>
          </div>
          <Field name={`${names.rooms}[${roomIndex}].adults`}>
            {/* @ts-ignore */}
            {({ field, form }) => (
              <PlusMinusInput
                currentValue={field.value}
                minValue={1}
                maxValue={30}
                classes={{ button: classes.inputButton }}
                onChange={(value: number) =>
                  changeAdultsValue(value, field, form)
                }
              />
            )}
          </Field>
        </div>
        <div className={classes.children}>
          <div className={classes.label}>{__("Dzieci")}</div>
          <div className={classes.input}>
            <FieldArray name={`${names.rooms}[${roomIndex}].children`}>
              {(arrayHelpers) => (
                <PlusMinusInput
                  // @ts-ignore
                  currentValue={values[names.rooms][roomIndex].children.length}
                  minValue={0}
                  maxValue={10}
                  classes={{ button: classes.inputButton }}
                  onChange={(value: number) =>
                    changeChildrenValue(value, arrayHelpers)
                  }
                />
              )}
            </FieldArray>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      {values[names.rooms][roomIndex].children.length > 0 ? (
        <div className={classes.childrenSettings}>
          <div className={classes.label}>
            {__("Podaj wiek dziecka w dniu zakwaterowania")}
          </div>
          {/* @ts-ignore */}
          {values[names.rooms][roomIndex]?.children?.map(
            // @ts-ignore
            (child, childIndex) => (
              // @ts-ignore
              <Child
                key={childIndex}
                roomIndex={roomIndex}
                childIndex={childIndex}
              />
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProductRoomPeopleMobile;
