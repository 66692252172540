/* eslint-disable import/no-extraneous-dependencies */
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import registerServiceWorker from "scripts/registerServiceWorker";

import External from "web/Layout/External";
import LanguageWrapper from "web/Layout/Translations/LanguageWrapper/languageWrapper";

import RootErrorBoundary from "web/rootErrorBoundary";
import store, { persistor } from "web/store";

import logLocalStorageAvailability from "../scripts/logLocalStorageAvailability";
import App from "./Layout/App";
import InternetConnection from "./context/internetConnection";
import WebViewInfo from "./context/webView";
import "./index.scss";
import "./polyfills";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <InternetConnection>
        <RootErrorBoundary>
          <WebViewInfo>
            <LanguageWrapper>
              <External>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </External>
            </LanguageWrapper>
          </WebViewInfo>
        </RootErrorBoundary>
      </InternetConnection>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

logLocalStorageAvailability();
registerServiceWorker();
