/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useRef } from "react";
import SwiperCore, { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import CarouselMobile from "web/Layout/CarouselMobile";
import { IReview } from "web/types/Review";
import { IMergedReviewProduct } from "web/utils/page/customer/mergeReviewsProducts";

import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import Review from "./Review";
import ReviewMobile from "./ReviewMobile";
import classesDesktop from "./reviewsDesktop.scss";
import classesMobile from "./reviewsMobile.scss";

SwiperCore.use([Mousewheel]);

interface IPageGeneratorReviewsProps {
  isMobile: boolean;
  reviews: IMergedReviewProduct<IReview>[];
  header: string;
}

const PageGeneratorReviews: FC<IPageGeneratorReviewsProps> = ({ isMobile = false, reviews = [], header = "" }) => {
  const classes = isMobile ? classesMobile : classesDesktop;
  const titleClassName = `container ${classes.title || ""}`.trim();
  const reviewWrapperRef = useRef([]);

  useEffect(() => {
    if (reviews) {
      const currentItem = reviewWrapperRef.current.slice(0, reviews.length);

      currentItem.forEach((item: HTMLElement) => {
        item?.setAttribute("draggable", "false");
        item?.setAttribute("ondragstart", "return false");
      });
    }
  }, [reviews]);

  return (
    <section className={classes.root} data-t1="pageGeneratorReviews">
      {header && (
        <h2 className={titleClassName} data-t1="pageGeneratorReviews__title">
          {header}
        </h2>
      )}
      {isMobile ? (
        <CarouselMobile className={classes.reviews}>
          {reviews?.map(
            ({
              productUrl,
              product_id: id,
              nickname,
              value: rating,
              productImage,
              productName,
              detail: contentText,
            }) => (
              <ReviewMobile
                className={classes.review}
                key={`${nickname}${id}${contentText}`}
                url={processUrlKey(productUrl)}
                title={productName}
                name={nickname}
                rating={parseInt(rating as string, 10)}
                photo={productImage}
                contentText={contentText}
              />
            )
          )}
        </CarouselMobile>
      ) : (
        <Swiper
          slidesPerView="auto"
          mousewheel={{ forceToAxis: true, sensitivity: 2 }}
          className={classes.reviews}
        >
          {reviews?.map(
            (
              {
                productUrl,
                product_id: id,
                nickname,
                value: rating,
                productImage,
                productName,
                detail: contentText,
              },
              index
            ) => (
              <SwiperSlide key={`${nickname}${id}${contentText}`}>
                <div
                  ref={(element) => {
                    // @ts-ignore
                    reviewWrapperRef.current[index] = element;
                  }}
                >
                  <Review
                    key={`${nickname}${id}${contentText}`}
                    className={classes.review}
                    url={processUrlKey(productUrl)}
                    title={productName}
                    name={nickname}
                    rating={parseInt(rating as string, 10)}
                    image={productImage}
                    contentText={contentText}
                  />
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      )}
    </section>
  );
};

export default PageGeneratorReviews;
