import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import type { DomesticTourismProductInList } from "web/types/Product";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const productsDomesticTourismAdapter =
  createEntityAdapter<ICachedItem<DomesticTourismProductInList>>();

const productsDomesticTourismSlice = createSlice({
  name: "productsDomesticTourism",
  initialState: productsDomesticTourismAdapter.getInitialState(),
  reducers: {
    addProductsDomesticTourismEntries: productsDomesticTourismAdapter.addMany,
    removeProductsDomesticTourismEntries:
      productsDomesticTourismAdapter.removeMany,
  },
});

export const {
  addProductsDomesticTourismEntries,
  removeProductsDomesticTourismEntries,
} = productsDomesticTourismSlice.actions;

export const { selectEntities } =
  productsDomesticTourismAdapter.getSelectors<RootState>(
    (state) => state.productsDomesticTourism
  );

export const productsDomesticTourismSliceName =
  productsDomesticTourismSlice.name;

export default productsDomesticTourismSlice.reducer;
