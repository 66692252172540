import { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import { filterName, pageName } from "web/constants/toolbar";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultStyles from "./filterButton.scss";

const ProductFilterButton: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const paramsSearch = new URLSearchParams(search);
  const filterParam = paramsSearch.get(filterName);
  const isFiltered = filterParam === "true";
  const buttonClassName = isFiltered ? classes.buttonChecked : classes.button;
  const handleChange = () => {
    paramsSearch.delete(pageName);
    paramsSearch.set(filterName, encodeURIComponent(`${!isFiltered}`));
    push(`${pathname}?${paramsSearch.toString()}`);
  };

  return (
    <div className={classes.root}>
      <button type="button" className={buttonClassName} onClick={handleChange}>
        {__("Tylko oferty z wyżywieniem")}
      </button>
    </div>
  );
};

export default classify(defaultStyles)(ProductFilterButton);
