import { FC, useMemo } from "react";
import { IParsedParameter } from "web/types/PageGenerator";

import jsonParse from "web/utils/data/parser/string/jsonParse";

import ErrorBoundary from "../../../Layout/ErrorBoundary";
import Text from "./text";

interface IPageGeneratorTextContainerProps {
  params: string;
  isMobile: boolean;
}

const PageGeneratorTextContainer: FC<IPageGeneratorTextContainerProps> = ({ params, isMobile = false }) => {
  const {
    color_font_header: headerFontColor,
    color_font_description: descriptionFontColor,
    color_background: backgroundColor,
    height,
    header,
    description,
  } = useMemo<IParsedParameter>(() => jsonParse(params), [params]);
  return header || description ? (
    <Text
      isMobile={isMobile}
      headerFontColor={headerFontColor}
      descriptionFontColor={descriptionFontColor}
      backgroundColor={backgroundColor}
      height={height}
      header={header}
      description={description}
    />
  ) : null;
};

interface IPageGeneratorTextContainerErrorProps {
  params: string;
  isMobile: boolean;
}

const PageGeneratorTextContainerError: FC<IPageGeneratorTextContainerErrorProps> = ({ isMobile = false, params }) => {
  return (
    <ErrorBoundary>
      <PageGeneratorTextContainer isMobile={isMobile} params={params} />
    </ErrorBoundary>
  );
};

export default PageGeneratorTextContainerError;
