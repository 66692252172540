export interface IDomesticTourismFormField {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  required?: boolean;
  rules: (string | { name: string; additional: string })[];
  component?: string;
}

export const names = {
  tourismObject: "tourism_object",
  location: "tourism_location",
  rooms: "tourism_rooms",
  dateFrom: "tourism_date_from",
  dateTo: "tourism_date_to",
};

export const roomField = {
  name: names.rooms,
  rules: [],
};

export const tourismObjectField = {
  component: "field",
  name: names.tourismObject,
  type: "text",
  label: "Szukaj po obiektach",
  placeholder: "Wpisz nazwę obiektu",
  initialValue: "",
  required: false,
  rules: [{ name: "requiredDependent", additional: "tourism_location" }],
};

export const locationField = {
  component: "field",
  name: names.location,
  type: "text",
  label: "Szukaj po lokalizacji",
  placeholder: "Wpisz lokalizację",
  initialValue: "",
  required: true,
  rules: [{ name: "requiredDependent", additional: "tourism_object" }],
};

export const dateFields = [
  {
    name: names.dateFrom,
    type: "text",
    label: "Przyjazd",
    placeholder: "Data od",
    initialValue: "",
    required: true,
    rules: ["required"],
  },
  {
    name: names.dateTo,
    type: "text",
    label: "Wyjazd",
    placeholder: "Data do",
    initialValue: "",
    required: true,
    rules: ["required"],
  },
];

export type LocationField = typeof locationField;
export type DateFields = typeof dateFields;
