import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import urls from "web/constants/urls";

import {
  IMenuClientDataElement, // IMenuClientDataElementItem,
} from "web/types/MenuClient";

import getItemsWithRestrictions from "./utils/getItemsWithRestrictions";

// interfaces

export interface ICustomerMenuSectionItem {
  name: string;
  title?: string;
  url: string;
  position: number;
  isAnchor?: boolean;
}

export interface ICustomerMenuSection {
  name: string;
  originalName?: string;
  items: ICustomerMenuSectionItem[];
  // headerText?: IMenuClientDataElement["headerText"];
}
interface ICustomerMenuState {
  sections: ICustomerMenuSection[];
  restrictions: IMenuClientDataElement[];
}

// initial state

const initialState: ICustomerMenuState = {
  sections: [
    {
      name: "Mój portfel",
      items: [
        {
          name: "Szybki podgląd",
          url: urls.customerQuickView,
          position: 100,
        },
        {
          name: "Moje zamówienia",
          url: urls.customerOrders,
          position: 200,
        },
        {
          name: "Moje kody",
          url: urls.customerCodes,
          position: 300,
        },
        {
          name: "Punkty i banki punktów",
          url: urls.customerPoints,
          position: 500,
        },
        {
          name: "Zasilenia i Kasowania punktów",
          url: urls.pointsHistory,
          position: 600,
        },
      ],
    },
    {
      name: "Moje konto",
      items: [
        {
          name: "Moje dane",
          url: urls.customerData,
          position: 100,
        },
        {
          name: "Ulubione",
          url: urls.customerWishlist,
          position: 200,
        },
        {
          name: "Wiadomości",
          url: urls.customerMessages,
          position: 300,
        },
        {
          name: "Przypomnienia",
          url: urls.customerReminders,
          position: 400,
        },
        {
          name: "Moje opinie",
          url: urls.customerReviews,
          position: 500,
        },
        {
          name: "Moje zgody",
          url: urls.customerAgreements,
          position: 600,
        },
      ],
    },
  ],
  restrictions: [{}, {}] as IMenuClientDataElement[],
};

export const clientService: ICustomerMenuSection = {
  name: "Obsługa klienta",
  originalName: "Obsługa klienta",
  items: [
    {
      name: "Pomoc",
      url: urls.helpCenter,
      position: 100,
    },
    {
      name: "Kontakt",
      url: urls.contactForm,
      position: 200,
    },
  ],
};

// slice

export const customerMenuSlice = createSlice({
  name: "customerMenu",
  initialState,
  reducers: {
    setBenefit: (state: ICustomerMenuState) => {
      const [firstSection, ...otherSections] = state.sections;
      const [firstRestrictions] = state.restrictions;

      const name = "Moje abonamenty";
      const restriction = firstRestrictions.items?.filter(
        (restriction) => restriction.original_name === name
      );

      if (!restriction) {
        state.sections = [
          {
            name: firstSection.name,
            items: [
              ...firstSection.items,
              {
                name,
                title: name,
                url: urls.customerSubscriptions,
                position: 400,
              },
            ].sort((a, b) => a.position - b.position),
          },
          ...otherSections,
        ];
        return;
      }

      if (
        firstSection.items?.find((item) => item.name === name) ||
        restriction.length === 0 ||
        restriction[0].status === 0
      ) {
        return;
      }

      state.sections = [
        {
          name: firstSection.name,
          items: [
            ...firstSection.items,
            {
              name: restriction[0].original_name,
              title: restriction[0].name,
              url: urls.customerSubscriptions,
              position: 400,
            },
          ].sort((a, b) => a.position - b.position),
        },
        ...otherSections,
      ];
    },
    setRegulation: (state: ICustomerMenuState) => {
      const [firstSection, secondSection, ...otherSections] = state.sections;
      const [, secondRestrictions] = state.restrictions;
      const name = "Regulaminy";
      const restriction = secondRestrictions.items?.filter(
        (restriction) => restriction.original_name === name
      );

      if (!restriction) {
        state.sections = [
          firstSection,
          {
            name: secondSection.name,
            items: [
              ...secondSection.items,
              {
                name,
                title: name,
                url: urls.customerRegulations,
                position: 900,
              },
            ],
          },
          ...otherSections,
        ];
        return;
      }

      if (
        secondSection.items?.find((item) => item.name === name) ||
        restriction.length === 0 ||
        restriction[0].status === 0
      ) {
        return;
      }

      state.sections = [
        firstSection,
        {
          name: secondSection.name,
          items: [
            ...secondSection.items,
            {
              name: restriction[0].original_name,
              title: restriction[0].name,
              url: urls.customerRegulations,
              position: 900,
            },
          ],
        },
        ...otherSections,
      ];
    },
    setDocument: (state: ICustomerMenuState) => {
      const [firstSection, secondSection, ...otherSections] = state.sections;
      const [, secondRestrictions] = state.restrictions;
      const name = "Dokumenty";
      const restriction = secondRestrictions.items?.filter(
        (restriction) => restriction.original_name === name
      );

      if (!restriction) {
        state.sections = [
          firstSection,
          {
            name: secondSection.name,
            items: [
              ...secondSection.items,
              {
                name,
                title: name,
                url: urls.customerDocuments,
                position: 900,
              },
            ],
          },
          ...otherSections,
        ];
        return;
      }
      if (
        secondSection.items?.find((item) => item.name === name) ||
        restriction.length === 0 ||
        restriction[0].status === 0
      ) {
        return;
      }

      state.sections = [
        firstSection,
        {
          name: secondSection.name,
          items: [
            ...secondSection.items,
            {
              name: restriction[0].original_name,
              title: restriction[0].name,
              url: urls.customerDocuments,
              position: 900,
            },
          ],
        },
        ...otherSections,
      ];
    },
    setDiscountCodes: (state: ICustomerMenuState) => {
      const [firstSection, secondSection, ...otherSections] = state.sections;
      const [firstRestrictions] = state.restrictions;
      const name = "Kupony rabatowe";
      const restriction = firstRestrictions.items?.filter(
        (restriction) => restriction.original_name === name
      );
      if (!restriction) {
        state.sections = [
          {
            name: firstSection.name,
            items: [
              ...firstSection.items,
              {
                name,
                title: name,
                url: urls.customerDiscountCodes,
                position: 600,
              },
            ].sort((a, b) => a.position - b.position),
          },
          secondSection,
          ...otherSections,
        ];
        return;
      }
      if (
        firstSection.items?.find((item) => item.name === name) ||
        restriction.length === 0 ||
        restriction[0].status === 0
      ) {
        return;
      }

      state.sections = [
        {
          name: firstSection.name,
          items: [
            ...firstSection.items,
            {
              name: restriction[0].original_name,
              title: restriction[0].name,
              url: urls.customerDiscountCodes,
              position: 600,
            },
          ].sort((a, b) => a.position - b.position),
        },
        secondSection,
        ...otherSections,
      ];
    },
    setRestrictions: (
      state: ICustomerMenuState,
      { payload }: PayloadAction<IMenuClientDataElement[]>
    ) => {
      if (!payload) return;
      const [firstSection, secondSection, thirdSection] = state.sections;
      const sections: ICustomerMenuSection[] = [
        {
          name: payload[0].headerText.name
            ? payload[0].headerText.name
            : firstSection.name,
          items: getItemsWithRestrictions(firstSection, payload, 0),
        },
        {
          name: payload[1].headerText.name
            ? payload[1].headerText.name
            : secondSection.name,
          items: getItemsWithRestrictions(secondSection, payload, 1),
        },
      ];

      if (payload[2]) {
        sections.push({
          name: payload[2].headerText.name
            ? payload[2].headerText.name
            : (thirdSection || clientService).name,
          originalName: payload[2].headerText.original_name
            ? payload[2].headerText.original_name
            : (thirdSection || clientService).originalName,
          items: getItemsWithRestrictions(
            thirdSection || clientService,
            payload,
            2
          ),
        });
      }

      state.sections = sections;
      state.restrictions = payload;
    },
    setPrepaidCards: (state: ICustomerMenuState) => {
      const [firstSection, secondSection, ...otherSections] = state.sections;
      const name = "Karty przedpłacone";
      if (firstSection.items.find((item) => item.name === name)) {
        return;
      }

      state.sections = [
        {
          name: firstSection.name,
          items: [
            ...firstSection.items,
            {
              name,
              url: urls.customerPrepaidCards,
              position: 400,
            },
          ].sort((a, b) => a.position - b.position),
        },
        secondSection,
        ...otherSections,
      ];
    },
  },
});

export const {
  setBenefit,
  setRegulation,
  setDocument,
  setDiscountCodes,
  setRestrictions,
  setPrepaidCards,
} = customerMenuSlice.actions;

export default customerMenuSlice.reducer;
