import { FC } from "react";
import { Nullable } from "web/types/Utils";

import classesDesktop from "./textDesktop.scss";
import classesMobile from "./textMobile.scss";

interface IPageGeneratorTextProps {
  headerFontColor: string;
  backgroundColor: string;
  descriptionFontColor: string;
  height: Nullable<string>;
  header: string;
  description: string;
  isMobile: boolean;
}

const PageGeneratorText: FC<IPageGeneratorTextProps> = ({
  isMobile = false,
  headerFontColor,
  descriptionFontColor,
  backgroundColor,
  height = "",
  header = "",
  description = "",
}) => {
  const classes = isMobile ? classesMobile : classesDesktop;
  const rowClassName = isMobile
    ? classes.row
    : `container ${classes.row || ""}`.trim();

  const headerStyles = {
    color: headerFontColor,
  };
  const blockStyles = {
    color: descriptionFontColor,
    backgroundColor,
  };
  const rowStyles = {
    height: !isMobile && height ? `${height}px` : undefined,
  };

  return header || description ? (
    <section
      className={classes.root}
      style={blockStyles}
      data-t1="page_generator_text"
    >
      <div className={rowClassName} style={rowStyles}>
        {header && (
          <h2
            className={classes.header}
            style={headerStyles}
            data-t1="page_generator_text_title"
          >
            {header}
          </h2>
        )}
        {description && (
          <div
            className={classes.content}
            data-t1="page_generator_text_content"
          >
            {description}
          </div>
        )}
      </div>
    </section>
  ) : null;
};

export default PageGeneratorText;
