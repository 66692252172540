import { FC } from "react";

import __ from "web/Layout/Translations";

import DateBox from "../DateBox";

interface DateCardNumberRowProps {
  cardNumber: string;
  className?: string;
  date?: string;
}

const DateCardNumberRow: FC<DateCardNumberRowProps> = ({
  cardNumber,
  className = "",
  date,
}) => {
  return (
    <div>
      <p className={className}>{cardNumber}</p>
      {date && (
        <DateBox date={date} label={__("Termin możliwości zasilenia karty:")} />
      )}
    </div>
  );
};

export default DateCardNumberRow;
