import { FC } from "react";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { IHelpCenterSpecificCategoryCategoryArticle } from "web/types/HelpCenter";
import { IStoreConfig } from "web/types/StoreConfig";

import HelpCenterCategoryArticleCard from "./ArticleCard";
import { HelpCenterCategoryArticleCardLink } from "./ArticleCard/articleCard";
import classes from "./articlesSection.scss";

interface IHelpCenterCategoryArticlesSectionProps {
  articles: IHelpCenterSpecificCategoryCategoryArticle[];
  prefix: IStoreConfig["help_center_prefix"];
}

const HelpCenterCategoryArticlesSection: FC<
  IHelpCenterCategoryArticlesSectionProps
> = ({ articles, prefix }) => {
  const { langLinkSuffix } = useLangFromParams();
  return (
    <ul>
      {articles?.map(
        ({ id, title, short_description: description, url_key: url }) => {
          const link: HelpCenterCategoryArticleCardLink = `/${prefix}/${url}.html${langLinkSuffix}`;

          return (
            <li className={classes.item} key={id}>
              <HelpCenterCategoryArticleCard
                title={title}
                description={description}
                link={link}
              />
            </li>
          );
        }
      )}
    </ul>
  );
};

export default HelpCenterCategoryArticlesSection;
