/* eslint-disable */

/**
 * fromEntries
 */

if (!Object.fromEntries) {
  Object.defineProperty(Object, "fromEntries", {
    value(entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error(
          "Object.fromEntries() requires a single iterable argument"
        );
      }

      return [...entries].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {});
    },
  });
}
