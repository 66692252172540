import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import formatNumber from "web/utils/data/parser/number/formatNumber";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { ITourismInternationalValues } from "web/utils/page/product/universal/groupProducts";

import tourismDefinition from "web/constants/tourismDefinition";

import classes from "./reservation.scss";

interface ICheckoutTourismInternationalReservationMobileProps {
  values: ITourismInternationalValues;
  removeBg?: boolean;
}

const CheckoutTourismInternationalReservationMobile: FC<
  ICheckoutTourismInternationalReservationMobileProps
> = ({ values, removeBg = false }) => {
  const items = useMemo(() => {
    const { date_from: dateFrom, date_to: dateTo, ...restData } = values || {};
    const reservationNumber =
      restData && restData.booking_nr
        ? [
            {
              label: tourismDefinition.booking_nr,
              value: restData.booking_nr,
            },
          ]
        : [];
    const goal =
      restData && restData.trip_goal
        ? [
            {
              label: tourismDefinition.trip_goal,
              value: restData.trip_goal,
            },
          ]
        : [];
    const consultant =
      restData && restData.consultant_email
        ? [
            {
              label: tourismDefinition.consultant_email,
              value: restData.consultant_email,
            },
          ]
        : [];
    const date =
      dateFrom && dateTo
        ? [
            {
              label: tourismDefinition.date,
              value: `${formatDate(dateFrom)} - ${formatDate(dateTo)}`,
            },
          ]
        : [];
    const phone =
      restData && restData.phone
        ? [
            {
              label: tourismDefinition.phone,
              value: restData.phone,
            },
          ]
        : [];
    const email =
      restData && restData.email
        ? [
            {
              label: tourismDefinition.email,
              value: restData.email,
            },
          ]
        : [];
    const cost =
      restData && restData.cost_all_booking
        ? [
            {
              label: tourismDefinition.cost_all_booking,
              value: formatNumber(restData.cost_all_booking),
            },
          ]
        : [];
    return [
      ...goal,
      ...reservationNumber,
      ...cost,
      ...consultant,
      ...date,
      ...phone,
      ...email,
    ];
  }, [JSON.stringify(values)]);
  return isArrayHasItems(items) ? (
    <div
      className={
        window.location.href.includes(
          "checkout/internationalTourism/summary"
        ) || removeBg
          ? classes.rootSummary
          : classes.root
      }
      style={{ background: removeBg ? "none" : "#e8f5f5" }}
    >
      <strong className={classes.header}>
        {__("Dane dotyczące rezerwacji")}
      </strong>
      <ul>
        {items.map((item) => (
          <li key={item.label} className={classes.pair}>
            <span className={classes.label}>{item.label}:</span>
            <span className={classes.value}>{item.value}</span>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default CheckoutTourismInternationalReservationMobile;
