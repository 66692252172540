import { IBankItem } from "web/types/Banks";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import { AdditionalInfoDataType, OrderDetailsType } from "../../types";
import AdditionalInfo from "./AdditionalInfo";
import Details from "./Details";
import classes from "./orderDetails.scss";

type OrderDetailsProps = {
  orderDetails: OrderDetailsType;
};

const OrderDetails = ({ orderDetails }: OrderDetailsProps) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { items: banks } = dataBanks || {};

  const financingSources =
    !!banks &&
    banks.length > 0 &&
    orderDetails?.payments?.length > 0 &&
    (orderDetails.payments.map((payment) => ({
      ...payment,
      bankName: banks.find(
        (bank: IBankItem) => bank.points_bank_id === payment.financingSourceId
      )?.points_bank_name,
    })) as AdditionalInfoDataType[]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.row}>
          <div className={classes.content}>
            <Details
              prepaidCard={orderDetails?.prepaidCard}
              payments={orderDetails?.payments}
              employeeTotalAmount={orderDetails.employeeTotalAmount}
              name={orderDetails.name}
            />
          </div>
        </div>
        {financingSources && financingSources?.length > 0 && (
          <AdditionalInfo data={financingSources} />
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
