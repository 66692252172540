/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useMemo } from "react";

import getProductConfigurableData from "web/Pages/Checkout/Default/utils/getProductConfigurableData";
import Details from "web/Pages/Checkout/Product/Details";
import Totals from "web/Pages/Checkout/Product/Totals";
import ObjectInformation from "web/Pages/Checkout/TourismDomestic/SharedComponents/ObjectInformation";
import getDetailsItems from "web/Pages/Checkout/TourismInternational/getDetailsItems";

import __ from "web/Layout/Translations";

import DetailsList from "web/Components/Common/ConfigurableDetailsList";
import { detailsListVariants } from "web/Components/Common/ConfigurableDetailsList/detailsList";

import formatNumber from "web/utils/data/parser/number/formatNumber";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import { useAppContext } from "web/context/app";

import { ICartItem, ICartItemProductData, ICartParentProductAttribute } from "web/types/Cart";
import { MBProductType } from "web/types/Product";
import { IParsedCodeItem, IParsedOrder, IParsedTourismOrderItem } from "web/types/Order";
import { StringNumber, ValueOf } from "web/types/Utils";
import { IPaymentMethodItem } from "web/types/Payment";
import { ITourismInternationalValues } from "web/utils/page/product/universal/groupProducts";
import classes from "./product.scss";
import classesDetailsAnixe from "./detailsAnixe.scss";
import PaymentSources from "./PaymentSources";
import getProductAdditionalInfo from "../../TourismSuccess/getProductAdditionalInfo";

interface ISuccessProductProps {
  quote: IParsedOrder<MBProductType>["items"][number] & ICartItem,
  full: IParsedCodeItem["product_data"] & ICartItemProductData,
}

  export const getMainData = (quote: IParsedOrder<MBProductType>["items"][number]) => {
  switch (quote && quote.mb_product_type) {
    case "universal_code": {
      return formatNumber(quote.row_total_incl_tax);
    }
    case "international_tourism_travelplanet":
    case "international_tourism_other": {
      const tourismQuote = quote as IParsedTourismOrderItem;
      const amount =
        tourismQuote.tourism_offer_data && tourismQuote.tourism_offer_data.mb_payment_amount;
      return (
        <p className={classes.totalName} data-t1="product_total_name">
          {__("Płatność")}
          {": "}

          <span
            className={classes.totalValue}
            data-t1="product_total_value"
            data-t2={formatNumber(amount)}
          >
            {formatNumber(amount)}
          </span>
        </p>
      );
    }
    default:
      return null;
  }
};
export const getAdditionalData = (quote: IParsedOrder<MBProductType>["items"][number], isMobile: boolean) => {
  switch (quote && quote.mb_product_type) {
    case "universal_code": {
      return (
        <>
          <div className={classes.qtyLabel}>
            {__("za sztukę")}
            {": "}
          </div>
          <span data-t1="product_qty_value" data-t2={formatNumber(quote.price)}>
            {formatNumber(quote.price)}
          </span>
          <span className={classes.qty} data-t1="product_qty">
            x{(quote as IParsedCodeItem).qty}
          </span>
        </>
      );
    }
    case "international_tourism_travelplanet":
    case "international_tourism_other": {
      if (isMobile) return null;
      const tourismQuote = quote as IParsedTourismOrderItem;
      const costAllBooking =
        tourismQuote.tourism_offer_data && tourismQuote.tourism_offer_data.cost_all_booking;
      return (
        <p
          className={classes.additional}
          data-t1="totals_additional"
          data-t2={formatNumber(costAllBooking)}
        >
          {__("Wartość całej rezerwacji")}
          {": "}
          {formatNumber(costAllBooking)}
        </p>
      );
    }
    default:
      return null;
  }
};
export const getTotalPrice = (quote: IParsedOrder<MBProductType>["items"][number]) => {
  switch (quote && quote.mb_product_type) {
    case "universal_code": {
      return quote.row_total_incl_tax;
    }
    case "international_tourism_travelplanet":
    case "international_tourism_other": {
      const tourismQuote = quote as IParsedTourismOrderItem;
      return (
        tourismQuote.tourism_offer_data && tourismQuote.tourism_offer_data.mb_payment_amount
      );
    }
    case "anixe_tourism": {
      return isArrayHasItems(quote.payment_method)
        ? quote.payment_method.reduce((result: number, current: IPaymentMethodItem) => {
            const currentTotal =
              current && isArrayHasItems(current.values)
                ? current.values.reduce((subResult, subCurrent) => {
                    return subCurrent && +subCurrent.value
                      ? subResult + +subCurrent.value
                      : subResult;
                  }, 0)
                : 0;
            return result + currentTotal;
          }, 0)
        : 0;
    }
    default:
      return null;
  }
};
export const additionalInfoData = (quote: IParsedOrder<MBProductType>["items"][number], additional: {
  attribute: string;
  value: ValueOf<ICartParentProductAttribute>;
}[] | null, isMobile: boolean) => {
  switch (quote && quote.mb_product_type) {
    case "anixe_tourism": {
      return getProductAdditionalInfo(quote as IParsedTourismOrderItem);
    }
    case "universal_code": {
      return additional;
    }
    case "international_tourism_travelplanet":
    case "international_tourism_other": {
      if (isMobile) return null;
      const tourismQuote = quote as IParsedTourismOrderItem;
      return tourismQuote && tourismQuote.tourism_offer_data
        ? [
            ...(tourismQuote.tourism_offer_data.trip_goal
              ? [
                  {
                    name: __("Cel wyjazdu"),
                    value: tourismQuote.tourism_offer_data.trip_goal,
                    dataT1: "trip_goal",
                  },
                ]
              : []),
            ...(tourismQuote.tourism_offer_data.booking_nr
              ? [
                  {
                    name: __("Numer rezerwacji"),
                    value: tourismQuote.tourism_offer_data.booking_nr,
                    dataT1: "booking_nr",
                  },
                ]
              : []),
          ]
        : [];
    }
    default:
      return null;
  }
};
export const getDetails = (quote: IParsedOrder<MBProductType>["items"][number], isMobile: boolean) => {
  switch (quote && quote.mb_product_type) {
    case "universal_code": {
      return null;
    }
    case "international_tourism_travelplanet":
    case "international_tourism_other": {
      return getDetailsItems((quote as IParsedTourismOrderItem).tourism_offer_data as ITourismInternationalValues, isMobile);
    }
    default:
      return null;
  }
};

const SuccessProduct: FC<ISuccessProductProps> = memo(({ full, quote }) => {
  const { isMobile } = useAppContext();
  const {
    categoryId,
    name,
    image,
    link,
    main,
    additional,
    additionalInfo,
    details,
    isAnixeTourism,
  } = useMemo(() => {
    const [
      imageMemo,
      nameMemo,
      urlKeyMemo,
      categoryIdMemo,
      additionalInfoMemo,
    ] = getProductConfigurableData(quote, full);

    return {
      categoryId: categoryIdMemo,
      paymentSettings: full.payment_settings,
      name: nameMemo,
      image: imageMemo,
      link: processUrlKey(urlKeyMemo as string),
      qty: quote.qty,
      pointsBank: quote.points_bank,
      main: getMainData(quote),
      additional: getAdditionalData(quote, isMobile),
      additionalInfo: additionalInfoData(quote, additionalInfoMemo as {
        attribute: string;
        value: ValueOf<ICartParentProductAttribute>;
      }[], isMobile),
      totalPrice: getTotalPrice(quote),
      details: getDetails(quote, isMobile),
      isAnixeTourism: quote && (quote as IParsedCodeItem | IParsedTourismOrderItem).mb_product_type === MBProductType.ANIXE_TOURISM,
    };
  }, [full, quote]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Details
            classes={isAnixeTourism && classesDetailsAnixe}
            categoryId={categoryId as string}
            name={name as string}
            image={image as string}
            link={link}
            additionalInfo={additionalInfo as {
              attribute: string;
              value: ValueOf<ICartParentProductAttribute>;
            }[]}
            variant={
              isAnixeTourism
                ? detailsListVariants.ANIXE
                : detailsListVariants.DEFAULT
            }
          >
            {isAnixeTourism && !isMobile && (
              // @ts-ignore
              <ObjectInformation product={quote.product_data} />
            )}
          </Details>
          {!isMobile && (
            <Totals
              className={classes.totals}
              main={main as StringNumber}
              additional={additional as string | JSX.Element}
            />
          )}
        </div>
        <PaymentSources className={classes.points} item={quote} />
      </div>
      <DetailsList className={classes.details} items={details} />
    </div>
  );
});

export default SuccessProduct;
