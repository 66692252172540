import { Dispatch, SetStateAction, createContext, useContext } from "react";

import type { Nullable } from "web/types/Utils";

interface ICheckoutContextValue {
  isEditMode: boolean;
  toggleEditMode: Dispatch<SetStateAction<boolean>>;
  editProductId: Nullable<number>;
  setEditProductId: Dispatch<SetStateAction<Nullable<number>>>;
}

const contextDefaultValue = {};
const Context = createContext<ICheckoutContextValue>(
  contextDefaultValue as ICheckoutContextValue
);
const CheckoutContextProvider = Context.Provider;
const useCheckoutContext = () => useContext(Context);

export { CheckoutContextProvider, useCheckoutContext };
