import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";
import urls from "web/constants/urls";

import handleSafeTokenLog from "./handleSafeTokenLog";

const storage = new BrowserPersistence();

const handleTooManyTokenRenewals = async (id: string) => {
  const token = storage.getItem(storageNames.token);
  const tokenAccess = storage.getItem(storageNames.tokenAccess);

  const tokenInfo = {
    token: handleSafeTokenLog(token),
    tokenAccess: handleSafeTokenLog(tokenAccess),
  };

  newRelicErrorReport(
    new Error(`too many token renewals - ${id}`),
    JSON.stringify(tokenInfo)
  );

  window.location.replace(urls.error);
};

export default handleTooManyTokenRenewals;
