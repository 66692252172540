import { arrayOf, exact, oneOf, shape, string } from "prop-types";
import { useMemo } from "react";

import CarouselMobile from "web/Layout/CarouselMobile";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import SortItem from "./SortItem";
import classes from "./sort.scss";

const OrdersSortMobile = ({ options, defaultSort }) => {
  const optionsProcessed = useMemo(() => {
    return isArrayHasItems(options)
      ? options.reduce((result, option) => {
          if (option) {
            const optionDirections = isArrayHasItems(option.direction)
              ? option.direction.map((item) => {
                  return {
                    name: option.name,
                    title: __(option.label),
                    subtitle: __(item.label),
                    value: item.name,
                  };
                })
              : [
                  {
                    name: option.name,
                    title: option.label,
                  },
                ];

            return [...result, ...optionDirections];
          }
          return result;
        }, [])
      : [];
  }, [JSON.stringify(options)]);

  return optionsProcessed ? (
    <CarouselMobile className={classes.root} withoutOverflow freeMode>
      {optionsProcessed.map((option) => {
        return (
          <SortItem
            classes={classes}
            title={option.title}
            key={`${option.title}_${option.subtitle}`}
            subtitle={option.subtitle}
            name={option.name}
            value={option.value}
            parent={classes.root}
            defaultSort={defaultSort}
          />
        );
      })}
    </CarouselMobile>
  ) : null;
};

OrdersSortMobile.propTypes = {
  options: arrayOf(shape({})).isRequired,
  defaultSort: exact({ name: string, direction: oneOf(["DESC", "ASC"]) }),
};

OrdersSortMobile.defaultProps = {
  defaultSort: null,
};

export default OrdersSortMobile;
