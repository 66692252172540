import { FC } from "react";

import Rooms from "web/Pages/Checkout/TourismDomestic/SharedComponents/Rooms";
import Section from "web/Pages/Checkout/TourismDomestic/SharedComponents/Section";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { PropsWithClasses } from "web/types/Common";
import type { Guest, IRoom, Purchaser } from "web/types/Tourism";

import classify from "web/classify";
import { useAppContext } from "web/context/app";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

import defaultClasses from "./reservation.scss";

interface ICheckoutTourismDomesticReservationProps {
  guests: Guest[];
  purchaser: Purchaser;
  rooms: IRoom[];
  dateFrom: number;
  dateTo: number;
  title: string;
}

const CheckoutTourismDomesticReservation: FC<
  PropsWithClasses<ICheckoutTourismDomesticReservationProps>
> = ({
  guests,
  purchaser,
  rooms,
  dateFrom,
  dateTo,
  title = "",
  classes = {},
}) => {
  const { data: customerData } = useGetCustomerDetailsQuery();
  const { firstname, lastname } = customerData || {};
  const { isMobile, isPrint } = useAppContext();

  const roomsClasses = {
    root: defaultClasses.rooms,
    total: defaultClasses.roomsTotal,
  };
  const honorificMapLabel = {
    MR: __("Pan"),
    MRS: __("Pani"),
  };
  const purchaserArray = purchaser
    ? [
        {
          code: "name",
          label:
            honorificMapLabel[
              purchaser.honorific as keyof typeof honorificMapLabel
            ],
          value: `${firstname} ${lastname}`,
        },
        {
          code: "phone",
          label: __("Telefon"),
          value: purchaser.phone,
        },
        {
          code: "email",
          label: __("E-mail"),
          value: purchaser.email,
        },
      ]
    : [];

  return (
    <Section className={classes.sectionRoot}>
      {(!isMobile || isPrint) && <h2 className={classes.heading}>{title}</h2>}
      <div className={classes.root}>
        <Rooms
          classes={roomsClasses}
          rooms={rooms}
          dateFrom={dateFrom}
          dateTo={dateTo}
          guests={guests}
        />
        {isArrayHasItems(purchaserArray) ? (
          <>
            <strong className={classes.title}>{__("Dane kontaktowe")}</strong>
            <ul className={classes.purchaser}>
              {purchaserArray.map((purchaserItem) => {
                const valueComponent =
                  ["phone", "email"].indexOf(purchaserItem.code) === -1 ? (
                    <span className={classes.value}>{purchaserItem.value}</span>
                  ) : (
                    <a
                      href={`${
                        purchaserItem.code === "phone" ? "tel" : "mailto"
                      }:${purchaserItem.value}`}
                      className={classes.link}
                    >
                      {purchaserItem.value}
                    </a>
                  );

                return (
                  <li
                    className={classes.purchaserItem}
                    key={purchaserItem.value}
                  >
                    {purchaserItem.label}
                    {": "}
                    {valueComponent}
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}
      </div>
    </Section>
  );
};

export default classify<
  PropsWithClasses<ICheckoutTourismDomesticReservationProps>
>(defaultClasses)(CheckoutTourismDomesticReservation);
