import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import Totals from "web/Pages/Checkout/Product/Totals";
import totalsClasses from "web/Pages/Checkout/Success/Products/Product/product.scss";

import CategoryLink from "web/Layout/Common/CategoryLink";
import Image from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import DetailsList from "web/Components/Common/ConfigurableDetailsList";

import type { PropsWithClasses } from "web/types/Common";
import { StringNumber } from "web/types/Utils";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import defaultStyles from "./details.scss";

interface ICheckoutProductDetailsDesktopProps {
  categoryId: StringNumber;
  name: string;
  image: string;
  additionalInfo: {
    value: string;
    attribute?: string;
    name?: string;
    dataT1?: string;
  }[];
  link: string;
  dataT1?: string;
  variant?: string;
  main?: StringNumber;
  additional?: string | JSX.Element;
}

const CheckoutProductDetailsDesktop: FC<
  PropsWithChildren<PropsWithClasses<ICheckoutProductDetailsDesktopProps>>
> = ({
  name,
  categoryId,
  image,
  link,
  classes = {},
  additionalInfo = [],
  children,
  dataT1 = "details",
  main = "",
  additional,
  variant,
}) => {
  const { isMobile } = useAppContext();

  return (
    <div className={classes.root} data-t1={dataT1}>
      <Link
        className={classes.imageBox}
        to={link}
        data-t1="checkout_agreements_link"
      >
        <Image
          src={image}
          className={classes.image}
          alt={name}
          isProduct
          dataT1="details_image"
        />
      </Link>
      <div
        className={classes.content}
        data-t1="checkout_agreements_category_link_wrapper"
      >
        <CategoryLink
          className={classes.category}
          id={categoryId}
          dataT1="checkout_agreement_category_link"
        />
        <h2 className={classes.title} data-t1="checkout_agreements_title_link">
          <Link
            to={link}
            title={__(name)}
            data-t1="product_name"
            data-t2={name}
          >
            {__(name)}
          </Link>
        </h2>
        {isMobile && (
          <Totals
            className={totalsClasses.totals}
            main={main}
            additional={additional}
          />
        )}
        {children}
        <DetailsList
          className={classes.details}
          items={additionalInfo}
          variant={variant}
        />
      </div>
    </div>
  );
};

export default classify<
  PropsWithChildren<PropsWithClasses<ICheckoutProductDetailsDesktopProps>>
>(defaultStyles)(CheckoutProductDetailsDesktop);
