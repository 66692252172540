import { FC, useMemo } from "react";
import { IParsedParameter } from "web/types/PageGenerator";

import jsonParse from "web/utils/data/parser/string/jsonParse";

import Closeable from "./Closeable";
import Foldable from "./Foldable";
import Permanently from "./Permanently";

interface IPageGeneratorSingleProps {
  isMobile: boolean;
  params: string;
  id: number;
}

const PageGeneratorSingle: FC<IPageGeneratorSingleProps> = ({ params, isMobile = false, id }) => {
  const {
    color_font_header: headerFontColor,
    color_background: backgroundColor,
    color_font_description: descriptionFontColor,
    color_font_button: buttonFontColor,
    color_background_button: buttonBackgroundColor,
    background_file: imageSrc,
    visibility,
    height,
    header,
    url,
    layout,
    button,
    description,
  } = useMemo<IParsedParameter>(() => jsonParse(params), [params]);
  const heightProcessed = isMobile ? null : height;
  switch (visibility) {
    case "set_permanently": {
      return (
        <Permanently
          isMobile={isMobile}
          headerFontColor={headerFontColor}
          backgroundColor={backgroundColor}
          descriptionFontColor={descriptionFontColor}
          buttonFontColor={buttonFontColor}
          buttonBackgroundColor={buttonBackgroundColor}
          imageSrc={imageSrc}
          url={url}
          layout={layout}
          button={button}
          header={header}
          height={heightProcessed}
          description={description}
        />
      );
    }
    case "foldable": {
      return (
        <Foldable
          isMobile={isMobile}
          headerFontColor={headerFontColor}
          backgroundColor={backgroundColor}
          descriptionFontColor={descriptionFontColor}
          buttonFontColor={buttonFontColor}
          buttonBackgroundColor={buttonBackgroundColor}
          imageSrc={imageSrc}
          url={url}
          layout={layout}
          button={button}
          header={header}
          height={heightProcessed}
          description={description}
        />
      );
    }
    default:
      return (
        <Closeable
          id={id}
          isMobile={isMobile}
          headerFontColor={headerFontColor}
          backgroundColor={backgroundColor}
          descriptionFontColor={descriptionFontColor}
          buttonFontColor={buttonFontColor}
          buttonBackgroundColor={buttonBackgroundColor}
          imageSrc={imageSrc}
          url={url}
          layout={layout}
          button={button}
          header={header}
          height={heightProcessed}
          description={description}
        />
      );
  }
};

export default PageGeneratorSingle;
