import { FC } from "react";

import { IArticleOpinion } from "web/Pages/HelpCenterArticle/types";

import HappyFace from "web/assets/icons/happyFace.svg";
import SadFace from "web/assets/icons/sadFace.svg";

import classes from "./opinionOptions.scss";

interface IHelpCenterArticleOpinionOptions {
  onClickPositive: (args: IArticleOpinion) => void;
  onClickNegative: () => void;
}

const HelpCenterArticleOpinionOptions: FC<IHelpCenterArticleOpinionOptions> = ({
  onClickPositive,
  onClickNegative,
}) => {
  const onClickPositiveHandler = () => {
    onClickPositive({ opinion: 1 });
  };

  return (
    <div className={classes.btnsWrapper}>
      <button
        type="button"
        className={classes.btn}
        onClick={onClickPositiveHandler}
      >
        <HappyFace />
      </button>
      <button type="button" className={classes.btn} onClick={onClickNegative}>
        <SadFace />
      </button>
    </div>
  );
};

export default HelpCenterArticleOpinionOptions;
