import msProductsFullQuery from "web/queries/ms/productsByIds.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { ProductInList } from "web/types/Product";

import {
  addProductsEntries,
  removeProductsEntries,
  selectEntities,
} from "../products/productsSlice";
import useDataCached from "./useDataCached";

const mapData = new MapWrapper();

const useDataCachedProductsInList = ({
  ids,
  skip,
}: {
  ids: (number | string)[];
  skip?: boolean;
}) =>
  useDataCached<ProductInList>({
    ids: ids.map((id) => +id),
    query: msProductsFullQuery,
    addToCacheAction: addProductsEntries,
    removeFromCacheAction: removeProductsEntries,
    selectEntities,
    isShortProduct: true,
    mapData,
    isList: true,
    skip,
  });

export default useDataCachedProductsInList;
