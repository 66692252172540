import __ from "web/Layout/Translations";

import { FeeType, FeeTypePayer, OrderStatusType } from "../types";

export type TotalAmountDataType = {
  type: FeeType;
  payer: FeeTypePayer;
  amount: number;
  financingSourceId?: string;
  bankName?: string;
  date?: string;
  status?: OrderStatusType;
};

export const getTotalAmount = (
  data: TotalAmountDataType[],
  type: FeeType,
  payer: FeeTypePayer
) => {
  return (
    data?.length > 0 &&
    data
      .filter((el) => el.type === type && el.payer === payer)
      .reduce((a, b) => a + b.amount, 0)
  );
};

export const getStatusText = (orderStatus: OrderStatusType) => {
  switch (orderStatus) {
    case "COMPLETED":
      return __("zapłacono");
    case "CANCELED":
      return __("odrzucono");
    case "FAILED":
      return __("odrzucono");
    case "NEW":
      return __("nowe");
    default:
      return null;
  }
};
