/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, MouseEvent } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import SwiperCore, { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import Image from "web/Layout/Common/Image";

import Anchor from "web/Components/Common/Anchor";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IParsedParameter } from "web/types/PageGenerator";

import { useDispatch } from "react-redux";
import onGtmPromoClick from "web/utils/system/GTM/onGtmPromoClick";
import MyPoints from "../MyPoints";
import classes from "./slider.scss";


SwiperCore.use([Mousewheel, Pagination]);

interface IPageGeneratorSliderMobileProps {
  slides: IParsedParameter[];
  promoPosition: number;
}

const PageGeneratorSliderMobile: FC<IPageGeneratorSliderMobileProps> = ({
  slides = [],
  promoPosition = 0,
}) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const onSwiperSetTranslate = (swiper) => {
    const activeIndex = swiper.activeIndex || 0;

    if (
      activeIndex < swiper.snapGrid.length &&
      swiper.snapGrid[activeIndex + 1] < swiper.translate * -1
    ) {
      swiper.setTranslate(swiper.snapGrid[activeIndex + 1] * -1);
      return;
    }

    if (
      activeIndex > 0 &&
      swiper.snapGrid[activeIndex - 1] > swiper.translate * -1
    ) {
      swiper.setTranslate(swiper.snapGrid[activeIndex - 1] * -1);
      return;
    }

    if (Math.abs(activeIndex - swiper.previousIndex) > 1) {
      const activeIndexToSet =
        activeIndex > swiper.previousIndex
          ? swiper.previousIndex + 1
          : swiper.previousIndex - 1;
      // eslint-disable-next-line
      swiper.activeIndex = activeIndexToSet;
      swiper.setTranslate(swiper.snapGrid[activeIndexToSet] * -1);
    }
  };

  const onPromoClick = ({ currentTarget }: MouseEvent<HTMLDivElement>) => {
    const { promoSrc = "", promoPosition = 1, link = "" } = currentTarget?.dataset || {};
    onGtmPromoClick({
      promoSrc,
      promoPosition,
      link,
      dispatch,
    });
  };

  return isArrayHasItems(slides) ? (
    <section className={classes.root}>
      <div className={classes.slider}>
        <Swiper
          slidesPerView={1.1}
          mousewheel={{ forceToAxis: true, sensitivity: 2 }}
          className={classes.slides}
          pagination
          onSetTranslate={onSwiperSetTranslate}
        >
          {slides.map(
            (
              {
                id,
                type,
                graphic_file: imageSrc,
                link,
                title,
                color_background: backgroundColor,
              },
              index
            ) => {
              switch (type) {
                case "graphical": {
                  const linkValid = link?.charAt(0) !== "/" ? `/${link}` : link;
                  const image = (
                    <Image
                      className={classes.image}
                      src={imageSrc}
                      alt={title}
                      width={1024}
                      height={1024}
                    />
                  );
                  return (
                    <SwiperSlide
                      key={id}
                      data-promo-position={promoPosition + index}
                      data-promo-src={imageSrc}
                      data-link={linkValid}
                      onClick={promoPosition ? onPromoClick : () => {}}
                      style={{ backgroundColor }}
                    >
                      <Anchor
                        to={linkValid}
                        className={classes.slide}
                        key={id}
                        title={title}
                      >
                        <LazyLoadComponent>{image}</LazyLoadComponent>
                      </Anchor>
                    </SwiperSlide>
                  );
                }
                case "wallet": {
                  return (
                    <SwiperSlide key={id}>
                      <MyPoints />
                    </SwiperSlide>
                  );
                }
                default:
                  return null;
              }
            }
          )}
        </Swiper>
      </div>
    </section>
  ) : null;
};

export default PageGeneratorSliderMobile;
