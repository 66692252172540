import { Form } from "formik";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import Checkbox from "web/Components/Common/Form/Checkbox";
import Field from "web/Components/Common/Form/Field";

import ArrowRightIcon from "web/assets/icons/arrow-right.svg";
import { PropsWithClasses } from "web/types/Common";
import { FC } from "react";

interface IPageGeneratorFormNewsletterProps {
  fields: {
    name: string;
    type: string;
    label: string;
    placeholder?: string;
    initialValue: string | boolean;
    rules: string[];
  }[];
}

const PageGeneratorFormNewsletter: FC<PropsWithClasses<IPageGeneratorFormNewsletterProps>> = ({ fields, classes = {} }) => {
  const [fieldEmail, fieldAgreement] = fields;
  const buttonText = __("Zapisz się na newsletter");
  const moreText = __("Dowiedz się więcej");
  return (
    <Form className={classes.root} data-t1="pageGeneratorNewsletter__form">
      <div className={classes.wrapper}>
        <Field
          label={fieldEmail.label}
          className={classes.field}
          type={fieldEmail.type}
          name={fieldEmail.name}
          placeholder={fieldEmail.placeholder}
        />
        <button
          type="submit"
          className={classes.button}
          title={buttonText}
          data-t1="pageGeneratorNewsletter__submit"
        >
          <ArrowRightIcon className={classes.icon} width={12} />
        </button>
      </div>

      <div
        className={classes.agreement}
        data-t1="pageGeneratorNewsletter__agreement"
      >
        <Checkbox className={classes.checkbox} name={fieldAgreement.name}>
          {fieldAgreement.label}
        </Checkbox>
        <Link
          to="/"
          className={classes.link}
          data-t1="pageGeneratorNewsletter__agreement-link"
        >
          {moreText}
        </Link>
      </div>
    </Form>
  );
};

export default PageGeneratorFormNewsletter;
