import { FC, useCallback, useState } from "react";

import __ from "web/Layout/Translations";

import { PropsWithClasses } from "web/types/Common";

interface IFormikValues {
  values: Record<string, unknown>;
  setFieldValue: (key: string, value: unknown) => void;
}

interface IDomesticTourismFormClearButtonProps extends IFormikValues {
  canShow: boolean;
}

const hasValues = (values: Record<string, unknown>) => {
  const { tourism_rooms, ...valuesWithoutRooms } = values;
  return Object.values(valuesWithoutRooms).some(Boolean);
};

const DomesticTourismFormClearButton: FC<
  PropsWithClasses<IDomesticTourismFormClearButtonProps>
> = ({ canShow, values, setFieldValue, classes = {} }) => {
  const [areFieldsCleared, setAreFieldsCleared] = useState(false);
  const clearFields = useCallback(
    ({ values, setFieldValue }: IFormikValues) => {
      Object.keys(values).forEach((key) => {
        if (key === "tourism_rooms") return;
        setFieldValue(key, "");
      });
      setAreFieldsCleared(true);
    },
    []
  );

  const canShowClearBtn = canShow && !areFieldsCleared;

  return (
    <div
      className={`${classes.clearButtonContainer} ${
        !canShowClearBtn || !hasValues(values) ? classes.hide : ""
      } `}
    >
      {canShowClearBtn && hasValues(values) && (
        <button
          type="button"
          onClick={() => clearFields({ values, setFieldValue })}
          className={classes.clearButton}
        >
          {__("Wyczyść pola")}
        </button>
      )}
    </div>
  );
};

export default DomesticTourismFormClearButton;
