import { FC, PureComponent } from "react";

interface IWrapperComponentProps {
  className?: string;
  classes?: Record<string, string>;
}

const defaultObject = {};
const classify =
  <T extends IWrapperComponentProps = Record<string, unknown>>(
    defaultClasses: Record<string, string>
  ) =>
  (WrappedComponent: FC<T>) => {
    const name =
      WrappedComponent.displayName || WrappedComponent.name || "Component";

    class WrapperComponent extends PureComponent<T> {
      // eslint-disable-next-line react/static-property-placement
      static displayName = `Classify(${name})`;

      render() {
        const { className = "", classes = {}, ...restProps } = this.props;
        const classNameAsObject = className
          ? { root: className }
          : defaultObject;
        const finalClasses = {
          ...defaultClasses,
          ...classNameAsObject,
          ...classes,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        return <WrappedComponent {...restProps} classes={finalClasses} />;
      }
    }

    return WrapperComponent as unknown as FC<T>;
  };

export default classify;
