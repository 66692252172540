export const DOMESTIC_TOURISM = "domestic_tourism";

export const ANIXE_TOURISM = "anixe_tourism";

export const UNIVERSAL_CODE = "universal_code";

export const INTERNATIONAL_TOURISM_TRAVELPLANET =
  "international_tourism_travelplanet";

export const INTERNATIONAL_TOURISM_OTHER = "international_tourism_other";
