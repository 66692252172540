import { motion } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";

import Button from "web/Pages/PageGenerator/Button";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import __ from "web/Layout/Translations";

import { Nullable } from "web/types/Utils";

import CloseIcon from "../../../../assets/icons/close.svg";
import classesDesktop from "./closeableDesktop.scss";
import classesMobile from "./closeableMobile.scss";

const transitionConfig = { duration: 0.2 };

interface IPageGeneratorSingleCloseableProps {
  id: number;
  headerFontColor: string;
  backgroundColor: string;
  descriptionFontColor: string;
  buttonFontColor: string;
  buttonBackgroundColor: string;
  imageSrc: string;
  height: Nullable<string>;
  header: string;
  url: string;
  layout: string;
  button: string;
  description: string;
  isMobile: boolean;
}

const PageGeneratorSingleCloseable: FC<IPageGeneratorSingleCloseableProps> = ({
  id,
  isMobile = false,
  headerFontColor,
  backgroundColor,
  descriptionFontColor,
  buttonFontColor,
  buttonBackgroundColor,
  imageSrc,
  height,
  header,
  url,
  layout,
  button,
  description,
}) => {
  const buttonText = __("Zamknij");
  const classes = isMobile ? classesMobile : classesDesktop;
  const sectionClassName = isMobile
    ? classes.root
    : `${classes.root} container`.trim();
  const blockStyles = {
    color: descriptionFontColor,
    backgroundColor,
  };
  const titleStyles = {
    color: headerFontColor,
  };
  const wrapperClassName =
    layout === "right_hand" ? classes.wrapper : classes.wrapperReverse;
  const imageBoxClassName = height ? classes.imageBoxLimited : classes.imageBox;
  const [isOpen, toggleOpen] = useState(true);
  const [isVisible, toggleVisibility] = useState(!wasClosed(`${id}`));
  const toggleOpenHandler = useCallback(() => {
    toggleOpen((status) => !status);
    markAsClosed(`${id}`);
  }, [toggleOpen, id]);
  const toggleVisibilityHandler = useCallback(() => {
    toggleVisibility((status) => !status);
  }, [toggleVisibility]);
  const wrapperVariants = useMemo(
    () => ({
      open: {
        height: height ? `${height}px` : "auto",
        opacity: 1,
      },
      closed: {
        height: 0,
        opacity: 0,
      },
    }),
    [height]
  );

  return isVisible ? (
    <section className={sectionClassName} data-t1="pageGeneratorSinge">
      <div className={classes.row} style={blockStyles}>
        <Button
          className={classes.closeButton}
          buttonFontColor={buttonFontColor}
          buttonBackgroundColor={buttonBackgroundColor}
          title={buttonText}
          onClick={toggleOpenHandler}
          data-t1="pageGeneratorSinge__buttonClose"
        >
          <CloseIcon width={12} />
        </Button>
        <motion.div
          className={wrapperClassName}
          initial="open"
          animate={isOpen ? "open" : "closed"}
          variants={wrapperVariants}
          transition={transitionConfig}
          onAnimationComplete={!isOpen ? toggleVisibilityHandler : () => {}}
        >
          <div className={classes.content}>
            <h2
              className={classes.title}
              style={titleStyles}
              data-t1="pageGeneratorSinge__title"
            >
              {header}
            </h2>
            <div
              className={classes.description}
              data-t1="pageGeneratorSinge__description"
            >
              {description}
            </div>
            <Button
              to={url}
              className={classes.button}
              buttonFontColor={buttonFontColor}
              buttonBackgroundColor={buttonBackgroundColor}
            >
              {button}
            </Button>
          </div>
          <div
            className={imageBoxClassName}
            data-t1="pageGeneratorSinge__image"
          >
            {imageSrc && (
              <LazyLoadImage
                className={classes.image}
                src={imageSrc}
                alt={header}
              />
            )}
          </div>
        </motion.div>
      </div>
    </section>
  ) : null;
};

const closableClosedListKey = "closableClosedList";
const wasClosed = (id: string) => {
  const alreadyClosed = localStorage.getItem(closableClosedListKey) || "";
  return alreadyClosed.includes(id);
};
const markAsClosed = (id: string) => {
  if (!wasClosed(id)) {
    const alreadyClosed = localStorage.getItem(closableClosedListKey) || "";
    if (!alreadyClosed.includes(id)) {
      const updatedList = [...(alreadyClosed || []), id];
      localStorage.setItem(closableClosedListKey, JSON.stringify(updatedList));
    }
  }
};

export default PageGeneratorSingleCloseable;
