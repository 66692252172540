import { FC } from "react";

import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import styles from "./deletionReminder.scss";

interface IPageGeneratorDeletionReminderDesktopProps {
  date: string;
  bankName: string;
  points: number;
}

const PageGeneratorDeletionReminderDesktop: FC<IPageGeneratorDeletionReminderDesktopProps> = ({ date, bankName, points }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{__("Nadchodzi kasowanie punktów")}!</div>
      <div className={styles.detailsWrapper}>
        <span>
          {__("Kasowanie nastąpi dnia")}:&nbsp;
          <strong className={styles.details}>{date}</strong>
        </span>
        <span>
          {__("Dotyczy banku")}:&nbsp;
          <strong className={styles.details}>{bankName}</strong>
        </span>
        <span>
          {__("Ilość skasowana")}:&nbsp;
          <strong>
            <Price value={points} currency={__("pkt")} />
          </strong>
        </span>
      </div>
    </div>
  );
};

export default PageGeneratorDeletionReminderDesktop;
