/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import filterBanks from "web/Pages/Customer/Content/Points/utils";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import processPayloadCartPayload from "web/utils/system/redux/processPayloadCartPayload";
import separateFinancingSourcesPerProduct from "web/utils/system/redux/separateFinancingSourcesPerProduct";
import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import restUrls from "web/constants/restUrls";
import storageNames from "web/constants/storageNames";

import { IBankItem } from "web/types/Banks";
import {
  IFinancingSourcePaymentSettingsItem,
  IFinancingSources,
  ISources,
} from "web/types/FinancingSources";
import type { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { setPaymentMethods as setPaymentMethodsQuoteDomestic } from "web/features/tourismQuoteDomestic/tourismQuoteDomesticThunks";
import {
  ITourismInternationalState,
  setFinancingSources as setFinancingSourcesTourismInternational,
} from "web/features/tourismQuoteInternational/tourismQuoteInternationalSlice";
import { RootState } from "web/store";

import { setNotificationWarning } from "../app/appSlice";
import { setBuyNowDetails } from "../buyNow/buyNowSlice";
import { getDetailsReceive } from "../cart/cartSlice";
import {
  ICartRestErrorHandlerArgs,
  cartRestErrorHandler,
  createCart,
} from "../cart/cartThunks";
import { pointsBankApiSlice } from "../pointsBank/pointsBankApiSlice";
import {
  ITourismDomesticState,
  setAmount,
  setFinancingSources as setFinancingSourcesTourismDomestic,
} from "../tourismQuoteDomestic/tourismQuoteDomesticSlice";
import { setPaymentMethods } from "../tourismQuoteInternational/tourismQuoteInternationalThunks";
import { reset, warning } from "./financingSourcesSlice";
import { countSummary, getPaymentsWithNames, processSources } from "./utils";

const storage = new BrowserTemporality();
const name = "total_price_with_discount";
const nameInternationalTourism = "mb_payment_amount";
const nameDomesticTourism = "total_price";

export const separatePointsPerProduct = createAsyncThunk(
  "financingSources/separatePointsPerProduct",
  async (data: Nullable<IFinancingSources>, { dispatch, getState }) => {
    const { cart } = getState() as RootState;
    const cartId = cart?.cartId;

    if (!cartId) {
      await dispatch(createCart());
      dispatch(separatePointsPerProduct(data));
      return null;
    }

    if (!isArrayHasItems(cart?.items)) {
      return null;
    }

    const banks = await dispatch(
      pointsBankApiSlice.endpoints.getBanks.initiate()
    ).unwrap();

    const { financingSources } = getState() as RootState;
    const visibleBanks = filterBanks(banks.items);
    const manuallySeparatedSourcesFromStorage =
      storage.getItem(storageNames.manuallySeparatedSources, cartId) || [];
    const manuallySeparatedSources = isArrayHasItems(
      financingSources.manuallySeparatedSources
    )
      ? financingSources.manuallySeparatedSources
      : manuallySeparatedSourcesFromStorage;

    const manuallySeparatedItemsWithPayload = data
      ? manuallySeparatedSources.reduce(
          (
            resultManually: IFinancingSourcePaymentSettingsItem[],
            currentManually: IFinancingSourcePaymentSettingsItem
          ) => {
            return currentManually && currentManually.sku !== data.sku
              ? [...resultManually, currentManually]
              : resultManually;
          },
          [
            {
              sku: data.sku,
              payment_settings: Object.keys(data.sources).reduce(
                (result: ISources[], key: string) => {
                  const value = data.sources[key];

                  return value
                    ? [
                        ...result,
                        {
                          code: key,
                          value,
                        },
                      ]
                    : result;
                },
                []
              ),
            },
          ]
        )
      : manuallySeparatedSources;
    const cartItemsWithTotalDiscountPrice = isArrayHasItems(cart.items)
      ? cart.items.map((cartItem) => {
          return {
            ...cartItem,
            [name]: Object.prototype.hasOwnProperty.call(
              cartItem,
              "price_with_discount"
            )
              ? cartItem.price_with_discount * cartItem.qty
              : cartItem.row_total_incl_tax,
          };
        })
      : [];
    const payments = separateFinancingSourcesPerProduct({
      products: cartItemsWithTotalDiscountPrice,
      banks: visibleBanks,
      name,
      separated: manuallySeparatedItemsWithPayload,
    });

    try {
      const response = await request(restUrls.cartItemsPointsBank, {
        method: IApiRequestMethods.POST,
        body: JSON.stringify({
          payments,
          quoteId: cartId,
        }),
      });

      const paymentsWithNames = getPaymentsWithNames(
        payments,
        visibleBanks as IBankItem[]
      );
      const summary = countSummary(paymentsWithNames);

      await dispatch(getDetailsReceive(response));

      if (manuallySeparatedItemsWithPayload.length) {
        storage.setItem(storageNames.manuallySeparatedSources, cartId, [
          ...manuallySeparatedItemsWithPayload,
        ]);
      }

      if (Array.isArray(response)) {
        response.forEach(({ success, message }) => {
          if (success === false) {
            dispatch(setNotificationWarning({ message }));
            dispatch(warning(message));
          }
        });
      }
      return {
        items: paymentsWithNames,
        manuallySeparatedSources: [...manuallySeparatedItemsWithPayload],
        summary,
      };
    } catch (error) {
      await cartRestErrorHandler({
        error,
        dispatch,
      } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

export const resetStateAndRemoveItemFromSessionStorage =
  () => (dispatch: ThunkDispatch<IFinancingSources, unknown, Action>) => {
    storage.removeItem(storageNames.manuallySeparatedSources);
    dispatch(reset());
  };

export const separatePointsPerProductInternationalTourism = createAsyncThunk(
  "financingSources/separatePointsPerProductInternationalTourism",
  async (payload: Nullable<IFinancingSources>, { getState, dispatch }) => {
    const { sources } = payload || {};
    const { tourismQuoteInternational } = getState() as RootState;
    const { quote, financingSources }: ITourismInternationalState =
      tourismQuoteInternational;
    const id = quote?.id;

    if (!id) {
      return null;
    }

    const banks = await dispatch(
      pointsBankApiSlice.endpoints.getBanks.initiate()
    ).unwrap();

    const visibleBanks = filterBanks(banks.items);
    let separatePointsBody = null;
    switch (true) {
      case !!sources: {
        const sourcesProcessed = [
          {
            sku: quote?.sku,
            payment_settings: processSources(sources as ISources),
          },
        ];
        const payments = separateFinancingSourcesPerProduct({
          products: [
            {
              sku: quote?.sku,
              id,
              payment_settings: quote?.paymentSettings,
              [nameInternationalTourism]:
                quote?.values[nameInternationalTourism],
            },
          ],
          banks: visibleBanks,
          name: nameInternationalTourism,
          separated: sourcesProcessed,
        });
        const paymentsWithNames = getPaymentsWithNames(
          payments,
          visibleBanks as IBankItem[]
        );
        const summary = countSummary(paymentsWithNames);

        storage.setItem(
          `${storageNames.tourismInternational}${
            (payload as IFinancingSources).id
          }`,
          "financingSources",
          paymentsWithNames
        );
        await dispatch(
          setFinancingSourcesTourismInternational({
            financingSources: paymentsWithNames,
          })
        );
        separatePointsBody = {
          items: paymentsWithNames,
          summary,
        };
        break;
      }
      case !!isArrayHasItems(financingSources): {
        const summary = countSummary(financingSources);
        separatePointsBody = {
          items: financingSources,
          summary,
        };
        break;
      }
      default: {
        const payments = separateFinancingSourcesPerProduct({
          products: [
            {
              sku: quote?.sku,
              id,
              payment_settings: quote?.paymentSettings,
              [nameInternationalTourism]:
                quote?.values[nameInternationalTourism],
            },
          ],
          banks: visibleBanks,
          name: nameInternationalTourism,
          separated: [],
        });
        const paymentsWithNames = getPaymentsWithNames(
          payments,
          visibleBanks as IBankItem[]
        );
        const summary = countSummary(paymentsWithNames);

        await dispatch(
          setFinancingSourcesTourismInternational({
            financingSources: paymentsWithNames,
          })
        );
        separatePointsBody = {
          items: paymentsWithNames,
          summary,
        };
      }
    }

    await dispatch(setPaymentMethods(separatePointsBody));
    return separatePointsBody;
  }
);

export const separatePointsPerProductDomesticTourism = createAsyncThunk(
  "financingSources/separatePointsPerProductDomesticTourism",
  async (payload: Nullable<IFinancingSources>, { getState, dispatch }) => {
    const { sources } = payload || {};
    const { tourismQuoteDomestic } = getState() as RootState;
    const {
      quote,
      financingSources,
      total,
      amount,
      amountMinimal,
      howToPayMethod,
    }: ITourismDomesticState = tourismQuoteDomestic;
    const id = quote?.id;

    if (!id) {
      return null;
    }
    const isHowToPayMethodPercentage = howToPayMethod === "percentage";

    const banks = await dispatch(
      pointsBankApiSlice.endpoints.getBanks.initiate()
    ).unwrap();

    const visibleBanks = filterBanks(banks.items);
    let separatePointsBody = null;
    switch (true) {
      case !!sources: {
        const sourcesProcessed = [
          {
            sku: quote?.sku,
            payment_settings: processSources(sources as ISources),
          },
        ];
        const sourcesTotal =
          sources &&
          Object.keys(sources).length &&
          Object.keys(sources).reduce((result, currentKey) => {
            return result + +sources[currentKey];
          }, 0);
        const payments = separateFinancingSourcesPerProduct({
          products: [
            {
              sku: quote?.sku,
              id,
              payment_settings: quote?.paymentSettings,
              [nameDomesticTourism]: isHowToPayMethodPercentage
                ? sourcesTotal
                : total,
            },
          ],
          banks: visibleBanks,
          name: nameDomesticTourism,
          separated: sourcesProcessed,
        });
        const paymentsWithNames = getPaymentsWithNames(
          payments,
          visibleBanks as IBankItem[]
        );
        const summary = countSummary(paymentsWithNames);

        storage.setItem(
          `${storageNames.tourismDomestic}${id}`,
          "financingSources",
          paymentsWithNames
        );
        await dispatch(
          setFinancingSourcesTourismDomestic({
            financingSources: paymentsWithNames,
          })
        );

        separatePointsBody = {
          items: paymentsWithNames,
          summary,
        };

        if (isHowToPayMethodPercentage) {
          await dispatch(setAmount(sourcesTotal as number));
        }
        break;
      }
      case !!isArrayHasItems(financingSources): {
        const summary = countSummary(financingSources);
        separatePointsBody = {
          items: financingSources,
          summary,
        };
        break;
      }
      default: {
        const payments = separateFinancingSourcesPerProduct({
          products: [
            {
              sku: quote?.sku,
              id,
              payment_settings: quote?.paymentSettings,
              [nameDomesticTourism]: isHowToPayMethodPercentage
                ? Math.max(amount, amountMinimal)
                : total,
            },
          ],
          banks: visibleBanks,
          name: nameDomesticTourism,
          separated: [],
        });
        const paymentsWithNames = getPaymentsWithNames(
          payments,
          visibleBanks as IBankItem[]
        );
        const summary = countSummary(paymentsWithNames);

        await dispatch(
          setFinancingSourcesTourismDomestic({
            financingSources: paymentsWithNames,
          })
        );
        separatePointsBody = {
          items: paymentsWithNames,
          summary,
        };
      }
    }

    await dispatch(setPaymentMethodsQuoteDomestic(separatePointsBody));
    return separatePointsBody;
  }
);

export const separatePointsPerProductBuyNow = createAsyncThunk(
  "financingSources/separatePointsPerProductBuyNow",
  async (payload: Nullable<IFinancingSources>, { getState, dispatch }) => {
    const { buyNow } = getState() as RootState;
    const { cartId, items } = buyNow;

    if (!cartId || !isArrayHasItems(items)) {
      return null;
    }

    const banks = await dispatch(
      pointsBankApiSlice.endpoints.getBanks.initiate()
    ).unwrap();

    const { financingSources } = getState() as RootState;
    const visibleBanks = filterBanks(banks.items);
    const manuallySeparatedSourcesFromStorage =
      storage.getItem(storageNames.manuallySeparatedSources, cartId) || [];
    const manuallySeparatedSources = isArrayHasItems(
      financingSources.manuallySeparatedSources
    )
      ? financingSources.manuallySeparatedSources
      : manuallySeparatedSourcesFromStorage;

    const manuallySeparatedItemsWithPayload = payload
      ? manuallySeparatedSources.reduce(
          (
            resultManually: IFinancingSourcePaymentSettingsItem[],
            currentManually: IFinancingSourcePaymentSettingsItem
          ) => {
            return currentManually && currentManually.sku !== payload.sku
              ? [...resultManually, currentManually]
              : resultManually;
          },
          [
            {
              sku: payload.sku,
              payment_settings: Object.keys(payload.sources).reduce(
                (result: ISources[], key: string) => {
                  const value = payload.sources[key];

                  return value
                    ? [
                        ...result,
                        {
                          code: key,
                          value,
                        },
                      ]
                    : result;
                },
                []
              ),
            },
          ]
        )
      : manuallySeparatedSources;
    const cartItemsWithTotalDiscountPrice = isArrayHasItems(items)
      ? items.map((item) => {
          return {
            ...item,
            [name]: Object.prototype.hasOwnProperty.call(
              item,
              "price_with_discount"
            )
              ? item.price_with_discount * item.qty
              : item.row_total_incl_tax,
          };
        })
      : [];
    const payments = separateFinancingSourcesPerProduct({
      products: cartItemsWithTotalDiscountPrice,
      banks: visibleBanks,
      name,
      separated: manuallySeparatedItemsWithPayload,
    });

    const response = await request(restUrls.cartItemsPointsBank, {
      method: IApiRequestMethods.POST,
      body: JSON.stringify({
        payments,
        quoteId: cartId,
      }),
    });

    const paymentsWithNames = getPaymentsWithNames(
      payments,
      visibleBanks as IBankItem[]
    );
    const summary = countSummary(paymentsWithNames);

    await dispatch(setBuyNowDetails(processPayloadCartPayload(response || {})));

    if (manuallySeparatedItemsWithPayload.length) {
      storage.setItem(storageNames.manuallySeparatedSources, cartId, [
        ...manuallySeparatedItemsWithPayload,
      ]);
    }

    if (Array.isArray(response)) {
      response.forEach(({ success, message }) => {
        if (success === false) {
          dispatch(setNotificationWarning({ message }));
          dispatch(warning(message));
        }
      });
    }
    return {
      items: paymentsWithNames,
      manuallySeparatedSources: [...manuallySeparatedItemsWithPayload],
      summary,
    };
  }
);
