import { isEmpty } from "lodash";

import { FacetsStats } from "web/types/Product";

const emptyFacetsStatsMock = {
  facets: [],
  stats: [],
  items_ids: [],
  subscription_items: [],
  prices: [],
} as FacetsStats;

const transFormFacetsStatsData = (data?: FacetsStats[]) => {
  const extractedData = data?.[0];
  if (!extractedData) {
    return extractedData;
  }
  return isEmpty(extractedData) ? emptyFacetsStatsMock : extractedData;
};

export default transFormFacetsStatsData;
