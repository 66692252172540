import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import type { IAttribute } from "web/types/Attributes";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const attributesAdapter = createEntityAdapter<ICachedItem<IAttribute>>();

const attributesSlice = createSlice({
  name: "attributes",
  initialState: attributesAdapter.getInitialState(),
  reducers: {
    addAttributesEntries: attributesAdapter.addMany,
    removeAttributesEntries: attributesAdapter.removeMany,
  },
});

export const { addAttributesEntries, removeAttributesEntries } =
  attributesSlice.actions;

export const { selectEntities } = attributesAdapter.getSelectors<RootState>(
  (state) => state.attributes
);

export const attributesSliceName = attributesSlice.name;

export default attributesSlice.reducer;
