import { FC } from "react";

import { Classes, SimpleFn } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./navigationItem.scss";

interface ICheckoutNavigationItem {
  label: string | number;
  disabled: boolean;
  active: boolean;
  classes: Classes;
  onClick: SimpleFn;
  isThankYouPageHelper: boolean;
}

const CheckoutNavigationItem: FC<ICheckoutNavigationItem> = ({
  label,
  disabled,
  active,
  classes,
  onClick,
  isThankYouPageHelper,
}) => {
  if (disabled) {
    return (
      <>
        <span
          className={classes.connector}
          data-t1="navigation_list_item_connector"
        />
        <button
          type="button"
          className={classes.link}
          data-t1="navigation_list_item_label"
          onClick={onClick}
        >
          {label}
        </button>
      </>
    );
  }
  if (active) {
    return (
      <>
        {isThankYouPageHelper ? (
          <span
            className={classes.connector}
            data-t1="navigation_list_item_connector"
          />
        ) : null}
        <span data-t1="navigation_list_item_label" className={classes.active}>
          {label}
        </span>
      </>
    );
  }

  return (
    <span data-t1="navigation_list_item_label" className={classes.disabled}>
      {label}
    </span>
  );
};

export default classify<ICheckoutNavigationItem>(defaultClasses)(
  CheckoutNavigationItem
);
