import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import ObjectInformation from "web/Pages/Checkout/TourismDomestic/SharedComponents/ObjectInformation";

import Image from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import DetailsList from "web/Components/Common/ConfigurableDetailsList";

import type { ICartItemProductData } from "web/types/Cart";
import { PropsWithClasses } from "web/types/Common";
import { DomesticTourismCheckoutProduct } from "web/types/Product";

import classify from "web/classify";

import defaultStyles from "./details.scss";

interface ICheckoutProductDetailsMobileProps {
  name: string;
  image: string;
  link: string;
  additionalInfo: {
    attribute?: string;
    value: string;
  }[];
  dataT1?: string;
  variant?: string;
  productData?: ICartItemProductData | DomesticTourismCheckoutProduct;
}

const CheckoutProductDetailsMobile: FC<
  PropsWithChildren<PropsWithClasses<ICheckoutProductDetailsMobileProps>>
> = ({
  name,
  image,
  link,
  classes = {},
  additionalInfo = [],
  children,
  dataT1 = "details",
  productData,
  variant,
}) => {
  return (
    <div className={classes.root} data-t1={dataT1}>
      <Link
        className={classes.imageBox}
        to={link}
        data-t1="checkout_agreements_link"
      >
        <Image
          src={image}
          className={classes.image}
          alt={name}
          isProduct
          dataT1="details_image"
        />
      </Link>
      <div
        className={classes.content}
        data-t1="checkout_agreements_category_link_wrapper"
      >
        <h2 className={classes.title} data-t1="checkout_agreements_title_link">
          <Link to={link} title={name} data-t1="product_name" data-t2={name}>
            {__(name)}
          </Link>
        </h2>
        {children || null}
        <ObjectInformation
          product={productData as DomesticTourismCheckoutProduct}
        />
        <DetailsList
          className={classes.details}
          items={additionalInfo}
          variant={variant}
        />
      </div>
    </div>
  );
};

export default classify<
  PropsWithChildren<PropsWithClasses<ICheckoutProductDetailsMobileProps>>
>(defaultStyles)(CheckoutProductDetailsMobile);
