import {
  FC,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import { SimpleFn } from "web/types/Common";
import { Nullable } from "web/types/Utils";

export const enum BiometryLoginType {
  FACE_ID = "FaceID",
  TOUCH_ID = "TouchID",
  BIOMETRICS = "Biometrics",
}

export const enum OutcomingWebViewMessageType {
  LOGIN = "login",
  CHANGE_PIN = "change_pin",
  TOGGLE_BIOMETRY = "toggle_biometry",
  CONVERT_TO_PDF = "convert_to_pdf",
  REQUEST_REVIEW = "request_review",
  SAVE_FILE = "save_file",
}

interface OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType;
  payload?: unknown;
}

interface IOutcomingWebViewLoginMessagePayload {
  userId: string;
  token: string;
  idToken: string;
}

interface IOutcomingWebViewLoginMessage extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.LOGIN;
  payload: IOutcomingWebViewLoginMessagePayload;
}

export interface IOutcomingWebViewChangePinMessage
  extends OutcomingWebViewMessageBase {
  type:
    | OutcomingWebViewMessageType.TOGGLE_BIOMETRY
    | OutcomingWebViewMessageType.CHANGE_PIN;
  payload?: boolean;
}

interface IOutcomingWebViewConvertToPdfMessagePayload {
  html: string;
  pdfFileName: string;
  fileName: string;
}

interface IOutcomingWebViewSaveFileMessagePayload {
  fileName: string;
  mimeType: string;
  data: string;
}

interface IOutcomingWebViewConvertToPdfMessage
  extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.CONVERT_TO_PDF;
  payload: IOutcomingWebViewConvertToPdfMessagePayload;
}

interface IOutcomingWebViewRequestReviewMessage
  extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.REQUEST_REVIEW;
}

interface IOutcomingWebViewSaveFileMessage extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.SAVE_FILE;
  payload: IOutcomingWebViewSaveFileMessagePayload;
}

type OutcomingWebViewMessage =
  | IOutcomingWebViewLoginMessage
  | IOutcomingWebViewChangePinMessage
  | IOutcomingWebViewConvertToPdfMessage
  | IOutcomingWebViewRequestReviewMessage
  | IOutcomingWebViewSaveFileMessage;

export const enum IncomingWebViewMessageType {
  SET_BIOMETRY = "set_biometry",
  SET_IS_BIOMETRY_ENABLED = "set_is_biometry_enabled",
}

interface IWebViewMessageBase {
  type: IncomingWebViewMessageType;
  payload: unknown;
}

interface WebViewBiometryMessagePayload {
  isBiometryEnabled: boolean;
  biometryLoginType: Nullable<BiometryLoginType>;
}

type WebViewState = {
  isBiometryEnabled: boolean;
  biometryLoginType: Nullable<BiometryLoginType>;
};

type WebViewAction =
  | {
      type: IncomingWebViewMessageType.SET_BIOMETRY;
      payload: WebViewBiometryMessagePayload;
    }
  | {
      type: IncomingWebViewMessageType.SET_IS_BIOMETRY_ENABLED;
      payload: boolean;
    };

interface IWebViewBiometryMessage extends IWebViewMessageBase {
  type: IncomingWebViewMessageType.SET_BIOMETRY;
  payload: WebViewBiometryMessagePayload;
}

interface IWebViewIsBiometryEnabledMessage extends IWebViewMessageBase {
  type: IncomingWebViewMessageType.SET_IS_BIOMETRY_ENABLED;
  payload: boolean;
}

type WebViewMessage =
  | IWebViewBiometryMessage
  | IWebViewIsBiometryEnabledMessage;

export const initialState: WebViewState = {
  isBiometryEnabled: false,
  biometryLoginType: null,
};

export const webViewReducer = (
  state: WebViewState,
  action: WebViewAction
): WebViewState => {
  switch (action.type) {
    case IncomingWebViewMessageType.SET_BIOMETRY:
      return {
        ...state,
        isBiometryEnabled: action.payload.isBiometryEnabled,
        biometryLoginType: action.payload.biometryLoginType,
      };
    case IncomingWebViewMessageType.SET_IS_BIOMETRY_ENABLED:
      return {
        ...state,
        isBiometryEnabled: action.payload,
      };
    default:
      return state;
  }
};

export interface WebViewReviewCtxType {
  askForReview: SimpleFn;
  isBiometryEnabled: boolean;
  biometryLoginType: Nullable<BiometryLoginType>;
  toggleBiometry: (newState: boolean) => Promise<boolean>;
}

export const WebViewCtx = createContext<WebViewReviewCtxType>({
  askForReview: () => {},
  isBiometryEnabled: false,
  biometryLoginType: null,
  toggleBiometry: async () => false,
});

export const sendMessageToNativeApp = (message: OutcomingWebViewMessage) => {
  if (typeof window.ReactNativeWebView?.postMessage !== "function") return;
  const processedMessage = JSON.stringify(message);
  window.ReactNativeWebView.postMessage(processedMessage);
};

const WebViewInfo: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const [state, dispatch] = useReducer(webViewReducer, initialState);
  const [resolveToggle, setResolveToggle] = useState<
    ((success: boolean) => void) | null
  >(null);

  const onMessageHandler = useCallback(
    (e: Event) => {
      if (!(e instanceof MessageEvent)) return;
      const { type, payload } = e.data as WebViewMessage;

      switch (type) {
        case IncomingWebViewMessageType.SET_BIOMETRY:
          dispatch({ type, payload });
          break;
        case IncomingWebViewMessageType.SET_IS_BIOMETRY_ENABLED:
          dispatch({ type, payload });
          if (resolveToggle) {
            resolveToggle(true);
            setResolveToggle(null);
          }
          break;
        default:
          break;
      }
    },
    [resolveToggle]
  );

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", onMessageHandler);
    }

    return () => {
      document.removeEventListener("message", onMessageHandler);
    };
  }, [onMessageHandler]);

  const onAskForReviewHandler = () => {
    sendMessageToNativeApp({
      type: OutcomingWebViewMessageType.REQUEST_REVIEW,
    });
  };

  const toggleBiometry = (newState: boolean): Promise<boolean> => {
    return new Promise((resolve) => {
      setResolveToggle(() => resolve);
      sendMessageToNativeApp({
        type: OutcomingWebViewMessageType.TOGGLE_BIOMETRY,
        payload: newState,
      });
    });
  };

  if (!window.ReactNativeWebView) return children as ReactElement;

  return (
    <WebViewCtx.Provider
      value={{
        askForReview: onAskForReviewHandler,
        isBiometryEnabled: state.isBiometryEnabled,
        biometryLoginType: state.biometryLoginType,
        toggleBiometry,
      }}
    >
      {children}
    </WebViewCtx.Provider>
  );
};

export default WebViewInfo;
