import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useSelector } from "react-redux";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";
import { UrlResolverPageValues } from "web/Layout/UrlResolver/useResolveRoute";

import helpCenterCategory from "web/queries/default/helpCenterCategory.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IHelpCenterSpecificCategoryCategory } from "web/types/HelpCenter";

import { useAppContext } from "web/context/app";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export type HelpCenterCategoryPageProps = Pick<
  IHelpCenterSpecificCategoryCategory,
  "name" | "articles"
>;

interface IHelpCenterCategoryProps {
  id: UrlResolverPageValues["id"];
}

const HelpCenterCategoryPage: FC<HelpCenterCategoryPageProps> = (props) => {
  const { isMobile } = useAppContext();
  const { help_center_prefix: prefix } = useSelector(
    (state) => state.app.storeConfig
  );

  const Comp = isMobile ? Mobile : Desktop;

  return <Comp prefix={prefix} {...props} />;
};

const HelpCenterCategoryContainer: FC<IHelpCenterCategoryProps> = ({ id }) => {
  const { languageFromParam } = useLangFromParams();

  const { error, loading, data } = useQuery(helpCenterCategory, {
    variables: {
      categoryId: [id],
      lang: languageFromParam || getLanguageCode(),
    },
    fetchPolicy: "cache-and-network",
  });

  if (!loading && (!data || !Object.keys(data).length)) return <Loading />;

  if (error) {
    newRelicErrorReport(
      error,
      "web-app/web/Pages/HelpCenterCategory/container.js - 47"
    );
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  const category = data?.helpCenterCategory?.category?.[0];

  if (!category) return null;

  const { name, articles } = category;

  return <HelpCenterCategoryPage name={name} articles={articles} />;
};

export default HelpCenterCategoryContainer;
