import jsonParse from "web/utils/data/parser/string/jsonParse";
import convertBoolStringToBool from "web/utils/data/transform/convertBoolStringToBool";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import filterAgreementsById from "web/utils/page/customer/filterAgreementsById";
import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import configFieldNames from "web/constants/configFieldNames";
import { defaultMonthCountToDivide } from "web/constants/rentableGroups";
import restUrls from "web/constants/restUrls";
import storageNames from "web/constants/storageNames";

import { IAgreement } from "web/types/Agreement";
import {
  Functionality,
  IEmployee,
  IEmployeeConfig,
  IEmployeeConfigResponse,
  IEmployeeRentableGroupAdditionalData,
  IEmployeeRentableGroups,
  IGetEmployeeIntercom,
  IGetEmployeeRentableGroupAdditionalData,
  IHistoryCharges,
} from "web/types/Employee";

import api from "../api/apiSlice";
import {
  setDiscountCodes,
  setPrepaidCards,
} from "../customerMenu/customerMenuSlice";

const storage = new BrowserTemporality();
export const canEditIncomeKey = "canEditIncome";

const employeeDetailsRequestUrls = [
  restUrls.employeeDetails,
  restUrls.employeeRentableGroups,
];

export const employeeApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeDetails: builder.query<IEmployee, void>({
      queryFn: async (
        _,
        { dispatch },
        _extraOptions,
        fetchWithBQ
      ): Promise<{ data: IEmployee }> => {
        const [employee, employeeRentableGroups] = await Promise.all(
          employeeDetailsRequestUrls.map((url) => fetchWithBQ(url))
        );

        const {
          pathContentAbove,
          showRentableGroups,
          rentableGroupSelectionWindows: windows,
          rentableGroupId: id,
          rentableGroups: groups,
          calculatorRentableGroupDefault,
          calculatorRentableGroupHighest,
        } = (employeeRentableGroups?.data as IEmployeeRentableGroups) || {};

        const {
          msiCartsEnabled,
          mbActiveEnabled,
          alternativeStartPageDisabled,
          functionalities,
          subscriptionEmployerMaxPaidAmount,
        } = (employee?.data as IEmployee) || {};

        const sortedGroups = isArrayHasItems(groups)
          ? [...groups].sort((a, b) =>
              a.frontendName?.localeCompare(b.frontendName, undefined, {
                numeric: true,
              })
            )
          : null;

        const rentableGroupActive =
          isArrayHasItems(groups) && id
            ? groups.find((group) => group.id === id)
            : null;

        const hasPrepaidCardEnabled = functionalities?.includes(
          Functionality.ENABLE_PREPAID_CARDS
        );

        if (hasPrepaidCardEnabled) {
          dispatch(setPrepaidCards());
        }

        if (mbActiveEnabled) {
          dispatch(setDiscountCodes());
        }

        return {
          data: {
            rentableGroups: sortedGroups,
            rentableGroupActive,
            showRentableGroups,
            rentableGroupSelectionWindows: windows,
            pathContentAbove,
            msiCartsEnabled,
            mbActiveEnabled,
            alternativeStartPageDisabled,
            functionalities,
            subscriptionEmployerMaxPaidAmount,
            calculatorRentableGroupDefault,
            calculatorRentableGroupHighest,
          },
        };
      },
      providesTags: ["Employee"],
      async onCacheEntryAdded(arg, { cacheDataLoaded }) {
        const cache = await cacheDataLoaded;
        if (cache?.data?.rentableGroupSelectionWindows?.account) {
          storage.setItem(storageNames.agreements, canEditIncomeKey, true);
        }
      },
    }),
    updateEmployeeDetails: builder.mutation<
      void,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: restUrls.employeeUpdateDetails,
        body: JSON.stringify({
          rentableGroupId: id,
        }),
        headers: {
          "Content-type": "application/merge-patch+json",
        },
        method: "PATCH",
      }),
      invalidatesTags: ["Employee"],
    }),
    getEmployeeAgreements: builder.query<
      { agreements: IAgreement[]; filteredAgreements: IAgreement[] },
      void
    >({
      query: () => restUrls.getAgreements,
      transformResponse: (response: IAgreement[]) => {
        return {
          agreements: response,
          filteredAgreements: filterAgreementsById(response),
        };
      },
      providesTags: ["Employee_agreements"],
    }),
    getEmployeeConfiguration: builder.query<IEmployeeConfig, void>({
      query: () => restUrls.employeeConfiguration,
      transformResponse: (response: IEmployeeConfigResponse) => {
        const data: IEmployeeConfig = {
          rentableGroupConfirmationContent:
            response["rentable-group/selection-window/confirmation-content"],
          rentableGroupConfirmationContentSecondCheckbox:
            response[
              "rentable-group/selection-window/confirmation-content-second-checkbox"
            ],
          rentableGroupContentAbove:
            response["rentable-group/selection-window/content-above"],
          rentableGroupContentAboveForNew:
            response["rentable-group/selection-window/content-above-for-new"],
          rentableGroupHeaderPanel:
            response["rentable-group/selection-window/header-panel"],
          rentableGroupHeaderPopup:
            response["rentable-group/selection-window/header-popup"],
          rentableGroupTitle: response["rentable-group/selection-window/title"],
          rentableGroupAgreementsList: jsonParse(
            response["rentable-group/selection-window/agreements-list"],
            true
          ),
          rentableGroupCalculatorEnabled: convertBoolStringToBool(
            response["rentable-group/calculator/enable-calculator"]
          ),
          rentableGroupCalculatorMonthToDivide:
            +response["rentable-group/calculator/month-to-divide"] ||
            defaultMonthCountToDivide,
          rentableGroupCalculatorFillCheckbox: convertBoolStringToBool(
            response["rentable-group/calculator/fill-calculator-checkbox"]
          ),
          rentableGroupCalculatorFinancingCheckbox: convertBoolStringToBool(
            response["rentable-group/calculator/financing-checkbox"]
          ),
          subscriptionCompleteDataBlock:
            response["subscription/configurator/completeDataBlock"],
          consentAndDeclarations:
            response["subscription/configurator/consentAndDeclarations"],
          formEditInfoBlockEmployee:
            response["subscription/configurator/formEditInfoBlockEmployee"],
          formEditInfoBlockOthers:
            response["subscription/configurator/formEditInfoBlockOthers"],
          subscriptionHowItWorksBlock:
            response["subscription/configurator/howItWorksBlock"],
          bannerContent: response["subscription/generalConfig/bannerContent"],
          bannerForEmployeeWithoutDraft:
            response[
              "subscription/functionalities/bannerForEmployeeWithoutDraft"
            ],
          alternativeStartPageOverride:
            response[
              "subscription/functionalities/alternativeStartPageOverride"
            ],
          subscriptionEnableSubscriptions:
            response["subscription/generalConfig/enableSubscriptions"],
          enableSubscriptionsType:
            response["subscription/generalConfig/enableSubscriptionsType"],
          selectionIndividualWindowOpenFromDate:
            response[
              "subscription/generalConfig/selectionIndividualWindowOpenFromDate"
            ],
          selectionIndividualWindowOpenToDate:
            response[
              "subscription/generalConfig/selectionIndividualWindowOpenToDate"
            ],
          subscriptionMaxEmployeeAmount:
            response["subscription/generalConfig/maxEmployeeAmount"],
          subscriptionThresholdDay:
            response["subscription/generalConfig/tresholdDay"],
          subscriptionInfoBlock:
            response["subscription/generalConfig/subscriptionsInfoBlock"],
          completeDataBlock:
            response["subscription/configurator/completeDataBlock"],
          subscriptionsRequiredActivitiesInfoBlock:
            response[
              "subscription/configurator/subscriptionsRequiredActivitiesInfoBlock"
            ],
          showSubscriptionsInListing:
            response["subscription/generalConfig/showSubscriptionsInListing"],
          subscriptionsGroupInfoBlock:
            response["subscription/configurator/subscriptionsGroupInfoBlock"],
          // UMS Subscription START
          subscriptionCompleteDataBlockUMS:
            response["subscriptionUMS/configurator/completeDataBlock"],
          consentAndDeclarationsUMS:
            response["subscriptionUMS/configurator/consentAndDeclarations"],
          subscriptionHowItWorksBlockUMS:
            response["subscriptionUMS/configurator/howItWorksBlock"],
          bannerContentUMS:
            response["subscriptionUMS/generalConfig/bannerContent"],
          bannerForEmployeeWithoutDraftUMS:
            response[
              "subscriptionUMS/functionalities/bannerForEmployeeWithoutDraft"
            ],
          alternativeStartPageOverrideUMS:
            response[
              "subscriptionUMS/functionalities/alternativeStartPageOverride"
            ],
          subscriptionEnableSubscriptionsUMS:
            response["subscriptionUMS/generalConfig/enableSubscriptions"],
          subscriptionsForIndividualWindowsUMS:
            response[
              "subscriptionUMS/generalConfig/enableSubscriptionsForIndividualWindows"
            ],
          enableSubscriptionsTypeUMS:
            response["subscriptionUMS/generalConfig/enableSubscriptionsType"],
          selectionIndividualWindowOpenFromDateUMS:
            response[
              "subscriptionUMS/generalConfig/selectionIndividualWindowOpenFromDate"
            ],
          selectionIndividualWindowOpenToDateUMS:
            response[
              "subscriptionUMS/generalConfig/selectionIndividualWindowOpenToDate"
            ],
          subscriptionMaxEmployeeAmountUMS:
            response["subscriptionUMS/generalConfig/maxEmployeeAmount"],
          subscriptionThresholdDayUMS:
            response["subscriptionUMS/generalConfig/tresholdDay"],
          subscriptionInfoBlockUMS:
            response["subscriptionUMS/generalConfig/subscriptionsInfoBlock"],
          completeDataBlockUMS:
            response["subscriptionUMS/configurator/completeDataBlock"],
          subscriptionsRequiredActivitiesInfoBlockUMS:
            response[
              "subscriptionUMS/configurator/subscriptionsRequiredActivitiesInfoBlock"
            ],
          subscriptionsGroupInfoBlockUMS:
            response[
              "subscriptionUMS/configurator/subscriptionsGroupInfoBlock"
            ],
          formEditInfoBlockEmployeeUMS:
            response["subscriptionUMS/configurator/formEditInfoBlockEmployee"],
          formEditInfoBlockOthersUMS:
            response["subscriptionUMS/configurator/formEditInfoBlockOthers"],
          showSubscriptionsInListingUMS:
            response[
              "subscriptionUMS/generalConfig/showSubscriptionsInListing"
            ],
          // UMS Subscription END
          enableMonthlyDeductionInformation:
            response[
              "subscription/functionalities/enableMonthlyDeductionInformation"
            ],
          [configFieldNames.productWithFormDefaultAgreement]:
            response["product/product-with-form/default-agreement"],
          withdrawalOptions: {
            "product/withdrawal-policy/benefit-no-withdrawal":
              response["product/withdrawal-policy/benefit-no-withdrawal"],
            "product/withdrawal-policy/benefit-own-payment":
              response["product/withdrawal-policy/benefit-own-payment"],
            "product/withdrawal-policy/benefit-with-supplier":
              response["product/withdrawal-policy/benefit-with-supplier"],
            "product/withdrawal-policy/benefit-without-supplier":
              response["product/withdrawal-policy/benefit-without-supplier"],
            "product/withdrawal-policy/voucher-with-supplier":
              response["product/withdrawal-policy/voucher-with-supplier"],
            "product/withdrawal-policy/voucher-without-supplier":
              response["product/withdrawal-policy/voucher-without-supplier"],
            "product/withdrawal-policy/right-of-withdrawal":
              response["product/withdrawal-policy/right-of-withdrawal"],
            "product/withdrawal-policy/withdrawal-from-contract-payment-instrument":
              response[
                "product/withdrawal-policy/withdrawal-from-contract-payment-instrument"
              ],
          },
          rightOfWithdrawal:
            response["product/withdrawal-policy/right-of-withdrawal"],
          complaintsPolicy: response["product/default/complaints-policy"],
          fulfilmentRules: response["tourism/default-data/fulfilment-rules"],
          tourismRegulations: response["tourism/default-data/regulations"],
          tourismWithdrawal:
            response["tourism/default-data/withdrawal_from_contract"],
          tourismComplaints: response["tourism/default-data/complaints"],
          tourismOtherStatement:
            response["tourism/default-data/tourism_other_statement"],
          subscriptionAllowChangingPersonalData:
            response[
              "subscription/functionalities/allowChangingPersonalDataOnPWA"
            ],
          subscriptionAllowChangingPersonalDataUMS:
            response[
              "subscriptionUMS/functionalities/allowChangingPersonalDataOnPWA"
            ],
          declarationType: response["subscription/dynamicDeclaration/type"],
          declarationTypeUMS:
            response["subscriptionUMS/dynamicDeclaration/type"],
        };
        return data;
      },
      providesTags: ["Employee_configuration"],
    }),
    getEmployeePointsHistory: builder.query<
      { pointsHistory: IHistoryCharges },
      string
    >({
      query: (params) => `${restUrls.historyCharges}?${params}`,
      transformResponse: (response: string) => {
        return {
          pointsHistory: JSON.parse(response),
        };
      },
      providesTags: ["Employee_pointsHistory"],
    }),
    getEmployeeRentableGroupAdditionalData: builder.query<
      IGetEmployeeRentableGroupAdditionalData,
      void
    >({
      query: () => ({
        url: restUrls.employeeRentableGroupsAdditionalData,
      }),
      providesTags: ["Employee_RG_Additional_Data"],
    }),
    getEmployeeIntercom: builder.query<IGetEmployeeIntercom, void>({
      query: () => ({
        url: restUrls.employeeIntercom,
      }),
      providesTags: ["Employee_Intercom"],
    }),
    updateEmployeeRentableGroupAdditionalData: builder.mutation<
      void,
      { data: IEmployeeRentableGroupAdditionalData; isPatch: boolean }
    >({
      query: ({ data, isPatch }) => ({
        url: restUrls.employeeRentableGroupsAdditionalDataUpdate,
        body: isPatch ? JSON.stringify(data) : data,
        method: isPatch ? "PATCH" : "POST",
        headers: {
          "Content-type": isPatch
            ? "application/merge-patch+json"
            : "application/json",
        },
      }),
      invalidatesTags: ["Employee_RG_Additional_Data"],
    }),
  }),
});

export const {
  useGetEmployeeDetailsQuery,
  useGetEmployeeAgreementsQuery,
  useGetEmployeeConfigurationQuery,
  useLazyGetEmployeePointsHistoryQuery,
  useGetEmployeePointsHistoryQuery,
  useUpdateEmployeeDetailsMutation,
  useLazyGetEmployeeConfigurationQuery,
  useLazyGetEmployeeDetailsQuery,
  useGetEmployeeRentableGroupAdditionalDataQuery,
  useUpdateEmployeeRentableGroupAdditionalDataMutation,
  useGetEmployeeIntercomQuery,
} = employeeApiSlice;
