import { arrayOf, func, number, shape } from "prop-types";

import classes from "web/Pages/Product/Desktop/Gallery/gallery.scss";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

const Gallery = ({ firstImage, images, modalAction, count }) => {
  const rootClassName = isArrayHasItems(images)
    ? classes.root
    : classes.rootSingleImage;
  return (
    <div className={rootClassName}>
      <button
        type="button"
        className={classes.full}
        onClick={() => modalAction(0)}
        data-t1="gallery_modal_action_button"
        aria-label="Open gallery"
      >
        <LazyLoadImage
          src={firstImage.media_url}
          alt={firstImage.label}
          className={classes.image}
          notProcessed
        />
      </button>
      {isArrayHasItems(images) && (
        <aside className={classes.aside}>
          <div className={classes.thumbnails}>
            {images.map(({ label, media_url: mediaUrl }, index) => {
              return (
                <button
                  type="button"
                  className={classes.thumbnail}
                  onClick={() => modalAction(index + 1)}
                  key={mediaUrl}
                  data-t1="gallery_modal_add_action_button"
                >
                  <LazyLoadImage
                    src={mediaUrl}
                    alt={label}
                    className={classes.image}
                    width={390}
                    height={260}
                    notProcessed
                  />
                </button>
              );
            })}
          </div>
          <div className={classes.more}>
            <button
              type="button"
              className={classes.triggerMore}
              onClick={modalAction}
              data-t1="gallery_modal_action_see_all_button"
            >
              {__("Zobacz całą galerię")}
            </button>
            <p
              className={classes.availability}
              data-t1="gallery_avabile_images"
            >
              {__("Dostępne zdjęcia")}
              {": "}
              <span className={classes.count} data-t1="gallery_count">
                {count}
              </span>
            </p>
          </div>
        </aside>
      )}
    </div>
  );
};

Gallery.propTypes = {
  firstImage: shape({}).isRequired,
  images: arrayOf(shape({})),
  modalAction: func.isRequired,
  count: number.isRequired,
};

Gallery.defaultProps = {
  images: [],
};

export default Gallery;
