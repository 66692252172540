import { useField, useFormikContext } from "formik";
import { motion } from "framer-motion";
import { FC } from "react";

import type { DateFields } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import { DatepickerFormik } from "web/Layout/Datepicker";

import { dayMilliseconds } from "web/constants/date";

import { names } from "../domesticTourismFormFields";
import classes from "./dateFields.scss";

const variants = {
  open: {
    opacity: 1,
    height: "auto",
  },
  closed: {
    opacity: 0,
    height: 0,
  },
};
const transition = { duration: 0.2 };

interface IProductDateFieldsMobileProps {
  fields: DateFields;
  variant: "offer" | "listing" | "product";
  showErrorContainers?: boolean;
}

const ProductDateFieldsMobile: FC<IProductDateFieldsMobileProps> = ({
  fields,
  variant = "",
  showErrorContainers = true,
}) => {
  const { values } = useFormikContext<Record<string, string>>();

  const minDate =
    values[names.dateFrom] && +values[names.dateFrom]
      ? +values[names.dateFrom] + dayMilliseconds
      : Date.now() + dayMilliseconds;
  const maxDate =
    values[names.dateTo] && +values[names.dateTo]
      ? +values[names.dateTo] - dayMilliseconds
      : null;

  return (
    <div className={classes.root}>
      {fields.map(({ name, label, required, placeholder }) => {
        const [, meta] = useField({ name });
        const isError = meta.error && meta.touched;
        const errorClassName = isError ? classes.errorShown : classes.error;
        const [minDateValue, maxDateValue] =
          name === names.dateFrom ? ["today", maxDate] : [minDate, null];

        return (
          <div className={classes.wrapper} key={name}>
            <legend className={classes.badge}>{label}</legend>
            <div>
              <DatepickerFormik
                name={name}
                label={label}
                placeholder={placeholder}
                classes={{
                  labelText: required
                    ? classes.labelText_required
                    : classes.labelText,
                  calendar:
                    variant === "product"
                      ? classes.calendarProduct
                      : classes.calendar,
                }}
                className={classes.input}
                minDate={minDateValue}
                maxDate={maxDateValue}
              />
              {showErrorContainers && (
                <motion.div
                  className={errorClassName}
                  initial="closed"
                  variants={variants}
                  animate={isError ? "open" : "closed"}
                  transition={transition}
                >
                  {meta.error}
                </motion.div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductDateFieldsMobile;
