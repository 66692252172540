const getDataInOrder = <T>(data: T[], ids: (number | string)[]) => {
  return ids.reduce<T[]>((acc, currId) => {
    const dataItem = data.find(
      (item) => +(item as unknown as { id: string | number }).id === +currId
    );
    return dataItem ? [...acc, dataItem] : acc;
  }, []);
};

export default getDataInOrder;
