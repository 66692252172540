import { Button } from "@benefit-systems/common-components";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import Points from "web/Layout/Common/Points";
import __ from "web/Layout/Translations";

import PointsEqualIcon from "web/Components/Common/PointsEqualIcon";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";
import getSrcMedia from "web/utils/system/url/getSrcMedia";

import urls from "web/constants/urls";

import { BankItems } from "web/types/Banks";
import { Nullable } from "web/types/Utils";
import BanksList from "./BanksList";
import DeletionReminder from "./DeletionReminder";
import styles from "./myPoints.scss";
import { IReminder } from "../myPoints";

const webpStatus = canUseWebp();
const banksContainerClass = webpStatus
  ? styles.banksContainerWebp
  : styles.banksContainer;

interface IPageGeneratorMyPointsDesktopProps {
  banks?: BankItems;
  total?: number;
  reminderPointsDelete: Nullable<IReminder>;
  image: string;
}

const PageGeneratorMyPointsDesktop: FC<IPageGeneratorMyPointsDesktopProps> = ({
  banks = [],
  total = 0,
  reminderPointsDelete = null,
  image = "",
}) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const imageStyle = useMemo(() => {
    return image
      ? {
          backgroundImage: `url("${getSrcMedia({
            storeConfig,
            src: image,
          })}")`,
          backgroundColor: "transparent",
          backgroundSize: "cover",
        }
      : {};
  }, [image, storeConfig, getSrcMedia]);

  return (
    <section className={styles.root} data-t1="pageGeneratorSlider__slidePoints">
      <div className={banksContainerClass} style={imageStyle}>
        <div className={styles.pointsWrapper}>
          <div>
            <div className={styles.label}>{__("Moje punkty")}</div>
            <Points className={styles.pointsTotal} points={total} />
            <PointsEqualIcon badge={__("Równa wymiana")} />
          </div>
          <div className={styles.banks}>
            <div className={styles.label}>{__("Banki punktów")}</div>
            <BanksList pointsBankList={banks} size={6} />
          </div>
        </div>

        {
          // ? Why are these keys hardcoded? eg. bankName
          reminderPointsDelete ? (
            <>
              <div className={styles.label}>{__("Ważna Informacja")}</div>
              <DeletionReminder
                date="22.02.2021"
                bankName="ZFSS"
                points={1129}
              />
            </>
          ) : (
            <div className={styles.button}>
              <Button to={urls.customer}>
                {__("Sprawdż swoje banki punktów")}
              </Button>
            </div>
          )
        }
      </div>
    </section>
  );
};

export default PageGeneratorMyPointsDesktop;
