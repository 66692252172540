/* eslint-disable react/prop-types */
import { FC, useContext } from "react";

import { TourismObjectContext } from "web/Pages/Customer/Content/OrderDetails/ProductDetails/Shared/AnixeTourism/context";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading/loading";

import StarsRating from "web/Components/Common/StarsRating/starsRating";

import AgritourismIcon from "web/assets/icons/tourism/objectType/agritourism.svg";
import ApartmentIcon from "web/assets/icons/tourism/objectType/apartment.svg";
import CottagesAndCampsitesIcon from "web/assets/icons/tourism/objectType/cottages-and-campsites.svg";
import HolidayHouseIcon from "web/assets/icons/tourism/objectType/holiday-house.svg";
import HotelIcon from "web/assets/icons/tourism/objectType/hotel.svg";
import MotelIcon from "web/assets/icons/tourism/objectType/motel.svg";
import PensionIcon from "web/assets/icons/tourism/objectType/pension.svg";

import type { GroupedProduct } from "web/utils/page/product/universal/groupProducts";

import type { DomesticTourismProduct, MBProductType } from "web/types/Product";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import classes from "./tourismObjectInfo.scss";

const tourismIcons = {
  hotel: <HotelIcon />,
  pension: <PensionIcon />,
  apartment: <ApartmentIcon />,
  holiday_house: <HolidayHouseIcon />,
  cottages_and_campsites: <CottagesAndCampsitesIcon />,
  motel: <MotelIcon />,
  agritourism: <AgritourismIcon />,
};

const ratingClasses = { root: classes.stars, box: classes.box };

interface IProductTourismObjectInfoProps {
  className: string;
  product:
    | GroupedProduct<MBProductType.ANIXE_TOURISM>["full"]
    | DomesticTourismProduct;
  short?: boolean;
}

const ProductTourismObjectInfo: FC<IProductTourismObjectInfoProps> = ({
  className,
  product,
  short = false,
}) => {
  if (!product) {
    return null;
  }

  const {
    tourism_object_type: objectType,
    tourism_hotel_category_value: objectCategory,
  } = product;
  const { loading, error, data } =
    useContext(TourismObjectContext) ||
    useDataCachedAttributes({
      ids: ["tourism_object_type", "tourism_hotel_category"],
    });

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    default: {
      const tourismObject = data![0]
        ? data![0]?.attribute_options.find(
            (option) =>
              option.value === objectType || option.label === objectType
          )
        : null;
      const tourismIcon =
        tourismObject &&
        tourismIcons[tourismObject.code as keyof typeof tourismIcons];
      return tourismObject || objectCategory ? (
        <div
          className={`${className || classes.root} ${
            tourismObject ? classes.objectTypeWrapper : ""
          }`}
        >
          {short ? null : (
            <div className={classes.iconWrapper}>{tourismIcon}</div>
          )}
          {tourismObject && (
            <span className={classes.objectType}>{tourismObject?.label}</span>
          )}
          <StarsRating
            rating={+objectCategory / 10}
            classes={ratingClasses}
            count={+objectCategory / 10}
          />
        </div>
      ) : null;
    }
  }
};

export default ProductTourismObjectInfo;
