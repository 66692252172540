import { useParams } from "react-router-dom";

import __ from "web/Layout/Translations";

import ArrowLeftIcon from "web/assets/icons/arrow-left.svg";

import { CheckoutTypes } from "web/constants/urls";

import classes from "./splashHeaderMobile.scss";

type Params = {
  type: string;
};

const mobileDomesticTourismSteps = () => [
  {
    label: 1,
  },
  {
    label: 2,
  },
  {
    label: 3,
  },
  {
    label: 4,
  },
];

const mobileInternationalTourismSteps = () => [
  {
    label: 1,
  },
  {
    label: 2,
  },
  {
    label: 3,
  },
];

const mobileBuyNowSteps = () => [
  {
    label: 1,
  },
  {
    label: 2,
  },
  {
    label: 3,
  },
];

const mobileSteps = () => [
  {
    label: 1,
  },
  {
    label: 2,
  },
  {
    label: 3,
  },
];

const getStepsById = (type: string) => {
  switch (type) {
    case CheckoutTypes.DOMESTIC_TOURISM: {
      return mobileDomesticTourismSteps();
    }
    case CheckoutTypes.INTERNATIONAL_TOURISM: {
      return mobileInternationalTourismSteps();
    }
    case CheckoutTypes.BUY_NOW: {
      return mobileBuyNowSteps();
    }
    default: {
      return mobileSteps();
    }
  }
};

const SplashHeaderMobile = () => {
  const { type } = useParams<Params>();
  const links = getStepsById(type);

  return (
    <header className={classes.root}>
      <div className={classes.header}>
        <div className={classes.rootArrow}>
          <ArrowLeftIcon className={classes.arrow} />
          <span className={classes.headerTitle}>{__("Podsumowanie")}</span>
        </div>
        <div className={classes.nav}>
          {links.map((item) => {
            return (
              <>
                <span
                  key={item.label}
                  className={classes.item}
                  data-t1="navigation_list_item"
                >
                  <span
                    className={classes.connector}
                    data-t1="navigation_list_item_connector"
                  />

                  <span
                    data-t1="navigation_list_item_label"
                    className={classes.active}
                  >
                    {item.label}
                  </span>
                </span>
              </>
            );
          })}
        </div>
      </div>
    </header>
  );
};

export default SplashHeaderMobile;
