/* eslint-disable react/no-array-index-key, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { FC, MouseEvent, useCallback, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";

import Image from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import ArrowPrevIcon from "web/assets/icons/arrow-left.svg";
import ArrowNextIcon from "web/assets/icons/arrow-right.svg";

import isExternal from "web/utils/data/validator/url/isExternal";

import { IParsedParameter } from "web/types/PageGenerator";

import { useDispatch } from "react-redux";
import onGtmPromoClick from "web/utils/system/GTM/onGtmPromoClick";
import MyPoints from "../MyPoints";
import classes from "./slider.scss";

interface IPageGeneratorSliderDesktopProps {
  slides: IParsedParameter[];
  promoPosition: number;
}

const PageGeneratorSliderDesktop: FC<IPageGeneratorSliderDesktopProps> = ({
  slides = [],
  promoPosition = 0,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const onClickItemHandler = useCallback(
    (indexItem: number) => {
      const slide = slides && slides[indexItem];
      const link = slide && slide.link;
      if (link) {
        if (isExternal(link)) {
          window.location.href = link;
        } else {
          push(link?.charAt(0) !== "/" ? `/${link}` : link);
        }
      }
    },
    [push]
  );
  const updateCurrentSlide = useCallback(
    (index: number) => {
      if (currentSlide !== index) {
        setCurrentSlide(index);
      }
    },
    [currentSlide, setCurrentSlide]
  );

  const onPromoClick = ({ currentTarget }: MouseEvent<HTMLDivElement>) => {
    const { promoSrc = "", promoPosition = 0, link = "" } = currentTarget?.dataset || {};
    onGtmPromoClick({
      promoSrc,
      promoPosition,
      link,
      dispatch,
    });
  };

  const prevText = __("Poprzedni slajd");
  const nextText = __("Następny slajd");

  const currentSlideBGColor = slides[currentSlide]?.color_background;
  return (
    <section className={classes.root} data-t1="pageGeneratorSlider">
      <div
        className={classes.slider}
        style={{ backgroundColor: currentSlideBGColor }}
      >
        <button
          data-t1="pageGeneratorSlider__buttonPrev"
          className={classes.arrowPrev}
          onClick={() =>
            setCurrentSlide((state) => (state ? state - 1 : slides.length - 1))
          }
          type="button"
          title={prevText}
        >
          <ArrowPrevIcon className={classes.icon} />
        </button>
        <button
          data-t1="pageGeneratorSlider__buttonNext"
          className={classes.arrowNext}
          onClick={() =>
            setCurrentSlide((state) =>
              state === slides.length - 1 ? 0 : state + 1
            )
          }
          type="button"
          title={nextText}
        >
          <ArrowNextIcon className={classes.icon} />
        </button>
        <div className="container">
          <Carousel
            onClickItem={onClickItemHandler}
            swipeable
            emulateTouch
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            renderIndicator={(onClickHandler, isSelected, index) => {
              const { type, title, id, color_text: color } = slides[index];
              const dotClassName = isSelected ? classes.dotActive : classes.dot;
              const buttonBadge =
                type === "graphical" ? (
                  title
                ) : (
                  <span className={classes.dotWrapper}>{title}</span>
                );
              return (
                <button
                  className={dotClassName}
                  style={{
                    color,
                    backgroundColor: isSelected
                      ? currentSlideBGColor
                      : undefined,
                  }}
                  data-t1="pageGeneratorSlider__dot"
                  type="button"
                  key={id}
                  onClick={onClickHandler}
                >
                  {buttonBadge}
                </button>
              );
            }}
          >
            {slides.map(
              ({ id, type, graphic_file: imageSrc, title, link }, index) => {
                switch (type) {
                  case "graphical": {
                    return (
                      <div
                        className={classes.slide}
                        key={id}
                        data-t1="pageGeneratorSlider__slide"
                        data-promo-position={promoPosition + index}
                        data-promo-src={imageSrc}
                        data-link={link}
                        onClick={promoPosition ? onPromoClick : () => {}}
                      >
                        <LazyLoadComponent>
                          <Image
                            className={classes.image}
                            src={imageSrc}
                            alt={title}
                            width={1600}
                            height={600}
                          />
                        </LazyLoadComponent>
                      </div>
                    );
                  }
                  case "wallet": {
                    return <MyPoints key={index} image={imageSrc} />;
                  }
                  default:
                    return <></>;
                }
              }
            )}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default PageGeneratorSliderDesktop;
