import { FC, RefObject, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { Classes } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";
import { useCheckoutContext } from "web/context/checkout";

import CheckoutDesktopHeader from "./Desktop";
import CheckoutMobileHeader from "./Mobile";
import { ArePointsDisplayed, IsThankYouPage, Link } from "./Types/types";

interface ICheckoutHeaderContainerBase {
  links: Link[];
  classes?: Classes;
  arePointsDisplayed?: ArePointsDisplayed;
  isThankYouPage?: IsThankYouPage;
  logoRef?: RefObject<Nullable<HTMLImageElement>>;
}

const CheckoutHeaderContainer: FC<ICheckoutHeaderContainerBase> = ({
  links,
  classes = {},
  arePointsDisplayed = true,
  isThankYouPage = false,
  logoRef = null,
}) => {
  const { pathname, search } = useLocation();
  const { isMobile } = useAppContext();
  const { setEditProductId, toggleEditMode } = useCheckoutContext();

  const resetEditAction = useCallback(() => {
    if (
      typeof setEditProductId === "function" &&
      typeof toggleEditMode === "function"
    ) {
      setEditProductId(null);
      toggleEditMode(false);
    }
  }, [setEditProductId, toggleEditMode]);

  return isMobile ? (
    <CheckoutMobileHeader links={links} pathname={pathname} search={search} />
  ) : (
    <CheckoutDesktopHeader
      links={links}
      pathname={pathname}
      search={search}
      classes={classes}
      logoRef={logoRef || null!}
      arePointsDisplayed={arePointsDisplayed}
      resetEditing={resetEditAction}
      isThankYouPage={isThankYouPage}
    />
  );
};

export default CheckoutHeaderContainer;
