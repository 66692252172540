import { useSelector } from "react-redux";

import msCategoriesQuery from "web/queries/ms/categoriesByIds.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { rootCategoriesIds } from "web/constants/categories";

import { ICategory } from "web/types/Category";

import { useAppContext } from "web/context/app";

import {
  addCategoryEntries,
  removeCategoryEntries,
  selectEntities,
} from "../categories/categoriesSlice";
import useDataCached from "./useDataCached";

const mapData = new MapWrapper();

const TTL = process.env.CATEGORIES_CACHE_TTL
  ? +process.env.CATEGORIES_CACHE_TTL
  : undefined;

const useDataCachedCategories = ({ ids }: { ids: number[] }) => {
  const { isPrefetching } = useSelector((state) => state.categories);
  // temporary - switch checking to webview again
  const { isMobile } = useAppContext();

  const { data, loading, error } = useDataCached<ICategory>({
    ids: ids.filter((id) => !rootCategoriesIds.includes(+id)).map((id) => +id),
    query: msCategoriesQuery,
    addToCacheAction: addCategoryEntries,
    removeFromCacheAction: removeCategoryEntries,
    selectEntities,
    mapData,
    TTL,
    skip: isPrefetching,
    variables: {
      isMobile,
    },
  });

  return isPrefetching
    ? { data: [], error: null, loading: true }
    : { data: data?.filter(({ is_active }) => is_active), loading, error };
};

export default useDataCachedCategories;
