import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";

export const InternetConnectionCtx = createContext({
  isConnected: false,
  isDisconnectedWebView: false,
});

const InternetConnection: FC<PropsWithChildren> = ({ children }) => {
  const [isConnected, setIsConnected] = useState(true);

  const onConnectHandler = () => {
    setIsConnected(true);
  };

  const onDisconnectHandler = () => {
    setIsConnected(false);
  };

  useEffect(() => {
    window.addEventListener("online", onConnectHandler);
    window.addEventListener("offline", onDisconnectHandler);

    return () => {
      window.removeEventListener("online", onConnectHandler);
      window.removeEventListener("offline", onDisconnectHandler);
    };
  }, []);

  return (
    <InternetConnectionCtx.Provider
      value={{
        isConnected,
        isDisconnectedWebView: !isConnected && !!window.ReactNativeWebView,
      }}
    >
      {children}
    </InternetConnectionCtx.Provider>
  );
};

export default InternetConnection;
