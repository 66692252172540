import { useAppContext } from "web/context/app";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const DomesticTourismFormRoomFields = () => {
  const { isMobile } = useAppContext();

  return isMobile ? <Mobile /> : <Desktop />;
};

export default DomesticTourismFormRoomFields;
