export const names = {
  location: "tourism_location",
  rooms: "tourism_rooms",
  dateFrom: "tourism_date_from",
  dateTo: "tourism_date_to",
};

export const roomField = {
  name: names.rooms,
  rules: [],
};

export const locationField = {
  component: "field",
  name: names.location,
  type: "text",
  label: "Lokalizacja",
  placeholder: "Wpisz lokalizację lub nazwę obiektu",
  initialValue: "",
  rules: [],
};

export const dateFields = [
  {
    name: names.dateFrom,
    type: "text",
    label: "Przyjazd",
    placeholder: "Data od",
    initialValue: "",
    required: true,
    rules: ["required"],
  },
  {
    name: names.dateTo,
    type: "text",
    label: "Wyjazd",
    placeholder: "Data do",
    initialValue: "",
    required: true,
    rules: ["required"],
  },
];
