/* eslint-disable @typescript-eslint/ban-ts-comment */
import DOMPurify from "dompurify";
import Parser, { Comment, DOMNode, Element } from "html-react-parser";

import SetScript from "web/Pages/PageGenerator/utils/setScript";
import SetScriptFromSrc from "web/Pages/PageGenerator/utils/setScriptFromSrc";
import SetStyleFromSrc from "web/Pages/PageGenerator/utils/setStyleFromSrc";

import useHTMLLink from "web/hooks/useHTMLLink";

import jsonParse from "web/utils/data/parser/string/jsonParse";

import { FC } from "react";
import { IParsedParameter, IParsedParameters } from "web/types/PageGenerator";
import ErrorBoundary from "../../../Layout/ErrorBoundary";

const retrieveFunctionFromText = (domNode: DOMNode) => {
  if ((domNode as Element).children && (domNode as Element).children[0]) {
    const rawText = (domNode as Element).children[0];
    if (
      rawText.type === "text" &&
      (rawText as Comment).data &&
      typeof (rawText as Comment).data === "string"
    ) {
      return (rawText as Comment).data;
    }
  }
  return null;
};

const parseHtml = (content: string | Node | IParsedParameter, id: number, scripts: React.JSX.Element[]) => {
  const parserOptions = {
    replace(domNode: DOMNode) {
      if ((domNode as Element).name === "script") {
        const textToConvertToFunction = retrieveFunctionFromText(domNode);
        const scriptTag = textToConvertToFunction && (
          <SetScript
            key={`lp-generator-admin-scripts${id}`}
            js={textToConvertToFunction}
          />
        );
        scripts.push(scriptTag as React.JSX.Element);

        if ((domNode as Element).attribs && (domNode as Element).attribs.src) {
          const scriptSrc = (
            <SetScriptFromSrc
              key={`lp-generator-admin-scripts${id}`}
              src={(domNode as Element).attribs.src}
              async={!!(domNode as Element).attribs.async}
            />
          );
          scripts.push(scriptSrc);
        }
      }

      if ((domNode as Element).name === "link" && (domNode as Element).attribs && (domNode as Element).attribs.href) {
        const styleSrc = (
          <SetStyleFromSrc
            key={`lp-generator-admin-scripts${id}`}
            href={(domNode as Element).attribs.href}
          />
        );
        scripts.push(styleSrc);
      }

      return null;
    },
  };

  if (typeof content === "string") {
    Parser(content, parserOptions);
  }
};

interface IPageGeneratorHtmlExtendedProps {
  params: string;
  isMobile: boolean;
  id: number;
}

const PageGeneratorHtmlExtended: FC<IPageGeneratorHtmlExtendedProps> = ({ params, id, isMobile }) => {
  const contentRef = useHTMLLink();

  const scripts: React.JSX.Element[] = [];
  const data: IParsedParameters = jsonParse(params);
  const style = isMobile
    ? {
        marginTop: "30px",
        marginBottom: "30px",
      }
    : {
        marginTop: "60px",
        marginBottom: "60px",
      };

  if (data?.content) {
    parseHtml(data.content, id, scripts);
    return (
      <>
        <div
          data-t1="pageGeneratorHtmlExtended"
          style={style}
          className="container"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.content as string | Node),
          }}
          // @ts-ignore
          ref={contentRef}
        />
        {scripts}
      </>
    );
  }
  return null;
};

interface IPageGeneratorHtmlExtendedContainerErrorProps {
  params: string;
  isMobile?: boolean;
  id: number;
}

const PageGeneratorHtmlExtendedContainerError: FC<IPageGeneratorHtmlExtendedContainerErrorProps> = ({ isMobile = false, params, id }) => {
  return (
    <ErrorBoundary>
      <PageGeneratorHtmlExtended isMobile={isMobile} id={id} params={params} />
    </ErrorBoundary>
  );
};

export default PageGeneratorHtmlExtendedContainerError;
