import DateBox from "web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard/Aside/PrepaidCardAside/DateBox";
import {
  OrderNameType,
  OrderPaymentType,
  OrderPrepaidCardType,
} from "web/Pages/Customer/Content/PrepaidCards/types";
import { getTotalAmount } from "web/Pages/Customer/Content/PrepaidCards/utils";

import { ImageSource } from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

import classes from "./details.scss";

type DetailsProps = {
  prepaidCard: OrderPrepaidCardType;
  name: OrderNameType;
  employeeTotalAmount: number;
  payments: OrderPaymentType[];
  dataT1?: string;
};

const Details = ({
  prepaidCard,
  name,
  employeeTotalAmount,
  payments,
  dataT1 = "details",
}: DetailsProps) => {
  const title =
    name === "ORDER"
      ? `${__("Nowa karta przedpłacona")} + ${__("zasilenie")}`
      : __("Zasilenie karty przedpłaconej");

  const chargeAmount = getTotalAmount(payments, "CHARGE", "EMPLOYEE");
  const issuanceFeeAmount = getTotalAmount(
    payments,
    "ISSUANCE_FEE",
    "EMPLOYEE"
  );
  const rechargeCommissionAmount = getTotalAmount(
    payments,
    "RECHARGE_COMMISSION",
    "EMPLOYEE"
  );

  return (
    <div className={classes.root} data-t1={dataT1}>
      {prepaidCard?.imageUrl && (
        <ImageSource
          src={prepaidCard.imageUrl}
          className={classes.image}
          alt={name}
          dataT1="details_image"
        />
      )}
      <div className={classes.content}>
        <span className={classes.category}>{__("Karty przedpłacone")}</span>
        <h2 className={classes.title}>{title}</h2>
        <div className={classes.cardNumberAndDate}>
          {prepaidCard?.cardNumber && (
            <p className={classes.cardNumber}>
              {__("Numer karty")}: <span>{prepaidCard.cardNumber}</span>
            </p>
          )}

          {prepaidCard?.chargeDeadlineDate && (
            <DateBox
              label={__("Termin możliwości zasilenia karty:")}
              className={classes.date}
              date={prepaidCard.chargeDeadlineDate}
            />
          )}
        </div>
      </div>
      <div className={classes.cardDataContainer}>
        {employeeTotalAmount && <p>{formatNumber(employeeTotalAmount)}</p>}

        {chargeAmount && chargeAmount > 0 && (
          <p>
            {__("Kwota zasilenia")}: {formatNumber(chargeAmount)}
          </p>
        )}

        {issuanceFeeAmount && issuanceFeeAmount > 0 && (
          <p>
            {__("Opłata za wydanie karty")}: {formatNumber(issuanceFeeAmount)}
          </p>
        )}

        {rechargeCommissionAmount && rechargeCommissionAmount > 0 && (
          <p>
            {__("Prowizja za zasilenie")}:{" "}
            {formatNumber(rechargeCommissionAmount)}
          </p>
        )}
      </div>
    </div>
  );
};

export default Details;
