// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalClasses-root-OfX{z-index:199;display:block;position:fixed;top:0;left:0;right:0;bottom:0;outline:0;background-color:#fff}.modalClasses-content-Awf{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./web/Pages/Product/Desktop/Gallery/modalClasses.scss"],"names":[],"mappings":"AAIuB,uBACrB,WAAA,CACA,aAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,SAAA,CACA,qBAAA,CAGF,0BACE,UAAA,CACA,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  z-index: 199;\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  outline: 0;\n  background-color: white;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `modalClasses-root-OfX`,
	"content": `modalClasses-content-Awf`
};
export default ___CSS_LOADER_EXPORT___;
