import { IApiRequest } from "./apiRequestTypes";

const inflight = new Map();
const keyCache = new WeakMap();

const requestToKey = (req: IApiRequest) => {
  let key = keyCache.get(req);
  if (!key) {
    const { method, body } = req.opts;
    const parts = [method, req.resourceUrl];
    if (body) {
      parts.push(body);
    }
    key = parts.join("|||");
    keyCache.set(req, key);
  }
  return key;
};

export function match(req: IApiRequest) {
  return inflight.get(requestToKey(req));
}

export function store(req: IApiRequest) {
  inflight.set(requestToKey(req), req);
}

export function remove(req: IApiRequest) {
  if (match(req) === req) {
    inflight.delete(requestToKey(req));
  }
}
