import __ from "web/Layout/Translations";
import { IParsedTourismOrderItem } from "web/types/Order";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import getRoomText from "web/utils/page/product/domesticTourism/getRoomText";

const getAdultText = (count: number) =>
  count === 1 ? `${count} ${__("dorosły")}` : `${count} ${__("dorosłych")}`;
const getChildrenText = (count: number) =>
  count === 1 ? `${count} ${__("dziecko")}` : `${count} ${__("dzieci")}`;

export default (quote: IParsedTourismOrderItem) => {
  const roomCount = quote.product_data.room.length;
  const roomText = getRoomText(roomCount);
  const { adults, children } = quote.product_data.room.reduce(
    (result, current) => {
      const currentAdultsCount = Number(current.occupancy_adult);
      const currentChildrenCount = Number(current.occupancy_children);
      return {
        adults: result.adults + currentAdultsCount,
        children: result.children + currentChildrenCount,
      };
    },
    { adults: 0, children: 0 }
  );
  const adultText = getAdultText(adults);
  const childrenText = getChildrenText(children);
  const peopleText = `${adultText}, ${childrenText}`;

  return [
    {
      value: roomText,
    },
    {
      value: peopleText,
    },
    {
      value: `${formatDate(quote.tourism_offer_data.date_from)} - ${formatDate(
        quote.tourism_offer_data.date_to
      )}`,
    },
  ];
};
