import { Button } from "@benefit-systems/common-components";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import __ from "web/Layout/Translations";

import classify from "web/classify";

import { BooleanLiteral } from "typescript";
import { FC } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultStyles from "./banner.scss";

interface IPageGeneratorBannerProps {
  url?: string;
  imageSrc?: string;
  alt?: string;
  buttonTitle?: string;
  isButtonVisible?: BooleanLiteral;
}

const PageGeneratorBanner: FC<PropsWithClasses<IPageGeneratorBannerProps>> = ({
  imageSrc = "",
  alt = "",
  url = "none",
  classes = {},
  buttonTitle = "",
  isButtonVisible = true,
}) => {
  return (
    <div className={classes.root}>
      {imageSrc && (
        <LazyLoadImage
          className={classes.image}
          src={imageSrc}
          alt={alt}
          isAutoresized={false}
        />
      )}
      {isButtonVisible && (
        <Button className={classes.link} to={url}>
          {__(buttonTitle || "Zobacz więcej")}
        </Button>
      )}
    </div>
  );
};

export default classify(defaultStyles)(PageGeneratorBanner);
