import { useLocation } from "react-router-dom";

import CodeList from "web/Pages/Customer/Content/CodeList";

import removeHashFromString from "web/utils/data/parser/string/removeHashFromString";

import { hash as urlHash } from "web/constants/urls";

import OtherRealization from "./OtherRealization";
import Subscriptions from "./Subscriptions";

const ProductQuickListContent = () => {
  const { hash } = useLocation();
  const hashProcessed = removeHashFromString(hash);

  switch (hashProcessed) {
    case urlHash.customerSubscriptions: {
      return <Subscriptions />;
    }
    case urlHash.customerOtherRealization: {
      return <OtherRealization />;
    }
    default:
      return <CodeList title="" />;
  }
};

export default ProductQuickListContent;
