import { FC } from "react";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

interface ProvisionRowProps {
  type?: string;
  rechargeCommission?: number;
  rechargeCommissionPayer?: string;
  className?: string;
  label?: string;
}

const ProvisionRow: FC<ProvisionRowProps> = ({
  rechargeCommission = null,
  rechargeCommissionPayer = "",
  className = "",
  label = "",
  type = "",
}) => {
  const provision = `${formatNumber(rechargeCommission)} ${__("pkt")}`;

  return (
    <>
      {rechargeCommissionPayer && rechargeCommissionPayer === "EMPLOYEE" ? (
        <div
          className={className}
          style={{ marginBottom: type === "CHARGE" ? 30 : 0 }}
        >
          <p>{label}</p>
          <p>{provision}</p>
        </div>
      ) : null}
    </>
  );
};

export default ProvisionRow;
