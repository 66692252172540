/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isString } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import CheckoutHeader from "web/Pages/Checkout/Header";

import Loading from "web/Layout/Common/Loading";
import GtmEvent from "web/Layout/Gtm/GtmEvent";
import __ from "web/Layout/Translations";

import LogoSignIcon from "web/assets/icons/logo-sign.svg";

import useGetProductOptions from "web/hooks/useGetProductOptions";
import { IProductOption } from "web/hooks/useGetProductOptions/useGetProductOptions";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getProductInfo from "web/utils/page/product/universal/getProductInfo";
import parseOrderDetailResult from "web/utils/page/product/universal/parseOrderDetailResult";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import { defaultSplashPageTimeout } from "web/constants/orders";
import restUrls from "web/constants/restUrls";

import { ICartItemProductData, ICartParentProductData } from "web/types/Cart";
import {
  IGtm,
  IGtmCheckoutProduct,
  IGtmEcommerceImpression,
} from "web/types/Gtm";
import { IOrder, IOrderDetails } from "web/types/Order";
import { IPaymentMethodItem } from "web/types/Payment";
import { MBProductType } from "web/types/Product";
import { IStoreConfig } from "web/types/StoreConfig";

import { request } from "web/api";
import { useAppContext } from "web/context/app";

import SplashHeaderMobile from "./SplashHeaderMobile";
import classes from "./splash.scss";

const Splash = () => {
  const { search } = useLocation();
  const { isMobile } = useAppContext();
  const [order, setOrder] = useState<
    IOrderDetails<MBProductType> | IOrder<MBProductType> | null
  >(null);
  const { base_currency_code: baseCurrencyCode } = useSelector(
    (state) => state.app.storeConfig
  ) as IStoreConfig;
  const { push } = useHistory();

  const getRedirectURL = getSearchParameterAll({
    name: "redirectUrl",
    search,
  });

  const getPaymentType = getSearchParameterAll({
    name: "type",
    search,
  });

  const getToCheckoutSuccess = getSearchParameterAll({
    name: "toCheckoutSuccess",
    search,
  });

  const getOrderId = getSearchParameterAll({
    name: "orderId",
    search,
  });

  const [redirectURL] = getRedirectURL;
  const [paymentType] = getPaymentType;
  const [paymentToCheckoutSuccess] = getToCheckoutSuccess;
  const [orderId] = getOrderId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [orderDetail] = await Promise.all([
          request(
            restUrls.customerOrderDetails.replace(":orderId", orderId)
          ) as IOrderDetails<MBProductType> | IOrder<MBProductType>,
        ]);
        setOrder(
          parseOrderDetailResult(orderDetail) as
            | IOrderDetails<MBProductType>
            | IOrder<MBProductType>
        );
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/Pages/Checkout/Splash/splash.js - 77"
        );
        console.error(error);
      }
    };

    fetchData();
  }, [orderId]);

  /**
   * this is a fallback
   * if for some reason gtm options aren't ready after default time,
   * we still want to redirect to payment/success page
   */
  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      redirectToPaymentOrSuccess(false);
    }, defaultSplashPageTimeout);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  const productsOptionsData = isArrayHasItems(order?.items)
    ? order?.items.map((itemData) => {
        const itemParentProductData = isString(itemData?.parent_product_data)
          ? (jsonParse(itemData.parent_product_data) as ICartParentProductData)
          : (itemData.parent_product_data as ICartParentProductData);
        const itemProductData = isString(itemData?.product_data)
          ? (jsonParse(itemData.product_data) as ICartItemProductData)
          : (itemData.product_data as ICartItemProductData);

        const productObj = {
          productId: itemProductData?.entity_id || "",
          productCategoryId: +itemProductData?.main_category_id,
          productAttributesId: itemParentProductData?.super_attributes
            ? itemParentProductData?.super_attributes
            : [{}],
          productCategoriesIds: itemProductData?.category_ids,
        };
        return productObj;
      })
    : null;

  const [finalProductOptions, isReady] = useGetProductOptions(
    // @ts-ignore
    productsOptionsData as IProductOption[]
  );

  const dataLayerTemp = window.dataLayer ? [...window.dataLayer].reverse() : [];

  const lastCheckoutSummaryEvent =
    dataLayerTemp.find((element) => element.event === "checkoutSummary") ||
    ({} as IGtm);

  const {
    date_from: dateFrom,
    date_to: dateTo,
    amount_people: peopleAmount,
  } = lastCheckoutSummaryEvent?.ecommerce?.checkout?.products?.[0] || {};

  const gtmOrderProducts =
    isArrayHasItems(order?.items) && isReady
      ? order?.items.map((itemData) => {
          const lastEventProduct =
            lastCheckoutSummaryEvent?.ecommerce?.checkout?.products?.find(
              (prod) => {
                return Number(prod.id) === itemData.product_id;
              }
            );

          const { category, variant, fullCategoriesPaths } = getProductInfo(
            finalProductOptions,
            itemData
          );
          const itemParentProductData = isString(itemData?.parent_product_data)
            ? (jsonParse(
                itemData.parent_product_data
              ) as ICartParentProductData)
            : (itemData.parent_product_data as ICartParentProductData);
          const itemProductData = isString(itemData?.product_data)
            ? (jsonParse(itemData.product_data) as ICartItemProductData)
            : (itemData.product_data as ICartItemProductData);

          const productInfo = {
            name: itemParentProductData?.name || itemData?.name || "",
            id:
              itemParentProductData?.product_entity_id?.toString() ||
              itemProductData?.entity_id?.toString() ||
              "",
            price: itemData?.price_incl_tax?.toString() || "",
            brand:
              itemProductData?.supplier_name?.replace(/(<([^>]+)>)/gi, "") ||
              "",
            category: lastEventProduct?.category || category,
            variant,
            quantity: itemData?.qty_ordered || "",
            ...(itemProductData?.tourism_city
              ? { location_name: itemProductData.tourism_city }
              : {}),
            ...(dateFrom ? { date_from: dateFrom } : {}),
            ...(dateTo ? { date_to: dateTo } : {}),
            ...(peopleAmount ? { amount_people: peopleAmount } : {}),
            dimension16: lastEventProduct?.dimension16 || fullCategoriesPaths,
          } as IGtmEcommerceImpression;

          const paymentMethods = isString(itemData.payment_method)
            ? (jsonParse(itemData.payment_method) as IPaymentMethodItem[])
            : (itemData.payment_method as IPaymentMethodItem[]);
          paymentMethods?.forEach((paymentItem) => {
            let pointsPayment = 0;

            paymentItem.values?.forEach((paymentMethodType) => {
              if (paymentMethodType?.type === "BANK_OF_POINTS") {
                pointsPayment += +paymentMethodType.value;
                productInfo.metric1 = pointsPayment;
              }

              if (paymentMethodType?.type === "ONLINE_PAYMENT") {
                productInfo.metric2 = +paymentMethodType.value;
              }
            });
          });

          return productInfo;
        })
      : [];

  const gtmSortedOptions = gtmOrderProducts?.reduce(
    (result: IGtmEcommerceImpression[], current) => {
      const isAlready = result?.find((el) => el.id === current.id);
      if (isAlready) {
        // @ts-ignore
        (isAlready as IGtmEcommerceImpression).quantity += 1;
        return [...result];
      }
      return [...result, current];
    },
    []
  );

  const lastCheckoutSuccessEvent =
    dataLayerTemp.find((element) => element.event === "checkoutSuccess") ||
    ({} as IGtm);

  const gtmEnhancedEcommOptions = useMemo(
    () => ({
      ecommerce: {
        currencyCode: baseCurrencyCode || "",
        purchase: {
          actionField: {
            id:
              order?.entity_id?.toString() || order?.order_id?.toString() || "",
            affiliation: "",
            revenue: order?.total || "",
          },
          products:
            lastCheckoutSummaryEvent?.ecommerce?.checkout?.products ||
            gtmSortedOptions,
        },
      },
    }),
    [baseCurrencyCode, order, lastCheckoutSummaryEvent, gtmOrderProducts]
  ) as IGtm;

  const renderHeader = () => {
    let headerComponent;
    if (isMobile) {
      headerComponent = <SplashHeaderMobile />;
    } else if (!isMobile && paymentType === "default") {
      headerComponent = <CheckoutHeader isThankYouPage />;
    } else if (!isMobile) {
      headerComponent = <CheckoutHeader isThankYouPage />;
    }

    return headerComponent;
  };

  const redirectToPaymentOrSuccess = (isJSX: boolean) => {
    // if online payment, redirect to vendors website
    if (redirectURL?.includes("http")) {
      window.location.href = redirectURL;
      return null;
    }

    // else redirect to summary page
    return isJSX ? <Redirect to={redirectURL} /> : push(redirectURL);
  };

  return order ? (
    <div className={classes.root}>
      {renderHeader()}
      <div className={classes.container}>
        {paymentToCheckoutSuccess ? (
          <span className={classes.header}>
            {__(
              "Za chwilę zostaniesz przekierowany na stronę z potwierdzeniem zamówienia."
            )}
          </span>
        ) : (
          <>
            <span className={classes.header}>
              {__(
                "Za chwilę zostaniesz przekierowany na stronę płatności online."
              )}
            </span>
            <span className={classes.description}>
              {__("Jeśli chcesz przejść na tę stronę teraz, kliknij ")}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href={redirectURL} className={classes.link}>
                {__("Tutaj")}
              </a>
            </span>
          </>
        )}
        <div className={classes.logoContainer}>
          <LogoSignIcon className={classes.logo} />
        </div>
      </div>
      {isReady &&
        lastCheckoutSummaryEvent?.ecommerce?.checkout?.products.length &&
        lastCheckoutSuccessEvent?.ecommerce?.purchase?.actionField?.id !==
          gtmEnhancedEcommOptions?.ecommerce?.purchase?.actionField?.id &&
        (
          gtmEnhancedEcommOptions?.ecommerce?.purchase
            ?.products as IGtmCheckoutProduct[]
        )?.every((productObject) => productObject.category) && (
          <>
            <GtmEvent
              eventName="checkoutSuccess"
              options={gtmEnhancedEcommOptions as IGtm}
              isReady={isReady}
            />
            {redirectToPaymentOrSuccess(true)}
          </>
        )}
    </div>
  ) : (
    <Loading />
  );
};

export default Splash;
