import {
  ActionCreatorWithPayload,
  Dictionary,
  EntityId,
} from "@reduxjs/toolkit";

import msProductsFullQuery from "web/queries/ms/productsByIds.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { Product } from "web/types/Product";

import type { RootState } from "web/store";

import {
  addProductsEntries,
  removeProductsEntries,
  selectEntities,
} from "../products/productsSlice";
import useDataCached from "./useDataCached";
import { ICachedItem } from "./utils/addTimeExpiration";

const mapData = new MapWrapper();

const useDataCachedProducts = ({
  ids,
  skip,
}: {
  ids: (number | string)[];
  skip?: boolean;
}) =>
  useDataCached<Product>({
    ids: ids.map(id => +id),
    query: msProductsFullQuery,
    addToCacheAction: addProductsEntries as ActionCreatorWithPayload<
      readonly ICachedItem<Product>[] | Record<EntityId, ICachedItem<Product>>,
      "products/addProductsEntries"
    >,
    removeFromCacheAction: removeProductsEntries,
    selectEntities: selectEntities as (
      state: RootState
    ) => Dictionary<ICachedItem<Product>>,
    isFullProduct: true,
    mapData,
    skip,
  });

export default useDataCachedProducts;
