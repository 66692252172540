import isValidToken from "web/utils/system/essentials/isValidToken";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import restUrls from "web/constants/restUrls";
import storageNames from "web/constants/storageNames";

import { ICustomer } from "web/types/Customer";
import { Email, Nullable } from "web/types/Utils";

import api from "../api/apiSlice";
import { processCustomerPayload } from "./customerUtils";

const storage = new BrowserPersistence();

interface ICustomerProfile {
  gender: string;
  ageRangeFrom: Nullable<string>;
  ageRangeTo: Nullable<string>;
  favoriteCites: string[];
  favoriteCategories: number[];
  favoriteOther: unknown;
}
interface ICustomerState {
  id: string | number;
  groupId: number;
  storeId: number;
  websiteId: number;
  email: string;
  contactEmail: Nullable<Email>;
  firstname: string;
  lastname: string;
  city: string;
  login: string;
  phone: Nullable<string>;
  province: Nullable<string>;
  language: string;
  profile: ICustomerProfile;
  isSubscribed: boolean;
  ssoRole: string;
  impersonated: Nullable<boolean>;
  createdAt: string;
}

export const customerApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerDetails: builder.query<ICustomerState, void>({
      query: () => restUrls.customer,
      providesTags: ["Customer"],
      transformResponse: (response: ICustomer) => {
        const dataProcessed = processCustomerPayload(response);
        return dataProcessed;
      },
    }),
    changeCustomerPassword: builder.mutation<
      void,
      { currentPassword: string; password: string }
    >({
      query: (data) => ({
        url: restUrls.changePassword,
        body: {
          currentPassword: data?.currentPassword,
          newPassword: data?.password,
          confirmation: data?.password,
        },
        headers: {
          authorization: `Bearer ${storage.getItem(storageNames.tokenAccess)}`,
        },
        method: "POST",
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomer: builder.mutation<
      ICustomerState,
      {
        id?: number;
        contactEmail?: string;
        phone?: string;
        city?: string;
        province?: string;
        language?: string;
      }
    >({
      query: (data) => ({
        url: restUrls.customer,
        body: {
          customer: {
            id: data?.id,
            contact_email: data?.contactEmail,
            phone: data?.phone,
            city: data?.city,
            province: data?.province,
            language: data?.language,
          },
        },
        headers: {
          authorization: `Bearer ${storage.getItem(storageNames.tokenAccess)}`,
        },
        method: "PUT",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          customerApiSlice.util.updateQueryData(
            "getCustomerDetails",
            undefined,
            () => data
          )
        );
      },
    }),
    getCustomerReviews: builder.query<void, void>({
      query: () => restUrls.customerReviews,
      providesTags: ["Customer"],
    }),
  }),
});

const { useGetCustomerDetailsQuery: useGetCustomerDetailsQueryWithoutGuard } =
  customerApiSlice;

export const useGetCustomerDetailsQuery = (
  _?: undefined,
  options?: { skip: boolean }
) =>
  useGetCustomerDetailsQueryWithoutGuard(undefined, {
    skip: options?.skip || !isValidToken,
  });

export const {
  useChangeCustomerPasswordMutation,
  useUpdateCustomerMutation,
  useGetCustomerReviewsQuery,
} = customerApiSlice;
