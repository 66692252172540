import { FC } from "react";
import { useSelector } from "react-redux";

import SearchSection from "web/Pages/HelpCenter/Desktop/SearchSection";

import Breadcrumbs from "../Shared/Breadcrumbs";
import { IHelpCenterArticleWithId } from "../helpCenterArticle";
import HelpCenterArticleDesktopAside from "./Aside";
import HelpCenterArticleDesktopContent from "./Content";
import HelpCenterDesktopActicleFooter from "./Content/Footer/footer";
import classes from "./desktop.scss";

const HelpCenterDesktopArticle: FC<IHelpCenterArticleWithId> = ({
  id,
  title,
  content,
  categories,
}) => {
  const { help_center_prefix: prefix } = useSelector(
    ({ app }) => app.storeConfig
  );

  return (
    <section className={`container ${classes.container}`}>
      <Breadcrumbs categories={categories} title={title} />
      <SearchSection />
      <div className={classes.wrapper}>
        <HelpCenterArticleDesktopContent title={title} content={content}>
          <HelpCenterDesktopActicleFooter id={id} />
        </HelpCenterArticleDesktopContent>
        <HelpCenterArticleDesktopAside
          categories={categories}
          prefix={prefix}
        />
      </div>
    </section>
  );
};

export default HelpCenterDesktopArticle;
