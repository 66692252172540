/* eslint-disable import/prefer-default-export */
import { TypedDocumentNode } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentNode, print } from "graphql";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import storeConfigQuery from "web/queries/default/storeConfig.graphql";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import graphQlAsGet from "web/utils/system/query/graphQlAsGet";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";
import { getOperationName } from "web/utils/system/storage/storagesAsync/utils";

import { magentoUrlKey } from "web/constants/graphqlUrls";
import storageNames from "web/constants/storageNames";

import { IStoreConfig } from "web/types/StoreConfig";

import { request } from "web/api";

import { customerApiSlice } from "../customer/customerApiSlice";

const storage = new BrowserPersistence();

interface IGetStoreConfigArgs {
  id?: number;
  query?: DocumentNode | TypedDocumentNode;
  omitToken?: boolean;
}

export const getStoreConfig = createAsyncThunk(
  "app/getStoreConfig",
  async (args: IGetStoreConfigArgs, { dispatch }) => {
    let additionalLogMessage = "no additional log message";
    try {
      const { id, query = storeConfigQuery, omitToken = false } = args || {};
      additionalLogMessage = `before customer fetch, storeId from args: ${id}`;
      const processedId =
        id ??
        (
          await dispatch(
            customerApiSlice.endpoints.getCustomerDetails.initiate()
          ).unwrap()
        )?.storeId;
      additionalLogMessage = `should fetch customer: ${!id}, storeId: ${processedId}`;
      if (!processedId) {
        return null;
      }

      const configFromStorage = storage.getItem(
        storageNames.storeConfig,
        processedId
      );

      if (configFromStorage) {
        return configFromStorage;
      }

      const queryProcessed = print(query);
      const operationName = getOperationName(query);
      const response = (await graphQlAsGet(
        magentoUrlKey,
        {
          method: IApiRequestMethods.POST,
          body: JSON.stringify({
            query: queryProcessed,
            operationName,
            variables: {
              id: processedId,
            },
          }),
          omitToken,
        },
        request
      )) as { data: { storeConfig: IStoreConfig } };
      const config = response?.data?.storeConfig ?? {};
      storage.setItem(storageNames.storeConfig, id, config);

      return config;
    } catch (error) {
      newRelicErrorReport(
        error,
        `getStoreConfig - additional log: ${additionalLogMessage}`
      );
      newRelicErrorReport(error, "appThunks - getStoreConfig");
      throw error;
    }
  }
);
