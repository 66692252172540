/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import ErrorBoundary from "web/Layout/ErrorBoundary";

import productsIdsByCategoryId from "web/queries/ms/productsIdsByCategoryId.graphql";

import useQueryWithRender from "web/hooks/useQueryWithRender";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { pageDefault, pageName } from "web/constants/toolbar";

import { IProduct } from "web/types/Product";
import { IStoreConfig } from "web/types/StoreConfig";

import useDataCachedProductsDomesticTourism from "web/features/useDataCached/useDataCachedProductsDomesticTourism";
import useDataCachedProductsInList from "web/features/useDataCached/useDataCachedProductsInList";

import useSetGtmPromoItemsByPathname from "web/hooks/useSetGtmPromoItemsByPathname";
import ProductCarousel from "./productCarousel";
import SkeletonLoader from "./skeletonLoader";

interface IPageGeneratorProductCarouselWithProductsProps {
  ids: number[];
  isAnixe: boolean;
  header: string;
  headerFontColor: string;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorProductCarouselWithProducts: FC<
  IPageGeneratorProductCarouselWithProductsProps
> = ({
  ids,
  isMobile,
  headerFontColor,
  header,
  listingPosition,
  listingAmount,
  isAnixe,
}) => {
  const getProductsCache = isAnixe
    ? useDataCachedProductsDomesticTourism
    : useDataCachedProductsInList;
  // @ts-ignore
  const { loading, error, data } = getProductsCache({
    ids,
  });

  useSetGtmPromoItemsByPathname(ids);

  if (loading) {
    return <SkeletonLoader ids={ids} />;
  }
  if (error) {
    newRelicErrorReport(
      error,
      "web-app/web/Pages/PageGenerator/ProductCarousel/container.js - 56"
    );
    return <ErrorComponent />;
  }

  return (
    <ProductCarousel
      isMobile={isMobile}
      header={header}
      headerFontColor={headerFontColor}
      products={data as IProduct[]}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
    />
  );
};

interface IPageGeneratorProductCarouselContainerProductIdsProps {
  ids: string[];
  anixeIds?: string[];
  header: string;
  headerFontColor: string;
  storeConfig: IStoreConfig;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorProductCarouselContainerProductIds: FC<
  IPageGeneratorProductCarouselContainerProductIdsProps
> = ({
  storeConfig,
  ids: productIds,
  anixeIds = [],
  header,
  headerFontColor,
  isMobile,
  listingPosition,
  listingAmount,
}) => {
  const { grid_per_page: gridPerPage } = storeConfig;
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const idsProcessed = useMemo(() => {
    const ids = [...productIds, ...anixeIds];
    const pageSizeDefault = gridPerPage;
    const pageCurrent = parseInt(params.get(pageName) || `${pageDefault}`, 10);
    const pageSizeCurrent = pageCurrent * pageSizeDefault;
    const idsAmount = ids.slice(0, pageSizeCurrent).map((id) => Number(id));

    return isArrayHasItems(ids) ? idsAmount : [];
  }, [gridPerPage, params, pageName, pageDefault, productIds, anixeIds]);

  return (
    <PageGeneratorProductCarouselWithProducts
      isMobile={isMobile}
      isAnixe={!!anixeIds.length}
      header={header}
      headerFontColor={headerFontColor}
      ids={idsProcessed}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
    />
  );
};

interface IPageGeneratorProductCarouselContainerCategoryProps {
  id: string;
  header: string;
  headerFontColor: string;
  storeConfig: IStoreConfig;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorProductCarouselContainerCategory: FC<
  IPageGeneratorProductCarouselContainerCategoryProps
> = ({
  id,
  header,
  headerFontColor,
  isMobile,
  storeConfig,
  listingPosition,
  listingAmount,
}) => {
  const { token, id: storeId } = storeConfig;
  const options = useMemo(() => {
    return {
      variables: {
        token,
        storeId,
        categoryId: [parseInt(id, 10)],
        facet: true,
      },
      context: {
        clientName: "ms",
      },
    };
  }, [id, token, storeId]);
  const result = useQueryWithRender(
    productsIdsByCategoryId,
    options,
    // @ts-ignore
    ErrorComponent,
    null
  );

  return result &&
    result.msProducts &&
    result.msProducts.edges &&
    result.msProducts.edges[0] &&
    result.msProducts.edges[0].node &&
    result.msProducts.edges[0].node.items_ids ? (
    <PageGeneratorProductCarouselContainerProductIds
      storeConfig={storeConfig}
      ids={result.msProducts.edges[0].node.items_ids}
      header={header}
      headerFontColor={headerFontColor}
      isMobile={isMobile}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
    />
  ) : (
    result
  );
};

interface IPageGeneratorProductCarouselContainerProps {
  storeConfig: IStoreConfig;
  params: string;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorProductCarouselContainer: FC<
  IPageGeneratorProductCarouselContainerProps
> = ({ storeConfig, params, isMobile, listingPosition, listingAmount }) => {
  const {
    category_id: categoryId,
    product_id: productIds,
    anixe_product_id: anixeProductIds,
    color_font_header: headerFontColor,
    header,
  } = useMemo<{
    category_id: string;
    product_id: string;
    anixe_product_id: string;
    color_font_header: string;
    header: string;
  }>(() => jsonParse(params), [params]);
  switch (true) {
    case typeof categoryId === "string" && !!categoryId.length: {
      return (
        <PageGeneratorProductCarouselContainerCategory
          storeConfig={storeConfig}
          id={categoryId}
          header={header}
          headerFontColor={headerFontColor}
          isMobile={isMobile}
          listingPosition={listingPosition}
          listingAmount={listingAmount}
        />
      );
    }
    case typeof productIds === "string" &&
      (!!productIds.length || !!anixeProductIds.length): {
      const ids = productIds?.length ? productIds.split(",") : [];
      const anixeIds = anixeProductIds?.length
        ? anixeProductIds.split(",")
        : [];
      return (
        <PageGeneratorProductCarouselContainerProductIds
          storeConfig={storeConfig}
          ids={ids}
          anixeIds={anixeIds}
          header={header}
          headerFontColor={headerFontColor}
          isMobile={isMobile}
          listingPosition={listingPosition}
          listingAmount={listingAmount}
        />
      );
    }
    default: {
      return null;
    }
  }
};

interface IPageGeneratorProductCarouselContainerErrorProps {
  params: string;
  isMobile?: boolean;
  listingPosition?: number;
  listingAmount?: number;
}

const PageGeneratorProductCarouselContainerError: FC<
  IPageGeneratorProductCarouselContainerErrorProps
> = ({ params, isMobile = false, listingPosition, listingAmount }) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  return (
    <ErrorBoundary>
      <PageGeneratorProductCarouselContainer
        params={params}
        isMobile={isMobile}
        storeConfig={storeConfig as IStoreConfig}
        listingPosition={listingPosition as number}
        listingAmount={listingAmount as number}
      />
    </ErrorBoundary>
  );
};

export default PageGeneratorProductCarouselContainerError;
