import { FC, useCallback, useState } from "react";

import Button from "web/Pages/PageGenerator/Button";

import __ from "web/Layout/Translations";

import LogoSignIcon from "web/assets/icons/logo-sign.svg";

import classesDesktop from "./applicationDesktop.scss";
import classesMobile from "./applicationMobile.scss";

interface IPageGeneratorApplicationProps {
  prompt?: () => void;
  url?: string;
  isMobile?: boolean;
}

const PageGeneratorApplication: FC<IPageGeneratorApplicationProps> = ({ prompt = null, url = null, isMobile = false }) => {
  const [isOpen, closeAction] = useState(true);
  const classes = isMobile ? classesMobile : classesDesktop;
  const closeHandlerAction = useCallback(
    () => closeAction(false),
    [closeAction]
  );
  const laterText = __("Nie teraz");
  const installText = __("Zainstaluj");
  const contentText = __(
    "Dodaj MyBenefit do ekranu głównego, aby przeglądać szybciej i wygodniej."
  );
  return (
    isOpen ? (
      <section className={classes.root} data-t1="page_generator_application">
        <div className={classes.content}>
          <LogoSignIcon className={classes.icon} width={200} />
          <h2
            className={classes.title}
            data-t1="pageGenerator_application_title"
          >
            MyBenefit
          </h2>
          <p className={classes.text} data-t1="page_generator_application_text">
            {contentText}
          </p>
          <div className={classes.actions}>
            <Button className={classes.later} onClick={closeHandlerAction}>
              {laterText}
            </Button>
            <Button onClick={prompt} to={url} className={classes.install}>
              {installText}
            </Button>
          </div>
        </div>
      </section>
    ) : null
  );
};

export default PageGeneratorApplication;
