import { createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import getWebViewRedirectionBody from "web/utils/data/transform/getWebViewRedirectionBody";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import restUrls from "web/constants/restUrls";
import storageNames from "web/constants/storageNames";
import urls from "web/constants/urls";

import {
  IFinancingSourceItem,
  IFinancingSourcePaymentSettingsItem,
} from "web/types/FinancingSources";
import type { IPlaceOrder } from "web/types/Order";
import { IPaymentMethod } from "web/types/Payment";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { AppDispatch, RootState } from "web/store";

import {
  IPaymentMethodsState,
  paymentMethodsApiSlice,
} from "../paymentMethods/paymentMethodsApiSlice";
import { invalidateBanks } from "../pointsBank/pointsBankApiSlice";
import {
  ITourismInternationalQuote,
  reset,
} from "./tourismQuoteInternationalSlice";

const storage = new BrowserTemporality();

export const resetStateAndRemoveItemFromSessionStorage =
  ({ id }: { id: string }) =>
  (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
    storage.removeItem(`${storageNames.tourismInternational}${id}`);
    dispatch(reset());
  };

export const setPaymentMethods = createAsyncThunk(
  "tourismQuoteInternational/setPaymentMethods",
  async (
    payload: {
      items: Nullable<IFinancingSourceItem[]>;
      summary: IFinancingSourcePaymentSettingsItem[] | undefined;
    },
    { dispatch, getState }
  ) => {
    const paymentMethods: IPaymentMethodsState = await dispatch(
      paymentMethodsApiSlice.endpoints.getMethods.initiate()
    ).unwrap();

    const { financingSources } = getState() as RootState;
    const { methods = [] } = paymentMethods;
    const { summary } = payload || financingSources;

    const isOnlinePaymentExist =
      isArrayHasItems(summary) &&
      summary.some(
        (summaryItem) => summaryItem && summaryItem.type === "ONLINE_PAYMENT"
      );

    const methodsAvailable = methods.reduce<IPaymentMethod[]>(
      (result, methodItem) => {
        if (methodItem) {
          if (isOnlinePaymentExist) {
            return methodItem.method !== "points_payment"
              ? [...result, methodItem]
              : result;
          }
          return methodItem.method === "points_payment"
            ? [...result, methodItem]
            : result;
        }

        return result;
      },
      []
    );

    return methodsAvailable;
  }
);

interface IPlaceOrderPayments {
  sku: string;
  payment_settings: {
    type: string;
    value: number;
    code: string;
  }[];
}

export const placeOrder = createAsyncThunk(
  "tourismQuoteInternational/placeOrder",
  async (
    payload: { id: string; push: ReturnType<typeof useHistory>["push"] },
    { getState, dispatch }
  ) => {
    const { id, push } = payload || {};
    const { tourismQuoteInternational } = getState() as RootState;
    const { quote, paymentMethod, financingSources } =
      tourismQuoteInternational;

    const {
      date_from: dateFrom,
      date_to: dateTo,
      ...restValues
    } = quote?.values as ITourismInternationalQuote["values"];

    const { items, payments } =
      financingSources?.reduce<{
        items: { sku: string }[];
        payments: IPlaceOrderPayments[];
      }>(
        (result, current) => {
          const { sku, payment_settings: paymentSettings } = current;
          const paymentSettingsNew = isArrayHasItems(paymentSettings)
            ? paymentSettings.map(({ name, ...rest }) => rest)
            : [];
          return {
            items: [...result.items, { sku }],
            payments: [
              ...result.payments,
              {
                sku,
                payment_settings: paymentSettingsNew,
              },
            ],
          };
        },
        { items: [], payments: [] }
      ) ??
      ({} as { items: { sku: string }[]; payments: IPlaceOrderPayments[] });

    const defaultBody = {
      chosenPaymentMethod: {
        code: paymentMethod,
      },
      items,
      payments,
      tourism_foreign_form: sanitizeObject({
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
        ...restValues,
      }),
    };

    const body = window.ReactNativeWebView
      ? getWebViewRedirectionBody(defaultBody)
      : defaultBody;

    const response = (await request(restUrls.placeOrderTourismInternational, {
      method: IApiRequestMethods.POST,
      body: JSON.stringify(body),
    })) as IPlaceOrder;

    if (typeof response.redirect_url === "string") {
      dispatch(resetStateAndRemoveItemFromSessionStorage({ id }));
      if (!response.redirect_url.includes("http")) {
        invalidateBanks(dispatch as AppDispatch);
      }
      push({
        pathname: urls.checkoutSplash,
        search: `?redirectUrl=${encodeURIComponent(
          response.redirect_url
        )}&orderId=${response.order_id}`,
      });
    }

    return null;
  }
);
