import { FC } from "react";

import PageGenerator from "web/Pages/PageGenerator";

import HeaderSearch from "web/Layout/HeaderSearch";
import __ from "web/Layout/Translations";

import Banner from "web/Components/Common/Banner";
import Breadcrumbs from "web/Components/Common/Breadcrumbs";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import urls from "web/constants/urls";

import type { IBanner } from "web/types/Banners";
import { ICampaignGraphics } from "web/types/CampaignGraphics";
import type { IStoreConfig } from "web/types/StoreConfig";

import { useAppContext } from "web/context/app";

import classes from "./tourism.scss";

interface ITourismProps {
  storeConfig: IStoreConfig;
  campaignGraphic?: ICampaignGraphics;
}

const Tourism: FC<ITourismProps> = ({ storeConfig, campaignGraphic = {} }) => {
  const tourismPageId = storeConfig?.page_generator_anixe_tourism_id;
  const { isMobile } = useAppContext();
  const breadcrumbsDefault = [
    {
      label: __("Strona główna"),
      url: urls.home,
    },
    {
      label: __("Turystyka"),
      url: urls.tourism,
    },
  ];

  return (
    <>
      {isMobile && <HeaderSearch back />}
      {campaignGraphic && <Banner banner={campaignGraphic as IBanner} />}
      {!isMobile && (
        <div className={`container ${classes.header}`}>
          <Breadcrumbs breadcrumbs={breadcrumbsDefault} />
        </div>
      )}

      <PageGenerator id={tourismPageId} />
    </>
  );
};

export default withScrollToTop(Tourism);
