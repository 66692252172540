import { Button } from "@benefit-systems/common-components";
import { Field, Form, Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { pageName } from "web/constants/toolbar";

import { getCodes } from "web/features/codes/codesThunks";
import { FilterValues } from "web/features/codes/utils/helpers";

import classes from "./filters.scss";

interface ICustomerCodeListFiltersMobileProps {
  filters: { label: string; value: string; count: number }[];
  name: string;
  closeModal: () => void;
}

const CustomerCodeListFiltersMobile: FC<
  ICustomerCodeListFiltersMobileProps
> = ({ name, filters, closeModal }) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const type = params.get(name) || filters[0]?.value;

  const initialValues = {
    [name]: type,
  };

  const onSubmit = useCallback(
    (values: { [key: string]: FilterValues | string }) => {
      params.delete(pageName);
      params.set(name, values[name]);
      push(`${pathname}?${params.toString()}`);
      dispatch(
        getCodes({ page: 1, filterValue: values[name] as FilterValues })
      );
      closeModal();
    },
    [name, params, pathname]
  );

  return isArrayHasItems(filters) ? (
    <div className={classes.root}>
      <h2 className={classes.filtersHeader}>{__("Filtry")}</h2>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className={classes.form}>
          <h3 className={classes.filtersSecondHeader}>{__("Wyświetl kody")}</h3>
          {filters.map(({ label, value }) => (
            <div
              className={classes.item}
              key={value}
              data-t1="code_list_filters_item"
              data-t2={label}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className={classes.label}>
                <Field
                  className="visually-hidden"
                  type="radio"
                  name={name}
                  value={value}
                  dataT1={`${value}_field`}
                />
                <span className={classes.text} data-t1={`${value}_label`}>
                  {label}
                </span>
              </label>
            </div>
          ))}
          <div className={classes.buttonContainer}>
            <Button
              variant="secondary"
              transparent
              dataT1="undo_filters"
              onClick={closeModal}
            >
              {__("Anuluj")}
            </Button>
            <Button variant="primary" type="submit" dataT1="submit_filters">
              {__("Zatwierdź")}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  ) : null;
};

export default CustomerCodeListFiltersMobile;
