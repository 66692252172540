import { createSlice } from "@reduxjs/toolkit";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import processPayloadCartPayload from "web/utils/system/redux/processPayloadCartPayload";

import type { ICartItem, ICartSummaryItem } from "web/types/Cart";
import { ReducerPaymentMethod } from "web/types/Payment";
import type { Nullable } from "web/types/Utils";

import {
  addItemListToCart,
  addItemToCart,
  createCart,
  getCartDetails,
  placeOrder,
  removeItemFromCart,
  setPaymentMethod,
  updateItemQuantity,
} from "./cartThunks";

export interface ICartState {
  cartId: Nullable<string>;
  message: Nullable<string>;
  coupon: Nullable<string>;
  paymentMethod: Nullable<string>;
  paymentMethods: Nullable<ReducerPaymentMethod[]>;
  total: number;
  totalCount: Nullable<number>;
  items: ICartItem[];
  summary: ICartSummaryItem[];
  isOrderPlacing: boolean;
  isAddingItem: boolean;
  isAddingItems: boolean;
  isAddedItem: boolean;
  isDetailsGotten: boolean;
  isRemovingItem: boolean;
  isUpdatingItem: boolean;
  isLoading: boolean;
  isSettingPaymentMethod: boolean;
}

export const initialState: ICartState = {
  cartId: null,
  message: null,
  coupon: null,
  paymentMethod: null,
  paymentMethods: null,
  total: 0,
  totalCount: null,
  items: [],
  summary: [],
  isOrderPlacing: false,
  isAddingItem: false,
  isAddingItems: false,
  isAddedItem: false,
  isDetailsGotten: false,
  isRemovingItem: false,
  isUpdatingItem: false,
  isLoading: false,
  isSettingPaymentMethod: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetAddedItemToCart: (state) => {
      state.isAddedItem = false;
    },
    resetDetailsGotten: (state) => {
      state.isDetailsGotten = false;
    },
    reset: () => initialState,

    getDetailsReceive: (state, action) => {
      const processedPayload = processPayloadCartPayload(action.payload);
      return {
        ...state,
        ...processedPayload,
        isDetailsGotten: true,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    // create cart
    builder.addCase(createCart.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(createCart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cartId = action.payload;
    });

    builder.addCase(createCart.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - createCart");
      console.error(error);
      state.isLoading = false;
    });

    // get cart details
    builder.addCase(getCartDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCartDetails.fulfilled, (state, action) => {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }
      const processedPayload = processPayloadCartPayload(action.payload);
      return {
        ...state,
        ...processedPayload,
        isDetailsGotten: true,
        isLoading: false,
      };
    });

    builder.addCase(getCartDetails.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - getCartDetails");
      console.error(error);
      state.isLoading = false;
    });

    // add item to cart
    builder.addCase(addItemToCart.pending, (state) => {
      state.isAddedItem = false;
      state.isAddingItem = true;
    });

    builder.addCase(addItemToCart.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        return state;
      }

      const processedPayload = processPayloadCartPayload(action.payload);
      return {
        ...state,
        ...processedPayload,
        isAddedItem: true,
        isAddingItem: false,
      };
    });

    builder.addCase(addItemToCart.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - addItemToCart");
      console.error(error);
      state.isAddingItem = false;
    });

    // remove item from cart
    builder.addCase(removeItemFromCart.pending, (state) => {
      state.isRemovingItem = true;
    });

    builder.addCase(removeItemFromCart.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        return state;
      }

      const processedPayload = processPayloadCartPayload(action.payload);

      return {
        ...state,
        ...processedPayload,
        isRemovingItem: false,
      };
    });

    builder.addCase(removeItemFromCart.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - removeItemFromCart");
      console.error(error);
      state.isRemovingItem = false;
    });

    // update item quantity
    builder.addCase(updateItemQuantity.pending, (state) => {
      state.isUpdatingItem = true;
    });

    builder.addCase(updateItemQuantity.fulfilled, (state, action) => {
      const processedPayload = processPayloadCartPayload(action.payload);
      return {
        ...state,
        ...processedPayload,
        isUpdatingItem: false,
      };
    });

    builder.addCase(updateItemQuantity.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - updateItemQuantity");
      console.error(error);
      state.isUpdatingItem = false;
    });

    // add item list to cart
    builder.addCase(addItemListToCart.pending, (state) => {
      state.isAddingItems = true;
      state.isLoading = true;
    });

    builder.addCase(addItemListToCart.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        return state;
      }

      const processedPayload = processPayloadCartPayload(action.payload);
      return {
        ...state,
        ...processedPayload,
        isDetailsGotten: true,
        isLoading: false,
        isAddingItems: false,
      };
    });

    builder.addCase(addItemListToCart.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - addItemListToCart");
      console.error(error);
      state.isAddingItems = false;
      state.isLoading = false;
    });

    // place order
    builder.addCase(placeOrder.pending, (state) => {
      state.isOrderPlacing = true;
    });

    builder.addCase(placeOrder.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        return state;
      }
      return {
        ...state,

        isOrderPlacing: false,
      };
    });

    builder.addCase(placeOrder.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - placeOrder");
      console.error(error);
      state.isOrderPlacing = false;
    });

    // set payment method
    builder.addCase(setPaymentMethod.pending, (state) => {
      state.isSettingPaymentMethod = true;
    });

    builder.addCase(setPaymentMethod.fulfilled, (state, action) => {
      const processedPayload = processPayloadCartPayload(action.payload);
      return {
        ...state,
        ...processedPayload,
        isSettingPaymentMethod: false,
      };
    });

    builder.addCase(setPaymentMethod.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "cartSlice - setPaymentMethod");
      console.error(error);
      state.isSettingPaymentMethod = false;
    });
  },
});

export const {
  getDetailsReceive,
  reset,
  resetAddedItemToCart,
  resetDetailsGotten,
} = cartSlice.actions;

export default cartSlice.reducer;
