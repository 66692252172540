import { Redirect, useParams } from "react-router-dom";

import urls from "web/constants/urls";

import Success from "./Success";

const OrderPrepaidCardSuccess = () => {
  const { orderId } = useParams<{ orderId: string }>();

  return orderId ? <Success orderId={orderId} /> : <Redirect to={urls.home} />;
};

export default OrderPrepaidCardSuccess;
