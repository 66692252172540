import restUrls from "web/constants/restUrls";

import { IPaymentMethod } from "web/types/Payment";

import api from "../api/apiSlice";
import { cartRestErrorHandler } from "../cart/cartThunks";

export interface IPaymentMethodsState {
  methods: IPaymentMethod[];
}

export const paymentMethodsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getMethods: builder.query<IPaymentMethodsState, void>({
      query: () => restUrls.payments,
      providesTags: ["PaymentsMethods"],
      transformResponse: (response: IPaymentMethod[]) => {
        return { methods: response };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.catch(async (error) => {
          cartRestErrorHandler({ error, dispatch });
        });
      },
    }),
  }),
});

export const { useGetMethodsQuery } = paymentMethodsApiSlice;

export default paymentMethodsApiSlice.reducer;
