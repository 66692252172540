import { useQuery } from "@apollo/client";
import { Button } from "@benefit-systems/common-components";
import { FC } from "react";
import { useHistory } from "react-router";

import HelpCenterArticleDesktopAside from "web/Pages/HelpCenterArticle/Desktop/Aside/aside";
import HelpCenterArticleDesktopContent from "web/Pages/HelpCenterArticle/Desktop/Content/content";
import HelpCenterArticleMobileContent from "web/Pages/HelpCenterArticle/Mobile/Content/content";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import helpCenterArticle from "web/queries/default/helpCenterArticle.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IHelpCenterArticle } from "web/types/HelpCenter";

import { useAppContext } from "web/context/app";

import { FAQ_PREFIX } from "../faq";
import classes from "./FAQArticle.scss";

interface IFAQArticleProps {
  id: number;
}

const FAQArticle: FC<IFAQArticleProps> = ({ id }) => {
  const { goBack } = useHistory();
  const { isMobile } = useAppContext();

  const { languageFromParam, isParamLangEn } = useLangFromParams();

  const { error, loading, data } = useQuery<{
    helpCenterArticle: IHelpCenterArticle;
  }>(helpCenterArticle, {
    variables: {
      articleIds: [id],
      lang: languageFromParam || getLanguageCode(),
    },
  });

  if (error) {
    newRelicErrorReport(error, "web-app/web/Pages/FAQ/faq.tsx - 39");
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  const destructuredData = data?.helpCenterArticle?.article?.[0];

  if (loading && !destructuredData) return <Loading />;

  if (!destructuredData) return null;

  const { title, content, categories } = destructuredData;

  const Comp = isMobile
    ? HelpCenterArticleMobileContent
    : HelpCenterArticleDesktopContent;

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Comp title={title} content={content} />
        <Button variant="secondary" onClick={goBack}>
          {isParamLangEn ? "Back" : __("Wróć")}
        </Button>
      </div>
      {!isMobile && (
        <HelpCenterArticleDesktopAside
          categories={categories}
          prefix={FAQ_PREFIX}
        />
      )}
    </div>
  );
};

export default FAQArticle;
