import { createSlice } from "@reduxjs/toolkit";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import type { ICodeItem } from "web/types/Code";
import type { Nullable } from "web/types/Utils";

import { getCodes, setCodeUsed } from "./codesThunks";

interface ICodesState {
  items: ICodeItem[];
  settingMarkIds: number[];
  itemsIdsMarkedAsUsed: number[];
  itemsIdsMarkedAsToUse: number[];
  isCodesGotten: boolean;
  error: Nullable<Error>;
  isLoading: boolean;
  toUseCount: number;
  usedCount: number;
  totalCount: number;
  cache: { [key: string]: string };
}

const initialState: ICodesState = {
  items: [],
  settingMarkIds: [],
  itemsIdsMarkedAsUsed: [],
  itemsIdsMarkedAsToUse: [],
  isCodesGotten: false,
  error: null,
  isLoading: false,
  toUseCount: 0,
  usedCount: 0,
  totalCount: 0,
  cache: {},
};

export const codesSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCodesCache: (state) => {
      state.cache = {};
    },
    setCodeUsedRequest: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        settingMarkIds: payload.mark,
        itemsIdsMarkedAsUsed: payload.used,
        itemsIdsMarkedAsToUse: payload.toUse,
      };
    },
    revertCodeUsed: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        settingMarkIds: payload.mark,
      };
    },
  },
  extraReducers: (builder) => {
    // get codes

    builder.addCase(getCodes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCodes.fulfilled, (state, action) => {
      const { payload } = action;
      const { cacheKey } = payload;

      return {
        ...state,
        items: payload.codes,
        itemsIdsMarkedAsUsed: initialState.itemsIdsMarkedAsUsed,
        itemsIdsMarkedAsToUse: initialState.itemsIdsMarkedAsToUse,
        isLoading: false,
        isCodesGotten: true,
        toUseCount: payload.toUseCount,
        usedCount: payload.usedCount,
        totalCount: payload.totalCount,
        cache: { ...state.cache, [cacheKey]: JSON.stringify(payload.codes) },
      };
    });

    builder.addCase(getCodes.rejected, (state, action) => {
      const { error } = action as { error: Error };
      newRelicErrorReport(error, "codesSlice - getCodes");

      return {
        ...state,
        error,
        isLoading: false,
        isCodesGotten: true,
      };
    });

    // set code used

    builder.addCase(setCodeUsed.fulfilled, (state, action) => {
      const { payload } = action;
      return {
        ...state,
        settingMarkIds: payload.mark,
        cache: {},
      };
    });

    builder.addCase(setCodeUsed.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "codesSlice - setCodeUsed");
    });
  },
});

export const { setCodeUsedRequest, revertCodeUsed, resetCodesCache } =
  codesSlice.actions;

export default codesSlice.reducer;
