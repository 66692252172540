import { FC } from "react";

import PrepaidCardDescription from "./prepaidCardDescription";

interface PrepaidCardDescriptionProps {
  product: {
    [key: string]: any;
  };
}

const Content: FC<PrepaidCardDescriptionProps> = ({ product }) => {
  return <PrepaidCardDescription product={product} />;
};

export default Content;
