import ErrorBoundary from "web/Layout/ErrorBoundary";

import { useAppContext } from "web/context/app";

import { ICodeItem } from "web/types/Code";
import { FC } from "react";
import ListDesktop from "../../Desktop/List/list";
import ListMobile from "../../Mobile/List/list";

interface ICustomerCodeListContainerProps {
  codes: ICodeItem[];
}

const CustomerCodeListContainer: FC<ICustomerCodeListContainerProps> = ({ codes }) => {
  const { isMobile } = useAppContext();

  if (isMobile) {
    return <ListMobile items={codes} />;
  }

  return <ListDesktop items={codes} />;
};

const CustomerCodeListContainerError: FC<ICustomerCodeListContainerProps> = ({ codes }) => {
  return (
    <ErrorBoundary>
      <CustomerCodeListContainer codes={codes} />
    </ErrorBoundary>
  );
};

export default CustomerCodeListContainerError;
