import { Field, Form, Formik, useFormikContext } from "formik";
import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { getCodes } from "web/features/codes/codesThunks";

import { FilterValues } from "web/features/codes/utils/helpers";
import classes from "./filters.scss";

interface IAutoSubmitProps {
  name: string;
  type: string;
}

const AutoSubmit: FC<IAutoSubmitProps> = ({ type, name }) => {
  const { values, submitForm } = useFormikContext<{ [key: string]: number | string }>();

  useEffect(() => {
    if (values[name] !== type) {
      submitForm();
    }
  }, [values, submitForm]);

  return null;
};


interface ICustomerCodeListFiltersDesktopProps {
  filters: { label: string; value: string; count: number; }[];
  name: string;
  type: string;
}

/* eslint-disable jsx-a11y/label-has-associated-control */
const CustomerCodeListFiltersDesktop: FC<ICustomerCodeListFiltersDesktopProps> = ({ name, filters, type }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const params = new URLSearchParams();
  const dispatch = useDispatch();

  const initialValues = {
    [name]: type,
  };
  const onSubmit = useCallback(
    (values: { [key: string]: FilterValues | string }) => {
      params.set(name, values[name]);
      push(`${pathname}?${params.toString()}`);
      dispatch(getCodes({ page: 1, filterValue: values[name] as FilterValues }));
    },
    [name, params, pathname]
  );

  return isArrayHasItems(filters) ? (
    <div className={classes.root}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className={classes.form}>
          {filters.map(({ label, value }) => (
            <div
              className={classes.item}
              key={value}
              data-t1="code_list_filters_item"
              data-t2={label}
            >
              <label className={classes.label}>
                <Field
                  className="visually-hidden"
                  type="radio"
                  name={name}
                  value={value}
                  data-t1={`${value}_field`}
                />
                <span className={classes.text} data-t1={`${value}_label`}>
                  {label}
                </span>
              </label>
            </div>
          ))}
          <AutoSubmit name={name} type={type} />
        </Form>
      </Formik>
    </div>
  ) : null;
};

export default CustomerCodeListFiltersDesktop;
