import { IMenuClientDataElement } from "web/types/MenuClient";

import {
  ICustomerMenuSection,
  ICustomerMenuSectionItem,
} from "web/features/customerMenu/customerMenuSlice";

const getItemsWithRestrictions = (
  section: ICustomerMenuSection,
  payload: IMenuClientDataElement[],
  payloadElementNumber: 0 | 1 | 2
): ICustomerMenuSectionItem[] => {
  return section.items.reduce<ICustomerMenuSectionItem[]>((acc, item) => {
    const currentPayloadElement = payload[payloadElementNumber].items.find(
      (payloadElement) => payloadElement.original_name === item.name
    );

    if (currentPayloadElement && +currentPayloadElement.status === 1) {
      return [
        ...acc,
        {
          name: currentPayloadElement.original_name,
          title: currentPayloadElement.name,
          url: item.url,
          position: item.position,
        },
      ];
    }

    return acc;
  }, []);
};

export default getItemsWithRestrictions;
