import { Link } from "react-router-dom";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import StarsRating from "web/Components/Common/StarsRating";

import classify from "web/classify";

import { FC } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultStyles from "./review.scss";

interface IPageGeneratorReviewProps {
  url: string;
  title: string;
  name: string;
  rating: number;
  image: string;
  contentText: string;
}

const PageGeneratorReview: FC<PropsWithClasses<IPageGeneratorReviewProps>> = ({
  url,
  title,
  name,
  rating,
  image,
  contentText,
  classes = {},
}) => {
  const ratingProcessed = rating.toFixed(1);
  return (
    <article className={classes.root} data-t1="pageGeneratorReviews__review">
      <div className={classes.row}>
        <div className={classes.preview}>
          <Link
            className={classes.imageBox}
            to={url}
            title={title}
            data-t1="pageGeneratorReviews__reviewLinkImage"
          >
            <LazyLoadImage
              className={classes.image}
              src={image}
              alt={title}
              width={321}
              height={218}
              isProduct
            />
          </Link>
          <h3
            className={classes.title}
            data-t1="pageGeneratorReviews__reviewTitle"
          >
            <Link
              className={classes.link}
              to={url}
              title={title}
              data-t1="pageGeneratorReviews__reviewTitleLink"
            >
              {title}
            </Link>
          </h3>
        </div>
        <blockquote className={classes.content}>
          <div
            className={classes.rating}
            data-t1="pageGeneratorReviews__reviewRating"
            data-t2={parseInt(`${rating}`, 10)}
          >
            <StarsRating
              className={classes.stars}
              rating={parseInt(`${rating}`, 10)}
            />
            <span className={classes.ratingAmount}>{ratingProcessed}</span>
          </div>
          <p
            className={classes.text}
            data-t1="pageGeneratorReviews__reviewText"
          >
            {contentText}
          </p>
          <cite
            className={classes.name}
            data-t1="pageGeneratorReviews__reviewAuthor"
          >
            {name}
          </cite>
        </blockquote>
      </div>
    </article>
  );
};

export default classify<PropsWithClasses<IPageGeneratorReviewProps>>(defaultStyles)(PageGeneratorReview);
