import { useFormikContext } from "formik";
import { FC, useEffect } from "react";

import { DatepickerFormik } from "web/Layout/Datepicker";
import __ from "web/Layout/Translations";

import { dayMilliseconds } from "web/constants/date";

import { DateFields, names } from "../domesticTourismFormFields";
import classes from "./dateFields.scss";

interface IDomesticTourismFormDateFieldsProps {
  fields: DateFields;
  variant: "offer" | "listing" | "product";
}

const DomesticTourismFormDateFields: FC<
  IDomesticTourismFormDateFieldsProps
> = ({ fields, variant = "" }) => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<Record<string, string>>();

  const minDate =
    values[names.dateFrom] && +values[names.dateFrom]
      ? +values[names.dateFrom] + dayMilliseconds
      : Date.now() + dayMilliseconds;

  useEffect(() => {
    if (
      +values[names.dateFrom] > +values[names.dateTo] &&
      +values[names.dateTo]
    ) {
      setFieldValue(names.dateTo, null);
    }
  }, [JSON.stringify(values), setFieldValue]);

  return (
    <div className={classes.root}>
      {fields.map(({ name, label, required, placeholder }) => {
        const isError = errors[name] && touched[name];

        const errorClassName = isError ? classes.inputError : classes.input;
        const [minDateValue, maxDateValue] =
          name === names.dateFrom ? ["today", null] : [minDate, null];

        return (
          <div className={classes.wrapper} key={name}>
            <legend className={classes.badge}>{__(label)}</legend>
            <div>
              <DatepickerFormik
                name={name}
                label={__(label)}
                placeholder={__(placeholder)}
                classes={{
                  labelText: required
                    ? classes.labelText_required
                    : classes.labelText,
                  calendar:
                    variant === "product"
                      ? classes.calendarProduct
                      : classes.calendar,
                }}
                className={errorClassName}
                minDate={
                  name === "tourism_date_to"
                    ? (minDateValue as number) - dayMilliseconds
                    : minDateValue
                }
                maxDate={maxDateValue}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DomesticTourismFormDateFields;
