import { FC } from "react";

import { useAppContext } from "web/context/app";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export interface IReminder {
  bank_name: string,
  expiration_date: string,
  balance: number,
}

interface IPageGeneratorMyPointsProps {
  reminder?: IReminder;
  image?: string;
}

const PageGeneratorMyPoints: FC<IPageGeneratorMyPointsProps> = ({ reminder = null, image = "" }) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { itemsActive: banks, total } = dataBanks || {};

  const { isMobile } = useAppContext();

  return isMobile ? (
    <Mobile banks={banks} />
  ) : (
    <Desktop
      banks={banks}
      reminderPointsDelete={reminder}
      image={image}
      total={total}
    />
  );
};

export default PageGeneratorMyPoints;
