// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionMobile-root-KAY{display:block}.sectionMobile-title-UI8{font-size:13px;font-weight:700;display:block;margin:0 0 20px}.sectionMobile-list-Jww{font-size:17px;font-weight:700;margin:0 0 20px}.sectionMobile-item-bJ1{position:relative;padding-left:14px;margin:0 0 10px;font-size:12px}.sectionMobile-item-bJ1:last-child{margin:0}.sectionMobile-item-bJ1::before{content:"";position:absolute;left:0;top:calc(50% - 2px);width:4px;height:4px;border-radius:50%;background-color:currentColor}`, "",{"version":3,"sources":["webpack://./web/Pages/Product/Shared/Amenities/sectionMobile.scss"],"names":[],"mappings":"AAIuB,wBACrB,aAAA,CAGF,yBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,eAAA,CAGF,wBACE,cAAA,CACA,eAAA,CACA,eAAA,CAGF,wBACE,iBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CAEA,mCACE,QAAA,CAGF,gCACE,UAAA,CACA,iBAAA,CACA,MAAA,CACA,mBAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,6BAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n}\n\n.title {\n  font-size: 13px;\n  font-weight: 700;\n  display: block;\n  margin: 0 0 20px;\n}\n\n.list {\n  font-size: 17px;\n  font-weight: 700;\n  margin: 0 0 20px;\n}\n\n.item {\n  position: relative;\n  padding-left: 14px;\n  margin: 0 0 10px;\n  font-size: 12px;\n\n  &:last-child {\n    margin: 0;\n  }\n\n  &::before {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    top: calc(50% - 2px);\n    width: 4px;\n    height: 4px;\n    border-radius: 50%;\n    background-color: currentColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sectionMobile-root-KAY`,
	"title": `sectionMobile-title-UI8`,
	"list": `sectionMobile-list-Jww`,
	"item": `sectionMobile-item-bJ1`
};
export default ___CSS_LOADER_EXPORT___;
