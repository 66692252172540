import { Nullable } from "web/types/Utils";

const handleSafeTokenLog = (token: Nullable<string>) => {
  if (!token) {
    return token;
  }
  if (token.length < 6) {
    return "token too short to log";
  }
  if (token.length < 9) {
    return token.substring(0, 3);
  }

  return `${token.substring(0, 3)}:::${token.substring(token.length - 3)}`;
};

export default handleSafeTokenLog;
