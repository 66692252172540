import { FC } from "react";

import SearchSection from "web/Pages/HelpCenter/Mobile/SearchSection";

import MobileHeader from "web/Layout/MobileHeader";
import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import HelpCenterArticleBreadcrumbs from "../Shared/Breadcrumbs";
import { IHelpCenterArticleWithId } from "../helpCenterArticle";
import HelpCenterArticleMobileContent from "./Content";
import HelpCenterMobileArticleFooter from "./Content/Footer/footer";

const HelpCenterArticleMobile: FC<IHelpCenterArticleWithId> = ({
  id,
  title,
  content,
  categories,
}) => {
  const { isParamLangEn } = useLangFromParams();
  return (
    <section>
      <MobileHeader back>
        {isParamLangEn ? "Help Center" : __("Centrum pomocy")}
      </MobileHeader>
      <HelpCenterArticleBreadcrumbs categories={categories} title={title} />
      <SearchSection />
      <HelpCenterArticleMobileContent content={content} title={title}>
        <HelpCenterMobileArticleFooter id={id} />
      </HelpCenterArticleMobileContent>
    </section>
  );
};

export default HelpCenterArticleMobile;
