import { FC, useState } from "react";

import HelpCenterArticleNegativeOpinionForm from "web/Pages/HelpCenterArticle/Shared/Footer/NegativeOpinionForm/negativeOpinionForm";
import HelpCenterArticleOpinionOptions from "web/Pages/HelpCenterArticle/Shared/Footer/OpinionOptions";
import HelpCenterArticleFooter from "web/Pages/HelpCenterArticle/Shared/Footer/footer";
import useHelpCenterArticleOpinion from "web/Pages/HelpCenterArticle/Shared/useOpinion";
import { IArticleOpinion } from "web/Pages/HelpCenterArticle/types";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import classes from "./footer.scss";

interface IHelpCenterActicleDesktopFooterProps {
  id: number;
}

const HelpCenterActicleDesktopFooter: FC<
  IHelpCenterActicleDesktopFooterProps
> = ({ id }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { message, isLoading, error, postOpinion, setError, setIsLoading } =
    useHelpCenterArticleOpinion(id);

  const { isParamLangEn } = useLangFromParams();

  const onCloseFormHandler = () => {
    setIsFormOpen(false);
  };

  const onPostPositiveOpinionHandler = (opinion: IArticleOpinion) => {
    postOpinion(opinion);
    setIsFormOpen(false);
  };

  const onOpenNegativeFormHandler = () => {
    setError(null);
    setIsLoading(false);
    setIsFormOpen(true);
  };

  const onPostNegativeOpinionHandler = (opinion: IArticleOpinion) => {
    postOpinion(opinion);
  };

  const container = (() => {
    switch (true) {
      case !!error: {
        console.error(error);
        if (error?.responseMessage) {
          return (
            <p className={classes.responseMessage}>{error.responseMessage}</p>
          );
        }
        return <ErrorComponent />;
      }
      case isLoading: {
        return <Loading />;
      }
      default: {
        return null;
      }
    }
  })();

  return (
    <HelpCenterArticleFooter>
      {isFormOpen && !error && !message ? (
        <HelpCenterArticleNegativeOpinionForm
          isLoading={isLoading}
          onReset={onCloseFormHandler}
          onPostOpinion={onPostNegativeOpinionHandler}
        />
      ) : (
        <>
          <div className={classes.opinionFormHeader}>
            <div className={classes.title}>
              {isParamLangEn
                ? "Was the answer helpful?"
                : __("Czy odpowiedź była pomocna?")}
            </div>
            {!message && !error && (
              <HelpCenterArticleOpinionOptions
                onClickPositive={onPostPositiveOpinionHandler}
                onClickNegative={onOpenNegativeFormHandler}
              />
            )}
          </div>
          {message && <div className={classes.container}>{message}</div>}
          {container && <div className={classes.container}>{container}</div>}
        </>
      )}
    </HelpCenterArticleFooter>
  );
};

export default HelpCenterActicleDesktopFooter;
