/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import Button from "web/Pages/PageGenerator/Button";

import GtmEvent from "web/Layout/Gtm/GtmEvent";
import { ProductCarouselMobile } from "web/Layout/ProductCarousel";

import useGtmProductImpression from "web/hooks/useGtmProductImpression";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IProduct } from "web/types/Product";

import Content from "./Content";
import classesContentDesktop from "./Content/contentDesktop.scss";
import classesContentMobile from "./Content/contentMobile.scss";
import Products from "./Products";
import classesDesktop from "./twoProductsDesktop.scss";
import classesMobile from "./twoProductsMobile.scss";

interface IPageGeneratorTwoProductsProps {
  data: IProduct[];
  headerFontColor: string;
  backgroundColor: string;
  descriptionFontColor: string;
  buttonFontColor: string;
  buttonBackgroundColor: string;
  imageSrc: string;
  header: string;
  url: string;
  layout: string;
  button: string;
  description: string;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorTwoProducts: FC<IPageGeneratorTwoProductsProps> = ({
  isMobile = false,
  data = [],
  imageSrc = "",
  header = "",
  description = "",
  button = "",
  url = "",
  layout = "",
  backgroundColor,
  descriptionFontColor,
  headerFontColor,
  buttonFontColor,
  buttonBackgroundColor,
  listingPosition = null,
  listingAmount = null,
}) => {
  const [classes, contentClasses] = isMobile
    ? [classesMobile, classesContentMobile]
    : [classesDesktop, classesContentDesktop];

  const sectionStyles = {
    backgroundColor,
  };
  const rowDirectionClassName =
    layout === "right_hand" ? classes.row : classes.rowReverse;
  const rowClassName = isMobile
    ? rowDirectionClassName
    : `container ${rowDirectionClassName || ""}`.trim();

  const [isGtmObjectReady, gtmEnhancedEcommOptions] = useGtmProductImpression(
    data,
    listingPosition || 0,
    listingAmount || 0,
    header
  );

  return isArrayHasItems(data) ? (
    <section
      className={classes.root}
      style={sectionStyles}
      data-t1="page_generator_two_products"
    >
      <div className={rowClassName}>
        <Content
          layout={layout}
          isMobile={isMobile}
          classes={contentClasses}
          imageSrc={imageSrc}
          header={header}
          description={description}
          button={button}
          url={url}
          headerFontColor={headerFontColor}
          buttonFontColor={buttonFontColor}
          buttonBackgroundColor={buttonBackgroundColor}
          descriptionFontColor={descriptionFontColor}
        />

        {isMobile ? (
          <ProductCarouselMobile
            className={classes.products}
            products={data}
            listingPosition={listingPosition}
            listingAmount={listingAmount}
            listingCategory={header}
          />
        ) : (
          <Products
            className={classes.products}
            items={data}
            listingPosition={listingPosition || 0}
            listingAmount={listingAmount || 0}
            listingCategory={header}
          />
        )}

        {isMobile && button && url && (
          <footer className={classes.footer}>
            <Button
              className={classes.button}
              to={url}
              buttonFontColor={buttonFontColor}
              buttonBackgroundColor={buttonBackgroundColor}
            >
              {button}
            </Button>
          </footer>
        )}
      </div>
      {isGtmObjectReady && gtmEnhancedEcommOptions && (
        <GtmEvent
          eventName="productImpression"
          // @ts-ignore
          options={gtmEnhancedEcommOptions}
        />
      )}
    </section>
  ) : null;
};

export default PageGeneratorTwoProducts;
