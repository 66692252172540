import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useSelector } from "react-redux";

import Breadcrumbs from "web/Pages/HelpCenter/Shared/Breadcrumbs";

import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import helpCenterCategory from "web/queries/default/helpCenterCategory.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { useAppContext } from "web/context/app";

import { IHelpCenterArticleWithId } from "../../helpCenterArticle";

type HelpCenterArticleBreadcrumbsProps = Pick<
  IHelpCenterArticleWithId,
  "categories" | "title"
>;

const HelpCenterArticleBreadcrumbs: FC<HelpCenterArticleBreadcrumbsProps> = ({
  categories,
  title,
}) => {
  const { isMobile } = useAppContext();

  const { languageFromParam, langLinkSuffix, isParamLangEn } =
    useLangFromParams();

  const { loading, data } = useQuery(helpCenterCategory, {
    variables: {
      categoryId: categories,
      lang: languageFromParam || getLanguageCode(),
    },
  });
  const { help_center_prefix: prefix } = useSelector(
    (state) => state.app.storeConfig
  );

  const categoryName = data?.helpCenterCategory?.category?.[0]?.name;
  const urlKey = data?.helpCenterCategory?.category?.[0]?.url_key;
  const categoryLink = `/${prefix}/${urlKey}.html${langLinkSuffix}`;

  const answerCopy = isParamLangEn ? "Answer" : __("Odpowiedź");

  const breadcrumbs = [
    {
      label: categoryName,
      url: categoryLink,
    },
    {
      label: isMobile ? answerCopy : title,
    },
  ];

  return categoryName ? (
    <Breadcrumbs loading={loading} breadcrumbs={breadcrumbs} />
  ) : null;
};

export default HelpCenterArticleBreadcrumbs;
