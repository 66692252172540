/* eslint-disable react/no-array-index-key */
import { FC } from "react";

import Section from "web/Pages/Checkout/TourismDomestic/SharedComponents/Section";

import __ from "web/Layout/Translations";

import Accordion from "web/Components/Common/Accordion";
import type { AccordionVariant } from "web/Components/Common/Accordion/accordion";

import tourismDefinition from "web/constants/tourismDefinition";

import { ICnsx } from "web/types/Tourism";

import CancellationPolicyOption from "./CancellationPolicyOption/cancellationPolicyOption";
import classes from "./cancellationPolicy.scss";
import useFreeCancellationInfo from "./utils/useFreeCancellationInfo";

interface ICheckoutTourismDomesticCancellationPolicyProps {
  type: string;
  options: ICnsx[];
  variant: AccordionVariant;
  createdAt?: Date;
}

const CheckoutTourismDomesticCancellationPolicy: FC<
  ICheckoutTourismDomesticCancellationPolicyProps
> = ({ type, options, variant = "primary", createdAt }) => {
  const optionsVisible = type === "Refundable" || type === "FeeApplies";
  const message = !type.length
    ? tourismDefinition.cancellationPolicyMessages.NonRefundable
    : tourismDefinition.cancellationPolicyMessages[
        type as keyof typeof tourismDefinition.cancellationPolicyMessages
      ];

  const freeCancellationInfo = useFreeCancellationInfo(
    options[0]?.from,
    options[0]?.from_time,
    createdAt
  );

  return (
    <Section>
      <Accordion
        label={__("Polityka anulacji")}
        variant={variant}
        arrow
        className={variant === "tertiary" ? null : classes.accordionRoot}
        expanded
      >
        <p className={classes.content}>{__(message)}</p>
        {optionsVisible && (
          <>
            {options.map((option) => (
              <CancellationPolicyOption
                key={option.from}
                option={option}
                className={classes.content}
              />
            ))}
            {freeCancellationInfo && (
              <p className={classes.content}>{freeCancellationInfo}</p>
            )}
          </>
        )}
      </Accordion>
    </Section>
  );
};

export default CheckoutTourismDomesticCancellationPolicy;
