import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import __ from "web/Layout/Translations";

import Loader from "web/Components/Common/Loader";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import urls from "web/constants/urls";

import {
  generateCssLinks,
  generateJsScripts,
  loadAppData,
} from "web/External/utils/assets-manifest";
import { getAccessToken } from "web/External/utils/authorization";

const customElementName = "company-events";

export const CompanyEvents = () => {
  const appRef = useRef();
  const [initAppData, setInitAppData] = useState(null);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  const { mybenefit_active_url: myBenefitActiveUrl } = useSelector(
    (state) => state.app.storeConfig
  );
  useEffect(() => {
    const initApp = async () => {
      const helmetContent = await loadAppData(customElementName);
      setInitAppData(helmetContent);
    };

    initApp().catch((e) => {
      newRelicErrorReport(
        e,
        "web-app/web/External/company-events/company-events.js - 34"
      );
      setHasLoadingError(true);
    });
  }, []);

  useEffect(() => {
    updateAppData();
  }, [initAppData]);

  const updateAppData = () => {
    if (appRef.current) {
      appRef.current.getAccessToken = getAccessToken;
    }
  };

  if (hasLoadingError) {
    return <>{__("Błąd pobierania danych")}</>;
  }

  if (!initAppData) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        {generateCssLinks(initAppData.appName, initAppData.entrypoints.css)}
        {generateJsScripts(initAppData.appName, initAppData.entrypoints.js)}
      </Helmet>
      <company-events
        ref={appRef}
        basename={urls.companyEvents}
        myBenefitActiveUrl={myBenefitActiveUrl}
      />
    </>
  );
};

export default withScrollToTop(CompanyEvents);
