/* eslint-disable react/no-array-index-key */
import DOMPurify from "dompurify";
import { FC } from "react";

import Section from "web/Pages/Checkout/TourismDomestic/SharedComponents/Section";

import __ from "web/Layout/Translations";

import Accordion from "web/Components/Common/Accordion";

import InfoIcon from "web/assets/icons/info.svg";

import classes from "./additionalCosts.scss";

interface ICheckoutTourismDomesticAdditionalCostsProps {
  remark: string[];
}

const CheckoutTourismDomesticAdditionalCosts: FC<
  ICheckoutTourismDomesticAdditionalCostsProps
> = ({ remark = [] }) => {
  return (
    <Section>
      <Accordion
        label={
          <div className={classes.labelContainer}>
            <InfoIcon className={classes.icon} />
            <span className={classes.label}>
              {__(
                "Dodatkowe koszty do zapłaty w obiekcie (niewliczone w cenę pobytu)"
              )}
            </span>
          </div>
        }
        variant="primary"
        arrow
        className={classes.accordionRoot}
        expanded
      >
        <div className={classes.content}>
          {remark.map((item, index) => (
            <div
              key={item + index}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item),
              }}
            />
          ))}
        </div>
      </Accordion>
    </Section>
  );
};

export default CheckoutTourismDomesticAdditionalCosts;
