import { FC } from "react";

import __ from "web/Layout/Translations";

import classes from "./prepaidCardType.scss";

interface PrepaidCardTypeProps {
  label: string;
}

const PrepaidCardType: FC<PrepaidCardTypeProps> = ({ label }) => {
  return (
    <aside className={classes.root}>
      {__("Typ produktu")}: <span>{__(label)}</span>
    </aside>
  );
};

export default PrepaidCardType;
