import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import SubscriptionGroupSummary from "web/Layout/SubscriptionGroupSummary";
import SubscriptionSummaryExtended from "web/Layout/SubscriptionGroupSummary/SubscriptionSummary/subscriptionSummaryExtended";
import __ from "web/Layout/Translations";

import Title from "web/Components/Common/Title";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import prepareGroupBenefitsSummary from "web/utils/page/customer/prepareGroupBenefitsSummary";
import filterOutHiddenBenefits from "web/utils/page/product/subscription/filterOutHiddenBenefits";

import {
  ISubscriptionItemCurrentUserSubscription,
  ISubscriptionUserCurrent,
  RelationBenefitAutomaticTypeConfiguration,
} from "web/types/Subscription";

import classes from "./successList.scss";

interface ISuccessListProps {
  data: ISubscriptionUserCurrent;
}

const titleClasses = { header: classes.header };

const CheckoutSubscriptionSuccessList: FC<ISuccessListProps> = ({ data }) => {
  const { benefitGroupType } = useSelector((state) => state.subscriptionQuote);

  const benefitsFiltered =
    filterOutHiddenBenefits<ISubscriptionItemCurrentUserSubscription>(
      data.items,
      RelationBenefitAutomaticTypeConfiguration.HIDE_WHEN_CHOOSING_BENEFITS
    );
  const { totals } = data || {};

  const benefitList = useMemo(() => {
    if (isArrayHasItems(benefitsFiltered)) {
      const formsDataByGroup =
        prepareGroupBenefitsSummary<ISubscriptionItemCurrentUserSubscription>(
          benefitsFiltered
        );
      return formsDataByGroup.map((group) => {
        const { benefitGroup: { groupPhoto, id } = {}, groupName } = group;
        const { contentUrl: groupPhotoURL = null } = groupPhoto || {};

        return (
          <SubscriptionGroupSummary
            key={id}
            benefitGroupType={benefitGroupType}
            className={classes.benefit}
            name={groupName}
            groupPhotoURL={groupPhotoURL}
            extendedView
          >
            {group.items.map((subscription: any) => {
              const {
                employerPriceType,
                employerPriceAlternativeText = null,
                pointsBankId,
              } = subscription;

              return (
                <SubscriptionSummaryExtended
                  key={subscription.id}
                  benefitGroupType={benefitGroupType}
                  data={subscription}
                  employerPriceType={employerPriceType}
                  employerPriceAlternativeText={employerPriceAlternativeText}
                  groupName={groupName}
                  pointsBankId={pointsBankId}
                  totals={totals}
                />
              );
            })}
          </SubscriptionGroupSummary>
        );
      });
    }
    return [];
  }, [data]);

  return isArrayHasItems(benefitList) ? (
    <>
      <Title
        name={__("Wybrane abonamenty")}
        classes={titleClasses}
        container="h2"
      />
      {benefitList}
    </>
  ) : null;
};

export default CheckoutSubscriptionSuccessList;
