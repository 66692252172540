import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import BreadcrumbsCommon from "web/Components/Common/Breadcrumbs";
import { IBreadcrumbsProps } from "web/Components/Common/Breadcrumbs/breadcrumbs";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import classes from "./breadcrumbs.scss";

const HelpCenterCategoryDesktopBreadcrumbs: FC<
  Partial<Pick<IBreadcrumbsProps, "breadcrumbs" | "loading">>
> = ({ breadcrumbs = [], loading = false }) => {
  const { isMobile } = useAppContext();

  const breadcrumbsItems = useMemo(() => {
    const firstItem = {
      label: __("Strona główna"),
      url: urls.home,
      dataT1: "breadcrumbs_main_page",
    };
    const helpCenterItem = {
      label: __("Centrum pomocy"),
      url: urls.helpCenter,
      dataT1: "breadcrumbs_help_center_page",
    };

    const basicBreadcumbs = isMobile
      ? [helpCenterItem]
      : [firstItem, helpCenterItem];

    return [...basicBreadcumbs, ...breadcrumbs];
  }, [breadcrumbs]);

  return (
    <BreadcrumbsCommon
      loading={loading}
      className={classes.root}
      breadcrumbs={breadcrumbsItems}
      noHome={isMobile}
    />
  );
};

export default HelpCenterCategoryDesktopBreadcrumbs;
