import { FC, useMemo } from "react";

import classes from "./dateBox.scss";

interface DateBoxProps {
  label?: string;
  date?: string;
  className?: string;
}

const DateBox: FC<DateBoxProps> = ({
  label = "",
  date = "",
  className = "",
}) => {
  const dateValue = useMemo(
    () =>
      new Date(date).toLocaleDateString("pl-PL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    [date]
  );

  return (
    <div className={`${classes.date} ${className}`}>
      <p>
        {label} <span>{dateValue}</span>
      </p>
    </div>
  );
};

export default DateBox;
