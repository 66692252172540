/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "web/Layout/Common/Loading";
import { withHelmetHeader } from "web/Layout/HelmetHeader";

import useInternetConnection from "web/hooks/useInternetConnection/useInternetConnection";
import usePaginationParams from "web/hooks/usePaginationParams";
import useUrlParams from "web/hooks/useUrlParams";

import { useAppContext } from "web/context/app";
import { getCodes } from "web/features/codes/codesThunks";
import { FilterValues } from "web/features/codes/utils/helpers";

import CodeListDesktop from "../Desktop/codeList";
import CodeListMobile from "../Mobile/codeList";

interface ICustomerCodeListContainerProps {
  className?: string;
  title?: string;
}

const CustomerCodeListContainer: FC<ICustomerCodeListContainerProps> = ({
  className = "",
  title = "",
}) => {
  const dispatch = useDispatch();
  const { isDisconnectedWebView } = useInternetConnection();
  const {
    items: codes = [],
    isLoading,
    isCodesGotten,
    toUseCount,
    usedCount,
    totalCount,
  } = useSelector((state) => state.codes);

  const { page } = usePaginationParams();

  const { availability } = useUrlParams("availability");

  useEffect(() => {
    if (isDisconnectedWebView) return;
    dispatch(getCodes({ page, filterValue: availability as FilterValues }));
  }, [isDisconnectedWebView, page]);

  const { isMobile } = useAppContext();

  if (!isCodesGotten && isLoading) {
    return <Loading />;
  }

  if (isMobile) {
    return (
      <CodeListMobile
        className={className}
        codes={codes}
        isLoading={isLoading}
        toUseCount={toUseCount}
        usedCount={usedCount}
        totalCount={totalCount}
      />
    );
  }

  return (
    <CodeListDesktop
      className={className}
      codes={codes}
      isLoading={isLoading}
      title={title}
      toUseCount={toUseCount}
      usedCount={usedCount}
      totalCount={totalCount}
    />
  );
};

export default withHelmetHeader<ICustomerCodeListContainerProps>(CustomerCodeListContainer);
