import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import {
  OutcomingWebViewMessageType,
  sendMessageToNativeApp,
} from "web/context/webView";

import simpleApiRequest from "./simpleApiRequest";

const getMimeType = (extension: string): string => {
  switch (true) {
    case ["png", "jpg", "jpeg"].includes(extension):
      return "image/*";
    case ["avi", "mp4", "mov"].includes(extension):
      return "video/*";
    case extension === "pdf":
      return "application/pdf";
    default:
      throw new Error(`Unsupported extension ${extension}`);
  }
};

const getExtensionByMimeType = (mimeType: string): string => {
  switch (mimeType) {
    case "image/jpeg":
      return "jpeg";
    case "image/png":
      return "png";
    case "video/x-msvideo":
      return "avi";
    case "video/quicktime":
      return "mov";
    case "video/mp4":
      return "mp4";
    case "application/pdf":
      return "pdf"
    default:
      throw new Error(`Unsupported mime type ${mimeType}`);
  }
}

const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(<string>reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const saveFileInNativeApp = async (blob: Blob, fileName: string) => {
  const data = await convertBlobToBase64(blob);
  const [prefix] = data.split(",");
  const mimeType = prefix.replace('data:', '').replace(';base64', '');
  const extension = getExtensionByMimeType(mimeType);

  sendMessageToNativeApp({
    type: OutcomingWebViewMessageType.SAVE_FILE,
    payload: {
      fileName: `${fileName}.${extension}`,
      mimeType,
      data,
    },
  });
};

const saveFile = (blob: Blob, fileName: string, openInNewTab: boolean) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  if (openInNewTab) {
    link.target = "_blank";
  } else {
    link.download = fileName;
  }
  link.click();
};

const downloadFile = async (
  source: string,
  fileName: string,
  isApiSource = false,
  extension?: string,
  openInNewTab = false
) => {
  const extensionProcessed = extension ?? source?.split(".").pop();
  const fileNameParsed = fileName?.replace(".", "_");

  try {
    const response = await simpleApiRequest(source, {
      responseType: "arraybuffer",
    });
    const file = await response.arrayBuffer();
    const blob = new Blob([file], {
      type: isApiSource
        ? `${response.headers.get("content-type")}`
        : getMimeType(extensionProcessed ?? ""),
    });

    if (window.ReactNativeWebView) {
      await saveFileInNativeApp(blob, fileName);
    } else {
      saveFile(blob, fileNameParsed, openInNewTab);
    }
  } catch (error) {
    newRelicErrorReport(error, "web-app/web/api/downloadFile.js - 107");
    console.error(error);
  }
};

export default downloadFile;
