// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQArticle-wrapper-x4t{display:flex;width:100%;gap:30px}.FAQArticle-content-a7U{display:flex;flex:1;flex-direction:column}`, "",{"version":3,"sources":["webpack://./web/Pages/FAQ/FAQArticle/FAQArticle.scss"],"names":[],"mappings":"AAIuB,wBACrB,YAAA,CACA,UAAA,CACA,QAAA,CAGF,wBACE,YAAA,CACA,MAAA,CACA,qBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .wrapper {\n  display: flex;\n  width: 100%;\n  gap: 30px;\n}\n\n.content {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FAQArticle-wrapper-x4t`,
	"content": `FAQArticle-content-a7U`
};
export default ___CSS_LOADER_EXPORT___;
