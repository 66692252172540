import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import PillowIcon from "web/assets/icons/pillow.svg";

import getRefundLabel from "web/utils/data/transform/getRefundLabel";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import {
  NAMES,
  getNameByCount,
} from "web/utils/page/product/domesticTourism/getNameByCount";
import getRoomsWithGuests from "web/utils/page/product/domesticTourism/getRoomsWIthGuests";

import { dayMilliseconds } from "web/constants/date";

import type { PropsWithClasses } from "web/types/Common";
import type { Guest, IAdultGuest, IRoom } from "web/types/Tourism";

import classify from "web/classify";

import defaultClasses from "./rooms.scss";

interface ICheckoutTourismDomesticRoomsProps {
  rooms: IRoom[];
  dateFrom: number;
  dateTo: number;
  guests?: Guest[];
}

type RoomWithQty = IRoom & { qty: number };

const CheckoutTourismDomesticRooms: FC<
  PropsWithClasses<ICheckoutTourismDomesticRoomsProps>
> = ({ rooms, dateFrom, dateTo, classes = {}, guests }) => {
  const honorificMapLabel = {
    MR: __("Pan"),
    MRS: __("Pani"),
  };

  const nights =
    +dateTo && +dateFrom
      ? Math.round((dateTo - dateFrom) / dayMilliseconds)
      : 0;

  const roomsWithQty = useMemo(() => {
    return rooms?.reduce<RoomWithQty[]>((result, current) => {
      const roomMhrIndex = result.findIndex(({ mhr }) => mhr === current.mhr);
      if (roomMhrIndex === -1) {
        return [...result, { ...current, qty: 1 }];
      }

      const roomMhrItem = result[roomMhrIndex];
      roomMhrItem.qty += 1;
      return result;
    }, []);
  }, [rooms]);

  const roomsWithGuests = getRoomsWithGuests(rooms, guests);

  return isArrayHasItems(rooms) ? (
    <>
      {isArrayHasItems(roomsWithGuests) ? (
        <div style={{ overflow: "auto" }}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.title}>{__("Pokoje")}</th>
                <th className={classes.titleDesktop}>{__("Goście")}</th>
              </tr>
            </thead>
            <tbody className={classes.root}>
              {roomsWithGuests.map((room) => {
                const refundLabel = getRefundLabel(room.cnx);
                return (
                  <tr className={classes.itemWithGuests} key={room.room_type}>
                    <td>
                      <div className={classes.innerWrap}>
                        <span className={classes.qty}>1x</span>
                        <header className={classes.header}>
                          <strong className={classes.name}>
                            {room.name_room}
                          </strong>
                        </header>
                      </div>
                      {room.sub_name_room ? (
                        <span className={classes.nameDetails}>
                          {room.sub_name_room}
                        </span>
                      ) : null}
                      <p className={classes.desc}>
                        {room.meal_name ? (
                          <>
                            <strong>{__("Wyżywienie")}:</strong>{" "}
                            {__(room.meal_name.trim())}
                            {room.meal_type_name
                              ? ` (${room.meal_type_name})`
                              : null}
                          </>
                        ) : null}
                      </p>
                      <p className={classes.refundLabel}>{refundLabel}</p>
                      {nights ? (
                        <div className={classes.total}>
                          <PillowIcon className={classes.icon} width={24} />
                          {nights} {__(getNameByCount(nights, NAMES.NIGHT))}
                        </div>
                      ) : null}
                    </td>

                    <td className={classes.titleMobile}>{__("Goście")}:</td>

                    <td>
                      <div className={classes.guests}>
                        {room.guests.map((guest, index) => {
                          const key = `${guest.first_name}${index}`;
                          const honorificLabel = (guest as IAdultGuest)
                            .honorific
                            ? honorificMapLabel[
                                (guest as IAdultGuest)
                                  .honorific as keyof typeof honorificMapLabel
                              ]
                            : __("Dziecko");
                          const name = `${guest.first_name} ${guest.last_name}`;
                          return (
                            <div className={classes.guest} key={key}>
                              {honorificLabel}
                              {": "}
                              <span className={classes.value}>{name}</span>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <ul className={classes.root}>
          {roomsWithQty.map((room) => {
            const refundLabel = getRefundLabel(room.cnx);
            return (
              <li className={classes.item} key={room.room_type}>
                <div className={classes.innerWrap}>
                  <span className={classes.qty}>{room.qty}x</span>
                  <header className={classes.header}>
                    <strong className={classes.name}>{room.name_room}</strong>
                  </header>
                </div>
                {room.sub_name_room ? (
                  <span className={classes.nameDetails}>
                    {room.sub_name_room}
                  </span>
                ) : null}
                <p className={classes.desc}>
                  {room.meal_name ? (
                    <>
                      <strong>{__("Wyżywienie")}:</strong>{" "}
                      {__(room.meal_name.trim())}
                      {room.meal_type_name ? ` (${room.meal_type_name})` : null}
                    </>
                  ) : null}
                </p>
                <p className={classes.refundLabel}>{refundLabel}</p>
                {nights ? (
                  <div className={classes.total}>
                    <PillowIcon className={classes.icon} width={24} />
                    {nights} {__(getNameByCount(nights, NAMES.NIGHT))}
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      )}
    </>
  ) : null;
};

export default classify<PropsWithClasses<ICheckoutTourismDomesticRoomsProps>>(
  defaultClasses
)(CheckoutTourismDomesticRooms);
