import { FC, RefObject } from "react";

import __ from "web/Layout/Translations";

import { useAppContext } from "web/context/app";

import PrepaidCardAside from "./PrepaidCardAside";
import PrepaidCardType from "./PrepaidCardType";
import Title from "./Title";
import classes from "./aside.scss";

interface AsideProps {
  bankTotalPoints: number;
  type: string;
  id: number;
  className: string;
  issuanceFee: number;
  issuanceFeePayer: string;
  rechargeCommission: number;
  rechargeCommissionPayer: string;
  chargeDeadlineDate: string;
  cardNumber: string;
  formRef?: RefObject<{ submitForm: () => void }>;
}

const Aside: FC<AsideProps> = ({
  bankTotalPoints,
  type = "",
  id,
  className = "",
  issuanceFee = 0,
  issuanceFeePayer = "",
  rechargeCommission,
  rechargeCommissionPayer,
  chargeDeadlineDate,
  cardNumber,
  formRef,
}) => {
  const title =
    type === "CREATE"
      ? `${__("Nowa karta przedpłacona")} + ${__("zasilenie")}`
      : __("Zasilenie karty przedpłaconej");

  const { isMobile } = useAppContext();

  return (
    <aside className={className}>
      <div className={classes.asideContainer}>
        {!isMobile && <PrepaidCardType label="Karta przedpłacona" />}
        <Title title={title} />
        <PrepaidCardAside
          bankTotalPoints={bankTotalPoints}
          id={id}
          type={type}
          issuanceFee={issuanceFee}
          issuanceFeePayer={issuanceFeePayer}
          rechargeCommission={rechargeCommission}
          rechargeCommissionPayer={rechargeCommissionPayer}
          chargeDeadlineDate={chargeDeadlineDate}
          cardNumber={cardNumber}
          formRef={formRef}
        />
      </div>
    </aside>
  );
};

export default Aside;
