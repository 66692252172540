import { FC } from "react";

import Points from "web/Layout/Common/Points";
import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";
import { IParsedOrder } from "web/types/Order";
import { IPaymentMethodItem } from "web/types/Payment";
import { MBProductType } from "web/types/Product";

import classes from "./paymentSources.scss";
import useSources from "./useSources";

interface ISuccessProductPaymentSourcesProps {
  className: string;
  item: IParsedOrder<MBProductType>["items"][number];
}

const SuccessProductPaymentSources: FC<ISuccessProductPaymentSourcesProps> = ({ className, item }) => {
  const sources = useSources(item?.payment_method as IPaymentMethodItem[]);

  return (
    <div className={className}>
      <strong className={classes.title}>{__("Źródła finansowania")}</strong>
      <ul>
        {sources.map(({ name, id, type, value, code }) => {
          const totalValue = value;
          const isPrice = type !== "BANK_OF_POINTS";
          const parsedName =
            typeof name !== "string" ? name?.props.children : name;
          return (
            <li
              className={classes.item}
              key={name as string}
              data-t1="financing_sources_item"
              data-t2={
                id === "OWN_PAYMENT" ? "own_payment" : code || parsedName
              }
            >
              <span
                className={classes.name}
                data-t1="payment_sources_title"
                data-t2={parsedName}
              >
                {name}
              </span>
              {isPrice ? (
                <Price
                  className={classes.value}
                  value={totalValue}
                  currency={__("zł")}
                  dataT1="payment_sources_price"
                />
              ) : (
                <Points
                  className={classes.value}
                  points={totalValue}
                  dataT1="payment_sources_points"
                  hidePointsText
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SuccessProductPaymentSources;
