import DOMPurify from "dompurify";
import { FC } from "react";

import classes from "./content.scss";

interface IHelpCenterArticleContentProps {
  content: string;
}

const HelpCenterArticleContent: FC<IHelpCenterArticleContentProps> = ({
  content,
}) => {
  return (
    <div
      className={classes.content}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    />
  );
};

export default HelpCenterArticleContent;
