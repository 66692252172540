import Points from "web/Layout/Common/Points";
import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

import { FeeType, FinancingSourceType } from "../../../../types";
import classes from "./item.scss";

export type FinancingSourcesItemType = Pick<
  FinancingSourceType,
  "bankName" | "amount"
>;

type ItemProps = {
  type: FeeType;
  total: number;
  financingSources: FinancingSourcesItemType[];
};

const Item = ({ financingSources, type, total }: ItemProps) => {
  const getTitle = (type: FeeType) => {
    switch (type) {
      case "CHARGE":
        return "Kwota zasilenia";
      case "ISSUANCE_FEE":
        return "Opłata za wydanie";
      case "RECHARGE_COMMISSION":
        return "Prowizja za zasilenie";
      default:
        return null;
    }
  };

  const title = getTitle(type);
  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.left}>
          {title ? __(title) : null}: {formatNumber(total)}
        </div>

        <div className={classes.right}>
          <p className={classes.smallText}>{__("Źródło finansowania")}</p>
          {financingSources?.map((el) => (
            <div className={classes.pointsBox}>
              <p>{__(el.bankName)}</p>
              <Points
                className={classes.pointsValue}
                points={el.amount}
                pointsFontSize={14}
                textFontSize={12}
              />
            </div>
          ))}
        </div>
      </div>
      <span className={classes.line} />
    </div>
  );
};

export default Item;
