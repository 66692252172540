/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import GtmEvent from "web/Layout/Gtm/GtmEvent";
import ProductCarousel, {
  ProductCarouselMobile,
} from "web/Layout/ProductCarousel";

import useGtmProductImpression from "web/hooks/useGtmProductImpression";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IProduct } from "web/types/Product";

import classesDesktop from "./productCarouselDesktop.scss";
import classesMobile from "./productCarouselMobile.scss";

interface IPageGeneratorProductCarouselSectionProps {
  isMobile?: boolean;
  products: IProduct[];
  headerFontColor?: string;
  header: string;
  listingPosition?: number;
  listingAmount?: number;
}

const PageGeneratorProductCarouselSection: FC<
  IPageGeneratorProductCarouselSectionProps
> = ({
  isMobile = false,
  products = [],
  headerFontColor = "",
  header = "",
  listingPosition,
  listingAmount = 0,
}) => {
  const classes = isMobile ? classesMobile : classesDesktop;
  const Carousel = isMobile ? ProductCarouselMobile : ProductCarousel;
  const [isGtmObjectReady, gtmEnhancedEcommOptions] = useGtmProductImpression(
    products,
    listingPosition!,
    listingAmount,
    header
  );

  return isArrayHasItems(products) ? (
    <section className={classes.root} data-t1="pageGeneratorProductCarousels">
      {header && (
        <h2
          className={classes.header}
          style={{ color: headerFontColor }}
          data-t1="pageGeneratorProductCarousels__header"
        >
          {header}
        </h2>
      )}
      <Carousel
        className={classes.products}
        products={products}
        listingPosition={listingPosition}
        listingAmount={listingAmount}
        listingCategory={header}
      />
      {isGtmObjectReady && gtmEnhancedEcommOptions && (
        <GtmEvent
          eventName="productImpression"
          // @ts-ignore
          options={gtmEnhancedEcommOptions}
        />
      )}
    </section>
  ) : null;
};

export default PageGeneratorProductCarouselSection;
