import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import __ from "web/Layout/Translations";

import Loader from "web/Components/Common/Loader";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import urls from "web/constants/urls";

import {
  generateCssLinks,
  generateJsScripts,
  loadAppData,
} from "web/External/utils/assets-manifest";
import { getAccessToken } from "web/External/utils/authorization";

const customElementName = "total-reward-statement";

export const TotalReward = () => {
  const appRef = useRef();
  const [initAppData, setInitAppData] = useState(null);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  useEffect(() => {
    const initApp = async () => {
      const helmetContent = await loadAppData(customElementName);
      setInitAppData(helmetContent);
    };

    initApp().catch((e) => {
      newRelicErrorReport(
        e,
        "web-app/web/External/total-reward/total-reward.js"
      );
      setHasLoadingError(true);
    });
  }, []);

  useEffect(() => {
    updateAppData();
  }, [initAppData]);

  const updateAppData = () => {
    if (appRef.current) {
      appRef.current.getAccessToken = getAccessToken;
    }
  };

  if (hasLoadingError) {
    return <>{__("Błąd pobierania danych")}</>;
  }

  if (!initAppData) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        {generateCssLinks(initAppData.appName, initAppData.entrypoints.css)}
        {generateJsScripts(initAppData.appName, initAppData.entrypoints.js)}
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <total-reward-statement
              ref={appRef}
              basename={urls.totalRewardStatement}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withScrollToTop(TotalReward);
