import {
  availableLanguagesKeys,
  convertToKeyIfCode,
  defaultLanguage,
} from "web/Layout/Translations/LanguageWrapper";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { ICustomer } from "web/types/Customer";

export const processCustomerPayload = (payload: ICustomer) => {
  const {
    id,
    group_id: groupId,
    store_id: storeId,
    website_id: websiteId,
    email,
    contact_email: contactEmail,
    firstname,
    lastname,
    city,
    login,
    phone,
    province,
    language,
    is_subscribed: isSubscribed,
    sso_role: ssoRole,
    impersonated,
    created_at: createdAt,
  } = payload;

  const profile = {
    gender: payload?.gender,
    ageRangeFrom: payload?.age_range_from,
    ageRangeTo: payload?.age_range_to,
    favoriteCites: payload?.favorite_cites,
    favoriteCategories: payload?.category_ids,
    favoriteOther: payload?.other,
  };

  const parsedLogin = parseLogin(login);

  return {
    id,
    groupId,
    storeId,
    websiteId,
    email,
    contactEmail,
    firstname,
    lastname,
    city,
    login: parsedLogin,
    phone,
    province,
    profile,
    language: availableLanguagesKeys.includes(convertToKeyIfCode(language))
      ? convertToKeyIfCode(language)
      : defaultLanguage,
    isSubscribed,
    ssoRole,
    impersonated,
    createdAt,
  };
};

const parseLogin = (login: string) => {
  try {
    if (!login) {
      return login;
    }
    const parts = login.split(":");
    if (parts.length < 2) {
      return login;
    }
    parts.pop();
    return parts.join(":");
  } catch (errorData) {
    newRelicErrorReport(
      errorData,
      "web-app/web/features/customer/customerApiSlice.ts - 224"
    );
    console.error(errorData);
    return login;
  }
};

export const mapCodeToMessage = (code: string) => {
  switch (code) {
    case "invalidPasswordHistoryMessage":
      return "Twoje nowe hasło nie może być takie samo jak jedno z ostatnio używanych haseł.";
    case "INVALID_PASSWORD":
      return "Podane hasło jest niepoprawne.";
    default:
      return "Wystąpił błąd";
  }
};
