import msProductsDomesticTourismQuery from "web/queries/ms/listingProductsDomesticTourismByIds.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import type { DomesticTourismProductInList } from "web/types/Product";

import {
  addProductsDomesticTourismEntries,
  removeProductsDomesticTourismEntries,
  selectEntities,
} from "../productsDomesticTourism/productsDomesticTourismSlice";
import useDataCached from "./useDataCached";

const mapData = new MapWrapper();

const useDataCachedProductsDomesticTourism = ({
  ids,
  skip,
  additionalParameters,
  additionalOptions,
}: {
  ids: number[];
  skip?: boolean;
  additionalParameters?: string;
  additionalOptions?: Record<string, unknown>;
}) =>
  useDataCached<DomesticTourismProductInList>({
    ids: ids.map((id) => +id),
    query: msProductsDomesticTourismQuery,
    addToCacheAction: addProductsDomesticTourismEntries,
    removeFromCacheAction: removeProductsDomesticTourismEntries,
    selectEntities,
    skip,
    additionalParameters,
    additionalOptions,
    mapData,
    isList: true,
  });

export default useDataCachedProductsDomesticTourism;
