import __ from "web/Layout/Translations";

import restUrls from "web/constants/restUrls";

import { IWishlistItem } from "web/types/Wishlist";

import api from "../api/apiSlice";
import { setNotificationSuccess } from "../app/appSlice";

export const wishlistApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getWishlist: builder.query<{ items: IWishlistItem[]; count: number }, void>(
      {
        query: () => restUrls.wishlist,
        providesTags: ["Wishlist"],
        transformResponse: (response: { wishlist_items: string[] }) => {
          const items = response.wishlist_items.map((item) => JSON.parse(item));
          return { items, count: items.length };
        },
      }
    ),
    getWishlistCount: builder.query<{ count: number }, void>({
      query: () => restUrls.wishlistCount,
      providesTags: ["Wishlist"],
    }),
    postWishlistItem: builder.mutation<any, string>({
      query: (sku) => ({
        url: restUrls.wishlistAddItem,
        method: "POST",
        body: { sku },
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const data = (await cacheDataLoaded) as any;
        if (data.meta.response.status === 200 && arg) {
          dispatch(
            setNotificationSuccess({
              message: __("Dodałeś produkt do ulubionych"),
            })
          );
        }
      },
      invalidatesTags: ["Wishlist"],
    }),
    deleteWishlistItem: builder.mutation<any, string>({
      query: (itemId) => ({
        url: restUrls.wishlistRemoveItem.replace("{itemId}", itemId),
        method: "DELETE",
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const data = (await cacheDataLoaded) as any;
        if (data.meta.response.status === 200 && arg) {
          dispatch(
            setNotificationSuccess({
              message: __("Usunąłeś produkt z ulubionych"),
            })
          );
        }
      },
      invalidatesTags: ["Wishlist"],
    }),
  }),
});

export const {
  useGetWishlistQuery,
  useGetWishlistCountQuery,
  usePostWishlistItemMutation,
  useDeleteWishlistItemMutation,
} = wishlistApiSlice;
