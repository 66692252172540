/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IFinalProductOption } from "web/hooks/useGetProductOptions/useGetProductOptions";

import getAttrFromJSON from "web/utils/data/parser/string/getAttrFromJSON";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import sendGtmEvent from "web/utils/system/GTM/sendGtmEvent";

import { ICart } from "web/types/Cart";
import { IStoreConfig } from "web/types/StoreConfig";
import { IPromoItems } from "web/types/Gtm";

import { ICartState } from "../cartSlice";

export interface ISendGtmAddListArgs {
  addedItems: {
    position: number;
    sku: string;
    qty: number;
    productId: string;
    productVariant: string;
    productCategory: string;
    productCategories: string[];
    parentProductName: string;
    parentProductId: string;
  }[];
  response: ICart;
  storeConfig: IStoreConfig;
  isReady: boolean;
  isWishlist?: boolean;
  promoItems: IPromoItems,
}

export function sendGtmAddList({
  addedItems,
  response,
  storeConfig,
  isReady,
  isWishlist = false,
  promoItems,
}: ISendGtmAddListArgs) {
  if (isArrayHasItems(response?.items) && isArrayHasItems(addedItems)) {
    const dataLayerTemp = window.dataLayer
      ? [...window.dataLayer].reverse()
      : [];
    const lastVisitedPage = dataLayerTemp.find((element) =>
      isWishlist
        ? element.event === "productImpression"
        : element.event === "detailImpression"
    );
    const listData = isWishlist
      ? lastVisitedPage?.ecommerce?.impressions?.[0]?.list
      : lastVisitedPage?.ecommerce?.detail?.actionField?.list;

    const gtmProductData = addedItems.map(
      ({
        sku,
        parentProductName,
        parentProductId,
        productId,
        productVariant,
        productCategory,
        qty,
        productCategories,
        position,
      }) => {
        const addedProductData = response.items.find(
          (item) => item.sku === sku
        );
        const { creative_slot, promotion_name } = promoItems?.[parentProductId] || promoItems?.[productId] || {};
        const addedProductDataObject = addedProductData
          ? {
              name: parentProductName || addedProductData.name || "",
              id: parentProductId || productId || "",
              price: addedProductData.price_with_discount.toString(),
              brand: getAttrFromJSON(
                addedProductData.product_data,
                "supplier_name"
              ),
              variant: productVariant,
              category: productCategory,
              quantity: qty,
              clickType: isWishlist
                ? "Dodaj listę do koszyka"
                : "Dodaj do koszyka",
              dimension16: productCategories,
              item_list_name: listData || "",
              ...(creative_slot && promotion_name ? { creative_slot, promotion_name } : {}),
              index: position || 1,
            }
          : {};
        if (position) {
          // @ts-ignore
          addedProductDataObject.position = position;
        }
        return addedProductDataObject;
      }
    );
    const gtmOptions = {
      ecommerce: {
        currencyCode: storeConfig?.base_currency_code || "",
        add: {
          actionField: {
            list: listData || "",
          },
          products: gtmProductData,
        },
      },
    };
    sendGtmEvent("addToCart", gtmOptions, isReady);
  }
}

interface ISendGtmRemoveArgs {
  id: string;
  options: IFinalProductOption[];
  cart: ICartState;
  storeConfig: IStoreConfig;
  promoItems: IPromoItems,
}

export function sendGtmRemove({
  id,
  options,
  cart,
  storeConfig,
  promoItems,
}: ISendGtmRemoveArgs) {
  if (isArrayHasItems(cart?.items)) {
    // @ts-ignore
    const removedProductData = cart.items.find((item) => item.item_id === +id);
    const productIdInCart = removedProductData?.parent_product_data?.[0]?.product_entity_id || removedProductData?.product_entity_id || "";
    const dataLayerTemp = window.dataLayer
      ? [...window.dataLayer].reverse()
      : [];
    const currentProductAddToCart =
      dataLayerTemp.find((element) =>
        element.event === "addToCart"
        && element?.ecommerce?.add?.products?.[0]?.id === `${productIdInCart}`) || {};
    const { creative_slot, promotion_name } = promoItems?.[removedProductData?.product_entity_id?.toString() || ""] || {};

    const gtmProductData = removedProductData
      ? {
          name: removedProductData?.name || "",
          id: removedProductData?.product_entity_id?.toString() || "",
          price: removedProductData.price_with_discount?.toString() || "",
          brand: getAttrFromJSON(
            removedProductData?.product_data,
            "supplier_name"
          ),
          category: options[0].fullCategoryPath,
          variant: options[0].fullAttributePath,
          quantity: removedProductData?.qty || "",
          // @ts-ignore
          ...(removedProductData?.tourism_city
            ? // @ts-ignore
              { location_name: removedProductData.tourism_city }
            : {}),
          dimension16: options[0].fullCategoriesPaths,
          item_list_name: currentProductAddToCart?.ecommerce?.add?.products?.[0]?.item_list_name || "Konto klienta:1:1:Koszyk",
          ...(creative_slot && promotion_name ? { creative_slot, promotion_name } : {}),
          index: currentProductAddToCart?.ecommerce?.add?.products?.[0]?.index || 1,
        }
      : {};
    const gtmOptions = {
      ecommerce: {
        currencyCode: storeConfig?.base_currency_code || "",
        remove: {
          products: [gtmProductData],
        },
      },
    };
    sendGtmEvent("removeFromCart", gtmOptions);
  }
}
