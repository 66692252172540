import { FC } from "react";

import type { Email, Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import ContactsDesktop from "./Desktop";
import ContactsMobile from "./Mobile";

export interface ICheckoutTourismDomesticContactsProps {
  phone: Nullable<string>;
  email: Nullable<Email>;
  site: Nullable<string>;
  street?: string;
  city?: string;
  postalCode?: string;
  provinceLabel?: string;
}

const CheckoutTourismDomesticContacts: FC<
  ICheckoutTourismDomesticContactsProps
> = ({
  phone = "",
  email = "" as Email,
  site = "",
  street = "",
  city = "",
  postalCode = "",
  provinceLabel = "",
}) => {
  const { isMobile, isPrint } = useAppContext();

  return isMobile && !isPrint ? (
    <ContactsMobile
      phone={phone}
      email={email}
      site={site}
      street={street}
      city={city}
      postalCode={postalCode}
      provinceLabel={provinceLabel}
    />
  ) : (
    <ContactsDesktop phone={phone} email={email} site={site} />
  );
};

export default CheckoutTourismDomesticContacts;
