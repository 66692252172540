import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { ICampaignGraphics } from "web/types/CampaignGraphics";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const campaignGraphicsAdapter =
  createEntityAdapter<ICachedItem<ICampaignGraphics>>();

const campaignGraphicsSlice = createSlice({
  name: "campaignGraphics",
  initialState: campaignGraphicsAdapter.getInitialState(),
  reducers: {
    addCampaignGraphicsEntries: campaignGraphicsAdapter.addMany,
    removeCampaignGraphicsEntries: campaignGraphicsAdapter.removeMany,
  },
});

export const { addCampaignGraphicsEntries, removeCampaignGraphicsEntries } =
  campaignGraphicsSlice.actions;

export const { selectEntities } =
  campaignGraphicsAdapter.getSelectors<RootState>(
    (state) => state.campaignGraphics
  );

export const campaignGraphicsSliceName = campaignGraphicsSlice.name;

export default campaignGraphicsSlice.reducer;
