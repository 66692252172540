import { PureComponent } from "react";

interface ISetStyleFromSrcProps {
  href: string;
}

class SetStyleFromSrc extends PureComponent<ISetStyleFromSrcProps> {
  get styleId() {
    const { href } = this.props;
    return encodeURIComponent(href);
  }

  loadStyle = () => {
    const { href } = this.props;
    const id = this.styleId;
    if (!document.getElementById(id)) {
      const el = document.createElement("link");
      el.href = href;
      el.id = id;
      el.rel = "stylesheet";
      el.type = "text/css";
      document.body.appendChild(el);
    }
  };

  deleteStyle = () => {
    const el = document.getElementById(this.styleId);
    if (el) {
      el.remove();
    }
  };

  componentDidMount = () => {
    this.loadStyle();
  };

  componentWillUnmount = () => {
    this.deleteStyle();
  };

  render = () => null;
}

export default SetStyleFromSrc;
