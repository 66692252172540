import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import ToolTip from "web/Components/Common/ToolTip";

import WalletIcon from "web/assets/icons/wallet.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { getPointsBankName } from "web/utils/page/product/subscription/prepareBenefitsPricesOption";

import { pointsPerMonth, zlotyPerMonth } from "web/constants/benefits";

import { ISubscriptionPointsBank } from "web/types/Subscription";

import { useGetEmployeeConfigurationQuery } from "web/features/employee/employeeApiSlice";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import styles from "./employeePrice.scss";

interface IEmployeePriceProps {
  employeePrice: string;
  employeePayUPrice?: string;
  employerTaxAmount: string;
  pointsBanks?: ISubscriptionPointsBank[];
  isFetching?: boolean;
}

const EmployeePrice: FC<IEmployeePriceProps> = ({
  employeePrice,
  employeePayUPrice,
  employerTaxAmount = "0",
  pointsBanks,
  isFetching = false,
}) => {
  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const { enableMonthlyDeductionInformation } = employeeConfig || {};
  const { data: dataBanks } = useGetBanksQuery();
  const { items: availablePointsBanks } = dataBanks || {};

  return (
    <>
      <strong className={styles.label} data-t1="payments_sumary_label_3">
        {__("Comiesięczne potrącenie z wynagrodzenia")}
      </strong>
      <div className={styles.price}>
        <div className={styles.icon}>
          <WalletIcon width={25} />
        </div>
        {isFetching ? (
          <Skeleton width={120} height={30} />
        ) : (
          <>
            <Price value={+employeePrice} currency={__(zlotyPerMonth)} />
            <ToolTip
              variant="top"
              message={__(
                "Wartość świadczeń, które zdecydowałeś się opłacać z własnych środków będzie co miesiąc potrącana z Twojego wynagrodzenia."
              )}
            />
          </>
        )}
      </div>

      {+employerTaxAmount && enableMonthlyDeductionInformation ? (
        <div className={styles.summary}>
          {+employeePrice ? (
            <span className={styles.legend}>{__("Dodatkowo:")}</span>
          ) : null}
          <dl className={styles.summary__list}>
            <dt className={styles.summary__label}>
              {__(
                "Wartość podatku i składek ZUS od abonamentów opłacanych przez pracodawcę:"
              )}
              <span className={styles.summary__desc}>
                {__("(Wartość orientacyjna dla 1. progu podatkowego)")}
              </span>
            </dt>
            <dd className={styles.summary__value}>
              ~<Price value={employerTaxAmount} currency={__(zlotyPerMonth)} />
              <ToolTip
                variant="right"
                customStyles={styles}
                message={__(
                  "Wartość świadczeń stanowi przychód pracownika, od którego naliczane są obowiązkowe składki na ZUS oraz podatek dochodowy. W przypadku świadczeń zwolnionych z PIT i/lub ZUS nie następują żadne potrącenia z tego tytułu."
                )}
              />
            </dd>
          </dl>
        </div>
      ) : null}

      {isArrayHasItems(pointsBanks) ? (
        <>
          <strong className={styles.label} data-t1="payments_sumary_label_4">
            {__("Comiesięczne potrącenie z banku punktów")}
          </strong>
          <div className={styles.bank__list}>
            {pointsBanks.map((bank) => {
              const pointsBankName = getPointsBankName(
                availablePointsBanks,
                bank.pointsBankId
              );

              return (
                <div className={styles.bank__item} key={bank.pointsBankId}>
                  <div className={styles.price}>
                    <div className={styles.icon}>
                      <WalletIcon width={25} />
                    </div>
                    <div>
                      <Price
                        value={bank.pointsBankPrice}
                        currency={__(pointsPerMonth)}
                      />
                      <span className={styles.bank__name}>
                        {pointsBankName}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}

      {employeePayUPrice && employeePayUPrice !== "0.00" ? (
        <>
          <strong className={styles.label} data-t1="payments_sumary_label_5">
            {__("Płatność PayU")}
          </strong>
          <div className={styles.price}>
            <div className={styles.icon}>
              <WalletIcon width={25} />
            </div>
            <Price value={employeePayUPrice} currency={__(zlotyPerMonth)} />
          </div>
          <span className={styles.summary__desc}>
            {__("Przejdziesz na platformę PayU w celu realizacji płatności.")}
          </span>
        </>
      ) : null}
    </>
  );
};

export default EmployeePrice;
