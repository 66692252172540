/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategoryCount } from "web/types/CategoryCount";
import { IParsedParameter } from "web/types/PageGenerator";

import { IPromoCounter } from "../pageGeneratorQuery";
import { createGtmImpressionProps, modifyImage } from "../utils/gtmPromoEvent";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

interface IPageGeneratorSliderContainerProps {
  isMobile?: boolean;
  params?: IParsedParameter;
  availableCategories?: ICategoryCount[];
  promoItem?: IPromoCounter;
}

const PageGeneratorSliderContainer: FC<IPageGeneratorSliderContainerProps> = ({
  isMobile = false,
  params = {},
  availableCategories = [],
  promoItem = {},
}) => {
  const slides = useMemo(() => {
    return Object.keys(params).map((key) => {
      // @ts-ignore
      return typeof params[key] !== "string" && params[key];
    });
  }, [JSON.stringify(params)]);

  const slidesToRender = useMemo(() => {
    return slides.filter((slide) => {
      if (slide.image_category_ids) {
        if (slide.image_category_ids.includes(",")) {
          const slideCategoryIdsArray = slide.image_category_ids.split(",");
          const areAllCategoriesAvailable = slideCategoryIdsArray.every(
            (slideIdCat: string) => {
              return (
                availableCategories &&
                availableCategories.find((availableCat) => {
                  return +availableCat.id === +slideIdCat;
                })
              );
            }
          );
          return areAllCategoriesAvailable ? slide : null;
        }
        return isArrayHasItems(availableCategories)
          ? availableCategories.find((category) => {
              return (
                slide.image_category_ids === category.id &&
                +category.product_count > 0
              );
            })
          : null;
      }
      return slide;
    });
  }, [slides]);

  useEffect(() => {
    if (!slidesToRender || !slidesToRender?.length) return;

    const gtmOptions = slidesToRender.map(({ graphic_file }, index) => {
      const name = modifyImage(graphic_file);
      return {
        name,
        position: index + (promoItem as IPromoCounter)?.promoPosition,
        promotion_name: name,
        creative_slot: index + (promoItem as IPromoCounter)?.promoPosition,
      };
    });

    if (gtmOptions) {
      createGtmImpressionProps(gtmOptions);
    }
  }, [slidesToRender]);

  return slides ? (
    <ErrorBoundary>
      {isMobile ? (
        <Mobile
          slides={slidesToRender}
          promoPosition={(promoItem as IPromoCounter)?.promoPosition || 0}
        />
      ) : (
        <Desktop
          slides={slidesToRender}
          promoPosition={(promoItem as IPromoCounter)?.promoPosition || 0}
        />
      )}
    </ErrorBoundary>
  ) : null;
};

export default PageGeneratorSliderContainer;
