// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productCarouselMobile-root-lvr{display:block;margin-top:30px;margin-bottom:30px}.productCarouselMobile-products-nuv{display:block;-ms-overflow-style:none;scrollbar-width:none;padding-bottom:30px}.productCarouselMobile-products-nuv::-webkit-scrollbar{display:none}.productCarouselMobile-header-zmx{display:block;font-size:22px;margin-bottom:20px;padding-left:20px}.productCarouselMobile-product-yC3{margin:0 15px}`, "",{"version":3,"sources":["webpack://./web/Pages/PageGenerator/ProductCarousel/productCarouselMobile.scss"],"names":[],"mappings":"AAIuB,gCACrB,aAAA,CACA,eAAA,CACA,kBAAA,CAGF,oCACE,aAAA,CACA,uBAAA,CACA,oBAAA,CACA,mBAAA,CAEA,uDACE,YAAA,CAIJ,kCACE,aAAA,CACA,cAAA,CACA,kBAAA,CACA,iBAAA,CAGF,mCACE,aAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.products {\n  display: block;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  padding-bottom: 30px;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.header {\n  display: block;\n  font-size: 22px;\n  margin-bottom: 20px;\n  padding-left: 20px;\n}\n\n.product {\n  margin: 0 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `productCarouselMobile-root-lvr`,
	"products": `productCarouselMobile-products-nuv`,
	"header": `productCarouselMobile-header-zmx`,
	"product": `productCarouselMobile-product-yC3`
};
export default ___CSS_LOADER_EXPORT___;
