import { FC } from "react";

import ProductDescriptionContainer from "web/Pages/Product/Shared/Description";

import __ from "web/Layout/Translations";

import classes from "./prepaidCardDescription.scss";

interface PrepaidCardDescriptionProps {
  product: {
    [key: string]: any;
  };
}

const PrepaidCardDescription: FC<PrepaidCardDescriptionProps> = ({
  product,
}) => {
  const { description, implementationRules, methodAndDateOfCardDelivery } =
    product;
  const text = `${description} <br> ${implementationRules} <br> ${methodAndDateOfCardDelivery}`;

  return (
    <div className={classes.details}>
      <ProductDescriptionContainer text={text} label={__("Opis")} isSmall />
    </div>
  );
};

export default PrepaidCardDescription;
