import { Button } from "@benefit-systems/common-components";
import { memo } from "react";
import { Link, Redirect } from "react-router-dom";

import Loading from "web/Layout/Common/Loading";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import PrintButton from "web/Components/Common/PrintButton";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import urls from "web/constants/urls";

import { OrderStatus } from "web/types/Order";

import OrderSummary from "../../Success/OrderSummary";
import Products from "../../Success/Products";
import TourismSuccess from "../../Success/TourismSuccess";
import ReservationPending from "./ReservationPending";
import classes from "./checkoutPendingDomesticTourism.scss";
import useOrderData from "./utils/useOrderData";

const getBannerClassName = (webpStatus: boolean, currentLanguage: string) => {
  if (currentLanguage === "en") {
    return webpStatus ? classes.bannerWebpEn : classes.bannerEn;
  }

  return webpStatus ? classes.bannerWebp : classes.banner;
};

const webpStatus = canUseWebp();
const currentLanguage = getLanguageCode();
const bannerClassName = getBannerClassName(webpStatus, currentLanguage);

const orderSuccessStatuses = [
  OrderStatus.PAID_BOOKING_CONFIRMED,
  OrderStatus.PARTIALLY_PAID_BOOKING_CONFIRMED,
];
const orderFailedStatuses = [
  OrderStatus.ROLL_BACK,
  OrderStatus.ROLL_BACK_ERROR,
  OrderStatus.ROLLED_BACK,
  OrderStatus.TO_CANCEL,
  OrderStatus.CANCELED,
  OrderStatus.CANCELED_ANIXE,
  OrderStatus.CANCELED_ERROR,
  OrderStatus.CANCELED_MISSING_PAYMENT,
  OrderStatus.CANCELED_ANIXE_ERROR,
  OrderStatus.BOOKING_FAILED,
  OrderStatus.BOOKING_ERROR,
  OrderStatus.BOOKING_CONFIRM_ERROR,
  OrderStatus.BOOKING_PAYMENT_ERROR,
];

const CheckoutPendingDomesticTourism = memo(
  ({ orderId }: { orderId: string }) => {
    const { order, isLoading } = useOrderData(orderId);

    if (isLoading || !order) {
      return <Loading />;
    }

    // when user refreshes checkout pending page and order status changed - redirect to correct checkout
    if (orderSuccessStatuses.includes(order.status_code)) {
      return (
        <Redirect
          to={urls.checkoutSuccess.replace(":orderId", `${order.order_id}`)}
        />
      );
    }

    if (orderFailedStatuses.includes(order.status_code)) {
      return <Redirect to={urls.checkoutFailed} />;
    }

    return (
      <div className={classes.root}>
        <Link to={urls.home}>
          <button
            type="button"
            className={classes.closeButton}
            value="Wróć do strony głównej"
            aria-label="close button"
          />
        </Link>

        <div className={classes.order}>
          <div className={bannerClassName} />
          <h1 className={classes.orderHeader}>
            {__("Dziękujemy za złożenie rezerwacji!")}
          </h1>
          <section className={classes.reservationPending}>
            <ReservationPending />
          </section>
          <ErrorBoundary>
            <OrderSummary order={order} isAnixeTourism isAnixePending />
          </ErrorBoundary>
        </div>
        <div className={classes.wrapper}>
          <div className="col col-9">
            <div className={classes.headerWrapper}>
              <h2
                className={classes.header}
                data-t1="success_page_details_header"
              >
                {__("Szczegóły zamówienia")}
              </h2>
              <PrintButton
                className={classes.print}
                label={__("Drukuj stronę")}
              />
            </div>
            <ErrorBoundary>
              <Products items={order.items} />
            </ErrorBoundary>

            <ErrorBoundary>
              <TourismSuccess order={order} />
            </ErrorBoundary>

            <div className={`${classes.back} nonPrintable`}>
              <Button
                to={urls.customerOrders}
                dataT1="success_page__button_continue_2"
              >
                {__("Przejdź do Moich zamówień")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default withScrollToTop(CheckoutPendingDomesticTourism);
