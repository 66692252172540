import { func, string } from "prop-types";
import { memo } from "react";

const OrdersSortItemMobile = memo(
  ({ title, subtitle, clickHandler, className }) => {
    const content = subtitle ? (
      <>
        <span data-t1={title} data-t2="sort_item">
          {title}:
        </span>{" "}
        {subtitle}
      </>
    ) : (
      <span data-t2={title} data-t1="sort_item">
        {title}
      </span>
    );

    return (
      <button
        type="button"
        className={className}
        onClick={clickHandler}
        data-t1="sort_item_button"
      >
        {content}
      </button>
    );
  }
);

OrdersSortItemMobile.propTypes = {
  className: string,
  title: string.isRequired,
  subtitle: string,
  clickHandler: func.isRequired,
};

OrdersSortItemMobile.defaultProps = {
  className: "button",
  subtitle: "",
};

export default OrdersSortItemMobile;
