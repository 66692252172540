import { createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { broadcastCartChange } from "web/Layout/Routes/utils/useCartRefreshHandler";
import __ from "web/Layout/Translations";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import { IFinalProductOption } from "web/hooks/useGetProductOptions/useGetProductOptions";

import sanitizeString from "web/utils/data/parser/string/sanitizeString";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import processCustomOptionsForCart from "web/utils/system/redux/processCustomOptionsForCart";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import { ENABLE_RIGHT_OF_WITHDRAWAL } from "web/constants/functionalities";
import restUrls from "web/constants/restUrls";
import storageNames from "web/constants/storageNames";
import urls from "web/constants/urls";

import type { ICart } from "web/types/Cart";
import type { Functionality } from "web/types/Employee";
import type { IPlaceOrder } from "web/types/Order";
import type { IStoreConfig } from "web/types/StoreConfig";
import type { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { AppDispatch, RootState } from "web/store";

import { setNotificationSuccess } from "../app/appSlice";
import { resetCodesCache } from "../codes/codesSlice";
import { customerApiSlice } from "../customer/customerApiSlice";
import { employeeApiSlice } from "../employee/employeeApiSlice";
import { invalidateBanks } from "../pointsBank/pointsBankApiSlice";
import { ICartState, reset } from "./cartSlice";
import {
  ISendGtmAddListArgs,
  sendGtmAddList,
  sendGtmRemove,
} from "./utils/gtm";

const storage = new BrowserPersistence();

type CartDispatch = ThunkDispatch<ICartState, unknown, Action>;

export const createCart = createAsyncThunk("cart/createCart", async () => {
  const response = (await request(restUrls.cart, {
    method: IApiRequestMethods.POST,
  })) as string;

  return response;
});

export const resetCart = () => (dispatch: CartDispatch) => {
  storage.removeItem(storageNames.cartId);
  broadcastCartChange();
  dispatch(reset());
};

export const createNewCart = () => (dispatch: CartDispatch) => {
  dispatch(resetCart());
  dispatch(createCart());
};

export const getCartDetails = createAsyncThunk(
  "cart/getCartDetails",
  async (arg, { dispatch, getState }) => {
    const { cart } = getState() as RootState;

    if (cart.isDetailsGotten) {
      return null;
    }

    try {
      const response = (await request(restUrls.cart)) as ICart;
      return response;
    } catch (error) {
      cartRestErrorHandler({
        error,
        dispatch,
        isGetCartDetails: true,
      } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

export interface IAddToCartItem {
  customOptionTitles: { id: string; title: string }[]; // todo
  customOptions: Nullable<string>;
  isReady: boolean;
  parentId: Nullable<string>;
  parentProductId: string;
  parentProductName: string;
  productCategories: string[];
  productCategory: string;
  productId: string;
  productVariant: string;
  qty: number;
  sku: string;
}

export const addItemToCart = createAsyncThunk(
  "cart/addItemToCart",
  async (item: IAddToCartItem, { dispatch, getState }) => {
    const {
      sku,
      qty = 1,
      customOptions,
      customOptionTitles,
      parentProductName,
      parentProductId,
      parentId,
      productId,
      productVariant,
      productCategory,
      productCategories,
      isReady,
    } = item;

    const productPosition = getSearchParameterAll({
      name: "position_id",
      search: window.location.search.slice(1),
    });
    const position = parseInt(productPosition.toString(), 10);

    const { cart, app, gtm } = getState() as RootState;
    const { cartId } = cart || {};
    const { promoItems } = gtm || {};

    if (!cartId) {
      await dispatch(createCart());
      dispatch(addItemToCart(item));
      return null;
    }

    const { storeConfig } = (app || {}) as { storeConfig: IStoreConfig };

    try {
      const customOptionsParsed = customOptions
        ? JSON.parse(customOptions)
        : null;
      const customOptionsProcessed = customOptionsParsed
        ? processCustomOptionsForCart(customOptionsParsed, customOptionTitles)
        : {};

      const response = (await request(restUrls.cartAddItem, {
        method: IApiRequestMethods.POST,
        body: JSON.stringify({
          cartItem: {
            sku,
            qty,
            quote_id: cartId,
          },
          ...(parentId ? { parentId } : {}),
          ...customOptionsProcessed,
        }),
      })) as ICart;

      const addedItem = [
        {
          position,
          sku,
          qty,
          productId,
          productVariant,
          productCategory,
          productCategories,
          parentProductName,
          parentProductId,
        },
      ];

      const gtmAddCartOptions = {
        addedItems: addedItem,
        response,
        storeConfig,
        isReady,
        promoItems
      };

      sendGtmAddList(gtmAddCartOptions);
      broadcastCartChange();
      return response;
    } catch (error) {
      cartRestErrorHandler({ error, dispatch } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

export type AddItemToCartType = typeof addItemToCart;

interface IRemoveFromCartItem {
  id: string;
  options: IFinalProductOption[];
}

export const removeItemFromCart = createAsyncThunk(
  "cart/removeItemfromCart",
  async (item: IRemoveFromCartItem, { dispatch, getState }) => {
    const { id, options } = item;

    const { cart, app, gtm } = getState() as RootState;
    const { cartId } = cart || {};
    const { storeConfig } = (app as { storeConfig: IStoreConfig }) || {};
    const { promoItems } = gtm || {};

    if (!cartId) {
      await dispatch(createCart());
      dispatch(removeItemFromCart(item));
      return null;
    }

    const endpoint = restUrls.cartRemoveItem.replace(":itemId", id);
    try {
      const response = await request(endpoint, {
        method: IApiRequestMethods.DELETE,
      });

      sendGtmRemove({ id, options, cart, storeConfig, promoItems });
      broadcastCartChange();
      return response;
    } catch (error) {
      cartRestErrorHandler({ error, dispatch } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

interface IUpdateItemQuantityPayload {
  id: string;
  sku: string;
  qty: number;
  customOptions?: string;
  customOptionTitles?: string[] | { id: string; title: string }[];
}

export const updateItemQuantity = createAsyncThunk(
  "cart/updateItemQuantity",
  async (payload: IUpdateItemQuantityPayload, { dispatch, getState }) => {
    const { id, sku, qty, customOptions, customOptionTitles } = payload || {};

    const { cart } = getState() as RootState;
    const cartId = cart?.cartId;

    try {
      const customOptionsParsed = customOptions
        ? JSON.parse(customOptions)
        : null;
      const customOptionsProcessed = processCustomOptionsForCart(
        customOptionsParsed,
        customOptionTitles
      );
      const response = await request(
        restUrls.cartUpdateItem.replace(":itemId", id),
        {
          method: IApiRequestMethods.PUT,
          body: JSON.stringify({
            cartItem: {
              sku,
              qty,
              quote_id: cartId,
              item_id: id,
            },
            ...customOptionsProcessed,
          }),
        }
      );
      broadcastCartChange();
      return response;
    } catch (error) {
      await cartRestErrorHandler({
        error,
        dispatch,
      } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

interface IAddItemListToCartPayload {
  items: {
    sku: string;
    qty: number;
  }[];
  isReady: boolean;
}

export const addItemListToCart = createAsyncThunk(
  "cart/addItemListToCart",
  async (payload: IAddItemListToCartPayload, { dispatch, getState }) => {
    const { items, isReady } = payload || {};

    const { cart, app, gtm } = getState() as RootState;
    const cartId = cart && cart.cartId;
    const storeConfig = app && app.storeConfig;
    const { promoItems } = gtm || {};

    if (!cartId) {
      await dispatch(createCart());
      dispatch(addItemListToCart(payload));
      return null;
    }

    if (!isArrayHasItems(items)) {
      return null;
    }

    try {
      await Promise.all(
        items.map(async ({ sku, qty }) => {
          await request(restUrls.cartAddItem, {
            method: IApiRequestMethods.POST,
            body: JSON.stringify({
              cartItem: {
                sku,
                qty,
                quote_id: cartId,
              },
            }),
          });
        })
      );
      dispatch(setNotificationSuccess({ message: __("Ponowiłeś zamówienie") }));
      const response = await request(restUrls.cart);

      const gtmAddListOptions = {
        addedItems: items,
        response,
        storeConfig,
        isReady,
        isWishlist: true,
        promoItems,
      };

      sendGtmAddList(gtmAddListOptions as ISendGtmAddListArgs);
      broadcastCartChange();
      return response;
    } catch (error) {
      dispatch(getCartDetails());
      cartRestErrorHandler({ error, dispatch } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

interface PlaceOrderPayload {
  push: ReturnType<typeof useHistory>["push"];
  type: string;
}

export const placeOrder = createAsyncThunk(
  "cart/placeOrder",
  async ({ push, type }: PlaceOrderPayload, { dispatch, getState }) => {
    const { cart } = getState() as RootState;
    const { cartId, paymentMethod } = cart || {};
    const VALUE_EMPTY = "-";

    if (!cartId) {
      await dispatch(createCart());
      dispatch(placeOrder({ push, type }));
      return null;
    }

    const tokenAccess = storage.getItem(storageNames.tokenAccess);

    try {
      const employee = await dispatch(
        employeeApiSlice.endpoints.getEmployeeDetails.initiate()
      ).unwrap();
      const customer = await dispatch(
        customerApiSlice.endpoints.getCustomerDetails.initiate()
      ).unwrap();

      const { firstname, lastname, phone, email: customerEmail } = customer;
      const email = customerEmail ?? VALUE_EMPTY;

      const updateOpts = ({ accessToken: updatedAccessToken }: { accessToken: string }) => {
        return {
          method: IApiRequestMethods.POST,
          body: JSON.stringify({
            allowWithdrawal: !!employee.functionalities?.includes(
              ENABLE_RIGHT_OF_WITHDRAWAL as Functionality
            ),
            paymentMethod: {
              method: paymentMethod,
            },
            billing_address: {
              email,
              firstname,
              lastname,
              city: VALUE_EMPTY,
              postcode: VALUE_EMPTY,
              telephone: phone,
              street: [VALUE_EMPTY],
              country_id: "PL",
            },
            tokenAccess: updatedAccessToken,
          }),
        };
      };

      const response = (await request(restUrls.placeOrder, updateOpts({ accessToken: tokenAccess }), null, undefined, updateOpts)) as IPlaceOrder;

      if (!response.redirect_url?.includes("http")) {
        invalidateBanks(dispatch as AppDispatch);
      }
      (dispatch as CartDispatch)(resetCart());
      dispatch(resetCodesCache());

      if (typeof response.redirect_url === "string") {
        push({
          pathname: urls.checkoutSplash,
          search: `?type=${type}&redirectUrl=${encodeURIComponent(
            response.redirect_url
          )}&orderId=${response.order_id}`,
        });
      }
      return response;
    } catch (error) {
      cartRestErrorHandler({ error, dispatch } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

export const setPaymentMethod = createAsyncThunk(
  "cart/setPaymentMethod",
  async (method: string, { dispatch }) => {
    try {
      const response = await request(restUrls.cartSelectPaymentMethod, {
        method: IApiRequestMethods.PUT,
        body: JSON.stringify({
          paymentMethod: {
            method: sanitizeString(method),
          },
        }),
      });

      return response;
    } catch (error) {
      newRelicErrorReport(
        error,
        "web-app/web/actions/cart/asyncActions.js - 546"
      );
      cartRestErrorHandler({ error, dispatch } as ICartRestErrorHandlerArgs);
      throw error;
    }
  }
);

export interface ICartRestErrorHandlerArgs {
  error: unknown;
  dispatch: CartDispatch;
  isGetCartDetails?: boolean;
}

interface IErrorWithResponse {
  response: { status: number };
}
export const cartRestErrorHandler = ({
  error,
  dispatch,
  isGetCartDetails = false,
}: ICartRestErrorHandlerArgs) => {
  if ((error as IErrorWithResponse)?.response) {
    switch ((error as IErrorWithResponse).response.status) {
      case 404: {
        dispatch(createNewCart());
        break;
      }
      case 400: {
        // protection - prevent requests loop on get cart details
        if (!isGetCartDetails) {
          dispatch(getCartDetails());
        }
        break;
      }
      default: {
        return null;
      }
    }
  }
  return null;
};
