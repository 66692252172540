/* eslint-disable react/jsx-props-no-spreading */
import { FC } from "react";

import Element from "web/Pages/PageGenerator/Triple/Element";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IParsedParameter } from "web/types/PageGenerator";

import { useDispatch } from "react-redux";
import onGtmPromoClick from "web/utils/system/GTM/onGtmPromoClick";
import classes from "./triple.scss";

interface IPageGeneratorTripleProps {
  images: IParsedParameter[];
  isMobile: boolean;
  promoPosition?: number;
}

const PageGeneratorTriple: FC<IPageGeneratorTripleProps> = ({
  images = [],
  isMobile,
  promoPosition = 0,
}) => {
  const dispatch = useDispatch();

  if (!isArrayHasItems(images)) return null;

  const parsedImages = images.map((image) => {
    return {
      headerFontColor: image.color_font_header,
      backgroundColor: image.color_background,
      descriptionFontColor: image.color_font_description,
      buttonFontColor: image.color_font_button,
      buttonBackgroundColor: image.color_background_button,
      imageSrc: image.background_file,
      url: image.url,
      button: image.button,
      header: image.header,
      description: image.description,
    };
  });

  return (
    <section className={`container ${classes.root}`}>
      <Element
        key="graphic_0"
        className={classes.large}
        {...parsedImages[0]}
        isLarge
        isMobile={isMobile}
        onClick={() =>
          onGtmPromoClick({
            promoSrc: parsedImages[0]?.imageSrc as string,
            promoPosition,
            link: parsedImages[0]?.url,
            dispatch,
          })
        }
      />
      <div className={classes.smallContainer}>
        <Element
          key="graphic_1"
          className={classes.small}
          {...parsedImages[1]}
          isMobile={isMobile}
          onClick={() =>
            onGtmPromoClick({
              promoSrc: parsedImages[1]?.imageSrc as string,
              promoPosition: 1 + promoPosition,
              link: parsedImages[1]?.url,
              dispatch,
            })
          }
        />
        <Element
          key="graphic_2"
          className={classes.small}
          {...parsedImages[2]}
          isMobile={isMobile}
          onClick={() =>
            onGtmPromoClick({
              promoSrc: parsedImages[2]?.imageSrc as string,
              promoPosition: 2 + promoPosition,
              link: parsedImages[2]?.url,
              dispatch,
            })
          }
        />
      </div>
    </section>
  );
};

export default PageGeneratorTriple;
