import { useEffect, useState } from "react";

import __ from "web/Layout/Translations";
import { IPaymentMethodItem } from "web/types/Payment";

const useSources = (paymentMethods: IPaymentMethodItem[]) => {
  const [sources, setSources] = useState<IPaymentMethodItem["values"]>([]);
  useEffect(() => {
    const allSources =
      paymentMethods?.reduce((result: IPaymentMethodItem["values"], { values }) => {
        return [...result, ...values];
      }, []) || [];
    let ownPaymentValue = 0;
    const mergeSources = allSources.reduce((result: IPaymentMethodItem["values"], item) => {
      if (item.type !== "ONLINE_PAYMENT") {
        return [...result, item];
      }
      ownPaymentValue += +item.value;
      return result;
    }, []);
    if (ownPaymentValue > 0) {
      mergeSources.push({
        name: (
          <span data-t1="payment_own_title" data-t2="own_payment">
            {__("Płatność własna")}
          </span>
        ) as JSX.Element,
        type: "OWN_PAYMENT",
        value: ownPaymentValue,
        id: "OWN_PAYMENT",
      });
    }

    setSources(mergeSources);
  }, [JSON.stringify(paymentMethods)]);

  return sources;
};

export default useSources;
