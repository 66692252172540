import { getOperationName } from "@apollo/client/utilities";
import { DocumentNode, print } from "graphql";

import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import deleteGraphqlExtraSpaces from "web/utils/system/query/deleteGraphqlExtraSpaces";
import graphQlAsGet from "web/utils/system/query/graphQlAsGet";

import { request } from "web/api";

const fetchPageSize = 100;

interface IFetchLackIdsArgs {
  ids: (number | string)[];
  query: DocumentNode;
  storeId: number;
  token: string;
  endpoint: string;
  additionalParameters?: string;
  additionalOptions?: Record<string, unknown>;
  isIdsFacetsStatsData?: boolean;
  isBlock?: boolean;
  isCustomerMenu?: boolean;
  isMenu?: boolean;
  variables?: Record<string, unknown>;
}

const fetchLackIds = ({
  ids,
  query,
  storeId,
  token,
  endpoint,
  additionalParameters,
  additionalOptions,
  isIdsFacetsStatsData,
  variables,
}: IFetchLackIdsArgs): Promise<unknown[]> => {
  const pages = Math.ceil(ids.length / fetchPageSize);
  const actions = [];

  const queryProcessed = print(query);
  const queryProcessedWithoutSpaces = deleteGraphqlExtraSpaces(queryProcessed);
  const operationName = getOperationName(query);

  if (isIdsFacetsStatsData && ids.length) {
    const options = {
      method: IApiRequestMethods.POST,
      body: JSON.stringify({
        query: queryProcessedWithoutSpaces,
        operationName,
        variables: {
          ...(variables || {}),
          ...JSON.parse(ids[0] as string)?.variables,
          token,
          storeId,
        },
      }),
    };
    actions.push(graphQlAsGet(endpoint, options, request));
    return Promise.all(actions);
  }

  for (let page = 1; page <= pages; page += 1) {
    const pageIds = ids.slice((page - 1) * fetchPageSize, page * fetchPageSize);
    const options = {
      method: IApiRequestMethods.POST,
      body: JSON.stringify({
        query: queryProcessedWithoutSpaces,
        operationName,
        variables: {
          ...(variables || {}),
          storeId,
          token,
          ids: pageIds,
          lang: getLanguageCode(),
          ...(additionalParameters ? { additionalParameters } : {}),
          ...(additionalOptions ?? {}),
        },
      }),
    };
    actions.push(graphQlAsGet(endpoint, options, request));
  }
  return Promise.all(actions);
};

export default fetchLackIds;
