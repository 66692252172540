import { FC, useEffect, useState } from "react";

import MinusIcon from "web/assets/icons/minus.svg";
import PlusIcon from "web/assets/icons/plus.svg";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./plusMinusInput.scss";

interface IDomesticTourismFormPlusMinusInputProps {
  currentValue?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (val: number) => void;
}

const DomesticTourismFormPlusMinusInput: FC<
  PropsWithClasses<IDomesticTourismFormPlusMinusInputProps>
> = ({
  currentValue = 1,
  minValue = 1,
  maxValue = 10,
  onChange = () => {},
  classes = {},
}) => {
  const [current, setCurrent] = useState(currentValue);

  useEffect(() => {
    onChange(current);
  }, [current]);

  return (
    <div className={classes.root}>
      <button
        type="button"
        className={classes.button}
        disabled={current <= minValue}
        onClick={() => {
          setCurrent(current - 1);
        }}
        aria-label="Decrease count"
      >
        <MinusIcon className={classes.icon} />
      </button>
      <input className={classes.input} value={current} readOnly />
      <button
        type="button"
        className={classes.button}
        disabled={current >= maxValue}
        onClick={() => {
          setCurrent(current + 1);
        }}
        aria-label="Increase count"
      >
        <PlusIcon className={classes.icon} />
      </button>
    </div>
  );
};

export default classify<
  PropsWithClasses<IDomesticTourismFormPlusMinusInputProps>
>(defaultClasses)(DomesticTourismFormPlusMinusInput);
