/* eslint-disable camelcase */
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import getProductConfigurableData from "web/Pages/Checkout/Default/utils/getProductConfigurableData";

import __ from "web/Layout/Translations";

import Loader from "web/Components/Common/Loader";

import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import { IParsedCodeItem, OrderStatus } from "web/types/Order";

import classes from "./orderSummary.scss";
import OrderSummaryShowCode from "./orderSummaryShowCode";

const htmlDataKey = "orderSummaryCodes";

interface ISuccessOrderSummaryCodesProps {
  universalCodes: IParsedCodeItem[];
  statusCode?: OrderStatus;
}

const SuccessOrderSummaryCodes: FC<ISuccessOrderSummaryCodesProps> = ({
  universalCodes,
  statusCode,
}) => {
  const innerScrollBox = universalCodes.length > 5;

  const codesList = useMemo(
    () =>
      universalCodes.map((item) => {
        const {
          name,
          item_id,
          product_data,
          product_used,
          product_expiration_date,
          product_code_business_id,
          payment_status,
          sku,
        } = item;
        const [, , urlKeyMemo] = getProductConfigurableData(
          product_data,
          item.product_data
        );
        const urlToBeProcessed =
          item?.parent_product_data?.url_key || urlKeyMemo;
        const productLink = processUrlKey(urlToBeProcessed as string);
        const codeIsComplete =
          statusCode &&
          statusCode === "paid_claimed_codes" &&
          !!product_code_business_id;
        const isExpired =
          product_expiration_date &&
          (Date.parse(product_expiration_date.replace(/-/g, "/")) || 0) <
            Date.now();

        const isCanceled = isExpired || product_used;

        return (
          <div key={item_id} className={classes.codesItem}>
            <div className={classes.codesItem__value}>
              <Link to={productLink}>
                <h3>{__(name)}</h3>
              </Link>

              {!codeIsComplete && (
                <p>
                  <Loader /> {__("Czekamy na Twój kod")}...
                </p>
              )}
            </div>

            {codeIsComplete && (
              <OrderSummaryShowCode
                htmlDataKey={htmlDataKey}
                name={name}
                itemId={item_id}
                isCanceled={!!isCanceled}
                orderPaid={payment_status === "PAID"}
                sku={sku}
              />
            )}
          </div>
        );
      }),
    [universalCodes]
  );

  return (
    <div className={classes.gridItem}>
      <div className={classes.gap}>
        <div className={classes.orderNumber} data-t1={`${htmlDataKey}__title`}>
          <h2>{__("Twoje kody")}:&nbsp;</h2>
          <p
            className={classes.description}
            data-t1={`${htmlDataKey}__description`}
          >
            {__(
              "Kody są widoczne również w szczegółach zamówienia w Moich zamówieniach"
            )}
          </p>
        </div>

        <div
          className={`${classes.codesList} ${
            innerScrollBox ? classes.scrollBar : ""
          }`}
        >
          {codesList}
        </div>
      </div>
    </div>
  );
};

export default SuccessOrderSummaryCodes;
