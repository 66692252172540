import { FC, useState } from "react";

import __ from "web/Layout/Translations";

import ArrowUpIcon from "web/assets/icons/arrow-up.svg";
import AccessibleRoomsIcon from "web/assets/icons/tourism/facilities/accesible-rooms.svg";
import AirConditioningIcon from "web/assets/icons/tourism/facilities/air-conditioning.svg";
import AirportShuttleIcon from "web/assets/icons/tourism/facilities/airport-shuttle.svg";
import BeachAccessIcon from "web/assets/icons/tourism/facilities/beach-access.svg";
import BikeRentalIcon from "web/assets/icons/tourism/facilities/bike-rental.svg";
import BusinessFacilitiesIcon from "web/assets/icons/tourism/facilities/business-facilities.svg";
import ChildrenClubIcon from "web/assets/icons/tourism/facilities/children-club.svg";
import ChildrenPoolIcon from "web/assets/icons/tourism/facilities/children-pool.svg";
import FitnessIcon from "web/assets/icons/tourism/facilities/fitness.svg";
import IndoorPool from "web/assets/icons/tourism/facilities/indoor-pool.svg";
import OutdoorPoolIcon from "web/assets/icons/tourism/facilities/outdoor-pool.svg";
import ParkingIcon from "web/assets/icons/tourism/facilities/parking.svg";
import PetsWelcomeIcon from "web/assets/icons/tourism/facilities/pets-welcome.svg";
import RestaurantIcon from "web/assets/icons/tourism/facilities/restaurant.svg";
import SkiFacilitiesIcon from "web/assets/icons/tourism/facilities/ski-facilities.svg";
import SpaFitnessIcon from "web/assets/icons/tourism/facilities/spa-fitness.svg";
import WlanIcon from "web/assets/icons/tourism/facilities/wlan.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IAttributeOption } from "web/types/Attributes";
import { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import classesDesktop from "./amenities.scss";
import classesMobile from "./amentiesMobile.scss";
import Section from "./section";

export const facilitiesIcons = [
  { label: "Klimatyzacja", icon: AirConditioningIcon },
  {
    label: "Transfer do/z lotniska",
    icon: AirportShuttleIcon,
  },
  {
    label: "Wewnętrzny basen",
    icon: IndoorPool,
  },
  {
    label: "Zewnętrzny basen",
    icon: OutdoorPoolIcon,
  },
  {
    label: "Parking",
    icon: ParkingIcon,
  },
  {
    label: "Zwierzęta mile widziane",
    icon: PetsWelcomeIcon,
  },
  {
    label: "SPA",
    icon: SpaFitnessIcon,
  },
  {
    label: "Wifi",
    icon: WlanIcon,
  },
  { label: "Basen dla dzieci", icon: ChildrenPoolIcon },
  { label: "Miejsce zabaw", icon: ChildrenClubIcon },
  { label: "Restauracja", icon: RestaurantIcon },
  { label: "Wypożyczalnia rowerów", icon: BikeRentalIcon },
  { label: "Zaplecze biznesowe", icon: BusinessFacilitiesIcon },
  { label: "Zaplecze narciarskie", icon: SkiFacilitiesIcon },
  { label: "Dostęp do plaży", icon: BeachAccessIcon },
  { label: "Fitness", icon: FitnessIcon },
  {
    label: "Pokoje z udogodnieniami dla niepełnosprawnych",
    icon: AccessibleRoomsIcon,
  },
];

const QUANTITY = 6;

interface IProductAmenitiesProps {
  facilities: Nullable<IAttributeOption[]>;
  forKids: Nullable<IAttributeOption[]>;
  others: Nullable<IAttributeOption[]>;
}

const ProductAmenities: FC<IProductAmenitiesProps> = ({
  facilities = [],
  forKids = [],
  others = [],
}) => {
  const { isMobile } = useAppContext();
  const classes = isMobile ? classesMobile : classesDesktop;
  const [openStatus, setOpenStatus] = useState(false);
  const isMoreThenQuantity =
    isArrayHasItems(facilities) && facilities.length > QUANTITY;
  const isExpandable =
    isMoreThenQuantity ||
    (isArrayHasItems(facilities) &&
      (isArrayHasItems(forKids) || isArrayHasItems(others)));
  const toggleStatus = () => setOpenStatus(!openStatus);
  const facilitiesProcessed = isMoreThenQuantity
    ? facilities.slice(0, openStatus ? facilities.length : QUANTITY)
    : facilities;
  const triggerClassName = openStatus ? classes.triggerActive : classes.trigger;
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <strong className={classes.title}>{__("Udogodnienia")}</strong>
        {isExpandable ? (
          <button
            className={triggerClassName}
            type="button"
            onClick={toggleStatus}
          >
            <ArrowUpIcon className={classes.arrow} />
          </button>
        ) : null}
      </header>
      <div className={classes.content}>
        {isArrayHasItems(facilitiesProcessed) ? (
          <ul className={classes.list}>
            {facilitiesProcessed.map((facility) => {
              const FacilityIcon = facilitiesIcons.find(
                (icon) => icon.label === facility.code
              )?.icon;
              return (
                <li key={facility.label} className={classes.item}>
                  {!!FacilityIcon && <FacilityIcon className={classes.icon} />}
                  {facility.label}
                </li>
              );
            })}
          </ul>
        ) : null}
        {(isExpandable && openStatus) ||
        (!isExpandable &&
          (isArrayHasItems(forKids) || isArrayHasItems(others))) ? (
          <div className={classes.additional}>
            {isArrayHasItems(forKids) ? (
              <Section
                className={classes.section}
                title={__("Dla dzieci")}
                items={forKids}
              />
            ) : null}
            {isArrayHasItems(others) ? (
              <Section
                className={classes.section}
                title={__("Inne")}
                items={others}
              />
            ) : null}
          </div>
        ) : null}
        {isExpandable ? (
          <div className={classes.actions}>
            <button
              className={classes.more}
              type="button"
              onClick={toggleStatus}
            >
              {openStatus ? __("Zobacz mniej") : __("Zobacz więcej")}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductAmenities;
