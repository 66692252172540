// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeletonLoader-root-SUS{width:100%;display:flex;justify-content:center;align-items:center}`, "",{"version":3,"sources":["webpack://./web/Pages/PageGenerator/ProductCarousel/skeletonLoader.scss"],"names":[],"mappings":"AAIuB,yBACrB,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `skeletonLoader-root-SUS`
};
export default ___CSS_LOADER_EXPORT___;
