import { useSelector } from "react-redux";

import msProductsIdsFacetsStatsQuery from "web/queries/ms/productsIdsFacetsStatsByCategoryId.graphql";
import msProductsIdsFacetsStatsQuerySub from "web/queries/ms/productsIdsFacetsStatsByCategoryIdSub.graphql";
import searchQuery from "web/queries/ms/search.graphql";
import searchQuerySub from "web/queries/ms/searchSub.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { FacetsStats } from "web/types/Product";

import {
  addFacetsStatsEntries,
  removeFacetsStatsEntries,
  selectEntities,
} from "../facetsStats/facetsStatsSlice";
import useDataCached from "./useDataCached";
import transFormFacetsStatsData from "./utils/transformFacetsStatsData";

const mapData = new MapWrapper();

const queries = {
  search: {
    default: searchQuery,
    sub: searchQuerySub,
  },
  facetsStats: {
    default: msProductsIdsFacetsStatsQuery,
    sub: msProductsIdsFacetsStatsQuerySub,
  },
};

const useDataCachedIdsFacetsStats = ({
  options,
  isSearch,
}: {
  options: string;
  isSearch?: boolean;
}) => {
  const { hasEnabledAnySubscriptions, isLoading } = useSelector(
    (state) => state.subscriptionQuote
  );
  const queryGroup = isSearch ? queries.search : queries.facetsStats;
  const query = hasEnabledAnySubscriptions
    ? queryGroup.sub
    : queryGroup.default;

  const {
    data: searchData,
    error,
    loading,
  } = useDataCached<FacetsStats>({
    ids: [options],
    query,
    addToCacheAction: addFacetsStatsEntries,
    removeFromCacheAction: removeFacetsStatsEntries,
    selectEntities,
    isIdsFacetsStatsData: true,
    mapData,
    skip: isLoading,
  });
  return { data: transFormFacetsStatsData(searchData), error, loading };
};

export default useDataCachedIdsFacetsStats;
