import { FC } from "react";

import __ from "web/Layout/Translations";

import ExternalLink from "web/Components/Common/ExternalLink/externalLink";

import EnvelopeIcon from "web/assets/icons/envelope-closed.svg";
import TelephoneIcon from "web/assets/icons/telephone.svg";
import WwwIcon from "web/assets/icons/www.svg";

import type { Email } from "web/types/Utils";

import { ICheckoutTourismDomesticContactsProps } from "../contacts";
import classes from "./contacts.scss";

const CheckoutTourismDomesticContactsMobile: FC<
  ICheckoutTourismDomesticContactsProps
> = ({
  phone = "",
  email = "" as Email,
  site = "",
  street = "",
  city = "",
  postalCode = "",
  provinceLabel = "",
}) => {
  const infoArray = [
    street,
    city,
    postalCode,
    provinceLabel && __(provinceLabel),
  ].filter((element) => !!element);
  const isInfo = infoArray.length > 0;

  return phone || email || site ? (
    <>
      <div className={classes.root}>
        <span className={classes.title}>{__("Kontakt do obiektu")}</span>
        {isInfo && (
          <span className={classes.address}>{infoArray.join(", ")}</span>
        )}
        {phone ? (
          <div className={classes.item}>
            <TelephoneIcon className={classes.icon} width={20} />
            <a className={classes.link} href={`tel:${phone}`} title={phone}>
              {phone}
            </a>
          </div>
        ) : null}
        {email ? (
          <div className={classes.item}>
            <EnvelopeIcon className={classes.icon} width={22} />
            <a className={classes.link} href={`mailto:${email}`} title={email}>
              {email}
            </a>
          </div>
        ) : null}
        {site ? (
          <div className={classes.item}>
            <WwwIcon className={classes.icon} width={22} />
            <ExternalLink className={classes.link} url={site} />
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

export default CheckoutTourismDomesticContactsMobile;
