/* eslint-disable react/no-array-index-key */
import { Field, FieldArray, useFormikContext } from "formik";
import { func, number } from "prop-types";
import { useEffect } from "react";

import PlusMinusInput from "web/Pages/Tourism/DomesticTourismForm/PlusMinusInput";
import Child from "web/Pages/Tourism/DomesticTourismForm/Room/Child";

import __ from "web/Layout/Translations";

import { names } from "../../domesticTourismFormFields";
import classes from "./people.scss";

const DomesticTourismFormRoomPeople = ({ index: roomIndex, onChange }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values[names.rooms][roomIndex]);
  }, [values]);

  const changeAdultsValue = (value, field, form) => {
    form.getFieldHelpers(field.name).setValue(value);
  };

  const changeChildrenValue = (value, arrayHelpers) => {
    const difference = value - values[names.rooms][roomIndex].children.length;
    if (value > values[names.rooms][roomIndex].children.length) {
      Array(difference)
        .fill({ age: 1 })
        .map((kido) => arrayHelpers.push(kido));
    }
    if (value < values[names.rooms][roomIndex].children.length) {
      arrayHelpers.pop();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {__("Pokój")} {roomIndex + 1}
      </div>
      <div className={classes.persons}>
        <div className={classes.adults}>
          <div className={classes.label}>
            {__("Dorośli")}
            <sup>(18+)</sup>
          </div>
          <Field name={`${names.rooms}[${roomIndex}].adults`}>
            {({ field, form }) => (
              <PlusMinusInput
                currentValue={field.value}
                minValue={1}
                maxValue={30}
                classes={{ button: classes.inputButton }}
                onChange={(value) => changeAdultsValue(value, field, form)}
              />
            )}
          </Field>
        </div>
        <div className={classes.children}>
          <div className={classes.label}>{__("Dzieci")}</div>
          <div className={classes.input}>
            <FieldArray name={`${names.rooms}[${roomIndex}].children`}>
              {(arrayHelpers) => (
                <PlusMinusInput
                  currentValue={values[names.rooms][roomIndex].children.length}
                  minValue={0}
                  maxValue={10}
                  classes={{ button: classes.inputButton }}
                  onChange={(value) => changeChildrenValue(value, arrayHelpers)}
                />
              )}
            </FieldArray>
          </div>
        </div>
      </div>
      {values[names.rooms][roomIndex].children.length > 0 ? (
        <div className={classes.childrenSettings}>
          <div className={classes.label}>
            {__("Podaj wiek dziecka w dniu zakwaterowania")}
          </div>
          <div className={classes.innerContainer}>
            {values[names.rooms]?.[roomIndex]?.children?.map(
              (child, childIndex) => (
                <Child
                  key={childIndex}
                  roomIndex={roomIndex}
                  childIndex={childIndex}
                  childAge={child.age}
                />
              )
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

DomesticTourismFormRoomPeople.propTypes = {
  index: number.isRequired,
  onChange: func,
};

DomesticTourismFormRoomPeople.defaultProps = {
  onChange: () => {},
};

export default DomesticTourismFormRoomPeople;
