import DOMPurify from "dompurify";
import { FC, useEffect, useState } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import LanguageSwitcherExternalForm from "web/Layout/Common/LanguageSwitcherExternalForm/languageSwitcherExternalForm";
import Loading from "web/Layout/Common/Loading";
import ActiveForm from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveForm";

import Title from "web/Components/Common/Title";
import withScrollToTop from "web/Components/Common/withScrollToTop";

import { IApiRequestMethods, IRequestOpts } from "web/api/apiRequestTypes";

import parseObjectToGetParameters from "web/utils/data/parser/object/parseObjectToGetParameters";
import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";
import urls from "web/constants/urls";

import {
  IActiveFormValues,
  ISubscriptionReceiverFormField,
  SubscriptionActiveFormData,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";

import classes from "./externalform.scss";

const infoClasses = { header: classes.infoTitle };
const titleClasses = { header: classes.title };

type IForm = {
  availableLanguages: string[];
  name: string;
  description: string;
  languages: [];
  data: SubscriptionActiveFormData[];
  fields: ISubscriptionReceiverFormField[];
} | null;

type IErrorData = {
  response: {
    status: number;
  };
};

const defaultFormData = {
  edited: false,
  error: false,
  fields: {},
  filledInByUser: false,
};

const ExternalForm: FC<{ token: string; subscriptionItemId: string }> = ({
  token,
  subscriptionItemId,
}) => {
  const [form, setForm] = useState<IForm>(null);
  const [completedInfo, setCompletedInfo] = useState<Nullable<string[]>>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | IErrorData>(false);
  const [lang, setLang] = useState({ key: "pl_PL", code: "pl" });

  useEffect(() => {
    const fetchData = async (
      requestUrl: string,
      options: IRequestOpts,
      setData: (arg: IForm) => void
    ) => {
      setLoading(true);
      try {
        const response = (await request(requestUrl, options)) as IForm;
        setData(response);
      } catch (errorData) {
        newRelicErrorReport(
          errorData,
          "web-app/web/Pages/ExternalForm/externalForm.js - 55"
        );
        switch ((errorData as IErrorData).response.status) {
          case 404:
            window.history.pushState("", "", `${urls.home}`);
            break;
          case 410:
            setCompletedInfo([
              "Formularz został wypełniony.",
              "Form has been completed.",
            ]);
            break;
          case 406:
            setCompletedInfo([
              "Formularz nie może być wyświetlony, ponieważ świadczenie jest dezaktywowane.",
              "Form cannot be displayed because the subscription is deactivated.",
            ]);
            break;
          default:
            setError(errorData as IErrorData);
        }
      } finally {
        setLoading(false);
      }
    };
    const params = parseObjectToGetParameters({
      subscriptionItemId,
      token,
    });
    fetchData(
      `${restUrls.externalActiveForms}?${params}`,
      {
        omitToken: true,
        headers: {
          "Content-language": lang.code,
        },
      },
      setForm
    );
  }, [lang]);

  const prepareFields = (fields: IActiveFormValues) => {
    const notEmptyFields = fields;
    Object.keys(notEmptyFields).forEach((key) => {
      if (!notEmptyFields[key]) {
        delete notEmptyFields[key];
      }
    });

    return notEmptyFields;
  };

  const submitExternalForm = async (formValues: IActiveFormValues) => {
    const submitData = {
      fields: sanitizeObject(prepareFields(formValues)),
      subscriptionItemId,
      token,
    };
    try {
      await request(restUrls.externalActiveForms, {
        method: IApiRequestMethods.POST,
        body: JSON.stringify(submitData),
        omitToken: true,
      });

      setCompletedInfo(
        lang.key === "pl_PL"
          ? ["Formularz został wysłany."]
          : ["The form has been sent."]
      );
    } catch (err) {
      newRelicErrorReport(
        err,
        "web-app/web/Pages/ExternalForm/externalForm.js - 111"
      );
      console.error(err);
      setError(err as IErrorData);
    }
  };

  const changeLanguage = (lang: { key: string; code: string }) => {
    setLang(lang);
  };

  let formContainer;
  switch (true) {
    case !!error: {
      console.error(error);
      formContainer = <ErrorComponent />;
      break;
    }
    case !!loading: {
      formContainer = <Loading />;
      break;
    }
    case !!completedInfo: {
      const titleNode = (
        <>
          {completedInfo?.map((info) => (
            <span className={classes.infoTitle__infoNodeText}>{info}</span>
          ))}
        </>
      );
      formContainer = (
        <>
          <Title name={titleNode} classes={infoClasses} />
        </>
      );
      break;
    }
    default: {
      formContainer = form ? (
        <div className={classes.form}>
          <div className={classes.externalFormHeader}>
            <Title name={form.name} classes={titleClasses} />
            {isArrayHasItems(form.availableLanguages) && (
              <div className={classes.externalFormHeader__langSwitcher}>
                <LanguageSwitcherExternalForm
                  lang={lang}
                  changeLanguage={changeLanguage}
                />
              </div>
            )}
          </div>
          <div
            className="cke_editable"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(form.description),
            }}
          />
          <section className={classes.content}>
            <ActiveForm
              configuration={form.data}
              fields={form.fields}
              formData={defaultFormData}
              nameFieldsDisabled={false}
              submitHandler={submitExternalForm}
              lang={lang}
            />
          </section>
        </div>
      ) : null;
    }
  }

  return (
    <article className={classes.root}>
      <div className={classes.wrapper}>{formContainer}</div>
    </article>
  );
};

export default withScrollToTop(ExternalForm);
