// types
import { Reducer, useMemo, useReducer } from "react";

interface IReducerState<T> {
  loading: boolean;
  error: unknown;
  data?: T[];
}
interface IReducerAction<T> {
  type: ActionTypes;
  data?: T[];
  error?: unknown;
}

const initialState = {
  loading: true,
  error: null,
  data: [],
};

// actions
const enum ActionTypes {
  LOADING = "loading",
  ERROR = "error",
  DATA = "data",
}

const reducer = <T>(state: IReducerState<T>, action: IReducerAction<T>) => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ActionTypes.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ActionTypes.DATA: {
      return {
        data: action.data,
        loading: false,
        error: null,
      };
    }
    default:
      return initialState;
  }
};

const useDataCachedReducer = <T>() => {
  const [{ loading, error, data }, dispatch] = useReducer<
    Reducer<IReducerState<T>, IReducerAction<T>>
  >(reducer, initialState);

  const { setLoading, setData, setError } = useMemo(
    () => ({
      setLoading: () => dispatch({ type: ActionTypes.LOADING }),
      setData: (data: T[]) => dispatch({ type: ActionTypes.DATA, data }),
      setError: (error: unknown) =>
        dispatch({ type: ActionTypes.ERROR, error }),
    }),
    []
  );

  return { loading, error, data, setLoading, setData, setError };
};

export default useDataCachedReducer;
