import { FC, useMemo } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import mergeReviewsProducts from "web/utils/page/customer/mergeReviewsProducts";

import { IReview } from "web/types/Review";

import useDataCachedProducts from "web/features/useDataCached/useDataCachedProducts";

import Reviews from "./reviews";

const REVIEWS_LIST_MAX_LENGTH = 15;

interface IPageGeneratorReviewsContainerProps {
  params: string;
  isMobile?: boolean;
}

const PageGeneratorReviewsContainer: FC<
  IPageGeneratorReviewsContainerProps
> = ({ isMobile = false, params }) => {
  const { title, items, ids } = useMemo(() => {
    const { header, reviews } = jsonParse<{
      reviews: IReview[];
      header: string;
    }>(params);
    let reviewsItems: IReview[] = [];
    const productIds = new Set();
    if (isArrayHasItems(reviews)) {
      reviews.some((review, index) => {
        if (review && index < REVIEWS_LIST_MAX_LENGTH) {
          reviewsItems = [...reviewsItems, review];

          if (review.product_id && +review.product_id) {
            productIds.add(+review.product_id);
          }
          return false;
        }
        return true;
      });
    }
    return {
      title: header,
      items: reviewsItems,
      ids: [...productIds],
    };
  }, [params]);

  const { data } = useDataCachedProducts({
    ids,
  } as { ids: string[] });

  const limitedReviewsLabel = mergeReviewsProducts(items, data);

  return limitedReviewsLabel ? (
    <Reviews header={title} reviews={limitedReviewsLabel} isMobile={isMobile} />
  ) : null;
};

interface IPageGeneratorReviewsContainerErrorProps {
  params: string;
  isMobile?: boolean;
}

const PageGeneratorReviewsContainerError: FC<
  IPageGeneratorReviewsContainerErrorProps
> = ({ params, isMobile }) => {
  return (
    <ErrorBoundary>
      <PageGeneratorReviewsContainer params={params} isMobile={isMobile} />
    </ErrorBoundary>
  );
};

export default PageGeneratorReviewsContainerError;
