import { OrderDetailsType } from "web/Pages/Customer/Content/PrepaidCards/types";

import restUrls from "web/constants/restUrls";

import { IPrepaidOrder } from "web/types/PrepaidCard";

import api from "../api/apiSlice";

export const prepaidCardsOrdersApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getPrepaidCardsOrders: builder.query<IPrepaidOrder[], void>({
      query: () => restUrls.prepaidCardsOrders,
      providesTags: ["PrepaidCardsOrders"],
    }),
    getPrepaidCardOrderDetails: builder.query<OrderDetailsType, string>({
      query: (id) => `${restUrls.prepaidCardsOrders}/${id}`,
      providesTags: ["PrepaidCardsOrders_details"],
    }),
  }),
});

export const {
  useGetPrepaidCardsOrdersQuery,
  useGetPrepaidCardOrderDetailsQuery,
} = prepaidCardsOrdersApiSlice;
