import Button from "web/Pages/PageGenerator/Button";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import { Nullable } from "web/types/Utils";
import { FC } from "react";
import { isNil } from "lodash";
import classesDesktop from "./permanentlyDesktop.scss";
import classesMobile from "./permanentlyMobile.scss";

interface IPageGeneratorSinglePermanentlyProps {
  headerFontColor: string;
  backgroundColor: string;
  descriptionFontColor: string;
  buttonFontColor: string;
  buttonBackgroundColor: string;
  imageSrc: string;
  height: Nullable<string>;
  header: string;
  url: string;
  layout: string;
  button: string;
  description: string;
  isMobile: boolean;
}

const PageGeneratorSinglePermanently: FC<IPageGeneratorSinglePermanentlyProps> = ({
  isMobile = false,
  headerFontColor,
  backgroundColor,
  descriptionFontColor,
  buttonFontColor,
  buttonBackgroundColor,
  imageSrc,
  height = null,
  header,
  url,
  layout,
  button,
  description,
}) => {
  const classes = isMobile ? classesMobile : classesDesktop;
  const imageBoxClassName = height ? classes.imageBoxLimited : classes.imageBox;
  const rowClassName =
    layout === "right_hand" ? classes.row : classes.rowReverse;
  const sectionClassName = isMobile
    ? classes.root
    : `${classes.root} container`.trim();
  const blockStyles = {
    color: descriptionFontColor,
    backgroundColor,
    height: isNil(height) ? undefined: `${height}px`,
  };
  const titleStyles = {
    color: headerFontColor,
  };

  return (
    <section className={sectionClassName} data-t1="pageGeneratorSingle">
      <div className={rowClassName} style={blockStyles}>
        <div className={classes.content}>
          <h2
            className={classes.title}
            style={titleStyles}
            data-t1="pageGeneratorSingle__title"
          >
            {header}
          </h2>
          <div
            className={classes.description}
            data-t1="pageGeneratorSingle__description"
          >
            {description}
          </div>
          <Button
            data-t1="pageGeneratorSingle__url"
            to={url}
            className={classes.button}
            buttonFontColor={buttonFontColor}
            buttonBackgroundColor={buttonBackgroundColor}
          >
            {button}
          </Button>
        </div>
        <div className={imageBoxClassName} data-t1="pageGeneratorSingle__image">
          {imageSrc && (
            <LazyLoadImage
              className={classes.image}
              src={imageSrc}
              alt={header}
              width={isMobile ? 600 : 800}
              height={isMobile ? 377 : 500}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default PageGeneratorSinglePermanently;
