import { BankItems } from "web/types/Banks";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

export const PAYMENT_ZFSS_GENERAL = "payment_zfss_general";
export const PAYMENT_BANK_OF_POINTS = "payment_bank_of_points";

const restrictedPaymentBanks = [PAYMENT_ZFSS_GENERAL, PAYMENT_BANK_OF_POINTS];

const filterBanks = (banks: BankItems) =>
  isArrayHasItems(banks)
    ? banks.filter(
        (bank) => !restrictedPaymentBanks.includes(bank.points_bank_name)
      )
    : [];

export default filterBanks;
