import { useQuery } from "@apollo/client";
import { FC } from "react";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";
import { UrlResolverPageValues } from "web/Layout/UrlResolver/useResolveRoute";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import helpCenterArticle from "web/queries/default/helpCenterArticle.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IHelpCenterArticleArticle } from "web/types/HelpCenter";

import { useAppContext } from "web/context/app";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export interface IHelpCenterArticleWithId extends IHelpCenterArticleArticle {
  id: UrlResolverPageValues["id"];
}

interface IHelpCenterArticleProps {
  article: IHelpCenterArticleWithId;
}

interface IHelpCenterArticleContainerProps {
  id: UrlResolverPageValues["id"];
}

const HelpCenterArticle: FC<IHelpCenterArticleProps> = ({ article }) => {
  const { isMobile } = useAppContext();

  const Comp = isMobile ? Mobile : Desktop;

  return <Comp {...article} />;
};

const HelpCenterArticleContainer: FC<IHelpCenterArticleContainerProps> = ({
  id,
}) => {
  const { languageFromParam } = useLangFromParams();

  const { error, loading, data } = useQuery(helpCenterArticle, {
    variables: {
      articleIds: [id],
      lang: languageFromParam || getLanguageCode(),
    },
  });

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <Loading />;
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Pages/HelpCenterArticle/container.js - 46"
      );
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    default: {
      return (
        data?.helpCenterArticle?.article[0] && (
          <HelpCenterArticle article={data.helpCenterArticle.article[0]} />
        )
      );
    }
  }
};

export default withScrollToTop(HelpCenterArticleContainer);
