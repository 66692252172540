import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import type { ProductByType } from "web/types/Product";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const productsTypesAdapter = createEntityAdapter<ICachedItem<ProductByType>>();

const productsTypesSlice = createSlice({
  name: "productsTypes",
  initialState: productsTypesAdapter.getInitialState(),
  reducers: {
    addProductsTypesEntries: productsTypesAdapter.addMany,
    removeProductsTypesEntries: productsTypesAdapter.removeMany,
  },
});

export const { addProductsTypesEntries, removeProductsTypesEntries } =
  productsTypesSlice.actions;

export const { selectEntities } = productsTypesAdapter.getSelectors<RootState>(
  (state) => state.productsTypes
);

export const productsTypesSliceName = productsTypesSlice.name;

export default productsTypesSlice.reducer;
