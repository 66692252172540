/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import GtmEvent from "web/Layout/Gtm/GtmEvent";

import campaignGraphicsIdsQuery from "web/queries/default/campaignGraphicsIds.graphql";

import useQueryWithRender from "web/hooks/useQueryWithRender";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getPageReferrer from "web/utils/system/GTM/getPageReferrer";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import placements from "web/constants/campaignGraphics";

import { ICampaignGraphics } from "web/types/CampaignGraphics";
import type { IStoreConfig } from "web/types/StoreConfig";

import useDataCachedCampaignGraphics from "web/features/useDataCached/useDataCachedCampaignGraphics";

import Tourism from "./tourism";

interface IDomesticTourismContainerWithCampaignDataProps {
  storeConfig: IStoreConfig;
  campaignIds: number[];
}

const DomesticTourismContainerWithCampaignData: FC<
  IDomesticTourismContainerWithCampaignDataProps
> = ({ storeConfig, campaignIds = [] }) => {
    const { loading, error, data } = useDataCachedCampaignGraphics({
      ids: campaignIds,
    });
    const location = useLocation();

  const campaignGraphic =
    isArrayHasItems(data) &&
    data.find(
      (banner) =>
        !!banner.active && banner.position === placements.tourismBanner
    );

  const gtmOptions = useMemo(
    () => ({
      cgroup1: "Strona turystyki",
      page_referrer: getPageReferrer(location),
    }),
    [location, getPageReferrer]
  );

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      newRelicErrorReport(error, "web-app/web/Pages/Tourism/container.js - 45");
      console.error(error);
      return <ErrorComponent />;
    }
    default: {
      return (
        <>
          <Tourism
            campaignGraphic={campaignGraphic as ICampaignGraphics}
            storeConfig={storeConfig}
          />
          <GtmEvent options={gtmOptions} withUrl />
        </>
      );
    }
  }
};

const DomesticTourismContainer = memo(() => {
  const storeConfig = useSelector(
    (state) => state.app.storeConfig
  ) as IStoreConfig;
  const [storeId, token] = storeConfig
    ? [storeConfig.id, storeConfig.token]
    : [];
  const options = {
    variables: {
      position: placements.bannerCategory,
      storeId,
      token,
    },
  };
  const result = useQueryWithRender(
    campaignGraphicsIdsQuery,
    options,
    // @ts-ignore
    ErrorComponent,
    Loading
  );
  if (result && result.campaignGraphicsIds) {
    return isArrayHasItems(result.campaignGraphicsIds.id) ? (
      <DomesticTourismContainerWithCampaignData
        campaignIds={result.campaignGraphicsIds.id}
        storeConfig={storeConfig}
      />
    ) : (
      <Tourism storeConfig={storeConfig} />
    );
  }

  return null;
});

export default DomesticTourismContainer;
