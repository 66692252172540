import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import BreadcrumbsCommon from "web/Components/Common/Breadcrumbs";

import { searchName } from "web/constants/toolbar";
import urls from "web/constants/urls";

const SearchBreadcrumbs = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchFromUrl = params.get(searchName);
  const breadcrumbs = useMemo(() => {
    const firstItem = {
      label: __("Strona główna"),
      url: urls.home,
    };
    const lastItem = {
      label: `${__("Wyniki wyszukiwania dla")}: ${searchFromUrl}`,
    };

    return [firstItem, lastItem];
  }, [searchFromUrl]);
  return <BreadcrumbsCommon breadcrumbs={breadcrumbs} />;
};

export default SearchBreadcrumbs;
