import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { GroupedProduct } from "web/utils/page/product/universal/groupProducts";

import {
  ICartItemProductData,
  ICartParentProductAttribute,
} from "web/types/Cart";
import { MBProductType } from "web/types/Product";
import { ValueOf } from "web/types/Utils";

const getProductConfigurableData = (
  quote: GroupedProduct<MBProductType>["quote"],
  full: ICartItemProductData
) => {
  switch (true) {
    case Boolean(quote && quote.parent_product_data): {
      const attributes = isArrayHasItems(
        quote?.parent_product_data?.super_attributes
      )
        ? quote?.parent_product_data?.super_attributes.reduce<
            {
              attribute: string;
              value: ValueOf<ICartParentProductAttribute>;
            }[]
          >((result, attribute) => {
            const keys = Object.keys(attribute);
            const key = isArrayHasItems(keys) ? keys[0] : null;
            const value = key
              ? attribute[key as keyof ICartParentProductAttribute]
              : null;
            return key && value
              ? [
                  ...result,
                  {
                    attribute: key,
                    value: full[key as keyof typeof full] as string,
                  },
                ]
              : result;
          }, [])
        : null;
      return [
        full.image === "no_selection"
          ? quote?.parent_product_data?.image
          : full.image,
        full.name,
        quote?.parent_product_data?.url_key,
        full && full.main_category_id,
        attributes,
        full && !!full.can_buy_multiple,
      ];
    }
    case Boolean(full): {
      return [
        full.small_image,
        full.name,
        full.url_key,
        full.main_category_id,
        null,
        full.can_buy_multiple,
      ];
    }
    default: {
      return [];
    }
  }
};

export default getProductConfigurableData;
