import { motion } from "framer-motion";

import __ from "web/Layout/Translations";

import classes from "./contactFormMobile.scss";

export const variants = {
  open: {
    opacity: 1,
    height: "auto",
    overflow: "visible",
  },
  close: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
};

export const transition = { duration: 0.25 };

const NewBenefitInfo = () => {
  return (
    <motion.div
      className={classes.info}
      initial="close"
      animate="open"
      transition={transition}
      variants={variants}
    >
      <div className={classes.text}>
        {__(
          "Chcesz dodać ulubiony pensjonat lub hotel do systemu MyBenefit? Masz pomysł na nowe świadczenie?"
        )}
      </div>
    </motion.div>
  );
};

export default NewBenefitInfo;
