import { FC, useMemo } from "react";
import { useLocation } from "react-router";

import Loading from "web/Layout/Common/Loading";
import LoadMore from "web/Layout/LoadMore";
import __ from "web/Layout/Translations";

import Title from "web/Components/Common/Title";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import { ICodeItem } from "web/types/Code";
import List from "../Shared/List";
import Filters from "./Filters";
import classes from "./codeList.scss";

interface ICustomerCodeListDesktopProps {
  className: string;
  codes: ICodeItem[];
  isLoading: boolean;
  title: string;
  toUseCount: number;
  usedCount: number;
  totalCount: number;
}

const titleClasses = {
  root: classes.header,
  header: classes.title,
};
const name = "availability";

const CustomerCodeListDesktop: FC<ICustomerCodeListDesktopProps> = ({
  className,
  codes,
  isLoading,
  title,
  toUseCount,
  usedCount,
  totalCount,
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(decodeURIComponent(search));
  const filters = useMemo(() => {
    return [
      {
        label: `${__("Do wykorzystania")} (${toUseCount})`,
        value: "use",
        count: toUseCount,
      },
      {
        label: `${__("Wykorzystane")} (${usedCount})`,
        value: "used",
        count: usedCount,
      },
      {
        label: `${__("Wszystkie")} (${totalCount})`,
        value: "all",
        count: totalCount,
      },
    ];
  }, [toUseCount, usedCount, totalCount]);
  const type = params.get(name) || filters[0].value;

  const currentTotal = codes?.length;
  const filterTotalCount = filters.find(
    (filter) => filter.value === type
  )?.count || 0;
  return (
    <div className={className}>
      <Title
        name={title}
        count={totalCount}
        classes={titleClasses}
        dataT1="code_list_title"
      />
      <Filters name={name} filters={filters} type={type} />
      <List codes={codes} />
      {isLoading ? <Loading className={classes.loading} /> : null}
      {!!currentTotal && filterTotalCount > currentTotal && (
        <div className={classes.loadMoreContainer}>
          <LoadMore
            total={filterTotalCount}
            currentTotal={currentTotal}
            shouldChangePage
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default withScrollToTop(CustomerCodeListDesktop);
