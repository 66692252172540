import restUrls from "web/constants/restUrls";

import type { IMessage, IUnreadMessagesInfo } from "web/types/Message";
import { HydraResponse } from "web/types/Utils";

import api from "../api/apiSlice";

export const messagesApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getMessageList: builder.query<
      { items: IMessage[]; itemsAmount: number },
      { page: number; pageSize: number }
    >({
      query: ({ page = 1, pageSize = 10 }) => ({
        url: `${restUrls.messagesGet}?page=${page}&itemsPerPage=${pageSize}`,
        method: "GET",
        headers: {
          "Content-Type": "application/ld+json",
          Accept: "application/ld+json",
        },
      }),
      transformResponse: (result: HydraResponse<IMessage>) => {
        return {
          items: result["hydra:member"],
          itemsAmount: result["hydra:totalItems"],
        };
      },
      providesTags: ["Messages"],
    }),

    getUnreadMessages: builder.query<IUnreadMessagesInfo, void>({
      query: () => restUrls.messagesUnread,
      providesTags: ["Messages_unread", "Popups"],
    }),

    deleteMessage: builder.mutation<boolean, number>({
      query: (messageId) => ({
        url: restUrls.messageDelete.replace(
          "{messageId}",
          messageId.toString()
        ),
        method: "DELETE",
        body: {
          messagesIds: [messageId],
        },
      }),
      invalidatesTags: ["Messages", "Messages_unread"],
    }),

    markMessageRead: builder.mutation<boolean, { messageId: number }>({
      query: ({ messageId }) => ({
        url: restUrls.messageMarkAsRead.replace(
          "{messageId}",
          messageId.toString()
        ),
        headers: {
          "Content-type": "application/merge-patch+json",
        },
        method: "PATCH",
        body: JSON.stringify({}),
      }),
      invalidatesTags: ["Messages", "Messages_unread"],
    }),

    markPopupRead: builder.mutation<boolean, { messageId: number }>({
      query: ({ messageId }) => ({
        url: restUrls.messageMarkAsRead.replace(
          "{messageId}",
          messageId.toString()
        ),
        headers: {
          "Content-type": "application/merge-patch+json",
        },
        method: "PATCH",
        body: JSON.stringify({}),
      }),
      invalidatesTags: ["Popups"],
    }),
  }),
});

export const {
  useGetMessageListQuery,
  useDeleteMessageMutation,
  useMarkMessageReadMutation,
  useMarkPopupReadMutation,
  useGetUnreadMessagesQuery,
} = messagesApiSlice;
