// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-orderDetailsContainer-MAY{max-width:1192px;width:100%}.success-buttonContainer-Zl9{margin-top:50px}.success-buttonContainer-Zl9 a{height:50px;padding:10px 34px}.success-loading-yL5{position:absolute;left:0;right:0;bottom:0;top:0;z-index:30;display:flex;justify-content:center;align-items:center;background-color:rgba(255,255,255,.5);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/PrepaidCards/OrderPrepaidCardSuccess/Success/success.scss"],"names":[],"mappings":"AAIuB,mCACrB,gBAAA,CACA,UAAA,CAGF,6BACE,eAAA,CAEA,+BACE,WAAA,CACA,iBAAA,CAIJ,qBACE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,KAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qCAAA,CACA,iCAAA,CAAA,yBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .orderDetailsContainer {\n  max-width: 1192px;\n  width: 100%;\n}\n\n.buttonContainer {\n  margin-top: 50px;\n\n  a {\n    height: 50px;\n    padding: 10px 34px;\n  }\n}\n\n.loading {\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  z-index: 30;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.5);\n  backdrop-filter: blur(5px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderDetailsContainer": `success-orderDetailsContainer-MAY`,
	"buttonContainer": `success-buttonContainer-Zl9`,
	"loading": `success-loading-yL5`
};
export default ___CSS_LOADER_EXPORT___;
