import { PureComponent } from "react";

interface ISetScriptFromSrcProps {
  src: string;
  async: boolean;
}

class SetScriptFromSrc extends PureComponent<ISetScriptFromSrcProps> {
  get scriptId() {
    const { src } = this.props;
    return encodeURIComponent(src);
  }

  loadScript = () => {
    const { src, async = false } = this.props;
    const id = this.scriptId;
    if (!document.getElementById(id)) {
      const el = document.createElement("script");
      el.src = src;
      el.id = id;
      el.async = Boolean(async);
      document.body.appendChild(el);
    }
  };

  deleteScript = () => {
    const el = document.getElementById(this.scriptId);
    if (el) {
      el.remove();
    }
  };

  componentDidMount = () => {
    this.loadScript();
  };

  componentWillUnmount = () => {
    this.deleteScript();
  };

  render = () => null;
}

export default SetScriptFromSrc;
