import { Loader } from "@googlemaps/js-api-loader";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import type { Nullable } from "web/types/Utils";

type AutocompleteServiceType = Nullable<google.maps.places.AutocompleteService>;
export type GeocoderServiceType = Nullable<google.maps.Geocoder>;

interface IGoogleContextValue {
  initGoogleServices: (apiKey: string) => void;
  autocompleteService: AutocompleteServiceType;
  geocoderService: GeocoderServiceType;
}

export const GoogleContext = createContext({} as IGoogleContextValue);

const GoogleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [autocompleteService, setAutocompleteService] =
    useState<AutocompleteServiceType>(null);
  const [geocoderService, setGeocoderService] =
    useState<GeocoderServiceType>(null);

  const initGoogleServices = async (apiKey: string) => {
    const loader = new Loader({
      apiKey,
      version: "weekly",
      libraries: ["places"],
    });

    try {
      await loader.load();
      const autocompleteService = new google.maps.places.AutocompleteService();
      const geocoderService = new google.maps.Geocoder();

      setAutocompleteService(autocompleteService);
      setGeocoderService(geocoderService);
    } catch (error) {
      newRelicErrorReport(error, "web-app/web/context/google.context.tsx - 50");
    }
  };
  return (
    <GoogleContext.Provider
      value={{ initGoogleServices, autocompleteService, geocoderService }}
    >
      {children}
    </GoogleContext.Provider>
  );
};

const useGoogleContext = () => useContext<IGoogleContextValue>(GoogleContext);

export { GoogleProvider, useGoogleContext };
