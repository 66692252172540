import { Button } from "@benefit-systems/common-components";
import { FC } from "react";

import SearchSection from "web/Pages/HelpCenter/Desktop/SearchSection";

import __ from "web/Layout/Translations";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import urls from "web/constants/urls";

import { IStoreConfig } from "web/types/StoreConfig";

import HelpCenterCatrgoryArticlesSection from "../Shared/ArticlesSection";
import HelpCenterCategoryBreadcrumbs from "../Shared/Breadcrumbs";
import { HelpCenterCategoryPageProps } from "../helpCenterCategory";
import classes from "./helpCenterArticles.scss";

interface IHelpCenterCategoryDesktopProps extends HelpCenterCategoryPageProps {
  prefix: IStoreConfig["help_center_prefix"];
}

const HelpCenterCategoryDesktop: FC<IHelpCenterCategoryDesktopProps> = ({
  name,
  articles,
  prefix,
}) => {
  const { isParamLangEn } = useLangFromParams();

  return (
    <section className={`container ${classes.container}`}>
      <HelpCenterCategoryBreadcrumbs name={name} />
      <SearchSection />
      <div className={classes.wrapper}>
        <h1 className={classes.title}>{name}</h1>
        <HelpCenterCatrgoryArticlesSection
          articles={articles}
          prefix={prefix}
        />
        <Button variant="secondary" to={urls.helpCenter}>
          {isParamLangEn ? "Back" : __("Wróć")}
        </Button>
      </div>
    </section>
  );
};

export default withScrollToTop(HelpCenterCategoryDesktop);
