/* eslint-disable camelcase */
import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IParsedCodeItem, IParsedOrder } from "web/types/Order";
import { MBProductType } from "web/types/Product";

import classes from "./orderSummary.scss";
import OrderSummaryCodes from "./orderSummaryCodes";
import OrderSummaryMain from "./orderSummaryMain";

interface ISuccessOrderSummaryWrapperProps {
  order: IParsedOrder<MBProductType>;
  isAnixeTourism?: boolean;
  isAnixePending?: boolean;
}

const UNIVERSAL_CODE = "universal_code";

const SuccessOrderSummaryWrapper: FC<ISuccessOrderSummaryWrapperProps> = ({
  order,
  isAnixeTourism,
  isAnixePending,
}) => {
  const { items } = order;

  const universalCodes = isArrayHasItems(items)
    ? (items.filter(({ mb_product_type }) => {
        return mb_product_type === UNIVERSAL_CODE;
      }) as IParsedCodeItem[])
    : [];

  return (
    <div className={classes.gridWrapper}>
      <OrderSummaryMain
        order={order}
        isAnixeTourism={isAnixeTourism}
        isAnixePending={isAnixePending}
      />

      {!!universalCodes.length && (
        <OrderSummaryCodes
          statusCode={order.status_code}
          universalCodes={universalCodes}
        />
      )}
    </div>
  );
};

export default SuccessOrderSummaryWrapper;
