import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import Newsletter from "./newsletter";

interface IPageGeneratorNewsletterContainerProps {
  isMobile?: boolean;
}

const PageGeneratorNewsletterContainer: FC<IPageGeneratorNewsletterContainerProps> = ({ isMobile = false }) => {
  return (
    <ErrorBoundary>
      <Newsletter isMobile={isMobile} />
    </ErrorBoundary>
  );
};

export default PageGeneratorNewsletterContainer;
