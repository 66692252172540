import { useFormikContext } from "formik";

import __ from "web/Layout/Translations";

import LetterCounter from "web/Components/Common/Form/LetterCounter";
import Field from "web/Components/Common/Form/NewField";

import useContactFormCategories, {
  IContactFormCategoriesValues,
} from "web/hooks/useContactFormCategories/useContactFormCategories";
import useProvinces from "web/hooks/useProvinces/useProvinces";

import { useAppContext } from "web/context/app";

import NewBenefitInfo from "../../Mobile/newBenefitInfo";
import { newBenefitFieldsMap } from "../../constants";
import classes from "./fields.scss";

const NewBenefitForm = () => {
  const { isMobile } = useAppContext();
  const { values, setFieldValue } = useFormikContext<
    IContactFormCategoriesValues & {
      [name: string]: string;
    }
  >();
  const { helpCategories } = useContactFormCategories(
    values,
    setFieldValue as (...args: unknown[]) => void
  );
  const provinces = useProvinces();
  return (
    <>
      {isMobile && <NewBenefitInfo />}
      <div className={classes.fields}>
        {newBenefitFieldsMap.map((field) => (
          <div key={field.id} className={getFieldStyle(field.name, isMobile)}>
            <Field
              type={field.type}
              name={field.name}
              options={getOptions(field.name, { helpCategories, provinces })}
              id={field.id}
              key={field.key}
              component={field.component}
              placeholder={field.placeholder}
              label={__(field.label)}
              rules={field.rules}
              readonly={field.readonly}
            />
            {field.name === "description" && (
              <LetterCounter
                maxCount={3000}
                currentCount={values.description.length}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

const getFieldStyle = (name: string, isMobile: boolean) => {
  if (name === "description") return classes.descriptionField;

  return isMobile ? classes.fieldMobile : classes.field;
};

const getOptions = (
  name: string,
  options: {
    helpCategories: string[];
    provinces: { value: string; label: string }[];
  }
) => {
  switch (name) {
    case "category":
      return options.helpCategories;
    case "province":
      return options.provinces;
    default:
      return null;
  }
};

export default NewBenefitForm;
