import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { Product, ProductInList } from "web/types/Product";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const productsAdapter =
  createEntityAdapter<ICachedItem<Product | ProductInList>>();

const productsSlice = createSlice({
  name: "products",
  initialState: productsAdapter.getInitialState(),
  reducers: {
    addProductsEntries: productsAdapter.addMany,
    removeProductsEntries: productsAdapter.removeMany,
  },
});

export const { addProductsEntries, removeProductsEntries } =
  productsSlice.actions;

export const { selectEntities } = productsAdapter.getSelectors<RootState>(
  (state) => state.products
);

export const productsSliceName = productsSlice.name;

export default productsSlice.reducer;
