import { FC, PropsWithChildren } from "react";

import __ from "web/Layout/Translations";

import classes from "./wrapperWithTitle.scss";

export const ROOM_OFFERS_ID = "roomOffers";

const ProductWrapperWithTitleDesktop: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <div
      id={ROOM_OFFERS_ID}
      className={classes.root}
      data-t1="room_offers"
      data-testid="room_offers"
    >
      <div className={classes.header}>
        {__("Oferty obiektu")}{" "}
        <small className={classes.header_small} data-t1="room_offers_desc">
          ({__("Sprawdź dostępne oferty dopasowane do Twoich filtrów")})
        </small>
      </div>
      {children}
    </div>
  );
};

export default ProductWrapperWithTitleDesktop;
