/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import __ from "web/Layout/Translations";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import getWebViewRedirectionBody from "web/utils/data/transform/getWebViewRedirectionBody";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import removeRidFromStorage from "web/utils/system/domesticTourism/rid/removeRidFromStorage";
import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import restUrls from "web/constants/restUrls";
import storageNames from "web/constants/storageNames";
import urls from "web/constants/urls";

import {
  IFinancingSourceItem,
  IFinancingSourcePaymentSettingsItem,
} from "web/types/FinancingSources";
import type { IPlaceOrder } from "web/types/Order";
import {
  IAdultGuest,
  IDomesticTourismHotelRatesOffer,
  IDomesticTourismMinPrice,
  IRoom,
  Purchaser,
} from "web/types/Tourism";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { AppDispatch, RootState } from "web/store";

import { customerApiSlice } from "../customer/customerApiSlice";
import { paymentMethodsApiSlice } from "../paymentMethods/paymentMethodsApiSlice";
import { invalidateBanks } from "../pointsBank/pointsBankApiSlice";
import {
  ITourismDomesticState,
  reset,
  setAnixeError,
} from "./tourismQuoteDomesticSlice";

const storage = new BrowserTemporality();
export const getDetailsById = createAsyncThunk(
  "tourismQuoteDomestic/getDetailsById",
  async (data: ITourismDomesticState, { dispatch, getState }) => {
    const {
      quote: quoteFromStorage,
      paymentMethod,
      financingSources,
      paymentMethods,
      guests,
      purchaser,
      howToPayMethod,
      amount,
    } = data;
    const { offer, values } = quoteFromStorage || {};

    let offerUpdated: Nullable<IDomesticTourismHotelRatesOffer> = null;

    if (offer && values) {
      const [dateFrom, dateTo] = [
        formatDate(values.date_from, true),
        formatDate(values.date_to, true),
      ];

      try {
        offerUpdated = (await request<IDomesticTourismHotelRatesOffer>(
          restUrls.hotelRatesAnixe,
          {
            method: IApiRequestMethods.POST,
            body: JSON.stringify({
              form: {
                mesh: offer.mesh,
                mhr: offer.mhr,
                date_from: dateFrom,
                date_to: dateTo,
                currency: offer.currency,
                rooms: values.rooms,
                rid: offer.rid,
              },
            }),
          }
        )) as IDomesticTourismHotelRatesOffer;
      } catch (error) {
        if (error instanceof Error) {
          const message =
            error.message?.split("Message:")[1]?.trim() ||
            __("Coś poszło nie tak");
          dispatch(setAnixeError(message));
        }
      }
    }

    const quote = offerUpdated
      ? {
          ...quoteFromStorage,
          offer: {
            ...(quoteFromStorage?.offer || {}),
            ...offerUpdated,
            rooms: isArrayHasItems(offerUpdated.rooms)
              ? offerUpdated.rooms.reduce<IRoom[]>((result, room, index) => {
                  const currentRoom = quoteFromStorage?.offer?.rooms?.[index]
                    ? quoteFromStorage.offer.rooms[index]
                    : ({} as IRoom);
                  if (room) {
                    const {
                      meal,
                      meal_name: mealName,
                      room_name: nameRoom,
                      ...restData
                    } = room || {};

                    return [
                      ...result,
                      {
                        ...currentRoom,
                        // @ts-ignore
                        meal_type: mealName,
                        // @ts-ignore
                        name_room: nameRoom,
                        ...restData,
                      },
                    ];
                  }

                  return [...result, currentRoom];
                }, [] as IRoom[])
              : quoteFromStorage?.offer.rooms,
          },
        }
      : quoteFromStorage;

    const customer = await dispatch(
      customerApiSlice.endpoints.getCustomerDetails.initiate()
    ).unwrap();
    const { tourismQuoteDomestic } = getState() as RootState;

    const { price: amountMinimal } = (
      await dispatch(
        setMinPrice(quote?.offer as IDomesticTourismHotelRatesOffer)
      )
    )?.payload as IDomesticTourismMinPrice;

    const purchaserProcessed = purchaser || {
      first_name: customer?.firstname,
      last_name: customer?.lastname,
      email: customer?.email,
      phone: null,
      honorific: null,
    };

    const total = quote?.offer?.total_price ? +quote.offer.total_price : 0;

    return {
      quote: quote || null,
      paymentMethod: paymentMethod || null,
      paymentMethods: paymentMethods || null,
      financingSources: financingSources || null,
      guests: guests || null,
      purchaser: purchaserProcessed,
      amountMinimal: +amountMinimal,
      total,
      howToPayMethod: howToPayMethod || tourismQuoteDomestic.howToPayMethod,
      amount,
    };
  }
);

export const setMinPrice = createAsyncThunk(
  "tourismQuoteDomestic/setMinPrice",
  async (offer: IDomesticTourismHotelRatesOffer) => {
    const response = (await request(restUrls.minPriceAnixe, {
      method: IApiRequestMethods.POST,
      body: JSON.stringify({
        form: {
          supplier_code: offer.source,
          cnx_type: offer.rooms[0].cnx_type,
          total_price: offer.total_price,
          cnxs: offer.cnxs,
        },
      }),
    })) as IDomesticTourismMinPrice;

    return response;
  }
);

export const setPaymentMethods = createAsyncThunk(
  "tourismQuoteDomestic/setPaymentMethods",
  async (
    payload: {
      items: Nullable<IFinancingSourceItem[]>;
      summary: IFinancingSourcePaymentSettingsItem[] | undefined;
    },
    { dispatch, getState }
  ) => {
    const paymentMethods = await dispatch(
      paymentMethodsApiSlice.endpoints.getMethods.initiate()
    ).unwrap();
    const { financingSources } = getState() as RootState;
    const { methods } = paymentMethods || {};
    const { summary } = payload || financingSources || {};

    if (isArrayHasItems(summary) && isArrayHasItems(methods)) {
      const pointsName = "points_payment";
      const isOnlineExist = summary.some(
        (item) => item.type.indexOf("ONLINE") !== -1
      );
      const paymentMethodsAvailable = isOnlineExist
        ? methods.filter(({ method }) => method !== pointsName)
        : methods.filter(({ method }) => method === pointsName);

      return paymentMethodsAvailable;
    }
    return [];
  }
);

export const resetStateAndRemoveItemFromSessionStorage =
  ({ id }: { id: string }) =>
  (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
    storage.removeItem(`${storageNames.tourismDomestic}${id}`);
    removeRidFromStorage();
    dispatch(reset());
  };

export const placeOrder = createAsyncThunk(
  "tourismQuoteDomestic/placeOrder",
  async (
    payload: { id: string; push: ReturnType<typeof useHistory>["push"] },
    { getState, dispatch }
  ) => {
    const { id, push } = payload || {};
    const { tourismQuoteDomestic } = getState() as RootState;
    const {
      quote,
      paymentMethod,
      financingSources,
      purchaser,
      guests,
      purchaserOnlyGuests,
    } = tourismQuoteDomestic;

    const payments = financingSources?.map((source) => {
      const { sku, payment_settings: paymentSettings } = source || {};
      const paymentSettingsNew = isArrayHasItems(paymentSettings)
        ? paymentSettings.map(({ name, value, ...rest }) => ({
            value: Number((Math.round(value * 100) / 100).toFixed(2)),
            ...rest,
          }))
        : [];
      return {
        sku,
        payment_settings: paymentSettingsNew,
      };
    });
    const { rooms, currency, rid, ...restOfferData } = quote?.offer || {};
    const formRooms =
      quote && quote.values && isArrayHasItems(quote.values.rooms)
        ? quote.values.rooms
        : [];
    const [dateFrom, dateTo] = [
      formatDate(quote?.values.date_from, true),
      formatDate(quote?.values.date_to, true),
    ];

    const { tourism_other_statement, purchaser_only, ...restPurcharserData } =
      purchaser || {};

    const restPurcharserDataTyped = restPurcharserData as Omit<
      Purchaser,
      "tourism_other_statement" | "purchaser_only"
    >;

    const roomsProcessed = isArrayHasItems(rooms)
      ? rooms.map((room, index) => {
          const {
            meal_name: mealName,
            price,
            remark,
            discount,
            ...restRoomData
          } = room;
          const { adults, children } =
            formRooms &&
            formRooms[index] &&
            Object.prototype.hasOwnProperty.call(formRooms[index], "adults") &&
            Object.prototype.hasOwnProperty.call(formRooms[index], "children")
              ? formRooms[index]
              : { adults: 0, children: [] };

          return {
            ...restRoomData,
            discount: discount || "",
            remark,
            occupancy_children: Array.isArray(children) ? children.length : 0,
            occupancy_adult: adults,
            date_from: dateFrom,
            date_to: dateTo,
          };
        })
      : [];

    const guestsToSend =
      purchaser_only && isArrayHasItems(purchaserOnlyGuests)
        ? purchaserOnlyGuests.map((guest) => {
            const guestHonorific =
              typeof (guest as IAdultGuest).honorific === "string"
                ? { honorific: restPurcharserDataTyped.honorific }
                : {};
            return {
              ...guest,
              first_name: restPurcharserDataTyped.first_name,
              last_name: restPurcharserDataTyped.last_name,
              ...guestHonorific,
            };
          })
        : guests;

    const defaultBody = {
      chosenPaymentMethod: {
        code: paymentMethod,
      },
      item: {
        sku: quote?.sku,
        ...restOfferData,
        additional_data: roomsProcessed,
      },
      payments,
      ...sanitizeObject({
        purchaser: restPurcharserData,
        guests: guestsToSend,
      }),
      tourism_other_statement,
      purchaser_only,
      form: {
        ...sanitizeObject({
          ...quote?.values,
          date_from: dateFrom,
          date_to: dateTo,
        }),
        rid,
      },
    };

    const body = window.ReactNativeWebView
      ? getWebViewRedirectionBody(defaultBody)
      : defaultBody;

    const response = (await request(restUrls.placeOrderTourismDomestic, {
      method: IApiRequestMethods.POST,
      body: JSON.stringify(body),
    })) as IPlaceOrder;

    if (typeof response.redirect_url === "string") {
      push({
        pathname: urls.checkoutSplash,
        search: `?redirectUrl=${encodeURIComponent(
          response.redirect_url
        )}&orderId=${response.order_id}`,
      });
      dispatch(resetStateAndRemoveItemFromSessionStorage({ id }));
      if (!response.redirect_url?.includes("http")) {
        invalidateBanks(dispatch as AppDispatch);
      }
    }
    return null;
  }
);
