import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IFinancingSourceItem } from "web/types/FinancingSources";
import { PaymentSettings, ReducerPaymentMethod } from "web/types/Payment";
import { Nullable } from "web/types/Utils";

import {
  placeOrder,
  setPaymentMethods,
} from "./tourismQuoteInternationalThunks";

export interface ITourismInternationalState {
  quote: Nullable<ITourismInternationalQuote>;
  isDetailsGotten: boolean;
  isLoading: boolean;
  error: unknown;
  paymentMethod: Nullable<string>;
  paymentMethods: Nullable<ReducerPaymentMethod[]>;
  financingSources: Nullable<IFinancingSourceItem[]>;
}

export interface ITourismInternationalQuoteValues {
  cost_all_booking: number | string;
  mb_payment_amount: number | string;
  trip_goal: string;
  booking_nr: string;
  consultant_email: string;
  date_from: string;
  date_to: string;
  email: string;
  phone: string;
}

export interface ITourismInternationalQuote {
  id: string;
  sku: string;
  info: string;
  values: ITourismInternationalQuoteValues;
  paymentSettings: PaymentSettings;
}

const initialState: ITourismInternationalState = {
  quote: null,
  isDetailsGotten: false,
  isLoading: false,
  error: null,
  paymentMethod: null,
  paymentMethods: null,
  financingSources: null,
};

export const tourismQuoteDomesticSlice = createSlice({
  name: "tourismQuoteInternational",
  initialState,
  reducers: {
    getDetails: (
      state,
      action: PayloadAction<
        Pick<
          ITourismInternationalState,
          "quote" | "paymentMethod" | "paymentMethods" | "financingSources"
        >
      >
    ) => {
      const {
        quote = null,
        paymentMethod = null,
        paymentMethods = null,
        financingSources = null,
      } = action.payload || {};
      return {
        ...state,
        isDetailsGotten: true,
        quote,
        paymentMethod,
        paymentMethods,
        financingSources,
      };
    },
    setPaymentMethod: (
      state,
      action: PayloadAction<ITourismInternationalState["paymentMethod"]>
    ) => {
      const paymentMethod = action.payload;
      if (state.quote?.id && paymentMethod) {
        state.paymentMethod = action.payload;
      }
    },
    setFinancingSources: (
      state,
      action: PayloadAction<
        Pick<ITourismInternationalState, "financingSources">
      >
    ) => {
      state.financingSources = action.payload?.financingSources;
    },
    resetFinancingSources: (state) => {
      if (state.quote?.id) {
        state.financingSources = initialState.financingSources;
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // set payment methods
    builder.addCase(setPaymentMethods.fulfilled, (state, action) => {
      if (action.payload) {
        state.paymentMethods = action.payload;
      }
    });
    builder.addCase(setPaymentMethods.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(
        error,
        "tourismQuoteInternational - setPaymentMethods"
      );
    });

    // place order
    builder.addCase(placeOrder.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(placeOrder.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(placeOrder.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;

      newRelicErrorReport(error, "tourismQuoteInternational - placeOrder");
    });
  },
});

export const {
  reset,
  resetFinancingSources,
  setPaymentMethod,
  setFinancingSources,
  getDetails,
} = tourismQuoteDomesticSlice.actions;

export default tourismQuoteDomesticSlice.reducer;
