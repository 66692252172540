/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "@benefit-systems/common-components";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import __ from "web/Layout/Translations";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import { PropsWithClasses } from "web/types/Common";
import type { ILocationItem } from "web/types/Geolocation";
import type { IProduct } from "web/types/Product";

import { useAppContext } from "web/context/app";
import { useGoogleContext } from "web/context/google.context";
import {
  IAnixeFiltersRoom,
  IAnixeFiltersSelectedDates,
  setSelectedDates,
  setSelectedLocation,
  setSelectedObject,
  setSelectedRooms,
} from "web/features/anixeFilters/anixeFiltersSlice";

import classify from "../../../../classify";
import jsonParse from "../../../../utils/data/parser/string/jsonParse";
import getSearchParameter from "../../../../utils/system/url/getSearchParameter";
import ModalContent from "./ModalContent";
import defaultClasses from "./editAccommodationData.scss";

interface IProductEditAccomodationContainerMobileProps {
  product?: IProduct;
  variant?: "offer" | "listing" | "product";
}

const ProductEditAccomodationContainerMobile: FC<
  PropsWithClasses<IProductEditAccomodationContainerMobileProps>
> = ({ product = null, variant = "product", classes = {} }) => {
  const { geocoderService } = useGoogleContext();
  const [city, setCity] = useState("");
  const { search } = useLocation();
  const { modal } = useAppContext();
  const { dispatch } = modal;
  const { can_show_anixe_search: canShowAnixeSearch } = useSelector(
    (state) => state.app.storeConfig
  );

  const reduxDispatch = useDispatch();

  const locationParameter = getSearchParameter({
    name: names.location,
    search,
  });
  const roomsSearch = getSearchParameterAll({
    name: names.rooms,
    search,
  });
  const dateFromSearch = getSearchParameterAll({
    name: names.dateFrom,
    search,
  });
  const dateToSearch = getSearchParameterAll({
    name: names.dateTo,
    search,
  });
  const dateFrom = parseInt(dateFromSearch[0], 10);
  const dateTo = parseInt(dateToSearch[0], 10);
  const dateFromFormatted = formatDate(dateFrom);
  const dateToFormatted = formatDate(dateTo);
  const locationParameterParsed = locationParameter
    ? jsonParse<{ placeId: string }>(locationParameter)
    : null;

  useEffect(() => {
    if (product?.tourism_city) {
      setCity(product?.tourism_city);
    } else if (
      locationParameterParsed &&
      locationParameterParsed.placeId &&
      !product?.tourism_city
    ) {
      geocoderService?.geocode(
        {
          placeId: locationParameterParsed.placeId,
        },
        (result, status) => {
          if (
            status === "OK" &&
            isArrayHasItems(result) &&
            result[0] &&
            result[0].formatted_address
          ) {
            setCity(result[0].formatted_address);
          }
        }
      );
    }
  }, [product?.tourism_city]);

  const openModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: <ModalContent variant={variant} />,
    });
  }, [dispatch]);

  const roomsData = useMemo(() => {
    const roomsDataParsed = jsonParse<
      { adults: number; children: { age: number }[] }[]
    >(roomsSearch?.[0]);

    return isArrayHasItems(roomsDataParsed) ? roomsDataParsed : [];
  }, [roomsSearch]);

  if (!roomsSearch?.length) {
    return null;
  }
  const roomsAmount = roomsData.length;
  const adultsAmount = roomsAmount
    ? roomsData.map((item) => item.adults).reduce((a, b) => a + b, 0)
    : 0;
  const childrenAmount = roomsAmount
    ? roomsData.map((item) => item.children.length).reduce((a, b) => a + b, 0)
    : 0;
  const guestsAmount = adultsAmount + childrenAmount;

  const initialValues = useMemo(() => {
    const locationParameter = getSearchParameter({
      name: names.location,
      search,
    });
    const locationParameterParsed = locationParameter
      ? jsonParse(locationParameter)
      : null;
    const roomsParameter = getSearchParameter({ name: names.rooms, search });
    const roomsParameterParsed = roomsParameter
      ? jsonParse(roomsParameter)
      : null;
    const dateFrom = getSearchParameter({ name: names.dateFrom, search });
    const dateTo = getSearchParameter({ name: names.dateTo, search });

    const tourismObjectParameter =
      getSearchParameter({
        name: names.tourismObject,
        search,
      }) ||
      getSearchParameter({
        name: "search",
        search,
      });

    return {
      [names.tourismObject]: tourismObjectParameter || null,
      [names.location]:
        // @ts-ignore
        locationParameterParsed && Object.keys(locationParameterParsed).length
          ? locationParameterParsed
          : null,
      [names.dateFrom]: dateFrom || null,
      [names.dateTo]: dateTo || null,
      [names.rooms]:
        // @ts-ignore
        roomsParameterParsed && Object.keys(roomsParameterParsed).length
          ? roomsParameterParsed
          : [{ adults: 2, children: [] }],
    };
  }, [search]);

  useEffect(() => {
    if (!canShowAnixeSearch) return;

    const tourismObjectParameter = initialValues[names.tourismObject];
    const locationParameter = initialValues[names.location];
    const dateFrom = initialValues[names.dateFrom];
    const dateTo = initialValues[names.dateTo];
    const dates = dateFrom &&
      dateTo && {
        dateFrom,
        dateTo,
      };
    const rooms = initialValues[names.rooms];

    type TourismParameters =
      | typeof tourismObjectParameter
      | typeof locationParameter
      | typeof dates
      | typeof rooms;
    type DispatchFunction = () => unknown;
    const dispatchMap = new Map<TourismParameters, DispatchFunction>([
      [
        tourismObjectParameter,
        () => setSelectedObject(tourismObjectParameter as string),
      ],
      [
        locationParameter,
        () => setSelectedLocation(locationParameter as ILocationItem),
      ],
      [
        dates,
        () => setSelectedDates(dates as unknown as IAnixeFiltersSelectedDates),
      ],
      [rooms, () => setSelectedRooms(rooms as IAnixeFiltersRoom[])],
    ]);

    dispatchMap.forEach((action) => {
      reduxDispatch(action());
    });
  }, [initialValues]);

  return !!roomsAmount && !!guestsAmount ? (
    <div className={classes.root}>
      <div>
        {city && <div className={classes.city}>{city}</div>}
        <div className={classes.info}>
          {roomsAmount} {__("pokój")}, {guestsAmount} {__("gości")}
        </div>
        <div className={classes.info}>
          {dateFromFormatted} - {dateToFormatted}
        </div>
      </div>
      <Button variant="secondary" onClick={() => openModal()}>
        {__("Edytuj")}
      </Button>
    </div>
  ) : null;
};

export default classify<
  PropsWithClasses<IProductEditAccomodationContainerMobileProps>
>(defaultClasses)(ProductEditAccomodationContainerMobile);
