/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDomesticTourismAttribute } from "web/types/Attributes";

interface ITourismAttributesState {
  storedAttributes: IDomesticTourismAttribute[],
}

const initialState: ITourismAttributesState = {
  storedAttributes: [],
};

export const tourismAttributesSlice = createSlice({
  name: "tourismAttributes",
  initialState,
  reducers: {
    setAttributes: (state, action: PayloadAction<IDomesticTourismAttribute[]>) => {
      state.storedAttributes = action.payload;
    },
  },
});

export const {
  setAttributes,
} = tourismAttributesSlice.actions;

export default tourismAttributesSlice.reducer;