import { useMemo } from "react";
import { useLocation } from "react-router";

import GtmEvent from "web/Layout/Gtm/GtmEvent";

import getPageReferrer from "web/utils/system/GTM/getPageReferrer";

import { useAppContext } from "web/context/app";

import ProductsQuickList from "./productsQuickList";

const ProductQuickListContainer = () => {
  const { isMobile } = useAppContext();
  const location = useLocation();

  const gtmOptions = useMemo(
    () => ({
      cgroup1: "Konto klienta",
      page_referrer: getPageReferrer(location),
    }),
    [location, getPageReferrer]
  );

  return (
    <>
      <ProductsQuickList isMobile={isMobile} />
      <GtmEvent options={gtmOptions} withUrl />
    </>
  );
};

export default ProductQuickListContainer;
