import { FC, memo } from "react";

import NavigationList from "web/Pages/Checkout/Header/Shared/NavigationList";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { Link, Pathname, Search } from "../Types/types";
import MobileHeader from "./MobileHeader";
import classes from "./header.scss";
import classesItem from "./navigationItem.scss";
import classesList from "./navigationList.scss";

export interface ICheckoutMobileHeader {
  links: Link[];
  pathname: Pathname;
  search: Search;
}

const CheckoutMobileHeader: FC<ICheckoutMobileHeader> = memo(
  ({ links, pathname, search }) => {
    return isArrayHasItems(links) ? (
      <header className={classes.root} data-testid="checkout-mobile-header">
        <div className={classes.header}>
          <MobileHeader links={links} pathname={pathname} search={search} />
          <NavigationList
            links={links}
            pathname={pathname}
            search={search}
            classes={classesList}
            listItemClasses={classesItem}
          />
        </div>
      </header>
    ) : null;
  }
);

export default CheckoutMobileHeader;
