import { FC } from "react";

import __ from "web/Layout/Translations";

import CodeItem from "web/Components/Common/CodeItem";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getProductOrParentImg from "web/utils/page/product/universal/getProductOrParentImg";

import urls from "web/constants/urls";

import { ICartParentProductAttribute } from "web/types/Cart";
import { ICodeItem } from "web/types/Code";
import { IParsedCodeItem } from "web/types/Order";

import classes from "./list.scss";

interface ICustomerCodeListListDesktopProps {
  items: ICodeItem[];
}

const CustomerCodeListListDesktop: FC<ICustomerCodeListListDesktopProps> = ({
  items,
}) => {
  return isArrayHasItems(items) ? (
    <ul className={classes.root}>
      {items.map(
        (
          {
            item_id: itemId,
            name,
            order_id: orderId,
            product_id: productId,
            product_expiration_date: expirationDate,
            product_user_used: userUsed,
            product_data: productData,
            product_options: productOptions,
            product_code_status: productStatus,
            order_status_code: orderStatusCode,
            sku,
          },
          index
        ) => {
          const productOptionsParsed = jsonParse(
            productOptions
          ) as IParsedCodeItem;
          const productDataParsed = jsonParse(productData) as IParsedCodeItem;
          const productOptionsAttributes =
            productOptionsParsed?.parent_product_data?.super_attributes;
          const idsAttributeOptions = isArrayHasItems(productOptionsAttributes)
            ? productOptionsAttributes.reduce(
                (
                  result: { attribute: ICartParentProductAttribute }[],
                  current
                ) => {
                  return current
                    ? [
                        ...result,
                        {
                          attribute: current,
                        },
                      ]
                    : result;
                },
                []
              )
            : [];

          const image = getProductOrParentImg(
            productDataParsed.small_image as string,
            productOptionsParsed
          );

          const link =
            orderId && productId
              ? urls.customerOrdersOrderProduct
                  .replace(":orderId", `${orderId}`)
                  .replace(":productId", `${productId}`)
              : null;

          return (
            <li
              data-t1={name}
              className={classes.item}
              // eslint-disable-next-line react/no-array-index-key
              key={`${itemId}${userUsed}${index}`}
            >
              <CodeItem
                link={link!}
                name={name}
                image={image!}
                expirationDate={expirationDate}
                userUsed={userUsed}
                itemId={itemId}
                options={idsAttributeOptions}
                codeDetails={productOptionsParsed}
                productStatus={productStatus}
                dataT1={`${itemId}_item`}
                producSuperOptions={productDataParsed}
                orderStatusCode={orderStatusCode}
                sku={sku}
              />
            </li>
          );
        }
      )}
    </ul>
  ) : (
    <div data-t1="code_list_info">{__("Aktualnie nie posiadasz kodów")}</div>
  );
};

export default CustomerCodeListListDesktop;
