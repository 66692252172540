// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fundingSource-employeeSubText-PXj{font-size:10px;white-space:nowrap}.fundingSource-columnOne__value-kLz{white-space:nowrap;font-size:10px}.fundingSource-columnOne__label-jvs{display:inline-block;margin-bottom:3px}`, "",{"version":3,"sources":["webpack://./web/Pages/Checkout/Subscription/FundingSource/fundingSource.scss"],"names":[],"mappings":"AAIuB,mCACrB,cAAA,CACA,kBAAA,CAGF,oCACE,kBAAA,CACA,cAAA,CAGF,oCACE,oBAAA,CACA,iBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .employeeSubText {\n  font-size: 10px;\n  white-space: nowrap;\n}\n\n.columnOne__value {\n  white-space: nowrap;\n  font-size: 10px;\n}\n\n.columnOne__label {\n  display: inline-block;\n  margin-bottom: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employeeSubText": `fundingSource-employeeSubText-PXj`,
	"columnOne__value": `fundingSource-columnOne__value-kLz`,
	"columnOne__label": `fundingSource-columnOne__label-jvs`
};
export default ___CSS_LOADER_EXPORT___;
