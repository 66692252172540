import { getOperationName } from "@apollo/client/utilities";
import { print } from "graphql";
import { isNil } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import msCategoriesQuery from "web/queries/ms/categoriesByIds.graphql";

import deleteGraphqlExtraSpaces from "web/utils/system/query/deleteGraphqlExtraSpaces";
import graphQlAsGet from "web/utils/system/query/graphQlAsGet";
import { getFlattedData } from "web/utils/system/storage/storagesAsync/utils";

import { msURlKey } from "web/constants/graphqlUrls";

import { ICategory } from "web/types/Category";

import { request } from "web/api";
import { useAppContext } from "web/context/app";

import {
  addCategoryEntries,
  setIsPrefetching,
  setPrefetchTTL,
} from "../categories/categoriesSlice";
import { Identifiers } from "./useDataCached";
import addTimeExpiration from "./utils/addTimeExpiration";

const TTL = process.env.CATEGORIES_CACHE_TTL
  ? +process.env.CATEGORIES_CACHE_TTL
  : undefined;

interface IUseDataCachedCategoriesPrefetchArgs {
  isLogged: boolean;
  loading: boolean;
}

const useDataCachedCategoriesPrefetch = ({
  isLogged,
  loading,
}: IUseDataCachedCategoriesPrefetchArgs) => {
  const dispatch = useDispatch();
  const { prefetchTTL } = useSelector((state) => state.categories);
  const { token, id: storeId } = useSelector((state) => state.app.storeConfig);
  // temporary - change to webbiew check
  const { isMobile } = useAppContext();

  const shouldPrefetch =
    (!prefetchTTL || Date.now() > prefetchTTL) &&
    isLogged &&
    !loading &&
    !isNil(token) &&
    !isNil(storeId);

  useEffect(() => {
    if (!shouldPrefetch) {
      dispatch(setIsPrefetching(false));
      return;
    }
    prefetchCategories({ token, storeId, dispatch, isMobile });
  }, [shouldPrefetch]);
};

const prefetchCategories = async ({
  token,
  storeId,
  dispatch,
  isMobile,
}: {
  token: string;
  storeId: number;
  dispatch: Dispatch;
  isMobile: boolean;
}) => {
  dispatch(setIsPrefetching(true));
  const queryProcessed = print(msCategoriesQuery);
  const queryProcessedWithoutSpaces = deleteGraphqlExtraSpaces(queryProcessed);
  const operationName = getOperationName(msCategoriesQuery);
  const options = {
    method: IApiRequestMethods.POST,
    body: JSON.stringify({
      query: queryProcessedWithoutSpaces,
      operationName,
      variables: {
        token,
        storeId,
        getAll: true,
        lang: getLanguageCode(),
        isMobile,
      },
    }),
  };
  const data = await graphQlAsGet(msURlKey, options, request);

  const dataFlatted = getFlattedData<ICategory>(
    data as { data: Record<string, { edges: { node: ICategory }[] }> },
    msCategoriesQuery
  ) as ICategory[];

  const dataWithExpiration = addTimeExpiration<ICategory>(
    dataFlatted,
    "id" as keyof Identifiers,
    false,
    TTL
  );

  dispatch(addCategoryEntries(dataWithExpiration));
  // set prefetch TTL 10s before categories expiration
  dispatch(setPrefetchTTL(Date.now() + (TTL || 0) - 10000));
  dispatch(setIsPrefetching(false));
};

export default useDataCachedCategoriesPrefetch;
