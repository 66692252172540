/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "@benefit-systems/common-components";
import { Link, useLocation } from "react-router-dom";

import GtmEvent from "web/Layout/Gtm/GtmEvent";
import __ from "web/Layout/Translations";

import CartSadIcon from "web/assets/icons/cart-sad.svg";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import getPageReferrer from "web/utils/system/GTM/getPageReferrer";
import { useMemo } from "react";
import classes from "./failed.scss";

const CheckoutFailed = () => {
  const { isMobile } = useAppContext();
  const location = useLocation();
  const gtmOptions = useMemo(() => ({
    cgroup1: "Koszyk",
    cgroup2: "Zamówienie nie zostało złożone",
    page_referrer: getPageReferrer(location),
  }),
  [location, getPageReferrer]);
  
  return (
    <>
      <div className={`container ${classes.container}`}>
        {!isMobile ? (
          <div className={classes.banner}>
            <span
              className={classes.bannerTitle}
              data-t1="checkout_failed_title"
            >
              {__("Błąd!")}
            </span>
            <span className={classes.bannerText} data-t1="checkout_failed_text">
              {__("nieudana płatność")}
            </span>
            <span className={classes.bannerIcon}>
              <CartSadIcon width={220} />
            </span>
          </div>
        ) : (
          <>
            <Link to={urls.home}>
              <button
                type="button"
                className={classes.closeButton}
                // @ts-ignore
                label="Wróć do strony głównej"
              />
            </Link>
            <div
              className={classes.headerTitle}
              data-t1="ccheckout_failed_title"
            >
              {__("Błąd!")}
            </div>
            <div className={classes.headerText} data-t1="checkout_failed_text">
              {__("nieudana płatność")}
            </div>
          </>
        )}
        <p
          className={isMobile ? classes.descriptionMobile : classes.description}
          data-t1="checkout_failed_description"
        >
          {__("Wystąpił błąd podczas przetwarzania płatności!")}
          <br />
          {__("Twoje zamówienie nie zostało opłacone i zostanie anulowane.")}
        </p>
        <strong
          className={isMobile ? classes.titleMobile : classes.title}
          data-t1="checkout_failed_subtitle"
        >
          {__("Co teraz?")}
        </strong>
        <p
          className={isMobile ? classes.textMobile : classes.text}
          data-t1="checkout_failed_info"
        >
          {__(
            "Jeśli podczas zamówienia wystąpił błąd, prosimy o skontaktowanie się z administratorem."
          )}
        </p>
        <div className={classes.buttonWrapper}>
          <Button
            to={urls.home}
            variant="tertiary"
            dataT1="checkout_failed_continue"
          >
            {__("Kontynuuj zakupy")}
          </Button>
        </div>
        {isMobile ? (
          <div className={classes.bannerMobile}>
            <span className={classes.bannerIcon}>
              <CartSadIcon />
            </span>
          </div>
        ) : null}
      </div>
      <GtmEvent options={gtmOptions} withUrl />
    </>
  );
};

export default CheckoutFailed;
