import categoryCountsQuery from "web/queries/ms/categoryCounts.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { ICategoryCount } from "web/types/CategoryCount";

import {
  addCategoryCountsEntries,
  removeCategoryCountsEntries,
  selectEntities,
} from "../categoryCounts/categoryCountsSlice";
import useDataCached from "./useDataCached";

const mapData = new MapWrapper();

const useDataCachedCategoryCounts = ({ ids }: { ids: number[] }) =>
  useDataCached<ICategoryCount>({
    ids: ids.map(id => +id),
    query: categoryCountsQuery,
    addToCacheAction: addCategoryCountsEntries,
    removeFromCacheAction: removeCategoryCountsEntries,
    selectEntities,
    isCategoryCounts: true,
    mapData,
  });

export default useDataCachedCategoryCounts;
