import { ICartItem } from "web/types/Cart";
import { IParsedCodeItem, IParsedOrder } from "web/types/Order";
import { MBProductType } from "web/types/Product";
import { IProducts } from "./container";

import Product from "./Product";
import classes from "./product.scss";

const SuccessProducts = ({ items }: { items: IParsedOrder<MBProductType>["items"] | IProducts[]}) => (
  <ul className={classes.root}>
    {items.map((item) => {
      return (
        <li
          className={classes.item}
          key={item.sku}
          data-t2={item.sku}
          data-t1="product_item"
        >
          <Product full={(item as IParsedCodeItem).product_data} quote={item as IParsedOrder<MBProductType>["items"][number] & ICartItem} />
        </li>
      );
    })}
  </ul>
);

export default SuccessProducts;
