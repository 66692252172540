import { Button } from "@benefit-systems/common-components";

import Points from "web/Layout/Common/Points";
import __ from "web/Layout/Translations";

import urls from "web/constants/urls";

import classes from "./orderSummary.scss";

type OrderSummaryProps = {
  orderNumber: string;
  totalAmount: number;
};

const OrderSummary = ({ orderNumber, totalAmount }: OrderSummaryProps) => {
  return (
    <>
      <div
        className={classes.orderNumberRow}
        data-t1="orderSummaryMain__orderNumber"
      >
        {__("Twój numer zamówienia to")}:{" "}
        <p
          className={classes.orderNumberValue}
          data-t1="orderSummaryMain__orderNumberValue"
          data-t2={orderNumber}
        >
          {" "}
          {orderNumber}
        </p>
      </div>

      <div className={classes.pointsRow}>
        {__("Zostało pobranych")}:{" "}
        <Points className={classes.pointsValue} points={totalAmount} />
      </div>

      <Button
        className={classes.button}
        to={urls.home}
        variant="tertiary"
        dataT1="success_page_button_continue"
      >
        {__("Kontynuuj zakupy")}
      </Button>
    </>
  );
};

export default OrderSummary;
