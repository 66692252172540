import { useMemo } from "react";

import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

import { ICnsx } from "web/types/Tourism";

const useFreeCancellationInfo = (
  dateFrom?: ICnsx["from"],
  timeFrom?: ICnsx["from_time"],
  orderDate?: Date
) =>
  useMemo(
    () =>
      timeFrom &&
      dateFrom &&
      +new Date(`${dateFrom} ${timeFrom}`) > +(orderDate ?? Date.now())
        ? `${__("Bezkosztowa anulacja możliwa jest do dnia")} ${formatDate(
            dateFrom
          )}, ${__("do godziny")} 21:59.`
        : null,
    [dateFrom, timeFrom]
  );

export default useFreeCancellationInfo;
