import { FC, useMemo } from "react";

import { MobileHeader } from "web/Layout/MobileHeader/mobileHeader";

import { ICheckoutMobileHeader } from "../header";

const CheckoutMobileHeaderContainer: FC<ICheckoutMobileHeader> = ({
  links,
  pathname,
  search,
}) => {
  const label = useMemo(() => {
    const currentLinkDataIndex = links.findIndex(
      ({ link }) => link.split("?")[0] === pathname
    );
    const currentLinkData =
      currentLinkDataIndex !== -1 ? links[currentLinkDataIndex] : null;

    if (!currentLinkData) return null;

    const { label } = currentLinkData;

    return label;
  }, [pathname, search]);

  return <MobileHeader back>{label}</MobileHeader>;
};

export default CheckoutMobileHeaderContainer;
