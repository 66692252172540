import restUrls from "web/constants/restUrls";

import {
  ISubscriptionBenefitGroup,
  ISubscriptionDraftBenefits,
  ISubscriptionDraftBenefitsAgreements,
  ISubscriptionDraftForms,
  ISubscriptionUserCurrent,
} from "web/types/Subscription";
import { HydraResponse } from "web/types/Utils";

import { AppDispatch } from "web/store";

import api from "../api/apiSlice";

export const subscriptionsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUserSubscription: builder.query<ISubscriptionUserCurrent[], void>(
      {
        query: () => ({
          url: restUrls.currentUserSubscription,
          headers: {
            "Content-Type": "application/ld+json",
            Accept: "application/ld+json",
          },
        }),
        transformResponse: (
          result: HydraResponse<ISubscriptionUserCurrent>
        ) => {
          return result["hydra:member"] || [];
        },
        providesTags: ["CurrentUserSubscriptions"],
      }
    ),
    getAvailableUserBenefits: builder.query<
      ISubscriptionBenefitGroup[],
      { benefitGroupType: string }
    >({
      query: ({ benefitGroupType }) => ({
        url: restUrls.availableUserBenefitsGrouped,
        headers: {
          "Content-Type": "application/ld+json",
          Accept: "application/ld+json",
        },
        params: { benefitGroupType },
      }),
      transformResponse: (result: HydraResponse<ISubscriptionBenefitGroup>) => {
        return result["hydra:member"] || [];
      },
      providesTags: ["AvailableUserBenefits"],
    }),
    getSubscriptionDraftBenefits: builder.query<
      ISubscriptionDraftBenefits,
      { benefitGroupType: string; uuid: string }
    >({
      query: ({ benefitGroupType, uuid }) => ({
        url: restUrls.employeeSubscriptionDraft.replace(":uuid", uuid),
        params: { benefitGroupType },
        headers: {
          "Content-Type": "application/ld+json",
          Accept: "application/ld+json",
        },
      }),
      providesTags: ["EmployeeSubscriptionDraft"],
    }),
    getSubscriptionDraftForms: builder.query<
      ISubscriptionDraftForms,
      { benefitGroupType: string; uuid: string }
    >({
      query: ({ benefitGroupType, uuid }) => ({
        url: restUrls.employeeSubscriptionDraftForms.replace(":uuid", uuid),
        params: { benefitGroupType },
        headers: {
          "Content-Type": "application/ld+json",
          Accept: "application/ld+json",
        },
      }),
      providesTags: ["EmployeeSubscriptionDraftForms"],
    }),
    getSubscriptionDraftAgreements: builder.query<
      ISubscriptionDraftBenefitsAgreements,
      { benefitGroupType: string; uuid: string }
    >({
      query: ({ benefitGroupType, uuid }) => ({
        url: restUrls.employeeSubscriptionDraftAgreements.replace(
          ":uuid",
          uuid
        ),
        params: { benefitGroupType },
        headers: {
          "Content-Type": "application/ld+json",
          Accept: "application/ld+json",
        },
      }),
      providesTags: ["EmployeeSubscriptionDraftAgreements"],
    }),
    getDuplicateCardConfiguration: builder.query<any, void>({
      query: () => ({
        url: restUrls.duplicateCardConfiguration,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      providesTags: ["DuplicateCardConfiguration"],
    }),
  }),
});

export const invalidateCurrentUserSubscription = (dispatch: AppDispatch) =>
  dispatch(
    subscriptionsApiSlice.util.invalidateTags(["CurrentUserSubscriptions"])
  );

export const {
  useGetCurrentUserSubscriptionQuery,
  useGetAvailableUserBenefitsQuery,
  useGetSubscriptionDraftBenefitsQuery,
  useGetSubscriptionDraftFormsQuery,
  useGetSubscriptionDraftAgreementsQuery,
  useGetDuplicateCardConfigurationQuery,
} = subscriptionsApiSlice;
