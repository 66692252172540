import { FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import __ from "web/Layout/Translations";

import urls, { CheckoutTypes } from "web/constants/urls";

import { useAppContext } from "web/context/app";

import { Link, Search } from "./Types/types";
import CheckoutHeader from "./header";

type UUID = string;
type StepType = string;
type Params = {
  uuid: UUID;
  type: StepType;
};

type GetStepsHandlerArgs = {
  type: StepType;
  isMobile: boolean;
  search: Search;
  uuid: UUID;
};

type GetStepsHandler = (args: GetStepsHandlerArgs) => Link[];

interface ICheckoutHeaderContainerContainer {
  isThankYouPage?: boolean;
}

const getMobileDomesticTourismSteps = (search: Search) => [
  {
    key: 1,
    link: `${urls.checkoutDomesticTourismInformation}${search}`,
    label: __("Informacje podstawowe"),
  },
  {
    key: 2,
    link: `${urls.checkoutDomesticTourismPaymentSources}${search}`,
    label: __("Źródła finansowania"),
  },
  {
    key: 3,
    link: `${urls.checkoutDomesticTourismPaymentMethod}${search}`,
    label: __("Metoda płatności"),
  },
  {
    key: 4,
    link: `${urls.checkoutDomesticTourismSummaryInformation}${search}`,
    label: __("Zgody i regulaminy"),
  },
  {
    key: 5,
    link: `${urls.checkoutDomesticTourismSummary}${search}`,
    label: __("Podsumowanie"),
  },
];

const getDesktopDomesticTourismSteps = (search: Search) => [
  {
    key: 1,
    link: `${urls.checkoutDomesticTourismInformation}${search}`,
    label: __("Informacje podstawowe"),
  },
  {
    key: 2,
    link: `${urls.checkoutDomesticTourismPayments}${search}`,
    label: __("Płatność"),
  },
  {
    key: 3,
    link: `${urls.checkoutDomesticTourismSummary}${search}`,
    label: __("Podsumowanie"),
  },
];

const getMobileInternationalTourismSteps = (search: Search) => [
  {
    key: 1,
    link: `${urls.checkoutInternationalTourismPaymentSources}${search}`,
    label: __("Źródła finansowania"),
  },
  {
    key: 2,
    link: `${urls.checkoutInternationalTourismPaymentMethod}${search}`,
    label: __("Metoda płatności"),
  },
  {
    key: 3,
    link: `${urls.checkoutInternationalTourismSummary}${search}`,
    label: __("Podsumowanie"),
  },
];

const getDesktopInternationalTourismSteps = (search: Search) => [
  {
    key: 1,
    link: `${urls.checkoutInternationalTourismPayments}${search}`,
    label: __("Płatność"),
  },
  {
    key: 2,
    link: `${urls.checkoutInternationalTourismSummary}${search}`,
    label: __("Podsumowanie"),
  },
];

const getMobileBuyNowSteps = (uuid: UUID) => [
  {
    key: 1,
    link: `${urls.checkoutBuyNowPaymentSources}/${uuid}`,
    label: __("Źródła finansowania"),
  },
  {
    key: 2,
    link: `${urls.checkoutBuyNowPaymentMethod}/${uuid}`,
    label: __("Metoda płatności"),
  },
  {
    key: 3,
    link: `${urls.checkoutBuyNowSummary}/${uuid}`,
    label: __("Podsumowanie"),
  },
];

const getDesktopBuyNowSteps = (uuid: UUID) => [
  {
    key: 1,
    link: `${urls.checkoutBuyNowPayments}/${uuid}`,
    label: __("Płatność"),
  },
  {
    key: 2,
    link: `${urls.checkoutBuyNowSummary}/${uuid}`,
    label: __("Podsumowanie"),
  },
];

const getDefaultSteps = () => [
  {
    key: 1,
    link: urls.checkoutDefaultCart,
    label: __("Koszyk"),
  },
  {
    key: 2,
    link: urls.checkoutDefaultPayments,
    label: __("Płatności"),
  },
  {
    key: 3,
    link: urls.checkoutDefaultSummary,
    label: __("Podsumowanie"),
  },
];

const getStepsById: GetStepsHandler = ({ type, isMobile, search, uuid }) => {
  switch (type) {
    case CheckoutTypes.DOMESTIC_TOURISM: {
      return isMobile
        ? getMobileDomesticTourismSteps(search)
        : getDesktopDomesticTourismSteps(search);
    }
    case CheckoutTypes.INTERNATIONAL_TOURISM: {
      return isMobile
        ? getMobileInternationalTourismSteps(search)
        : getDesktopInternationalTourismSteps(search);
    }
    case CheckoutTypes.BUY_NOW: {
      return isMobile
        ? getMobileBuyNowSteps(uuid)
        : getDesktopBuyNowSteps(uuid);
    }
    default: {
      return getDefaultSteps();
    }
  }
};

const CheckoutHeaderContainerContainer: FC<
  ICheckoutHeaderContainerContainer
> = ({ isThankYouPage = false }) => {
  const { uuid, type } = useParams<Params>();
  const { search } = useLocation();
  const { isMobile } = useAppContext();
  const links = getStepsById({ type, isMobile, search, uuid });

  return <CheckoutHeader links={links} isThankYouPage={isThankYouPage} />;
};

export default CheckoutHeaderContainerContainer;
