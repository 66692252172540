import { Dispatch, SetStateAction, useState } from "react";

import { Error, ErrorData, IApiRequestMethods } from "web/api/apiRequestTypes";

import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";

import { IHelpCenterArticleArticle } from "web/types/HelpCenter";

import { request } from "web/api";

import { IArticleOpinion } from "../types";

type UseOpinion = (
  articleId: IHelpCenterArticleArticle["id"],
  cb?: (args?: unknown) => void
) => {
  postOpinion: (opinion: IArticleOpinion) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<Error>>;
  isLoading: boolean;
  error: Error;
  message: string;
};

const useHelpCenterArticleOpinion: UseOpinion = (articleId, cb) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>(null);
  const [message, setMessage] = useState("");

  const postOpinion = async ({ opinion, comment = "" }: IArticleOpinion) => {
    if (opinion === 0 && !comment) return;

    setIsLoading(true);

    const body = {
      articleOpinion: {
        article_id: articleId,
        ...sanitizeObject({ opinion, comment }),
      },
    };

    try {
      const requestUrl = restUrls.helpCenterOpinion;
      const response = await request(requestUrl, {
        method: IApiRequestMethods.POST,
        body: JSON.stringify(body),
      });

      if (response) {
        if (typeof cb === "function") cb();
        setMessage("Dziękujemy za opinię.");
      }
    } catch (errorData) {
      newRelicErrorReport(
        errorData,
        "web-app/web/Pages/HelpCenterArticle/Shared/useOpinion.ts - 57"
      );
      setError(errorData as ErrorData);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    postOpinion,
    setIsLoading,
    setError,
    isLoading,
    error,
    message,
  };
};

export default useHelpCenterArticleOpinion;
