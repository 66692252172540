import { AppModes } from "web/hooks/useAppMode/useAppMode";

export const enum ContactFormSources {
  DESKTOP_BROWSER = "Desktop (przeglądarka)",
  DEKTOP_PWA = "Desktop (PWA)",
  MOBILE_BROWSER = "Mobile (przeglądarka)",
  MOBILE_PWA = "Mobile (PWA)",
  MOBILE_APP = "Mobile (aplikacja)",
}

const getContactFormSource = (isMobile: boolean, appMode: AppModes) => {
  if (isMobile) {
    switch (appMode) {
      case AppModes.BROWSER:
        return ContactFormSources.MOBILE_BROWSER;
      case AppModes.PWA:
        return ContactFormSources.MOBILE_PWA;
      case AppModes.WEBVIEW:
        return ContactFormSources.MOBILE_APP;
      default:
        return "";
    }
  } else {
    switch (appMode) {
      case AppModes.BROWSER:
        return ContactFormSources.DESKTOP_BROWSER;
      case AppModes.PWA:
        return ContactFormSources.DEKTOP_PWA;
      default:
        return "";
    }
  }
};

export default getContactFormSource;
