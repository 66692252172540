// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop-root-wdh{padding-top:30px}.desktop-body-XTo{position:relative}`, "",{"version":3,"sources":["webpack://./web/Pages/ContactForm/desktop.scss"],"names":[],"mappings":"AAIuB,kBACrB,gBAAA,CAGF,kBACE,iBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  padding-top: 30px;\n}\n\n.body {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `desktop-root-wdh`,
	"body": `desktop-body-XTo`
};
export default ___CSS_LOADER_EXPORT___;
