import { FC, useMemo } from "react";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading/loading";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IParsedParameter } from "web/types/PageGenerator";

import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

import ErrorBoundary from "../../../Layout/ErrorBoundary";
import TourismBanner from "./tourismBanner";

interface IPageGeneratorTourismBannerContainerProps {
  params: string;
}

const PageGeneratorTourismBannerContainer: FC<
  IPageGeneratorTourismBannerContainerProps
> = ({ params }) => {
  const {
    banner: imageSrc,
    url: buttonUrl,
    category_id: categoryId,
    alt: description,
    button: buttonTitle,
    button_visible: isButtonVisible,
  } = useMemo<IParsedParameter>(() => jsonParse(params), [params]);
  const categoryIdProcessed = parseInt(categoryId, 10);

  const { loading, error, data } = useDataCachedCategories({
    ids: [categoryIdProcessed],
  });

  if (error) {
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  if (loading || !isArrayHasItems(data)) {
    return <Loading />;
  }

  const categoryUrl = data[0]?.request_path || "";

  return (
    <TourismBanner
      imageSrc={imageSrc}
      description={description}
      url={buttonUrl}
      categoryUrl={categoryUrl}
      buttonTitle={buttonTitle}
      isButtonVisible={isButtonVisible}
    />
  );
};

interface IPageGeneratorTourismBannerContainerErrorProps {
  params: string;
}

const PageGeneratorTourismBannerContainerError: FC<
  IPageGeneratorTourismBannerContainerErrorProps
> = ({ params }) => {
  return (
    <ErrorBoundary>
      <PageGeneratorTourismBannerContainer params={params} />
    </ErrorBoundary>
  );
};

export default PageGeneratorTourismBannerContainerError;
