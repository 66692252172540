/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import Section from "web/Pages/Checkout/TourismDomestic/SharedComponents/Section";

import Accordion from "web/Components/Common/Accordion";

import DOMPurify from "dompurify";
import classes from "./checkoutInfoSection.scss";

interface ICheckoutTourismDomesticInfoSectionProps {
  name: string;
  info: string[];
  variant?: "primary" | "tertiary";
}

const CheckoutTourismDomesticInfoSection: FC<
  ICheckoutTourismDomesticInfoSectionProps
> = ({ name, info, variant = "primary" }) => {
  const [paragraph, warningCopy, ...listItems] = info;

  return (
    <Section>
      <Accordion
        label={name}
        arrow
        variant={variant}
        className={variant === "tertiary" ? null : classes.accordionRoot}
        expanded
      >
        <p className={classes.paragraph}>{paragraph}</p>
        {!!listItems.length && (
          <ul className={classes.list}>
            {listItems.map((item) => (
              <li key={item} className={classes.listItem}
                dangerouslySetInnerHTML={{
                  // @ts-ignore
                  __html: DOMPurify.sanitize(item),
                }}
              />
            ))}
          </ul>
        )}
        <p className={classes.paragraph}>{warningCopy}</p>
      </Accordion>
    </Section>
  );
};

export default CheckoutTourismDomesticInfoSection;
