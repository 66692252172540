import { arrayOf, node, oneOf, oneOfType, string } from "prop-types";
import { PureComponent } from "react";

import FatalError from "web/Layout/ErrorPages/FatalError";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import clearCache from "./utils/system/essentials/clearCache";

const alreadyRefreshedFlag = "errorBoundaryRecovery";

const isAlreadyRefreshedFlag = () => {
  return localStorage.getItem(alreadyRefreshedFlag) === "1";
};
const removeAlreadyRefreshedFlag = () => {
  return localStorage.removeItem(alreadyRefreshedFlag);
};
const setAlreadyRefreshedFlag = () => {
  return localStorage.setItem(alreadyRefreshedFlag, "1");
};

const localStorageToKeep = [
  "FENIKS_BROWSER_PERSISTENCE__token",
  "FENIKS_BROWSER_PERSISTENCE__tokenAccess",
  alreadyRefreshedFlag,
];
const clearCacheAndReload = async () => {
  sessionStorage.clear();
  Object.keys(localStorage).forEach((item) => {
    if (!localStorageToKeep.includes(item)) {
      localStorage.removeItem(item);
    }
  });
  await clearCache();
  window.location.reload(true);
};

class RootErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    newRelicErrorReport(
      error,
      `ROOT ERROR BOUNDARY - stack: ${errorInfo.componentStack}`
    );
    console.error(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      if (!isAlreadyRefreshedFlag()) {
        setAlreadyRefreshedFlag();
        clearCacheAndReload();
        return "";
      }
      removeAlreadyRefreshedFlag();
      return <FatalError />;
    }

    return children;
  }
}

RootErrorBoundary.propTypes = {
  children: oneOfType([node, string, arrayOf(node), oneOf([null])]),
};

RootErrorBoundary.defaultProps = {
  children: null,
};

export default RootErrorBoundary;
