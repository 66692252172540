import { useMemo } from "react";
import { useLocation } from "react-router";

import GtmEvent from "web/Layout/Gtm/GtmEvent";

import getPageReferrer from "web/utils/system/GTM/getPageReferrer";

import Page404 from "./page404";

const Page404Container = () => {
  const location = useLocation();
  const gtmOptions = useMemo(
    () => ({
      cgroup1: "404",
      page_referrer: getPageReferrer(location),
    }),
    [location, getPageReferrer]
  );

  return (
    <>
      <Page404 />
      <GtmEvent options={gtmOptions} withUrl />
    </>
  );
};

export default Page404Container;
