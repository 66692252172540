import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { motion } from "framer-motion";
import { useRef, useState } from "react";

import PlusMinusInput from "web/Pages/Tourism/DomesticTourismForm/PlusMinusInput";

import __ from "web/Layout/Translations";

import DropArrow from "web/Components/Common/DropArrow";

import useDropdown from "web/hooks/useDropdown";

import {
  NAMES,
  getNameByCount,
} from "web/utils/page/product/domesticTourism/getNameByCount";

import type { Nullable } from "web/types/Utils";

import type { IAnixeFiltersRoom } from "web/features/anixeFilters/anixeFiltersSlice";

import People from "../People";
import classes from "../room.scss";

const variants = {
  open: {
    opacity: 1,
    height: "auto",
    overflow: "visible",
  },
  closed: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
};

const DomesticTourismFormRoomFieldsDesktop = () => {
  const roomBoxRef = useRef<Nullable<HTMLDivElement>>(null);
  const { initialValues, values } = useFormikContext<{
    tourism_rooms: IAnixeFiltersRoom[];
  }>();
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.dropdown}`,
    clickOutside: true,
  });

  const [summary, setSummary] = useState({
    tourism_rooms: 1,
    adults: 2,
    children: 0,
  });

  const changeRoomsValue = (
    value: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (value > values.tourism_rooms.length) {
      arrayHelpers.insert(value, initialValues.tourism_rooms[0]);
    }
    if (value < values.tourism_rooms.length) {
      arrayHelpers.pop();
    }
  };

  const updateSummary = () => {
    let adultsSum = 0;
    let childrenSum = 0;
    values.tourism_rooms.forEach((room) => {
      adultsSum += room.adults;
      childrenSum += room.children.length;
    });

    setSummary({
      tourism_rooms: values.tourism_rooms.length,
      adults: adultsSum,
      children: childrenSum,
    });
  };
  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div className={classes.root}>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{__("Goście")}</legend>
        <button
          type="button"
          onClick={toggleDropdown}
          className={classes.trigger}
        >
          <p className={classes.summary}>
            {summary.tourism_rooms}{" "}
            {__(getNameByCount(summary.tourism_rooms, NAMES.ROOM))},{" "}
            {summary.adults}&nbsp;
            {__("dorosłych")}, {summary.children}&nbsp;{__("dzieci")}
          </p>
          <DropArrow open={!isOpen} />
        </button>
        <div>
          <motion.div
            className={classes.dropdown}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            transition={{ duration: 0.2 }}
            variants={variants}
          >
            <FieldArray name="tourism_rooms">
              {(arrayHelpers) => (
                <div className={classes.roomBox} ref={roomBoxRef}>
                  <div className={classes.roomCount}>
                    <span className={classes.label}>{__("Liczba pokoi")}</span>
                    <PlusMinusInput
                      currentValue={values.tourism_rooms.length}
                      onChange={(value) => {
                        changeRoomsValue(value, arrayHelpers);
                        updateSummary();
                      }}
                      maxValue={8}
                    />
                  </div>
                  <div className={`scrollbar ${classes.scrollbar}`}>
                    {values.tourism_rooms?.map((room, index) => {
                      const roomKey = `${index}${room.adults}`;

                      return (
                        <div key={roomKey} className={classes.roomSettings}>
                          <People index={index} onChange={updateSummary} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </FieldArray>
          </motion.div>
        </div>
      </fieldset>
    </div>
  );
};

export default DomesticTourismFormRoomFieldsDesktop;
