import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import Application from "./application";

const detectApplicationType = () => {
  const { userAgent } = navigator;
  switch (true) {
    case /iPad|iPhone|iPod/i.test(userAgent): {
      return "AppStore";
    }
    case /Android/i.test(userAgent): {
      return "Android";
    }
    default:
      return "PWA";
  }
};

interface IPageGeneratorApplicationContainerProps {
  isMobile?: boolean;
}

const PageGeneratorApplicationContainer: FC<IPageGeneratorApplicationContainerProps> = ({ isMobile = false }) => {
  const applicationType = detectApplicationType();

  switch (applicationType) {
    case "AppStore": {
      return (
        <Application
          isMobile={isMobile}
          url="https://www.apple.com/ios/app-store/"
        />
      );
    }
    case "Android": {
      return (
        <Application
          isMobile={isMobile}
          url="https://play.google.com/store/apps/"
        />
      );
    }
    case "PWA": {
      return <Application isMobile={isMobile} />;
    }
    default:
      return null;
  }
};

interface IPageGeneratorApplicationContainerErrorProps {
  isMobile?: boolean;
}

const PageGeneratorApplicationContainerError: FC<IPageGeneratorApplicationContainerErrorProps> = ({ isMobile = false }) => {
  return (
    <ErrorBoundary>
      <PageGeneratorApplicationContainer isMobile={isMobile} />
    </ErrorBoundary>
  );
};

export default PageGeneratorApplicationContainerError;
