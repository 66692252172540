import { FC, ReactNode } from "react";

import classes from "./fieldContainer.scss";

interface FieldContainerProps {
  label?: string;
  currency?: string;
  children: ReactNode;
}

const FieldContainer: FC<FieldContainerProps> = ({
  label = "",
  currency = "zł",
  children,
}) => {
  return (
    <div className={classes.fieldContainer}>
      <p className={classes.label}>{label}</p>
      <div className={classes.fieldInnerContainer}>
        {children}
        <p className={classes.currency}>{currency}</p>
      </div>
    </div>
  );
};

export default FieldContainer;
