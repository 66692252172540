import restUrls from "web/constants/restUrls";

import { ICodeSettings, IProductCode } from "web/types/Code";

import api from "../api/apiSlice";

export interface IProductCodeTransformed {
  isSplitted: boolean;
  code: string | string[];
}

export const codesApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCodeSettings: builder.query<ICodeSettings, string>({
      query: (sku) => ({
        url: restUrls.codeSettings.replace("{sku}", sku),
      }),
      providesTags: ["CodeSettings"],
    }),

    getProductCode: builder.query<IProductCodeTransformed, string | number>({
      query: (itemId) => ({
        url: `${restUrls.productCode}?item_id=${itemId}`,
      }),
      providesTags: ["ProductCode"],
      transformResponse: (response: IProductCode[]) => {
        const splittedCode = response[0]?.splittedCode;
        const splittedCodeTransformed = splittedCode
          ? [
              splittedCode.pierwsza_linijka_pod_kodem_kreskowym,
              splittedCode.druga_linijka_pod_kodem_kreskowym,
              splittedCode.trzecia_linijka_pod_kodem_kreskowym,
              splittedCode.czwarta_linijka_pod_kodem_kreskowym,
              splittedCode.piata_linijka_pod_kodem_kreskowym,
            ]
          : null;
        return {
          code: splittedCodeTransformed ?? response[0]?.code,
          isSplitted: !!splittedCode,
        };
      },
    }),

    getBarCode: builder.query<string, string | number>({
      query: (itemId) => ({
        url: `${restUrls.barCodeImage}?itemId=${itemId}`,
      }),
      providesTags: ["BarCode"],
      transformResponse: (response: string) =>
        `data:image/png;base64,${response}`,
    }),
  }),
});

export const {
  useGetCodeSettingsQuery,
  useGetProductCodeQuery,
  useGetBarCodeQuery,
} = codesApiSlice;
