import { useFormikContext } from "formik";
import { FC } from "react";

import __ from "web/Layout/Translations";

import GoogleLocalization from "web/Components/Common/GoogleLocalization";

import { locationField } from "../domesticTourismFormFields";
import { IDomesticTourismFormValues } from "../domesticTourismFormTypes";
import classes from "./localization.scss";

interface IDomesticTourismFormLocalizationProps {
  field: typeof locationField;
}

const DomesticTourismFormLocalization: FC<
  IDomesticTourismFormLocalizationProps
> = ({ field }) => {
  const { values } = useFormikContext<IDomesticTourismFormValues>();
  const locationValue = values && values[field.name as keyof typeof values];

  return (
    <div className={classes.root}>
      <div className={classes.fieldset}>
        <strong className={classes.legend}>
          {__("Szukaj po lokalizacji")}
        </strong>
        <GoogleLocalization
          name={field.name}
          placeholder={field.placeholder}
          initialValue={(locationValue || "") as string}
        />
      </div>
    </div>
  );
};

export default DomesticTourismFormLocalization;
