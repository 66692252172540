/* eslint-disable @typescript-eslint/ban-ts-comment, react/no-array-index-key */
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";

import PlusMinusInput from "web/Pages/Tourism/DomesticTourismForm/PlusMinusInput";

import __ from "web/Layout/Translations";

import type { Nullable } from "web/types/Utils";

import type { IAnixeFiltersRoom } from "web/features/anixeFilters/anixeFiltersSlice";

import People from "../People";
import classes from "../room.scss";

const DomesticTourismFormRoomFieldsMobile = () => {
  const { initialValues, values } = useFormikContext<{
    tourism_rooms: IAnixeFiltersRoom[];
  }>();
  const [hasChildren, setHasChildren] = useState(false);

  const peopleWrapperRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    if (values.tourism_rooms?.length !== 2) return;
    const { bottom } = peopleWrapperRef.current?.getBoundingClientRect() || {};
    const height = window.ReactNativeWebView
      ? window.screen.availHeight
      : window.innerHeight;
    window.scrollTo({
      top: (bottom as number) + window.scrollY - height + 50,
      behavior: "smooth",
    });
  }, [peopleWrapperRef.current, values]);

  const changeRoomsValue = (
    value: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (value > values.tourism_rooms.length) {
      arrayHelpers.insert(value, initialValues.tourism_rooms[0]);
    }
    if (value < values.tourism_rooms.length) {
      arrayHelpers.pop();
    }
  };

  useEffect(() => {
    setHasChildren(false);
    values.tourism_rooms.forEach((room) => {
      if (room.children.length > 0) {
        setHasChildren(true);
      }
    });
  }, [values]);

  return (
    <div className={classes.root}>
      {hasChildren ? <span className={classes.hasChildren}>Goście</span> : null}
      <fieldset className={classes.fieldset}>
        <FieldArray name="tourism_rooms">
          {(arrayHelpers) => (
            <>
              <div className={classes.roomCount}>
                <span className={classes.label}>{__("Liczba pokoi")}</span>
                <PlusMinusInput
                  currentValue={values.tourism_rooms.length}
                  onChange={(value) => {
                    changeRoomsValue(value, arrayHelpers);
                  }}
                  maxValue={8}
                />
              </div>
              <div ref={peopleWrapperRef} className={classes.scrollbar}>
                {values.tourism_rooms?.map((i, index) => (
                  <div
                    className={classes.roomSettings}
                    // @ts-ignore
                    key={i + index}
                  >
                    <People index={index} />
                  </div>
                ))}
              </div>
            </>
          )}
        </FieldArray>
      </fieldset>
    </div>
  );
};

export default DomesticTourismFormRoomFieldsMobile;
