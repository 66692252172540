import { useEffect, useState } from "react";

import parseOrderDetailResult from "web/utils/page/product/universal/parseOrderDetailResult";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";

import { IOrder, IOrderDetails, IParsedOrder } from "web/types/Order";
import { MBProductType } from "web/types/Product";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";

const useOrderData = (orderId: string) => {
  const [order, setOrder] =
    useState<Nullable<IParsedOrder<MBProductType>>>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderDetail = (await request(
          restUrls.successOrderDetails.replace(":orderId", orderId)
        )) as IOrderDetails<MBProductType> | IOrder<MBProductType>;
        setOrder(
          parseOrderDetailResult(orderDetail) as IParsedOrder<MBProductType>
        );
      } catch (error) {
        newRelicErrorReport(
          error,
          "web/Pages/Checkout/Pending/DomesticTourism/checkoutPendingDomesticTourism.tsx - 51"
        );
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { order, isLoading };
};

export default useOrderData;
