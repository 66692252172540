import { Button } from "@benefit-systems/common-components";
import { number, object } from "prop-types";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import ErrorBoundary from "web/Layout/ErrorBoundary/errorBoundary";
import HeaderSearch from "web/Layout/HeaderSearch";
import __ from "web/Layout/Translations";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import Aside from "./Aside";
import Content from "./Content";
import Gallery from "./Gallery";
import Legals from "./Legals";
import classes from "./productPrepaidCard.scss";

const ProductPrepaidCard = ({ prepaidCard, bankTotalPoints }) => {
  const formRef = useRef(null);
  const history = useHistory();

  const handleSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  useEffect(() => {
    if (prepaidCard && !prepaidCard.available) {
      history.push(urls.customerPrepaidCards);
    }
  }, [prepaidCard]);

  const { isMobile } = useAppContext();

  const asideProps = {
    bankTotalPoints,
    type: prepaidCard.type,
    className: classes.aside,
    id: prepaidCard.id,
    issuanceFee: prepaidCard.issuanceFee,
    issuanceFeePayer: prepaidCard.issuanceFeePayer,
    rechargeCommission: prepaidCard.rechargeCommission,
    rechargeCommissionPayer: prepaidCard.rechargeCommissionPayer,
    cardNumber: prepaidCard.cardNumber,
    chargeDeadlineDate: prepaidCard.chargeDeadlineDate,
  };

  return (
    <>
      {isMobile && <HeaderSearch back />}
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.main}>
            <ErrorBoundary>
              <Gallery image={prepaidCard.imageUrl || ""} />
              {isMobile && <Aside {...asideProps} formRef={formRef} />}
            </ErrorBoundary>
            <ErrorBoundary>
              <Content product={prepaidCard} />
              <Legals product={prepaidCard} />
              {isMobile && (
                <div className={classes.buttonContainer}>
                  <Button
                    variant="tertiary"
                    type="submit"
                    onClick={handleSubmitForm}
                  >
                    {__("Przejdź dalej")}
                  </Button>
                </div>
              )}
            </ErrorBoundary>
          </div>
          <ErrorBoundary>
            {!isMobile && <Aside {...asideProps} />}
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

ProductPrepaidCard.propTypes = {
  prepaidCard: object.isRequired,
  bankTotalPoints: number.isRequired,
};

export default ProductPrepaidCard;
