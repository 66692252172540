import Button from "web/Pages/PageGenerator/Button";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import { FC } from "react";
import { PropsWithClasses } from "web/types/Common";

interface IPageGeneratorTwoProductsContentProps {
  isMobile: boolean;
  imageSrc: string;
  header: string;
  description: string;
  button: string;
  url: string;
  layout: string;
  headerFontColor: string;
  buttonFontColor: string;
  buttonBackgroundColor: string;
  descriptionFontColor: string;
}

const PageGeneratorTwoProductsContent: FC<PropsWithClasses<IPageGeneratorTwoProductsContentProps>> = ({
  classes = {},
  isMobile = false,
  imageSrc = "",
  header = "",
  description = "",
  button = "",
  url = "",
  layout = "",
  headerFontColor = "",
  buttonFontColor = "",
  buttonBackgroundColor = "",
  descriptionFontColor = "",
}) => {
  const contentClassName =
    layout === "right_hand" ? classes.content : classes.contentReverse;
  const headerStyles = { color: headerFontColor || undefined };
  const descriptionStyles = { color: descriptionFontColor || undefined };

  return header || description || (button && url) ? (
    <div className={contentClassName}>
      <div className={classes.wrapper}>
        {header && (
          <h2 className={classes.header} style={headerStyles}>
            {header}
          </h2>
        )}
        {description && (
          <p className={classes.description} style={descriptionStyles}>
            {description}
          </p>
        )}
        {!isMobile && button && url && (
          <Button
            className={classes.button}
            to={url}
            buttonFontColor={buttonFontColor}
            buttonBackgroundColor={buttonBackgroundColor}
          >
            {button}
          </Button>
        )}
      </div>
      {!isMobile && imageSrc && (
        <LazyLoadImage
          className={classes.image}
          src={imageSrc}
          alt={header}
        />
      )}
    </div>
  ) : null;
};

export default PageGeneratorTwoProductsContent;
