import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import __ from "web/Layout/Translations";

import Breadcrumbs from "web/Components/Common/Breadcrumbs";

import useGetAvailableUserBenefits from "web/hooks/useGetAvailableUserBenefits";

import prepareSelectionWindowData from "web/utils/page/customer/prepareSelectionWindowData";
import getBenefitKeys from "web/utils/system/essentials/getBenefitKeys";

import urls from "web/constants/urls";

import { ISubscriptionItemMs } from "web/types/Subscription";

import { useAppContext } from "web/context/app";
import { useGetEmployeeConfigurationQuery } from "web/features/employee/employeeApiSlice";
import { getFilteredBenefitGroups } from "web/features/subscriptions/benefitGroupsThunks";
import useDataCachedIdsFacetsStats from "web/features/useDataCached/useDataCachedIdsFacetsStats";
import useDataCachedProductsInList from "web/features/useDataCached/useDataCachedProductsInList";

import SubscriptionBox from "./subscriptionsBox";
import styles from "./subscriptionsGroups.scss";

const SubscriptionsGroups = () => {
  const { isMobile } = useAppContext();
  const { subscriptions_category: subscriptionsCategory } = useSelector(
    (state) => state.app.storeConfig
  );
  const { config } = useSelector((state) => state.subscriptionQuote);
  const dispatch = useDispatch();

  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const {
    subscriptionThresholdDayUMS,
    subscriptionThresholdDay,
    showSubscriptionsInListing,
    showSubscriptionsInListingUMS,
  } = employeeConfig || {};

  const subscriptionEnable = {
    subscriptionThresholdDayUMS,
    subscriptionThresholdDay,
  };

  const options = useMemo(() => {
    return {
      variables: {
        categoryId: [+subscriptionsCategory],
        facet: false,
      },
      context: {
        clientName: "ms",
      },
      fetchPolicy: "cache-and-network",
    };
  }, [subscriptionsCategory]);
  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useDataCachedIdsFacetsStats({
    options: JSON.stringify(options),
  });

  const subscriptionItems = categoryData?.subscription_items ?? [];

  useEffect(() => {
    if (categoryData?.subscription_items) {
      const subscriptionsSku =
        (categoryData?.subscription_items as ISubscriptionItemMs[])?.map(
          (el) => el.sku
        ) || [];
      dispatch(getFilteredBenefitGroups({ skuArray: subscriptionsSku }));
    }
  }, [categoryData]);

  const dataFiltered = useSelector((state) => state.benefitGroups);

  const isLoadingFilteredSub = dataFiltered?.isLoading;

  const filteredSubSkuArray = getBenefitKeys(dataFiltered ?? null);

  const validSubscriptionIds = subscriptionItems?.reduce(
    (res: number[], curr: ISubscriptionItemMs) => {
      if (filteredSubSkuArray.includes(curr.sku)) {
        res.push(+curr.id);
      }
      return res;
    },
    []
  );

  const { loading, error, data } = useDataCachedProductsInList({
    ids: validSubscriptionIds,
  });

  const benefits = useGetAvailableUserBenefits(data);
  const selectionWindowData = prepareSelectionWindowData(
    { ...config, showSubscriptionsInListing, showSubscriptionsInListingUMS },
    subscriptionEnable
  );

  const breadcrumbsDefault = [
    {
      label: __("Strona główna"),
      url: urls.home,
    },
    {
      label: __("Abonamenty"),
      url: urls.subscriptions,
    },
  ];

  return (
    <div className={isMobile ? styles.rootMobile : styles.root}>
      {!isMobile && (
        <div className={`container ${styles.breadcrumbs}`}>
          <Breadcrumbs breadcrumbs={breadcrumbsDefault} />
        </div>
      )}
      <h1 className={styles.header}>{__("Abonamenty")}</h1>
      <div className={isMobile ? styles.groupsBoxMobile : styles.groupsBox}>
        {selectionWindowData &&
          selectionWindowData.map((windowData, index) => {
            return windowData.isGroupAvailable && windowData.showOnListing ? (
              <SubscriptionBox
                data={windowData}
                listingData={benefits[index]}
                isLoading={categoryLoading || loading || isLoadingFilteredSub}
                hasError={!!(categoryError || error)}
              />
            ) : null;
          })}
      </div>
    </div>
  );
};

export default SubscriptionsGroups;
