import { FC, PropsWithChildren } from "react";

import type { PropsWithClasses } from "web/types/Common";

import { useAppContext } from "web/context/app";

import DetailsDesktop from "./Desktop";
import DetailsMobile from "./Mobile";

interface ICheckoutProductDetailsProps {
  categoryId: string;
  name: string;
  image: string;
  additionalInfo: {
    attribute: string;
    value: string;
  }[];
  link: string;
  dataT1?: string;
  variant?: string;
}

const CheckoutProductDetails: FC<
  PropsWithChildren<PropsWithClasses<ICheckoutProductDetailsProps>>
> = ({
  categoryId,
  name,
  image,
  additionalInfo = [],
  classes,
  link,
  children,
  dataT1 = "details",
  variant,
}) => {
  const { isMobile } = useAppContext();
  return isMobile ? (
    <DetailsMobile
      name={name}
      image={image}
      additionalInfo={additionalInfo}
      classes={classes}
      link={link}
      dataT1={dataT1}
      variant={variant}
    >
      {children}
    </DetailsMobile>
  ) : (
    <DetailsDesktop
      categoryId={categoryId}
      name={name}
      image={image}
      additionalInfo={additionalInfo}
      classes={classes}
      link={link}
      dataT1={dataT1}
      variant={variant}
    >
      {children}
    </DetailsDesktop>
  );
};

export default CheckoutProductDetails;
