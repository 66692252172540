import { useParams } from "react-router-dom";

import Loading from "web/Layout/Common/Loading";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";
import { useGetPrepaidCardDetailsQuery } from "web/features/prepaidCards/prepaidCardsApiSlice";

import ProductPrepaidCard from "./productPrepaidCard";

const Container = () => {
  const { cardId } = useParams();

  const { data: prepaidCardDetails, isLoading } =
    useGetPrepaidCardDetailsQuery(cardId);

  const { data: dataBanks, isLoading: isLoadingBanks } = useGetBanksQuery();
  const { itemsActive: banks } = dataBanks || {};

  if (isLoading || isLoadingBanks) {
    return <Loading />;
  }

  const banksIds = prepaidCardDetails?.financingSources;
  const activeBanks = banks?.filter(({ points_bank_id }) =>
    banksIds?.includes(points_bank_id)
  );

  const bankTotalPoints = activeBanks
    ?.map((item) => Number(item.balance))
    .reduce((prev, next) => prev + next, 0);

  return (
    <ProductPrepaidCard
      prepaidCard={prepaidCardDetails}
      bankTotalPoints={bankTotalPoints}
    />
  );
};
export default Container;
