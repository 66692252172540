// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileAccordion-label-L9B{font-size:12px;line-height:16px;padding:4px 0}.mobileAccordion-button-hKb{background-color:rgba(0,0,0,0);display:flex;justify-content:space-between;border:0;font-weight:700;text-align:left;line-height:25px;width:100%;padding:8px 15px;font-size:12px}.mobileAccordion-content-JAo{padding:0 15px 10px 15px;font-size:12px}.mobileAccordion-additionalButton-gyV{font-size:12px;background-color:rgba(0,0,0,0);border:0;text-decoration:underline;font-weight:700;padding:10px 0 5px 15px}`, "",{"version":3,"sources":["webpack://./web/Pages/Product/Shared/Legals/mobileAccordion.scss"],"names":[],"mappings":"AAIuB,2BACrB,cAAA,CACA,gBAAA,CACA,aAAA,CAGF,4BACE,8BAAA,CACA,YAAA,CACA,6BAAA,CACA,QAAA,CACA,eAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CACA,gBAAA,CACA,cAAA,CAGF,6BACE,wBAAA,CACA,cAAA,CAGF,sCACE,cAAA,CACA,8BAAA,CACA,QAAA,CACA,yBAAA,CACA,eAAA,CACA,uBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .label {\n  font-size: 12px;\n  line-height: 16px;\n  padding: 4px 0;\n}\n\n.button {\n  background-color: transparent;\n  display: flex;\n  justify-content: space-between;\n  border: 0;\n  font-weight: 700;\n  text-align: left;\n  line-height: 25px;\n  width: 100%;\n  padding: 8px 15px;\n  font-size: 12px;\n}\n\n.content {\n  padding: 0 15px 10px 15px;\n  font-size: 12px;\n}\n\n.additionalButton {\n  font-size: 12px;\n  background-color: transparent;\n  border: 0;\n  text-decoration: underline;\n  font-weight: 700;\n  padding: 10px 0 5px 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `mobileAccordion-label-L9B`,
	"button": `mobileAccordion-button-hKb`,
	"content": `mobileAccordion-content-JAo`,
	"additionalButton": `mobileAccordion-additionalButton-gyV`
};
export default ___CSS_LOADER_EXPORT___;
