import { FC } from "react";
import { SkeletonProductCard } from "web/Layout/Common/SkeletonComponents";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classes from "./skeletonLoader.scss";

interface ISkeletonLoaderProps {
  ids: number[];
  height?: number | string;
}

const SkeletonLoader: FC<ISkeletonLoaderProps> = ({ ids, height = "auto" }) => {
  return isArrayHasItems(ids) ? (
    <div
      className={classes.root}
      style={{
        height,
      }}
    >
      {ids.slice(0, 3).map(() => {
        return <SkeletonProductCard isItemWithFixedWidth />;
      })}
    </div>
  ) : null;
};

export default SkeletonLoader;
