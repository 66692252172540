import { FC } from "react";

import __ from "web/Layout/Translations";

import DomesticTourismForm from "../DomesticTourismForm";
import classes from "./modalContent.scss";

interface IProductEditAccomodationModalContentMobileProps {
  variant: "offer" | "listing" | "product";
}

const ProductEditAccomodationModalContentMobile: FC<
  IProductEditAccomodationModalContentMobileProps
> = ({ variant }) => {
  return (
    <div className={classes.root}>
      <header className={classes.header}>{__("Edycja danych noclegu")}</header>
      <DomesticTourismForm variant={variant} />
    </div>
  );
};

export default ProductEditAccomodationModalContentMobile;
