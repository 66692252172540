import { createSlice } from "@reduxjs/toolkit";

import { IFinancingSourcesState } from "web/types/FinancingSources";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import {
  separatePointsPerProduct,
  separatePointsPerProductInternationalTourism,
  separatePointsPerProductDomesticTourism,
  separatePointsPerProductBuyNow,
} from "./financingSourcesThunks";

const initialState: IFinancingSourcesState = {
  items: [],
  manuallySeparatedSources: [],
  summary: [],
  error: null,
  warning: null,
  isLoading: false,
};

export const financingSourcesSlice = createSlice({
  name: "financingSources",
  initialState,
  reducers: {
    reset: () => initialState,
    warning: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        warning: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(separatePointsPerProduct.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(separatePointsPerProduct.fulfilled, (state, action) => {
      if (!action.payload) {
        return state;
      }

      const { items, manuallySeparatedSources, summary } = action.payload as IFinancingSourcesState;

      return {
        ...state,
        items,
        manuallySeparatedSources,
        summary,
        error: initialState.error,
        warning: initialState.warning,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProduct.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "tourismQuoteDomesticSlice - separatePointsPerProduct");
      return {
        ...state,
        error,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProductInternationalTourism.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(separatePointsPerProductInternationalTourism.fulfilled, (state, action) => {
      if (!action.payload) {
        return state;
      }

      const { items, summary } = action.payload;

      return {
        ...state,
        items,
        manuallySeparatedSources: initialState.manuallySeparatedSources,
        summary,
        error: initialState.error,
        warning: initialState.warning,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProductInternationalTourism.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "financingSourcesSlice - separatePointsPerProductInternationalTourism");
      return {
        ...state,
        error,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProductDomesticTourism.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(separatePointsPerProductDomesticTourism.fulfilled, (state, action) => {
      if (!action.payload) {
        return state;
      }

      const { items, summary } = action.payload;

      return {
        ...state,
        items,
        manuallySeparatedSources: initialState.manuallySeparatedSources,
        summary,
        error: initialState.error,
        warning: initialState.warning,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProductDomesticTourism.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "financingSourcesSlice - separatePointsPerProductDomesticTourism");
      return {
        ...state,
        error,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProductBuyNow.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(separatePointsPerProductBuyNow.fulfilled, (state, action) => {
      if (!action.payload) {
        return state;
      }

      const { items, manuallySeparatedSources, summary } = action.payload;

      return {
        ...state,
        items,
        manuallySeparatedSources,
        summary,
        error: initialState.error,
        warning: initialState.warning,
        isLoading: false,
      } as IFinancingSourcesState;
    });

    builder.addCase(separatePointsPerProductBuyNow.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "financingSourcesSlice - separatePointsPerProductBuyNow");
      return {
        ...state,
        error,
        isLoading: false,
      } as IFinancingSourcesState;
    });
  },
});

export const {
  reset,
  warning,
} = financingSourcesSlice.actions;

export default financingSourcesSlice.reducer;
