import { Link, useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import removeHashFromString from "web/utils/data/parser/string/removeHashFromString";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { hash } from "web/constants/urls";

import classify from "web/classify";

import { FC } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultClasses from "./tabs.scss";

interface ITabsProps {
  items: {
    name: string,
    link: {
      hash: string,
    },
  }[],
}

const Tabs: FC<PropsWithClasses<ITabsProps>> = ({ classes = {}, items = [] }) => {
  const { hash: hashLocation } = useLocation();
  const hashProcessed = removeHashFromString(hashLocation);
  return isArrayHasItems(items) ? (
    <div className={classes.root}>
      <ul className={classes.list} data-t1="tabs_list">
        {items.map(({ name, link }) => {
          const className =
            hashProcessed === link.hash ? classes.itemActive : classes.item;

          return (
            <li
              key={`${name}`}
              className={className}
              data-t2={hash}
              data-t1="tabs_item"
            >
              <Link
                className={classes.link}
                to={link}
                title={name}
                data-t1="tabs_item_name"
              >
                {__(name)}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default classify<PropsWithClasses<ITabsProps>>(defaultClasses)(Tabs);
