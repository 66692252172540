// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentMobile-content-EMu{padding:40px 20px 0}.contentMobile-contentReverse-IzI{}.contentMobile-wrapper-vsX{position:relative}.contentMobile-header-Ile{font-size:22px;font-weight:700;margin:0 0 20px}.contentMobile-description-gP4{display:block;font-size:12px;margin:0 0 30px}`, "",{"version":3,"sources":["webpack://./web/Pages/PageGenerator/TwoProducts/Content/contentMobile.scss"],"names":[],"mappings":"AAIuB,2BACrB,mBAAA,CAGF,kCACE,CAGF,2BACE,iBAAA,CAGF,0BACE,cAAA,CACA,eAAA,CACA,eAAA,CAGF,+BACE,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .content {\n  padding: 40px 20px 0;\n}\n\n.contentReverse {\n  composes: content;\n}\n\n.wrapper {\n  position: relative;\n}\n\n.header {\n  font-size: 22px;\n  font-weight: 700;\n  margin: 0 0 20px;\n}\n\n.description {\n  display: block;\n  font-size: 12px;\n  margin: 0 0 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `contentMobile-content-EMu`,
	"contentReverse": `contentMobile-contentReverse-IzI contentMobile-content-EMu`,
	"wrapper": `contentMobile-wrapper-vsX`,
	"header": `contentMobile-header-Ile`,
	"description": `contentMobile-description-gP4`
};
export default ___CSS_LOADER_EXPORT___;
