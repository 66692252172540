import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";

import { IApiRequestOpts } from "./apiRequestTypes";

const simpleApiRequest = async (url: string, options: IApiRequestOpts) => {
  const storage = new BrowserPersistence();
  const token = storage.getItem(storageNames.tokenAccess);
  const language =
    window.localStorage.getItem(storageNames.language) ||
    storage.getItem(storageNames.language);

  const response = await window.fetch(url, {
    ...options,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-language": `${language}`,
      ...options.headers,
    },
  });

  return response;
};

export default simpleApiRequest;
