/* eslint-disable @typescript-eslint/ban-ts-comment, react/no-array-index-key */
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { useRef } from "react";

import PlusMinusInput from "web/Pages/Product/Mobile/EditAccommodationData/DomesticTourismForm/PlusMinusInput";

import __ from "web/Layout/Translations";

import type { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";
import type { IAnixeFiltersRoom } from "web/features/anixeFilters/anixeFiltersSlice";

import People from "../People";
import classes from "../room.scss";
import useScrollToSecondRoom from "./utils/useScrollToSecondRoom";

const ProductRoomFieldsMobile = () => {
  const { initialValues, values } = useFormikContext<{
    tourism_rooms: IAnixeFiltersRoom[];
  }>();

  const { modal } = useAppContext();

  const { modalRef } = modal || {};

  const peopleWrapperRef = useRef<Nullable<HTMLDivElement>>(null);

  useScrollToSecondRoom({
    peopleWrapperElement: peopleWrapperRef.current,
    modalElement: modalRef?.current,
    tourismRoomsLength: values.tourism_rooms?.length,
  });

  const changeRoomsValue = (
    value: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (value > values.tourism_rooms.length) {
      arrayHelpers.insert(value, initialValues.tourism_rooms[0]);
    }
    if (value < values.tourism_rooms.length) {
      arrayHelpers.pop();
    }
  };

  return (
    <div className={classes.root}>
      <fieldset className={classes.fieldset}>
        <FieldArray name="tourism_rooms">
          {(arrayHelpers) => (
            <>
              <div className={classes.roomCount}>
                <span className={classes.label}>{__("Liczba pokoi")}</span>
                <PlusMinusInput
                  currentValue={values.tourism_rooms.length}
                  onChange={(value) => {
                    changeRoomsValue(value, arrayHelpers);
                  }}
                  classes={{ buttonVariant: classes.plusMinusButton }}
                  maxValue={8}
                />
              </div>
              <div ref={peopleWrapperRef} className={classes.scrollbar}>
                {values.tourism_rooms?.map((i, index) => (
                  // @ts-ignore
                  <div className={classes.roomSettings} key={i + index}>
                    <People index={index} />
                  </div>
                ))}
              </div>
            </>
          )}
        </FieldArray>
      </fieldset>
    </div>
  );
};

export default ProductRoomFieldsMobile;
