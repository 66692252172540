/* eslint-disable no-continue */
import { IGtmEcommerceImpressionPromotions } from "web/types/Gtm";
import sendGtmEvent from "web/utils/system/GTM/sendGtmEvent";

export const createGtmImpressionProps = (gtmOptions: IGtmEcommerceImpressionPromotions[]) => {
  const options = {
    ecommerce: {
      promoView: {
        promotions: gtmOptions,
      },
    },
  };
  sendGtmEvent("promotionImpression", options);
};

export const createGtmClickProps = (gtmOptions: IGtmEcommerceImpressionPromotions[]) => {
  const options = {
    ecommerce: {
      promoClick: {
        promotions: gtmOptions,
      },
    },
  };
  sendGtmEvent("promotionClick", options);
};

export const modifyImage = (src = "") => {
  const splittedImage = src.split("/");
  const splittedSubString = splittedImage[splittedImage.length - 1].split(".")[0];
  return cutTimestampFromImage(splittedSubString);
};

const cutTimestampFromImage = (img = "") => {
  const lastIndex = img.length - 1;
  let numbersCount = 0;
  let finalImg = img;
  for (let i = lastIndex; i >= 0; i -= 1) {
    if (!Number.isNaN(+img[i])) {
      numbersCount += 1;
      continue;
    }

    if (numbersCount >= 15) {
      finalImg = img.slice(0, i + 2);
    }
    break;
  }

  return finalImg;
};
