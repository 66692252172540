import { Button } from "@benefit-systems/common-components";
import { Field, Form, Formik } from "formik";
import { FC } from "react";

import { IArticleOpinion } from "web/Pages/HelpCenterArticle/types";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import { Error } from "web/api/apiRequestTypes";

import { useAppContext } from "web/context/app";

import classes from "./negativeOpinionForm.scss";

type IHelpCenterArticleNegativeOpinionForm = {
  onReset?: () => void;
  onPostOpinion: (opinion: IArticleOpinion) => void;
  error?: Error;
  isLoading: boolean;
};

type HandleSubmitDataArgs = {
  comment: string;
  customComment?: string;
};

const INITIAL_VALUES = {
  comment: "",
  customComment: "",
};

const OTHER_REASON = "Inny powód";

const ANSWERS = [
  {
    label: "Jest niepełna",
    value: "Jest niepełna",
  },
  {
    label: "Jest myląca",
    value: "Jest myląca",
  },
  {
    label: "Nie dotyczy informacji, których szukam",
    value: "Nie dotyczy informacji, których szukam",
  },
  {
    label: OTHER_REASON,
    value: OTHER_REASON,
  },
];
const COMMENT_FIELD = Object.keys(INITIAL_VALUES)[0];
const CUSTOM_COMMENT_FIELD = Object.keys(INITIAL_VALUES)[1];

const HelpCenterArticleNegativeOpinionForm: FC<
  IHelpCenterArticleNegativeOpinionForm
> = ({ onReset, onPostOpinion, error, isLoading }) => {
  const { isMobile } = useAppContext();

  const handleSubmit = ({ comment, customComment }: HandleSubmitDataArgs) => {
    if (!comment) return;

    const processedComment = comment === OTHER_REASON ? customComment : comment;

    onPostOpinion({ opinion: 0, comment: processedComment });
  };

  const content = (() => {
    switch (true) {
      case !!error: {
        console.error(error);
        if (error && error.responseMessage) {
          return <p className={classes.errorMsg}>{error.responseMessage}</p>;
        }
        return <ErrorComponent />;
      }
      case isLoading: {
        return <Loading />;
      }
      default: {
        return (
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={handleSubmit}
            onReset={onReset}
          >
            {({ values }) => (
              <Form className={classes.form}>
                <div className={classes.fieldsWrapper}>
                  {ANSWERS.map(({ value, label }) => {
                    const id = `${value}_${COMMENT_FIELD}`;
                    return (
                      <label className={classes.label} htmlFor={id}>
                        <Field
                          className={classes.radio}
                          type="radio"
                          name={COMMENT_FIELD}
                          id={id}
                          value={value}
                          dataT1="select_item_radio"
                        />
                        <span className={classes.checkmark} />
                        <span className={classes.labelText}>{__(label)}</span>
                      </label>
                    );
                  })}
                </div>
                {values.comment === OTHER_REASON && (
                  <Field
                    as="textarea"
                    placeholder={`${__("Opis")}...`}
                    className={classes.customCommentField}
                    name={CUSTOM_COMMENT_FIELD}
                  />
                )}
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.confirmBtn}
                    type="submit"
                    variant={isMobile ? "tertiary" : undefined}
                  >
                    {__("Wyślij")}
                  </Button>
                  <Button
                    className={classes.cancelBtn}
                    type="reset"
                    variant={isMobile ? "quaternary" : "secondary"}
                  >
                    {__("Anuluj")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        );
      }
    }
  })();

  return (
    <div className={classes.root}>
      <p className={classes.description}>
        {__(
          "Chcemy być lepsi dla Ciebie. Jeśli nie znalazłeś odpowiedzi na swoje pytanie w tym artykule, powiedz nam o tym."
        )}
      </p>
      <div className={classes.title}>
        {__("Co było nie tak z odpowiedzią?")}
      </div>
      {content}
    </div>
  );
};

export default HelpCenterArticleNegativeOpinionForm;
