/* eslint-disable lines-between-class-members */
interface IResponseError {
  resourceUrl: string;
  method: string;
  response: any;
  bodyText: string;
}

interface IErrorInfo {
  errors: {
    extensions: {
      category: string;
    };
    message: string;
  }[];
}

export default class ResponseError extends Error {
  public method: string;
  public resourceUrl: string;
  public response: any;
  public responseMessage?: string;

  constructor(
    { method, resourceUrl, response, bodyText }: IResponseError,
    ...args: any[]
  ) {
    let body = ``;
    let responseMessage;
    let isGraphQLError = false;

    try {
      const { message, trace, errorMessage, ...rest } = JSON.parse(bodyText);

      const messageProcessed = message || (rest && rest.detail) || errorMessage;
      if (messageProcessed) {
        body += `Message:\n\n  ${messageProcessed}\n`;
        responseMessage = messageProcessed;
      }

      if (args[0]?.tokenInfo) {
        body += `${args[0].tokenInfo}\n`;
      }

      const addl = Object.entries(rest);
      if (addl.length > 0) {
        body += `\nAdditional info:\n\n${JSON.stringify(rest, null, 4)}\n\n`;
      }
      if (trace) {
        body += `Stack trace: \n\n${trace}`;
      }

      body += "\n";

      // transform graphql errors
      if (isGraphQLErrors(rest)) {
        isGraphQLError = true;
        body = transformGraphqlErrorBody({
          rest,
          url: resourceUrl,
          method,
          status: response.status,
        });
      }
    } catch (e) {
      body = bodyText;
    }

    super(
      isGraphQLError
        ? body
        : `${method} ${resourceUrl} responded ${response.status} ${response.statusText}: \n\n${body}`,
      ...args
    );

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    }

    this.response = response;
    this.method = method;
    this.resourceUrl = resourceUrl;

    if (responseMessage) {
      this.responseMessage = responseMessage;
    }
  }
}

const isGraphQLErrors = (rest: IErrorInfo) => {
  const errors = rest?.errors;

  if (!Array.isArray(rest?.errors)) {
    return false;
  }

  return errors.some((error) => error.extensions?.category);
};

const transformGraphqlErrorBody = ({
  rest,
  url,
  method,
  status,
}: {
  rest: IErrorInfo;
  url: string;
  method: string;
  status: number;
}) => {
  const info = {
    query: url.split("query=")[1]?.substring(0, 30) || "no query",
    errors: rest?.errors
      .map((error, idx) => `${idx}:${error.message}`)
      .join("//"),
    status,
    method,
  };
  return JSON.stringify(info);
};
