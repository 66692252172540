import { FC } from "react";

import HelpCenterMobileSearchSection from "web/Pages/HelpCenter/Mobile/SearchSection";

import MobileHeader from "web/Layout/MobileHeader";
import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import urls from "web/constants/urls";

import { IStoreConfig } from "web/types/StoreConfig";

import HelpCenterCategoryBreadcrumbs from "../Shared/Breadcrumbs";
import { HelpCenterCategoryPageProps } from "../helpCenterCategory";
import HelpCenterCategoryMobileContent from "./Content";

export interface IHelpCenterCategoryMobileProps
  extends HelpCenterCategoryPageProps {
  prefix: IStoreConfig["help_center_prefix"];
}

const HelpCenterCategoryMobile: FC<IHelpCenterCategoryMobileProps> = ({
  name,
  articles,
  prefix,
}) => {
  const { isParamLangEn } = useLangFromParams();
  return (
    <section>
      <MobileHeader replacementPath={urls.helpCenter}>
        {isParamLangEn ? "Help Center" : __("Centrum pomocy")}
      </MobileHeader>
      <HelpCenterCategoryBreadcrumbs name={name} />
      <HelpCenterMobileSearchSection />
      <HelpCenterCategoryMobileContent
        name={name}
        articles={articles}
        prefix={prefix}
      />
    </section>
  );
};

export default HelpCenterCategoryMobile;
