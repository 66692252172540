import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import restUrls from "web/constants/restUrls";

import { Nullable } from "web/types/Utils";

import {
  setDocument,
  setRegulation,
} from "web/features/customerMenu/customerMenuSlice";

import api from "../api/apiSlice";

interface ICompanyAttachmentFile {
  language: "en" | "pl";
  url: string;
}
export interface ICompanyAttachment {
  name: string;
  description: string;
  type: string;
  files: ICompanyAttachmentFile[];
}

interface ICompanyState {
  attachments: ICompanyAttachment[];
  regulationsAttachments: ICompanyAttachment[];
  formAttachments: ICompanyAttachment[];
  otherAttachments: ICompanyAttachment[];
  city: Nullable<string>;
  fax: Nullable<string>;
  fullName: Nullable<string>;
  id: Nullable<string>;
  phone: Nullable<string>;
  postcode: string;
  senderEmail: Nullable<string>;
  shortName: Nullable<string>;
  street: Nullable<string>;
  taxNumber: Nullable<string>;
  isLoading: boolean;
  displayMessageOneTimeLossPoints: boolean;
  languages?: string[];
  enableCompanyEvents?: boolean;
  enableProposals?: boolean;
  enableTotalRewardStatement?: boolean;
}

export const companyApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyDetails: builder.query<ICompanyState, void>({
      query: () => restUrls.companyDetails,
      providesTags: ["Company"],
      transformResponse: (response: ICompanyState) => {
        const initialAttachments = {
          regulationsAttachments: [],
          formAttachments: [],
          otherAttachments: [],
        };
        const { regulationsAttachments, formAttachments, otherAttachments } =
          response && isArrayHasItems(response.attachments)
            ? response.attachments.reduce(
                (
                  result: {
                    regulationsAttachments: ICompanyAttachment[];
                    formAttachments: ICompanyAttachment[];
                    otherAttachments: ICompanyAttachment[];
                  },
                  current: ICompanyAttachment
                ) => {
                  const type =
                    current &&
                    isArrayHasItems(current.files) &&
                    typeof current.type === "string"
                      ? current.type.toLowerCase()
                      : "";
                  switch (type) {
                    case "form": {
                      return {
                        ...result,
                        formAttachments: [...result.formAttachments, current],
                      };
                    }
                    case "regulations": {
                      return {
                        ...result,
                        regulationsAttachments: [
                          ...result.regulationsAttachments,
                          current,
                        ],
                      };
                    }
                    case "other": {
                      return {
                        ...result,
                        otherAttachments: [...result.otherAttachments, current],
                      };
                    }
                    default: {
                      return result;
                    }
                  }
                },
                initialAttachments
              )
            : initialAttachments;

        return {
          ...response,
          regulationsAttachments,
          formAttachments,
          otherAttachments,
        };
      },
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const companyResponse = await cacheDataLoaded;
        const { data } = companyResponse || {};
        if (isArrayHasItems(data.otherAttachments)) {
          dispatch(setDocument());
        }
        if (isArrayHasItems(data.regulationsAttachments)) {
          dispatch(setRegulation());
        }
      },
    }),
  }),
});

export const { useGetCompanyDetailsQuery } = companyApiSlice;
