import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";

const storage = new BrowserPersistence();

export const getAccessToken = () => {
  return storage.getItem(storageNames.tokenAccess);
};

export default getAccessToken;
