import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IFinancingSourceItem } from "web/types/FinancingSources";
import { IPaymentSettingsItem, ReducerPaymentMethod } from "web/types/Payment";
import {
  Guest,
  IDomesticTourismHotelRatesOffer,
  Purchaser,
} from "web/types/Tourism";
import { Nullable } from "web/types/Utils";

import {
  getDetailsById,
  placeOrder,
  setMinPrice,
  setPaymentMethods,
} from "./tourismQuoteDomesticThunks";

type TourismDomesticMethod =
  | {
      value: "percentage";
      label: "Płatność";
    }
  | {
      value: "advance";
      label: "Płatność z góry";
    };

export interface ITourismDomesticState {
  quote: Nullable<ITourismDomesticQuote>;
  percentage: number;
  total: number;
  totalDifference: number;
  amount: number;
  amountMinimal: number;
  isDetailsGotten: boolean;
  isLoading: boolean;
  error: unknown;
  paymentMethod: Nullable<string>;
  paymentMethods: Nullable<ReducerPaymentMethod[]>;
  financingSources: Nullable<IFinancingSourceItem[]>;
  guests: Nullable<Guest[]>;
  purchaser: Nullable<Purchaser>;
  howToPayMethodName: string;
  howToPayMethod: TourismDomesticMethod["value"];
  howToPayMethods: TourismDomesticMethod[];
  purchaserOnlyGuests: Nullable<Guest[]>;
}

export interface ITourismDomesticQuote {
  id: string;
  sku: string;
  values: ITourismDomesticQuoteValues;
  offer: IDomesticTourismHotelRatesOffer;
  paymentSettings: IPaymentSettingsItem[];
}

export interface ITourismDomesticQuoteValues {
  rooms: ITourismDomesticQuoteRoom[];
  date_from: string;
  date_to: string;
}

interface ITourismDomesticQuoteRoom {
  adults: number;
  children: {
    age: number;
  }[];
}

const methods: TourismDomesticMethod[] = [
  {
    value: "percentage",
    label: "Płatność",
  },
  {
    value: "advance",
    label: "Płatność z góry",
  },
];

const initialState: ITourismDomesticState = {
  quote: null,
  percentage: 0,
  total: 0,
  totalDifference: 0,
  amount: 0,
  amountMinimal: 0,
  isDetailsGotten: false,
  isLoading: false,
  error: null,
  paymentMethod: null,
  paymentMethods: null,
  financingSources: null,
  guests: null,
  purchaser: null,
  howToPayMethodName: "howToPay",
  howToPayMethod: methods[0].value,
  howToPayMethods: methods,
  purchaserOnlyGuests: null,
};

export const tourismQuoteDomesticSlice = createSlice({
  name: "tourismQuoteDomestic",
  initialState,
  reducers: {
    setAmount: (
      state,
      action: PayloadAction<ITourismDomesticState["amount"]>
    ) => {
      const { amountMinimal, total } = state;
      const amount = action.payload;
      const amountProcessed = amount || amountMinimal;
      state.amount = amountProcessed;
      state.totalDifference = total - amountProcessed;
    },
    resetAmount: (state) => {
      const id = state.quote?.id;
      if (id) {
        state.amount = state.amountMinimal;
        state.totalDifference = state.total - state.amountMinimal;
      }
    },
    setHowToPayMethod: (
      state,
      action: PayloadAction<{
        id: ITourismDomesticQuote["id"];
        method: TourismDomesticMethod["value"];
      }>
    ) => {
      const { id, method } = action.payload;
      if (id && method) {
        state.howToPayMethod = method;
      }
    },
    setPaymentMethod: (
      state,
      action: PayloadAction<Pick<ITourismDomesticState, "paymentMethod">>
    ) => {
      const { paymentMethod } = action.payload;
      if (paymentMethod) {
        state.paymentMethod = action.payload?.paymentMethod;
      }
    },
    setFinancingSources: (
      state,
      action: PayloadAction<Pick<ITourismDomesticState, "financingSources">>
    ) => {
      state.financingSources = action.payload?.financingSources;
    },
    resetFinancingSources: (state) => {
      const id = state.quote?.id;
      if (id) {
        state.financingSources = initialState.financingSources;
      }
    },
    setGuestsPurchaser: (
      state,
      action: PayloadAction<Pick<ITourismDomesticState, "guests" | "purchaser">>
    ) => {
      const { guests, purchaser } = action.payload || {};
      state.guests = guests;
      state.purchaser = purchaser;
    },

    setPurchaserOnlyGuests: (
      state,
      action: PayloadAction<ITourismDomesticState["purchaserOnlyGuests"]>
    ) => {
      state.purchaserOnlyGuests = action.payload;
    },
    setAnixeError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // get details by id
    builder.addCase(getDetailsById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getDetailsById.fulfilled, (state, action) => {
      const {
        quote,
        paymentMethod,
        paymentMethods,
        financingSources,
        guests,
        purchaser,
        amountMinimal,
        total,
        howToPayMethod,
        amount,
      } = action.payload;

      const amountProcessed = amount || amountMinimal;

      return {
        ...state,
        isDetailsGotten: true,
        isLoading: false,
        quote,
        paymentMethod,
        paymentMethods,
        financingSources,
        guests,
        purchaser,
        amountMinimal,
        total,
        howToPayMethod,
        amount: amountProcessed,
      } as ITourismDomesticState;
    });

    builder.addCase(getDetailsById.rejected, (state, action) => {
      const { error } = action;
      state.error = error;
      state.isLoading = false;
      newRelicErrorReport(error, "tourismQuoteDomesticSlice - getDetailsById");
    });

    // place order
    builder.addCase(placeOrder.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(placeOrder.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(placeOrder.rejected, (state, action) => {
      const { error } = action;
      state.error = error;
      state.isLoading = false;
      newRelicErrorReport(error, "tourismQuoteDomesticSlice - placeOrder");
    });

    // set min price
    builder.addCase(setMinPrice.fulfilled, (state, action) => {
      state.percentage = action.payload.percent;
    });

    builder.addCase(setMinPrice.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(error, "tourismQuoteDomesticSlice - setMinPrice");
    });

    // set payment methods
    builder.addCase(setPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
    });

    builder.addCase(setPaymentMethods.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(
        error,
        "tourismQuoteDomesticSlice - setPaymentMethods"
      );
    });
  },
});

export const {
  reset,
  resetFinancingSources,
  resetAmount,
  setHowToPayMethod,
  setPaymentMethod,
  setFinancingSources,
  setGuestsPurchaser,
  setAmount,
  setPurchaserOnlyGuests,
  setAnixeError,
} = tourismQuoteDomesticSlice.actions;

export default tourismQuoteDomesticSlice.reducer;
