// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-containerBox-PEx>p{padding-top:0 !important;padding-bottom:0 !important;margin-top:0 !important;margin-bottom:0 !important}`, "",{"version":3,"sources":["webpack://./web/Pages/PageGenerator/Html/html.scss"],"names":[],"mappings":"AAKE,yBACE,wBAAA,CACA,2BAAA,CACA,uBAAA,CACA,0BAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .containerBox {\n  > p {\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n    margin-top: 0 !important;\n    margin-bottom: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBox": `html-containerBox-PEx`
};
export default ___CSS_LOADER_EXPORT___;
