// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twoProductsMobile-root-wkP{display:block}.twoProductsMobile-row-vM1,.twoProductsMobile-rowReverse-yms{display:flex;flex-direction:column;justify-content:center;overflow:hidden}.twoProductsMobile-products-B96{display:block;margin:0 0 30px}.twoProductsMobile-footer-fiC{text-align:center;padding:0 40px 40px}.twoProductsMobile-button-WJW{display:inline-block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./web/Pages/PageGenerator/TwoProducts/twoProductsMobile.scss"],"names":[],"mappings":"AAIuB,4BACrB,aAAA,CAGF,6DAEE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,eAAA,CAGF,gCACE,aAAA,CACA,eAAA,CAGF,8BACE,iBAAA,CACA,mBAAA,CAGF,8BACE,oBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n}\n\n.row,\n.rowReverse {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.products {\n  display: block;\n  margin: 0 0 30px;\n}\n\n.footer {\n  text-align: center;\n  padding: 0 40px 40px;\n}\n\n.button {\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `twoProductsMobile-root-wkP`,
	"row": `twoProductsMobile-row-vM1`,
	"rowReverse": `twoProductsMobile-rowReverse-yms`,
	"products": `twoProductsMobile-products-B96`,
	"footer": `twoProductsMobile-footer-fiC`,
	"button": `twoProductsMobile-button-WJW`
};
export default ___CSS_LOADER_EXPORT___;
