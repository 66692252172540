import { FC } from "react";

import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { IStoreConfig } from "web/types/StoreConfig";

import { IHelpCenterArticleWithId } from "../../helpCenterArticle";
import LinksList from "./LinksList";
import classes from "./aside.scss";

export interface IHelpCenterDesktopArticleAsideProps {
  categories: IHelpCenterArticleWithId["categories"];
  prefix: IStoreConfig["help_center_prefix"];
}

const HelpCenterDesktopArticleAside: FC<IHelpCenterDesktopArticleAsideProps> = (
  props
) => {
  const { isParamLangEn } = useLangFromParams();
  return (
    <aside className={classes.root}>
      <div className={classes.sticky}>
        <div className={classes.title}>
          {isParamLangEn ? "Also check" : __("Zobacz również")}
        </div>
        <LinksList {...props} />
      </div>
    </aside>
  );
};

export default HelpCenterDesktopArticleAside;
