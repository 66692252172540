/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";
import { IParsedCodeItem, IParsedOrder } from "web/types/Order";
import { IPaymentMethodItem } from "web/types/Payment";
import { MBProductType } from "web/types/Product";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import isEmpty from "web/utils/data/validator/object/isEmpty";
import { GroupedProduct } from "web/utils/page/product/universal/groupProducts";

import Products from "./products";

interface ISuccessProductsContainerProps {
  items: IParsedOrder<MBProductType>["items"];
}

export interface IProducts extends GroupedProduct {
  qty: number;
  points_bank: PointsBank[];
  product_id: number;
  base_row_total: number;
  base_row_total_incl_tax: number;
  discount_amount: number;
  row_total: number;
  row_total_incl_tax: number;
  payment_method: IPaymentMethodItem[];
  sku?: string;
}

type PointsBank = {
  id: string;
  balance: number;
};

const SuccessProductsContainer: FC<ISuccessProductsContainerProps> = ({ items }) => {
  const itemsGroupedById = useMemo(() => {
    return isArrayHasItems(items)
      // @ts-ignore
      ? items.reduce((result: IParsedOrder<MBProductType>["items"] | IProducts[], current) => {
          const {
            base_row_total: baseRowTotal = 0,
            base_row_total_incl_tax: baseRowTotalInclTax = 0,
            discount_amount: discountAmount,
            row_total: rowTotal,
            row_total_incl_tax: rowTotalInclTax,
            points_bank: pointsBank,
            qty_ordered: qty = 0,
            payment_method: paymentMethod,
            ...restValues
          } = current as IParsedCodeItem;

          const pointsBankParsed = isArrayHasItems(jsonParse(pointsBank))
            ? jsonParse(pointsBank) as PointsBank[]
            : [];
          const itemIndexById = result.findIndex(
            (item) => current.product_id === item.product_id
          );
          /* eslint-disable no-param-reassign */
          if (itemIndexById !== -1) {
            const resultItem = result[itemIndexById] as IProducts;
            // @ts-ignore
            result[itemIndexById] = {
              ...result[itemIndexById],
              base_row_total:
                baseRowTotal + resultItem.base_row_total,
              base_row_total_incl_tax:
                baseRowTotalInclTax +
                resultItem.base_row_total_incl_tax,
              discount_amount:
                discountAmount + resultItem.discount_amount,
              row_total: rowTotal + resultItem.row_total,
              row_total_incl_tax:
                rowTotalInclTax + resultItem.row_total_incl_tax,
              qty: qty + resultItem.qty,
              points_bank: resultItem.points_bank.map((bank) => {
                const bankById = pointsBankParsed.find(
                  ({ id }) => id === bank.id
                );

                return bankById
                  ? {
                      id: bank.id,
                      balance: bank.balance + bankById.balance,
                    }
                  : bank;
              }),
              payment_method: resultItem.payment_method.map(
                (paymentItem, paymentItemIndex) => {
                  // eslint-disable-next-line array-callback-return
                  paymentItem.values.map((paymentMethodType) => {
                    const currentPaymentValue =
                      (paymentMethod as IPaymentMethodItem[])[paymentItemIndex].values;
                    const sameNameValue = currentPaymentValue.find(
                      (currentValue) =>
                        currentValue.name === paymentMethodType.name
                    );
                    if (sameNameValue) {
                      sameNameValue.value =
                      +sameNameValue.value +
                      (!isEmpty(sameNameValue) ? +paymentMethodType.value : 0);

                    }
                  });
                  return paymentMethod;
                }
              )[0],
            };
            return result;
          }

          return [
            ...result,
            {
              base_row_total: baseRowTotal,
              base_row_total_incl_tax: baseRowTotalInclTax,
              discount_amount: discountAmount,
              row_total: rowTotal,
              row_total_incl_tax: rowTotalInclTax,
              points_bank: pointsBankParsed,
              payment_method: paymentMethod,
              ...restValues,
              qty,
            },
          ];
        }, [])
      : [];
  }, [JSON.stringify(items)]);

  return isArrayHasItems(itemsGroupedById) ? (
    <Products items={itemsGroupedById} />
  ) : null;
};

export default SuccessProductsContainer;
