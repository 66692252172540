import { FC } from "react";

import Points from "web/Layout/Common/Points";
import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";
import { IPaymentMethod } from "web/types/Payment";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classes from "./paidByMethods.scss";

interface ISuccessPaidByMethodsProps {
  payments: IPaymentMethod[];
}

const first = "points_payment";
const pointsPaymentFirst = (a: IPaymentMethod, b: IPaymentMethod) => {
  if (a.method === first) return -1;
  if (b.method === first) return 1;
  return 0;
};
const SuccessPaidByMethods: FC<ISuccessPaidByMethodsProps> = ({ payments }) => {
  return isArrayHasItems(payments) ? (
    <div className={classes.root}>
      {payments
        ?.sort(pointsPaymentFirst)
        .map(({ method, title, to_pay: total }) => {
          const isPrice = method !== "points_payment";
          return isPrice ? (
            <div className={classes.row} key={title} data-t1={title}>
              {__("Zapłacono")}
              {": "}
              <Price
                value={total}
                currency={__("zł")}
                className={classes.value}
                dataT1="paid_by_methods_price"
              />{" "}
              {title && (
                <span data-t1="paid_by_methods_title" className={classes.label}>
                  ({__(title)})
                </span>
              )}
            </div>
          ) : (
            <div
              className={classes.row}
              key={title}
              data-t1="paid_by_methods_label"
              data-t2={title}
            >
              {__("Zostało pobranych")}
              {": "}
              <Points
                className={classes.value}
                points={total}
                dataT1="paid_by_methods_points"
              />
            </div>
          );
        })}
    </div>
  ) : null;
};

export default SuccessPaidByMethods;
