import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import { ITourismInternationalValues } from "web/utils/page/product/universal/groupProducts";

export default (data: ITourismInternationalValues, isMobile = false) => {
  if (data && Object.keys(data).length) {
    const consultant = data.consultant_email
      ? [
          {
            name: __("Konsultant biura podróży"),
            value: data.consultant_email,
            dataT1: "consultant_email_label",
          },
        ]
      : [];
    const date =
      data.date_from && data.date_to
        ? [
            {
              name: __("Termin wyjazdu"),
              value: `${formatDate(data.date_from)} - ${formatDate(
                data.date_to
              )}`,
              dataT1: "date",
            },
          ]
        : [];
    const phone = data.phone
      ? [
          {
            name: __("Numer telefonu"),
            value: data.phone,
            dataT1: "phone",
          },
        ]
      : [];
    const email = data.email
      ? [
          {
            name: __("E-mail"),
            value: data.email,
            dataT1: "email",
          },
        ]
      : [];

    return isMobile
      ? [...consultant, ...date]
      : [...consultant, ...date, ...phone, ...email];
  }

  return [];
};
