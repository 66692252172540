// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop-container-oGn{margin-top:40px}.desktop-header-qAy{font-size:44px;margin-bottom:30px}.desktop-wrapper-fnT{display:flex;gap:30px}`, "",{"version":3,"sources":["webpack://./web/Pages/HelpCenterArticle/Desktop/desktop.scss"],"names":[],"mappings":"AAIuB,uBACrB,eAAA,CAEF,oBACE,cAAA,CACA,kBAAA,CAEF,qBACE,YAAA,CACA,QAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .container {\n  margin-top: 40px;\n}\n.header {\n  font-size: 44px;\n  margin-bottom: 30px;\n}\n.wrapper {\n  display: flex;\n  gap: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `desktop-container-oGn`,
	"header": `desktop-header-qAy`,
	"wrapper": `desktop-wrapper-fnT`
};
export default ___CSS_LOADER_EXPORT___;
