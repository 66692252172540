import { useQuery } from "@apollo/client";
import { Button } from "@benefit-systems/common-components";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import { ImageSource } from "web/Layout/Common/Image";
import Loading from "web/Layout/Common/Loading/loading";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import helpCenterCategory from "web/queries/default/helpCenterCategory.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import getSrcMedia from "web/utils/system/url/getSrcMedia";

import {
  IHelpCenterSpecificCategory,
  IHelpCenterSpecificCategoryCategory,
} from "web/types/HelpCenter";

import { useAppContext } from "web/context/app";

import classes from "./faq.scss";

const NUM_OF_ARTICLES = 5;
export const FAQ_PREFIX = "faq";

const FAQ = () => {
  const storeConfig = useSelector(({ app }) => app.storeConfig);
  const { isMobile } = useAppContext();
  const { url: prefix } = useRouteMatch();

  const { languageFromParam, langLinkSuffix, isParamLangEn } =
    useLangFromParams();

  const {
    storeConfig: { login_category_id },
  } = useSelector((state) => state.app);

  const { error, loading, data } = useQuery<{
    helpCenterCategory: IHelpCenterSpecificCategory;
  }>(helpCenterCategory, {
    variables: {
      categoryId: [login_category_id],
      lang: languageFromParam || getLanguageCode(),
    },
  });

  if (error) {
    newRelicErrorReport(error, "web-app/web/Pages/FAQ/faq.tsx - 48");
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  if (!!loading && !data) return <Loading />;

  const category =
    isArrayHasItems(data?.helpCenterCategory?.category) &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data!.helpCenterCategory.category[0];

  if (!category) return null;

  const { articles, name, url_key, image } =
    category as IHelpCenterSpecificCategoryCategory;

  const imageProcessedSrc = getSrcMedia({
    storeConfig,
    src: image,
    isHelpCenter: true,
  });

  return (
    <div>
      <h1 className={classes.title}>
        {isParamLangEn ? "Knowledge base" : __("Baza wiedzy")}
      </h1>
      <h2 className={classes.categoryTitle}>{name}</h2>
      <div className={classes.subcategory}>
        <div className={classes.subcategoryLeftSide}>
          <Link
            to={`${prefix}/${url_key}.html${langLinkSuffix}`}
            className={classes.subcategoryTitle}
          >
            {__("FAQ")}
          </Link>
          <ul className={classes.articlesLinksList}>
            {articles
              .slice(0, NUM_OF_ARTICLES)
              .map(({ id, title, url_key }) => (
                <li key={id}>
                  <Link to={`${prefix}/${url_key}.html${langLinkSuffix}`}>
                    {title}
                  </Link>
                </li>
              ))}
          </ul>
          <Link
            to={`${prefix}/${url_key}.html${langLinkSuffix}`}
            className={classes.moreLink}
          >
            {isParamLangEn
              ? "See all articles"
              : __("Zobacz wszystkie artykuły")}
          </Link>
        </div>
        {imageProcessedSrc && !isMobile && (
          <div className={classes.imageContainer}>
            <ImageSource className={classes.image} src={imageProcessedSrc} />
          </div>
        )}
      </div>
      <Button to="/" variant="secondary">
        {isParamLangEn ? "Go back to login page" : __("Wróc do logowania")}
      </Button>
    </div>
  );
};

export default FAQ;
