import { useAppContext } from "web/context/app";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const ProductRoomFields = () => {
  const { isMobile } = useAppContext();

  return <>{isMobile ? <Mobile /> : <Desktop />}</>;
};

export default ProductRoomFields;
