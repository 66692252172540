import { FC, RefObject, memo } from "react";
import { useSelector } from "react-redux";

import NavigationList from "web/Pages/Checkout/Header/Shared/NavigationList";

import BankResetAnnouncement from "web/Layout/BankResetAnnouncement";
import Logo from "web/Layout/Common/Logo";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { Classes, SimpleFn } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";
import { useGetCompanyDetailsQuery } from "web/features/company/companyApiSlice";

import {
  ArePointsDisplayed,
  IsThankYouPage,
  Link,
  Pathname,
  Search,
} from "../Types/types";
import Points from "./Points";
import defaultClasses from "./header.scss";

interface ICheckoutDesktopHeader {
  links: Link[];
  pathname: Pathname;
  search: Search;
  classes: Classes;
  arePointsDisplayed?: ArePointsDisplayed;
  resetEditing: SimpleFn;
  isThankYouPage: IsThankYouPage;
  logoRef?: RefObject<Nullable<HTMLImageElement>>;
}

const CheckoutDesktopHeader: FC<ICheckoutDesktopHeader> = memo(
  ({
    links,
    pathname,
    search,
    classes,
    arePointsDisplayed,
    resetEditing,
    isThankYouPage,
    logoRef = null,
  }) => {
    const storeConfig = useSelector((state) => state.app.storeConfig);
    const [headerLogoSrc, headerLogoAlt] = storeConfig
      ? [storeConfig.header_logo_src, storeConfig.header_logo_alt]
      : [null, null];

    const { data: company } = useGetCompanyDetailsQuery();
    const shouldShowMessage = company?.displayMessageOneTimeLossPoints || false;

    return isArrayHasItems(links) ? (
      <header className={classes.root} data-testid="checkout-desktop-header">
        <BankResetAnnouncement shouldShowMessage={shouldShowMessage} />
        <div className={classes.header}>
          <div className={`container ${classes.headerRow}`}>
            <div className={classes.logoBox} data-t1="header_logo">
              <Logo
                className={classes.logo}
                onClick={resetEditing}
                logoSrc={headerLogoSrc}
                logoAlt={headerLogoAlt}
                logoRef={logoRef || null!}
              />
            </div>
            {arePointsDisplayed ? <Points /> : null}
          </div>
          <NavigationList
            links={links}
            pathname={pathname}
            search={search}
            classes={classes}
            onClick={resetEditing}
            isThankYouPage={isThankYouPage}
          />
        </div>
      </header>
    ) : null;
  }
);

export default classify<ICheckoutDesktopHeader>(defaultClasses)(
  CheckoutDesktopHeader
);
