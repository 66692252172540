import DOMPurify from "dompurify";
import { FC, useMemo } from "react";
import { useLocation } from "react-router";

import GtmEvent from "web/Layout/Gtm/GtmEvent";
import getPageReferrer from "web/utils/system/GTM/getPageReferrer";

import classes from "./cms.scss";

interface ICmsProps {
  title: string;
  content: string;
  contentTitle: string;
}

const Cms: FC<ICmsProps> = ({ title = "", content = "", contentTitle = "" }) => {
  const location = useLocation();

  const gtmOptions = useMemo(() => (
    { cgroup1: title, page_referrer: getPageReferrer(location), }
  ), [location, title, getPageReferrer]);
  
  return (
    <div className="container">
      <h1 className={classes.title}>{contentTitle || title}</h1>
      {content ? (
        <div
          className={`reset-global-styles ${classes.content}`}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
      ) : null}
      <GtmEvent options={gtmOptions} withUrl />
    </div>
  );
};

export default Cms;
