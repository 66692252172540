import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { ProductInWishlist } from "web/types/Product";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const wishlistProductsAdapter =
  createEntityAdapter<ICachedItem<ProductInWishlist>>();

const wishlistProductsSlice = createSlice({
  name: "wishlistProducts",
  initialState: wishlistProductsAdapter.getInitialState(),
  reducers: {
    addWishlistProductsEntries: wishlistProductsAdapter.addMany,
    removeWishlistProductsEntries: wishlistProductsAdapter.removeMany,
  },
});

export const { addWishlistProductsEntries, removeWishlistProductsEntries } =
  wishlistProductsSlice.actions;

export const { selectEntities } =
  wishlistProductsAdapter.getSelectors<RootState>(
    (state) => state.wishlistProducts
  );

export const wishlistProductsSliceName = wishlistProductsSlice.name;

export default wishlistProductsSlice.reducer;
