import { useState } from "react";

import logout from "web/utils/system/essentials/logout";

const useLogout = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const logoutHandler = () => {
    setIsDisabled(true);
    logout("", null, "main header");
  };

  return { logoutHandler, isDisabled };
};

export default useLogout;
