import restUrls from "web/constants/restUrls";

import {
  IPrepaidCardDetails,
  PrepaidPayer,
  PrepaidType,
} from "web/types/PrepaidCard";

import api from "../api/apiSlice";

interface IPrepaidCards {
  items: {
    available: boolean;
    id: string;
    imageUrl: string;
    issuanceFeePayer: PrepaidPayer;
    issuanceFee: number;
    name: string;
    rechargeComissionPayer: PrepaidPayer;
    rechargeComission: number;
    type: PrepaidType;
    status: string;
    vatRate: string;
  }[];
}

export const prepaidCardsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getPrepaidCards: builder.query<IPrepaidCards, void>({
      query: () => restUrls.prepaidCards,
      providesTags: ["PrepaidCards"],
    }),
    getPrepaidCardDetails: builder.query<IPrepaidCardDetails, string>({
      query: (id) => `${restUrls.prepaidCards}/${id}`,
      providesTags: ["PrepaidCards_details"],
    }),
  }),
});

export const { useGetPrepaidCardsQuery, useGetPrepaidCardDetailsQuery } =
  prepaidCardsApiSlice;
