import { createApi } from "@reduxjs/toolkit/query/react";

import cmsBlocksQuery from "web/queries/default/cmsBlocks.graphql";
import msMenuWebviewQuery from "web/queries/ms/menuWebview.graphql";
import msMainMenuQuery from "web/queries/ms/menus.graphql";

import { magentoUrlKey, msURlKey } from "web/constants/graphqlUrls";

import { ICmsBlockItem, ICmsBlocks } from "web/types/CmsBlocks";
import { IMenu } from "web/types/Menu";
import { IMenuClient } from "web/types/MenuClient";
import { Nullable } from "web/types/Utils";

import graphQLBaseQuery from "./graphQLBaseQuery";

const api = createApi({
  reducerPath: "graphQlApi",
  baseQuery: graphQLBaseQuery,
  tagTypes: ["menus", "menuWebview", "footer"],
  endpoints: (builder) => ({
    // menu
    getMenu: builder.query<{ menu: string; customerMenu: string }, void>({
      query: () => ({
        endpoint: msURlKey,
        query: msMainMenuQuery,
        selectionSetNames: ["msMenuClients", "msMenus"],
      }),
      providesTags: ["menus"],
      transformResponse: ({
        msMenus,
        msMenuClients,
      }: {
        msMenus: IMenu[];
        msMenuClients: IMenuClient[];
      }): { menu: string; customerMenu: string } => {
        return {
          menu: msMenus[0].menu_data,
          customerMenu: msMenuClients[0].client_menu_data,
        };
      },
    }),

    // webview menu (without menuclient)
    getMenuWebview: builder.query<{ menu: string }, void>({
      query: () => ({
        endpoint: msURlKey,
        query: msMenuWebviewQuery,
        selectionSetNames: ["msMenus"],
      }),
      providesTags: ["menuWebview"],
      transformResponse: ({
        msMenus,
      }: {
        msMenus: IMenu[];
      }): { menu: string } => {
        return {
          menu: msMenus[0].menu_data,
        };
      },
    }),

    // footer
    getFooter: builder.query<Nullable<ICmsBlockItem[]>, void>({
      query: () => ({
        endpoint: magentoUrlKey,
        query: cmsBlocksQuery,
        id: "footer",
      }),
      providesTags: ["footer"],
      transformResponse: (response: ICmsBlocks) => {
        return response.items[0].cms_json;
      },
    }),
  }),
});

export const { useGetFooterQuery, useGetMenuWebviewQuery, useGetMenuQuery } =
  api;

export default api;
