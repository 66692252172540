import { useQuery } from "@apollo/client";
import { Button } from "@benefit-systems/common-components";
import { FC } from "react";

import HelpCenterCatrgoryArticlesSection from "web/Pages/HelpCenterCategory/Shared/ArticlesSection/articlesSection";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import helpCenterCategory from "web/queries/default/helpCenterCategory.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import urls from "web/constants/urls";

import { FAQ_PREFIX } from "../faq";
import classes from "./FAQCategory.scss";

interface IFAQCategoryProps {
  id: number;
}

const FAQCategory: FC<IFAQCategoryProps> = ({ id }) => {
  const { languageFromParam, isParamLangEn } = useLangFromParams();

  const { error, loading, data } = useQuery(helpCenterCategory, {
    variables: {
      categoryId: [id],
      lang: languageFromParam || getLanguageCode(),
    },
  });

  if (error) {
    newRelicErrorReport(
      error,
      "web-app/web/Pages/FAQ/FAQCategory/FAQCategory.tsx - 34"
    );
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  if (loading && !data) return <Loading />;

  const { articles } = data?.helpCenterCategory?.category?.[0];

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>{__("FAQ")}</h1>
      <HelpCenterCatrgoryArticlesSection
        articles={articles}
        prefix={FAQ_PREFIX}
      />
      <Button variant="secondary" to={urls.faq}>
        {isParamLangEn ? "Back" : __("Wróć")}
      </Button>
    </div>
  );
};

export default FAQCategory;
