import { createContext, useContext } from "react";

interface IPageGeneratorContextValue {
  pageName: string;
}

const contextDefaultValue = {};
const Context = createContext<IPageGeneratorContextValue>(
  contextDefaultValue as IPageGeneratorContextValue
);
const PageGeneratorContextProvider = Context.Provider;
const usePageGeneratorContext = () => useContext(Context);

export { PageGeneratorContextProvider, usePageGeneratorContext };
