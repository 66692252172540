import filterBanks from "web/Pages/Customer/Content/Points/utils";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { translateBanks } from "web/utils/system/redux/banksTranslations";

import { BankItems } from "web/types/Banks";

const translateAndTransformBanks = async (items: BankItems) => {
  if (!isArrayHasItems(items)) return { items: [], itemsActive: [], total: 0 };

  // eslint-disable-next-line no-underscore-dangle
  let _items = (await translateBanks(items)) as BankItems;

  const total = _items.reduce(
    (result, current) => (current ? result + +current.balance : result),
    0
  );
  const itemsActive = isArrayHasItems(_items)
    ? _items.filter(
        (item) => item && +item.balance && parseFloat(item.balance) > 0
      )
    : [];

  // hide unwanted banks
  _items = filterBanks(_items) as BankItems;

  return { items: _items, itemsActive, total };
};

export default translateAndTransformBanks;
