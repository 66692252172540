import {
  Dictionary,
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { isTestMode } from "config/env";

import { ICategory } from "web/types/Category";
import type { Nullable } from "web/types/Utils";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const categoriesAdapter = createEntityAdapter<ICachedItem<ICategory>>();

interface ICategoriesState {
  isPrefetching: boolean;
  prefetchTTL: Nullable<number>;
  ids: EntityId[];
  entities: Dictionary<ICachedItem<ICategory>>;
}

const initialState: ICategoriesState = {
  ...categoriesAdapter.getInitialState(),
  isPrefetching: !isTestMode,
  prefetchTTL: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    addCategoryEntries: categoriesAdapter.addMany,
    removeCategoryEntries: categoriesAdapter.removeMany,
    setIsPrefetching: (state, action: PayloadAction<boolean>) => {
      state.isPrefetching = action.payload;
    },
    setPrefetchTTL: (state, action: PayloadAction<number>) => {
      state.prefetchTTL = action.payload;
    },
  },
});

export const {
  addCategoryEntries,
  removeCategoryEntries,
  setIsPrefetching,
  setPrefetchTTL,
} = categoriesSlice.actions;

export const { selectEntities } = categoriesAdapter.getSelectors<RootState>(
  (state) => state.categories
);

export const categoriesSliceName = categoriesSlice.name;

export default categoriesSlice.reducer;
