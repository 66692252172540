import { FC } from "react";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

interface IssuanceFeeRowProps {
  className?: string;
  label?: string;
  issuanceFee?: number;
  issuanceFeePayer?: string;
  type: string;
}

const IssuanceFeeRow: FC<IssuanceFeeRowProps> = ({
  label = "Opłata",
  issuanceFee = null,
  issuanceFeePayer = null,
  className = "",
  type,
}) => {
  return (
    <>
      {issuanceFee && issuanceFeePayer === "EMPLOYEE" && type === "CREATE" ? (
        <div className={className} style={{ marginTop: "14px" }}>
          <p>{label}</p>
          <p>
            {formatNumber(issuanceFee)} {__("pkt")}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default IssuanceFeeRow;
