import dayjs from "dayjs";
import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import formatNumber from "web/utils/data/parser/number/formatNumber";

import { ICnsx } from "web/types/Tourism";

interface ICancellationPolicyOptionProps {
  option: ICnsx;
  className: string;
}

const CancellationPolicyOption: FC<ICancellationPolicyOptionProps> = ({
  option,
  className,
}) => {
  const { from, to, value, from_time, to_time } = option;
  const { fromFormatted, fromTime, toFormatted, toTime, valueFormatted } =
    useMemo(
      () => ({
        fromFormatted: formatDate(from),
        fromTime: dayjs(`${from} ${from_time}`).format("HH:mm"),
        toFormatted: formatDate(to),
        toTime: dayjs(`${to} ${to_time}`).format("HH:mm"),
        valueFormatted: formatNumber(value),
      }),
      [from, to, value]
    );

  const [cancelText, toText, paymentText, currencyText, hourText] = useMemo(
    () => [
      __("Anulacja w terminach od"),
      __("do"),
      __("- zostaniesz obciążony kwotą anulacji w wysokości"),
      __("zł"),
      __("godz."),
    ],
    []
  );

  return (
    <p className={className}>
      {cancelText}&nbsp;
      {fromFormatted},&nbsp;
      {hourText}&nbsp;{fromTime}&nbsp;
      {toText}&nbsp;
      {toFormatted},&nbsp;
      {hourText}&nbsp;{toTime}&nbsp;
      {paymentText}&nbsp;
      {valueFormatted}&nbsp;
      {currencyText}&nbsp;
    </p>
  );
};

export default CancellationPolicyOption;
