import { Redirect } from "react-router-dom";

import __ from "web/Layout/Translations";

import urls from "web/constants/urls";

import { FC } from "react";
import Tabs from "../Customer/Content/Tabs";
import Content from "./content";
import classes from "./productsQuickList.scss";

const tabs = [
  {
    name: "Moje kody",
    link: {
      hash: "",
    },
  },
];

interface IProductsQuickListProps {
  isMobile: boolean;
}

const ProductsQuickList: FC<IProductsQuickListProps> = ({ isMobile }) => {
  return isMobile ? (
    <div className={classes.root}>
      <header className={classes.header}>
        <h1 className={classes.title}>{__("Podręczna lista produktów")}</h1>
        <Tabs className={classes.tabs} items={tabs} />
      </header>
      <Content />
    </div>
  ) : (
    <Redirect to={urls.home} />
  );
};

export default ProductsQuickList;
