import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import Single from "./single";

interface IPageGeneratorSingleContainerProps {
  isMobile: boolean;
  params: string;
  id: number;
}

const PageGeneratorSingleContainer: FC<IPageGeneratorSingleContainerProps> = ({ isMobile = false, params = "", id }) => {
  return (
    <ErrorBoundary>
      <Single params={params} isMobile={isMobile} id={id} />
    </ErrorBoundary>
  );
};

export default PageGeneratorSingleContainer;
