import { createContext, useContext } from "react";

import useProviderValue from "web/hooks/useProviderValue";

type AppContext = ReturnType<typeof useProviderValue>;

const contextDefaultValue = {};
const Context = createContext<any>(contextDefaultValue);
const AppProvider = Context.Provider;
const useAppContext = (): AppContext => useContext(Context) as AppContext;

export { AppProvider, useAppContext };
