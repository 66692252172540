import { Link } from "react-router-dom";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import StarsRating from "web/Components/Common/StarsRating";

import truncateString from "web/utils/data/parser/string/truncateString";

import classify from "web/classify";

import { FC } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultStyles from "./reviewMobile.scss";


interface IPageGeneratorReviewMobileProps {
  url: string;
  title: string;
  name: string;
  rating: number;
  photo: string;
  contentText: string;
}

const PageGeneratorReviewMobile: FC<PropsWithClasses<IPageGeneratorReviewMobileProps>> = ({
  url,
  title,
  name,
  rating,
  photo,
  contentText,
  classes = {},
}) => {
  const textTruncated = truncateString(contentText, 110, " (...)");
  const ratingProcessed = rating.toFixed(1);
  return (
    <article className={classes.root}>
      <Link className={classes.imageBox} to={url} title={title}>
        <LazyLoadImage
          className={classes.image}
          src={photo}
          alt={title}
          isProduct
          width={286}
          height={189}
        />
      </Link>
      <div className={classes.content}>
        <h3 className={classes.title}>
          <Link className={classes.link} to={url} title={title}>
            {title}
          </Link>
        </h3>
        <blockquote className={classes.blockquote}>
          <div className={classes.rating}>
            <StarsRating
              className={classes.stars}
              rating={parseInt(`${rating}`, 10)}
            />
            <span className={classes.ratingAmount}>{ratingProcessed}</span>
          </div>
          <p className={classes.text}>{textTruncated}</p>
          <cite className={classes.name}>{name}</cite>
        </blockquote>
      </div>
    </article>
  );
};

export default classify<PropsWithClasses<IPageGeneratorReviewMobileProps>>(defaultStyles)(PageGeneratorReviewMobile);
