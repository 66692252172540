import { createSlice } from "@reduxjs/toolkit";
import { IPromoItems } from "web/types/Gtm";

interface IGtmState {
  promoItems: IPromoItems;
  promoLinks: IPromoItems;
}

const initialState: IGtmState = {
  promoItems: {},
  promoLinks: {},
};

export const gtmSlice = createSlice({
  name: "gtm",
  initialState,
  reducers: {
    setPromoItems: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        promoItems: { ...(state.promoItems || {}), ...(payload || {}) },
      };
    },
    setPromoLinks: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        promoLinks: { ...(state.promoLinks || {}), ...(payload || {}) },
      };
    },
    removePromoLink: (state, action) => {
      const { payload } = action;
      const promoLinksCopy = { ...(state.promoLinks || {}) };
      delete promoLinksCopy[payload];
      return {
        ...state,
        promoLinks: { ...(promoLinksCopy || {}) },
      };
    }
  },
});

export const { setPromoItems, setPromoLinks, removePromoLink } =
  gtmSlice.actions;

export default gtmSlice.reducer;
