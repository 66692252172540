import type { FetchArgs } from "@reduxjs/toolkit/query";

const isMSIQuery = (args: FetchArgs | string) => {
  if (
    (typeof args === "string" &&
      (args.startsWith("/api/") || args.startsWith("/auth/realms/"))) ||
    (typeof args !== "string" &&
      (args.url.startsWith("/api/") || args.url.startsWith("/auth/realms/")))
  ) {
    return true;
  }
  return false;
};
export default isMSIQuery;
