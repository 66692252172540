/* eslint-disable no-nested-ternary */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import GtmEvent from "web/Layout/Gtm/GtmEvent";

import getPageReferrer from "web/utils/system/GTM/getPageReferrer";

import urls from "web/constants/urls";

import { Nullable } from "web/types/Utils";

import PageGeneratorQuery from "./pageGeneratorQuery";

interface IPageGeneratorContextProps {
  id: Nullable<number>;
}

const PageGeneratorContext: FC<IPageGeneratorContextProps> = ({
  id = null,
}) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const [token, storeId, homePageId, tourismId] = storeConfig
    ? [
        storeConfig.token,
        storeConfig.id,
        storeConfig.page_generator_homepage_id,
        storeConfig.page_generator_anixe_tourism_id,
      ]
    : [];

  const { pathname, search } = useLocation();

  const [idProcessed, gtmOptions] = useMemo(() => {
    const pageReferrer = getPageReferrer({ pathname, search });
    const isLandingPage = pathname?.indexOf("/lp/") !== -1;

    return urls.home === pathname
      ? [homePageId, { cgroup1: "Strona główna", page_referrer: pageReferrer }]
      : isLandingPage
      ? [id, { cgroup1: "LP", page_referrer: pageReferrer }]
      : [id, { cgroup1: "Inne", page_referrer: pageReferrer }];
  }, [pathname, search, homePageId, id, getPageReferrer]);

  return idProcessed === tourismId && pathname !== urls.tourism ? (
    <Redirect to={urls.tourism} />
  ) : (
    <>
      <PageGeneratorQuery
        id={idProcessed as Nullable<number>}
        token={token as string}
        storeId={storeId as number}
      />
      <GtmEvent options={gtmOptions} withUrl />
    </>
  );
};

export default PageGeneratorContext;
