import { FC } from "react";

import { IAttributeOption } from "web/types/Attributes";

import { useAppContext } from "web/context/app";

import classesDesktop from "./section.scss";
import classesMobile from "./sectionMobile.scss";

interface IProductAmenitiesSectionProps {
  className: string;
  items: IAttributeOption[];
  title: string;
}

const ProductAmenitiesSection: FC<IProductAmenitiesSectionProps> = ({
  className = "",
  items,
  title,
}) => {
  const { isMobile } = useAppContext();

  const classes = isMobile ? classesMobile : classesDesktop;
  return (
    <div className={className || classes.root}>
      <span className={classes.title}>{title}</span>
      <ul className={classes.list}>
        {items?.map(({ label }) => (
          <li key={label} className={classes.item}>
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductAmenitiesSection;
