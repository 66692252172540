import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IStoreConfig } from "web/types/StoreConfig";
import type { Nullable } from "web/types/Utils";

import { getStoreConfig } from "./appThunks";

interface INotification {
  message: string;
  id: number;
  timeout?: number;
}

interface IAppState {
  storeConfig: IStoreConfig | Record<string, never>;
  error: Nullable<Error>;
  isStoreConfigGotten: boolean;
  notificationSuccess: INotification[];
  notificationWarning: INotification[];
  notificationError: INotification[];
  windowWidth: number;
  windowHeight: number;
  pageType: string;
  lastNavigationUrl: Nullable<string>;
  navigationCategoryId: Nullable<number>;
  isNavigationOpened: boolean;
  lastVisitedProductId: string;
  currentProductId: string;
  isFiltersLoading: boolean;
  tokenTTL?: number;
}

const initialState: IAppState = {
  storeConfig: {},
  error: null,
  isStoreConfigGotten: false,
  notificationSuccess: [],
  notificationWarning: [],
  notificationError: [],
  windowWidth: window && window.innerWidth ? window.innerWidth : 0,
  windowHeight: window && window.innerHeight ? window.innerHeight : 0,
  pageType: "",
  lastNavigationUrl: null,
  navigationCategoryId: null,
  isNavigationOpened: false,
  lastVisitedProductId: "",
  currentProductId: "",
  isFiltersLoading: true,
};

type NotificationPayload = Omit<INotification, "id">;

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setNotificationSuccess: (
      state,
      action: PayloadAction<NotificationPayload>
    ) => {
      const { payload } = action;
      const { message, timeout } = payload || {};
      state.notificationSuccess.push({
        message,
        id: Math.random(),
        timeout,
      });
    },

    deleteNotificationSuccess: (state, action: PayloadAction<number>) => {
      const { notificationSuccess } = state;
      const { payload: id } = action;
      state.notificationSuccess = notificationSuccess.filter(
        (notification) => notification && notification.id !== id
      );
    },

    setNotificationWarning: (
      state,
      action: PayloadAction<NotificationPayload>
    ) => {
      const { payload } = action;
      const { message, timeout } = payload || {};
      state.notificationWarning.push({
        message,
        id: Math.random(),
        timeout,
      });
    },

    deleteNotificationWarning: (state, action: PayloadAction<number>) => {
      const { notificationWarning } = state;
      const { payload: id } = action;
      state.notificationWarning = notificationWarning.filter(
        (notification) => notification && notification.id !== id
      );
    },

    updateNotificationWarning: (
      state,
      action: PayloadAction<INotification[]>
    ) => {
      state.notificationWarning = action.payload;
    },

    setNotificationError: (
      state,
      action: PayloadAction<NotificationPayload>
    ) => {
      const { payload } = action;
      const { message, timeout } = payload || {};
      state.notificationError.push({
        message,
        id: Math.random(),
        timeout,
      });
    },

    deleteNotificationError: (state, action: PayloadAction<number>) => {
      const { notificationError } = state;
      const { payload: id } = action;
      state.notificationError = notificationError.filter(
        (notification) => notification && notification.id !== id
      );
    },

    setWindowDimension: (
      state,
      action: PayloadAction<{ width: number; height: number }>
    ) => {
      const { width, height } = action.payload || {};
      state.windowWidth = width;
      state.windowHeight = height;
    },

    setPageType: (state, action: PayloadAction<string>) => {
      state.pageType = action.payload;
    },

    resetPageType: (state) => {
      state.pageType = initialState.pageType;
    },

    setLastNavigationUrl: (state, action: PayloadAction<string>) => {
      state.lastNavigationUrl = action.payload;
    },

    resetLastNavigationUrl: (state) => {
      state.lastNavigationUrl = null;
    },

    setNavigationCategoryId: (
      state,
      action: PayloadAction<Nullable<number>>
    ) => {
      state.navigationCategoryId = action.payload;
    },

    resetNavigationCategoryId: (state) => {
      state.navigationCategoryId = null;
    },

    openNavigation: (state) => {
      state.isNavigationOpened = true;
    },

    closeNavigation: (state) => {
      state.isNavigationOpened = false;
    },

    setLastVisitedProductId: (state, action: PayloadAction<string>) => {
      state.lastVisitedProductId = action.payload;
    },

    resetLastVisitedProductId: (state) => {
      state.lastVisitedProductId = initialState.lastVisitedProductId;
    },

    setCurrentProductId: (state, action: PayloadAction<string>) => {
      state.currentProductId = action.payload;
    },

    resetCurrentProductId: (state) => {
      state.currentProductId = initialState.currentProductId;
    },

    setFiltersLoading: (state, action: PayloadAction<boolean>) => {
      state.isFiltersLoading = action.payload;
    },

    setTokenTtl: (state, action: PayloadAction<number>) => {
      state.tokenTTL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStoreConfig.pending, (state) => {
      state.isStoreConfigGotten = false;
    });

    builder.addCase(getStoreConfig.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        return state;
      }
      return {
        ...state,
        storeConfig: payload,
        isStoreConfigGotten: true,
      };
    });

    builder.addCase(getStoreConfig.rejected, (state, action) => {
      const { error } = action;
      state.error = error as Error;
    });
  },
});

export const {
  setNotificationSuccess,
  deleteNotificationSuccess,
  setNotificationWarning,
  deleteNotificationWarning,
  setNotificationError,
  deleteNotificationError,
  updateNotificationWarning,
  setWindowDimension,
  setPageType,
  resetPageType,
  setLastNavigationUrl,
  resetLastNavigationUrl,
  setNavigationCategoryId,
  resetNavigationCategoryId,
  openNavigation,
  closeNavigation,
  setLastVisitedProductId,
  resetLastVisitedProductId,
  setCurrentProductId,
  resetCurrentProductId,
  setFiltersLoading,
  setTokenTtl,
} = appSlice.actions;

export default appSlice.reducer;
