import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import {
  ISubscriptionUserConfiguration,
  SubscriptionGroups,
} from "web/types/Subscription";

import { getSubscriptionConfiguration } from "./subscriptionQuoteThunks";

type SubscriptionQuoteConfig = ISubscriptionUserConfiguration & {
  benefitGroupType?: string;
};

interface ISubscriptionQuoteState {
  config: SubscriptionQuoteConfig;
  benefitGroupType: SubscriptionGroups;
  hasEnabledAnySubscriptions: boolean;
  isLoading: boolean;
  isGotten: boolean;
}

const initialState: ISubscriptionQuoteState = {
  config: {} as SubscriptionQuoteConfig,
  benefitGroupType: SubscriptionGroups.STANDARD,
  hasEnabledAnySubscriptions: false,
  isLoading: false,
  isGotten: false,
};

export const subscriptionQuoteSlice = createSlice({
  name: "subscriptionQuote",
  initialState,
  reducers: {
    setBenefitGroupType: (
      state,
      action: PayloadAction<Pick<ISubscriptionQuoteState, "benefitGroupType">>
    ) => {
      const { benefitGroupType } = action.payload || {};
      state.benefitGroupType = benefitGroupType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionConfiguration.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSubscriptionConfiguration.fulfilled, (state, action) => {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      const { config, hasEnabledAnySubscriptions } = action.payload;

      return {
        ...state,
        config,
        hasEnabledAnySubscriptions,
        isLoading: false,
        isGotten: true,
      };
    });

    builder.addCase(getSubscriptionConfiguration.rejected, (state, action) => {
      const { error } = action;
      newRelicErrorReport(
        error,
        "subscriptionQuoteSlice - getSubscriptionConfiguration"
      );
    });
  },
});

export const { setBenefitGroupType } = subscriptionQuoteSlice.actions;

export default subscriptionQuoteSlice.reducer;
