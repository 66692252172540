import { createContext, useContext as useContextReact } from "react";

import type { ICampaignGraphics } from "web/types/CampaignGraphics";
import type { ICategory } from "web/types/Category";

interface ICategoryContextValue {
  campaignGraphics: ICampaignGraphics[];
  category: ICategory;
}

const contextDefaultValue = {};
const Category = createContext<ICategoryContextValue>(
  contextDefaultValue as ICategoryContextValue
);
const CategoryContextProvider = Category.Provider;
const useCategoryContext = () => useContextReact(Category);

export { CategoryContextProvider, useCategoryContext };
