import { Field, useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import { PropsWithClasses } from "web/types/Common";

import { param, radioValues } from "../../constants";

const RadioGroupField: FC<PropsWithClasses<{ name: string }>> = ({
  name,
  classes = {},
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { values } = useFormikContext<{
    [name: string]: string;
  }>();

  useEffect(() => {
    const redirectUrl =
      values.statement === radioValues.newBenefit
        ? `${pathname}?${param}`
        : pathname;
    push(redirectUrl);
  }, [values.statement]);

  return (
    <div className={classes.radioGroup}>
      <div className={classes.radio}>
        <Field
          id={radioValues.productInformation}
          name={name}
          type="radio"
          value={radioValues.productInformation}
        />
        <label
          htmlFor={radioValues.productInformation}
          className={classes.label}
        >
          <div className={classes.text}>
            <p className={classes.paragraph}>
              {__("Potrzebuję informacji / pomocy")}
            </p>
          </div>
        </label>
      </div>
    </div>
  );
};

export default RadioGroupField;
