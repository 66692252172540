// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cms-title-ItZ{font-size:30px;font-weight:700;text-align:center;margin:60px 0 30px}.cms-content-OJt{word-wrap:break-word}.cms-content-OJt table{table-layout:fixed;width:100%}`, "",{"version":3,"sources":["webpack://./web/Pages/Cms/cms.scss"],"names":[],"mappings":"AAIuB,eACrB,cAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CAGF,iBACE,oBAAA,CACA,uBACE,kBAAA,CACA,UAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .title {\n  font-size: 30px;\n  font-weight: 700;\n  text-align: center;\n  margin: 60px 0 30px;\n}\n\n.content {\n  word-wrap: break-word;\n  table {\n    table-layout: fixed;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `cms-title-ItZ`,
	"content": `cms-content-OJt`
};
export default ___CSS_LOADER_EXPORT___;
