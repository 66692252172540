import { string } from "prop-types";
import { useCallback, useMemo } from "react";

import classes from "web/Pages/Product/Desktop/Gallery/gallery.scss";
import modalClasses from "web/Pages/Product/Desktop/Gallery/modalClasses.scss";

import { SkeletonProductImage } from "web/Layout/Common/SkeletonComponents";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { useAppContext } from "web/context/app";

import GalleryPrepaidFullscreen from "./GalleryPrepaidFullscreen";
import Gallery from "./gallery";

const Container = ({ image }) => {
  const { modal } = useAppContext();

  const product = {
    media_gallery: [
      {
        disabled: false,
        label: null,
        media_type: "image",
        media_url: image,
        position: 1,
      },
    ],
  };

  const { dispatch } = modal;
  const [firstImageMemo, imagesSlicedMemo, imagesFilteredMemo] = useMemo(() => {
    const imagesSorted =
      product && isArrayHasItems(product.media_gallery)
        ? [...product.media_gallery].sort(
            (first, second) => +first.position - +second.position
          )
        : [];
    const imagesFiltered = isArrayHasItems(imagesSorted)
      ? product.media_gallery.reduce((result, currentImage) => {
          switch (true) {
            case !!currentImage.disabled: {
              return result;
            }
            default: {
              return [...result, currentImage];
            }
          }
        }, [])
      : [];

    imagesFiltered.sort((a, b) => {
      return a.position - b.position;
    });
    const imagesSliced = imagesFiltered.slice(1, 3);

    return [imagesFiltered[0], imagesSliced, imagesFiltered];
  }, [JSON.stringify(product)]);

  const setModalAction = useCallback(
    (activeIndex) => {
      dispatch({
        type: "addModal",
        classes: modalClasses,
        modal: (
          <GalleryPrepaidFullscreen
            activeIndex={activeIndex}
            images={imagesFilteredMemo}
            dispatch={dispatch}
          />
        ),
      });
    },
    [dispatch, GalleryPrepaidFullscreen, imagesFilteredMemo, modalClasses]
  );

  return firstImageMemo ? (
    <Gallery
      firstImage={firstImageMemo}
      images={imagesSlicedMemo}
      modalAction={setModalAction}
      count={imagesFilteredMemo.length}
    />
  ) : (
    <div className={classes.skeletonImageWrapper}>
      <SkeletonProductImage />
    </div>
  );
};

Container.propTypes = {
  image: string.isRequired,
};

export default Container;
