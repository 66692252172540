import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import ProductCarouselSection from "web/Pages/PageGenerator/ProductCarousel/productCarousel";

import Loading from "web/Layout/Common/Loading";

import type { IProduct } from "web/types/Product";

import useDataCachedProducts from "web/features/useDataCached/useDataCachedProducts";

const AdditionalCarousel: FC = () => {
  const {
    subscription_success_page_product_carousel_ids: productsIds,
    subscription_success_page_product_carousel_title: title,
  } = useSelector((state) => state.app.storeConfig);

  if (!productsIds) return null;

  const ids = useMemo(
    () =>
      typeof productsIds === "string"
        ? productsIds.split(",").map((id) => +id)
        : [],
    [productsIds]
  );

  const { loading, error, data } = useDataCachedProducts({
    ids,
  });

  switch (true) {
    case !!error: {
      console.error(error);
      return null;
    }
    case !!loading: {
      return <Loading />;
    }
    default: {
      return (
        <ProductCarouselSection products={data as IProduct[]} header={title} />
      );
    }
  }
};

export default AdditionalCarousel;
