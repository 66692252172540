/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";

import AdditionalCosts from "web/Pages/Checkout/TourismDomestic/SharedComponents/AdditionalCosts";
import CancellationPolicy from "web/Pages/Checkout/TourismDomestic/SharedComponents/CancellationPolicy";
import Reservation from "web/Pages/Checkout/TourismDomestic/SharedComponents/Reservation";

import ErrorBoundary from "web/Layout/ErrorBoundary/errorBoundary";
import __ from "web/Layout/Translations";

import Box from "web/Components/Common/Box";
import HtmlTemplate from "web/Components/Common/HtmlTemplate";
import PaymentSummary from "web/Components/Common/PaymentSummary";

import { useTourismCheckoutInfoWithMinPriceFetch } from "web/hooks/useTourismCheckoutInfo";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  IParsedOrder,
  IParsedTourismOrderItem,
  ISources,
} from "web/types/Order";
import { MBProductType } from "web/types/Product";
import { Guest, IRoom, Purchaser } from "web/types/Tourism";
import { Email, Nullable } from "web/types/Utils";

import Contacts from "../../TourismDomestic/SharedComponents/Contacts";
import CheckoutTourismDomesticInfoSection from "../../TourismDomestic/SharedComponents/checkoutInfoSection/checkoutInfoSection";
import classes from "./tourismSuccess.scss";

interface ITourismSuccessProps {
  order: IParsedOrder<MBProductType>;
  sources?: ISources[];
  setSourcesOpen?: () => unknown;
}

const TourismSuccess: FC<ITourismSuccessProps> = ({
  order,
  sources,
  setSourcesOpen = () => {},
}) => {
  const {
    email,
    phone,
    site,
    guests,
    purchaser,
    rooms,
    dateFrom,
    dateTo,
    total,
    paid,
    purchaserOnly,
    objectRules,
    cancellationType,
    cancellationOptions,
    tourismOfferData,
    remark,
    createdAt,
  } = useMemo(() => {
    if (order) {
      const [productData, tourismOfferData, createdAt] =
        isArrayHasItems(order.items) && order.items[0]
          ? [
              (order.items[0] as IParsedTourismOrderItem).product_data,
              (order.items[0] as IParsedTourismOrderItem).tourism_offer_data,
              (order.items[0] as IParsedTourismOrderItem).created_at,
            ]
          : [];

      const productPrice = order.items?.[0]?.price;

      const paidAmount = order?.payments
        ? order.payments
            .map((item) => item.amount_ordered)
            .reduce((a, b) => a + b, 0)
        : order?.payment.map((item) => +item.to_pay).reduce((a, b) => a + b, 0);

      return productData
        ? {
            email: productData.tourism_email,
            phone: productData.tourism_phone,
            site: productData.tourism_website,
            guests: order.tourism_data && order.tourism_data.guest,
            purchaser: order.tourism_data && order.tourism_data.purchaser,
            rooms: tourismOfferData?.room,
            dateFrom: Date.parse((tourismOfferData?.date_from as string) || ""),
            dateTo: Date.parse((tourismOfferData?.date_to as string) || ""),
            total: +order.total || 0,
            paid: paidAmount,
            price: productPrice,
            purchaserOnly: order.tourism_data?.purchaser_only,
            objectRules: productData.tourism_object_rules,
            cancellationType: tourismOfferData?.room[0]?.cnx,
            // @ts-ignore
            cancellationOptions: tourismOfferData?.cnxs,
            tourismOfferData,
            remark: tourismOfferData?.room?.[0].remark,
            createdAt: (createdAt && new Date(createdAt)) as Date | undefined,
          }
        : {};
    }
    return {};
  }, [JSON.stringify(order)]);

  const paymentInformation =
    // @ts-ignore
    useTourismCheckoutInfoWithMinPriceFetch(tourismOfferData);

  return (
    <>
      <PaymentSummary
        total={total as number}
        paid={paid as number}
        setSourcesOpen={setSourcesOpen}
        sources={sources}
        orderState={order.state}
      />
      {isArrayHasItems(remark) && (
        <ErrorBoundary>
          <AdditionalCosts remark={remark} />
        </ErrorBoundary>
      )}
      <Reservation
        guests={purchaserOnly ? [] : (guests as Guest[])}
        purchaser={purchaser as Purchaser}
        rooms={rooms as IRoom[]}
        dateFrom={dateFrom as number}
        dateTo={dateTo as number}
        title={__("Rezerwacja")}
      />
      {cancellationType && (
        <ErrorBoundary>
          <CancellationPolicy
            type={cancellationType}
            options={cancellationOptions}
            variant="primary"
            createdAt={createdAt}
          />
        </ErrorBoundary>
      )}
      {paymentInformation && (
        <ErrorBoundary>
          <CheckoutTourismDomesticInfoSection
            name={__("Informacje o płatnościach")}
            info={paymentInformation}
          />
        </ErrorBoundary>
      )}
      {/* object rules */}
      {objectRules && (
        <Box
          headingTag="h2"
          headingText={__("Zasady obiektu")}
          sectionClass={classes.objectRules}
        >
          <HtmlTemplate
            template={objectRules}
            className={classes.objectRules__content}
          />
        </Box>
      )}

      {email || phone || site ? (
        <Contacts phone={phone} email={email as Nullable<Email>} site={site} />
      ) : null}
    </>
  );
};

export default TourismSuccess;
