/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";

import restUrls from "web/constants/restUrls";

import { ISubscriptionUserConfiguration } from "web/types/Subscription";

import { request } from "web/api";

import { setBenefit } from "../customerMenu/customerMenuSlice";
import { employeeApiSlice } from "../employee/employeeApiSlice";

export const getSubscriptionConfiguration = createAsyncThunk(
  "subscriptionQuote/getSubscriptionConfiguration",
  async (arg, { dispatch }) => {
    const {
      data: employee,
      isError,
      error,
    } = await dispatch(
      employeeApiSlice.endpoints.getEmployeeConfiguration.initiate()
    );

    if (isError) {
      throw new Error(
        `getSubscriptionConfiguration - employee config - ${JSON.stringify(
          error
        )}`
      );
    }

    if (
      employee?.subscriptionEnableSubscriptions !== "1" &&
      employee?.subscriptionEnableSubscriptionsUMS !== "1"
    ) {
      return null;
    }

    const subscriptionUserConfiguration = (await request(
      restUrls.subscriptionUserConfiguration
    )) as ISubscriptionUserConfiguration;

    const hasEnabledAnySubscriptions =
      subscriptionUserConfiguration.configuratorWindowOpen ||
      subscriptionUserConfiguration.configuratorWindowOpenUms;

    if (hasEnabledAnySubscriptions) dispatch(setBenefit());

    return {
      config: subscriptionUserConfiguration,
      hasEnabledAnySubscriptions,
    };
  }
);
