import { FC, useCallback, useMemo } from "react";
import { useLocation } from "react-router";

import Loading from "web/Layout/Common/Loading";
import LoadMore from "web/Layout/LoadMore";
import __ from "web/Layout/Translations";

import Title from "web/Components/Common/Title";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import FiltersIcon from "web/assets/icons/filters.svg";

import { useAppContext } from "web/context/app";

import { ICodeItem } from "web/types/Code";
import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";
import List from "../Shared/List";
import Filters from "./Filters";
import classes from "./codeList.scss";

interface ICustomerCodeListMobileProps {
  className: string;
  codes: ICodeItem[];
  isLoading: boolean;
  toUseCount: number;
  usedCount: number;
  totalCount: number;
}

const titleClasses = {
  root: classes.header,
  header: classes.title,
};
const name = "availability";

const CustomerCodeListMobile: FC<ICustomerCodeListMobileProps> = ({
  className,
  codes,
  isLoading,
  toUseCount,
  usedCount,
  totalCount,
}) => {
  const { search } = useLocation();

  const params = new URLSearchParams(decodeURIComponent(search));
  const filters = useMemo(() => {
    return [
      {
        label: `${__("Do wykorzystania")} (${toUseCount})`,
        value: "use",
        count: toUseCount,
      },
      {
        label: `${__("Wykorzystane")} (${usedCount})`,
        value: "used",
        count: usedCount,
      },
      {
        label: `${__("Wszystkie")} (${totalCount})`,
        value: "all",
        count: totalCount,
      },
    ];
  }, [toUseCount, usedCount, totalCount]);
  const type = params.get(name) || filters[0].value;

  const currentTotal = codes?.length;
  const filterTotalCount = filters.find(
    (filter) => filter.value === type
  )?.count || 0;

  let title;
  let codesAmount;
  switch (type) {
    case "use": {
      title = __("Do wykorzystania");
      codesAmount = toUseCount;
      break;
    }
    case "used": {
      title = __("Wykorzystane");
      codesAmount = usedCount;
      break;
    }
    default: {
      title = __("Wszystkie");
      codesAmount = totalCount;
      break;
    }
  }

  const { modal } = useAppContext();
  const { dispatch } = modal;

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  const setModalAction = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: <Filters name={name} filters={filters} closeModal={closeModal} />,
    });
  }, [dispatch, Filters, filters]);

  return (
    <div className={className}>
      <div className={classes.titleContainer}>
        <Title
          name={title}
          count={codesAmount}
          classes={titleClasses}
          dataT1="code_list_title"
        />
        <FiltersIcon
          width={18}
          className={classes.filterIcon}
          onClick={setModalAction}
        />
      </div>

      <List codes={codes} />
      {isLoading ? <Loading className={classes.loading} /> : null}
      {!!currentTotal && filterTotalCount > currentTotal && (
        <div className={classes.loadMoreContainer}>
          <LoadMore
            total={filterTotalCount}
            currentTotal={currentTotal}
            shouldChangePage
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default withScrollToTop(CustomerCodeListMobile);
