import checkoutClasses from "web/Pages/Checkout/Success/success.scss";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import Button from "web/Components/Common/Button";

import { IPrepaidOrder } from "web/types/PrepaidCard";

import {
  useGetPrepaidCardOrderDetailsQuery,
  useGetPrepaidCardsOrdersQuery,
} from "web/features/prepaidCardsOrders/prepaidCardsOrdersApiSlice";

import OrderDetails from "../OrderDetails";
import OrderSummary from "../OrderSummary";
import classes from "./success.scss";

type SuccessProps = {
  orderId: string;
};

const Success = ({ orderId }: SuccessProps) => {
  const setLanguage = localStorage.getItem("language") || "pl_PL";
  const bannerClassName =
    setLanguage === "pl_PL"
      ? checkoutClasses.banner
      : checkoutClasses.banner_EN;

  const {
    data: orders,
    isLoading: isLoadingOrders,
    error: ordersError,
  } = useGetPrepaidCardsOrdersQuery();
  const order = orders?.find((order: IPrepaidOrder) => order.id === orderId);

  const {
    data: orderDetails,
    isLoading: isLoadingOrderDetials,
    error: orderDetailsError,
  } = useGetPrepaidCardOrderDetailsQuery(orderId);

  if (ordersError || orderDetailsError) {
    return <ErrorComponentWithContainer />;
  }

  if (isLoadingOrders || isLoadingOrderDetials) {
    return <Loading className={classes.loading} />;
  }

  return (
    <div className={checkoutClasses.root}>
      <div className={checkoutClasses.order}>
        <div className={bannerClassName} />

        {order && (
          <OrderSummary
            totalAmount={order.employeeTotalAmount}
            orderNumber={order.orderNumber}
          />
        )}

        <div className={checkoutClasses.wrapper}>
          <div>
            <h2
              className={checkoutClasses.header}
              data-t1="success_page_details_header"
            >
              {__("Szczegóły zamówienia")}
            </h2>
          </div>
        </div>

        {orderDetails && (
          <div className={classes.orderDetailsContainer}>
            <OrderDetails orderDetails={orderDetails} />
          </div>
        )}

        <div className={classes.buttonContainer}>
          <Button
            variant="primary"
            type="button"
            to="/customer/prepaid-cards?show=history"
            dataT1="success_page__button"
          >
            {__("Przejdź do Innych realizacji")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Success;
