import { IMenuClientDataElement } from "web/types/MenuClient";

import {
  ICustomerMenuSection,
  ICustomerMenuSectionItem,
} from "web/features/customerMenu/customerMenuSlice";
import { IFinancingSourceItem, IFinancingSourcePaymentSettingsItem, ISources } from "web/types/FinancingSources";
import { IBankItem } from "web/types/Banks";
import { Nullable } from "web/types/Utils";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

export const getItemsWithRestrictions = (
  section: ICustomerMenuSection,
  payload: IMenuClientDataElement[],
  payloadElementNumber: 0 | 1 | 2
): ICustomerMenuSectionItem[] => {
  return section.items.reduce<ICustomerMenuSectionItem[]>((acc, item) => {
    const currentPayloadElement = payload[payloadElementNumber].items.find(
      (payloadElement) => payloadElement.original_name === item.name
    );

    if (currentPayloadElement && +currentPayloadElement.status === 1) {
      return [
        ...acc,
        {
          name: currentPayloadElement.original_name,
          title: currentPayloadElement.name,
          url: item.url,
          position: item.position,
        },
      ];
    }

    return acc;
  }, []);
};

export const getPaymentsWithNames = (payments: IFinancingSourceItem[], banksItems: IBankItem[]) =>
payments.map(({ sku, payment_settings: paymentSettings }) => {
  const paymentSettingsWithBankNames = paymentSettings.map(
    ({ code, ...restSettings }) => {
      const currentBankByCode = banksItems.find(
        (bank) => bank.points_bank_id === code
      );
      const currentBankName = currentBankByCode
        ? currentBankByCode.points_bank_name
        : code;

      return {
        ...restSettings,
        code,
        name: currentBankName,
      };
    }
  );

  return {
    sku,
    payment_settings: paymentSettingsWithBankNames,
  };
});

export const countSummary = (payments: Nullable<IFinancingSourceItem[]>) =>
payments?.reduce((result: IFinancingSourcePaymentSettingsItem[], current) => {
  let currentResult = result;

  if (isArrayHasItems(current.payment_settings)) {
    current.payment_settings.forEach((payment) => {
      const paymentIndex = currentResult.findIndex(
        ({ code }) => code === payment.code
      );

      if (paymentIndex === -1) {
        currentResult = [...currentResult, payment];
      } else {
        currentResult[paymentIndex] = {
          ...currentResult[paymentIndex],
          value: +currentResult[paymentIndex].value + +payment.value,
        };
      }
    });
  }

  return currentResult;
}, []);

export const processSources = (sources: ISources) =>
Object.keys(sources).reduce((result: ISources[], key) => {
  const value: string = sources[key];

  return value
    ? [
        ...result,
        {
          code: key,
          value,
        },
      ]
    : result;
}, []);
