import { FC, useMemo, useState } from "react";

import SubscriptionActivitiesList from "web/Layout/SubscriptionActivities";
import __ from "web/Layout/Translations";

import WarningIcon from "web/assets/icons/warning.svg";

import getRequiredActivities from "web/utils/page/product/subscription/getRequiredActivities";

import { ISubscriptionUserCurrent } from "web/types/Subscription";

import styles from "./requiredActivities.scss";

interface IRequiredActivitiesProps {
  hasReceiverFormsToFill: boolean;
  hasPdfBarcodes: boolean;
  data: ISubscriptionUserCurrent;
}

const setMessageType = (
  hasBenefitsWithReceiverForms: boolean,
  hasBenefitsWithPdfForms: boolean
) => {
  switch (true) {
    case hasBenefitsWithReceiverForms && hasBenefitsWithPdfForms:
      return "Pobierz, wypełnij, dostarcz dokumenty oraz poinformuj osoby, których adresy email podałeś, aby wypełniły formularze.";
    case hasBenefitsWithReceiverForms:
      return "Poinformuj osoby, których adresy e-mail podałeś, aby wypełniły formularze.";
    case hasBenefitsWithPdfForms:
      return "Pobierz, wypełnij, dostarcz dokumenty.";
    default:
      return null;
  }
};

const RequiredActivities: FC<IRequiredActivitiesProps> = ({
  hasReceiverFormsToFill,
  hasPdfBarcodes,
  data,
}) => {
  const [initialData, setInitialData] =
    useState<ISubscriptionUserCurrent>(data);

  const activities = useMemo(() => {
    if (!initialData) return [];
    return getRequiredActivities(initialData, false, setInitialData);
  }, [initialData]);
  const messageType = setMessageType(hasReceiverFormsToFill, hasPdfBarcodes);

  if (!data) return null;

  return (
    <div className="col col-9">
      <h2 className={styles.title}>{__("Wymagane czynności")}</h2>
      <article className="row">
        <div className={styles.icon}>
          <WarningIcon width={36} />
        </div>
        <div className={styles.messageWrapper}>
          <p className={styles.message}>{messageType && __(messageType)}</p>
          <p>
            {__("W przeciwnym razie aktywacja abonamentów nie będzie możliwa.")}
          </p>
        </div>
      </article>
      <SubscriptionActivitiesList activities={activities} />
    </div>
  );
};

export default RequiredActivities;
