// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tourism-root-pmo{display:block}.tourism-header-joL{padding-top:20px;padding-bottom:20px}.tourism-wide-YxK{padding-top:60px;padding-bottom:60px}`, "",{"version":3,"sources":["webpack://./web/Pages/Tourism/tourism.scss"],"names":[],"mappings":"AAIuB,kBACrB,aAAA,CAGF,oBACE,gBAAA,CACA,mBAAA,CAGF,kBACE,gBAAA,CACA,mBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n}\n\n.header {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.wide {\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `tourism-root-pmo`,
	"header": `tourism-header-joL`,
	"wide": `tourism-wide-YxK`
};
export default ___CSS_LOADER_EXPORT___;
