import { FC } from "react";

import Loading from "web/Layout/Common/Loading";
import SubscriptionLink from "web/Layout/SubscriptionLink";
import SubscriptionsDatesInfo from "web/Layout/SubscriptionsDatesInfo";
import __ from "web/Layout/Translations";

import convertToRomanNumeral from "web/utils/page/product/subscription/convertToRomanNumeral";
import getWindowDateFromBenefitGroups from "web/utils/page/product/subscription/getWindowDateFromBenefitGroups";

import { ProductInList } from "web/types/Product";
import {
  ChoiceButtonType,
  ISubscriptionUserInfoData,
} from "web/types/Subscription";

import { useAppContext } from "web/context/app";

import styles from "./subscriptionsGroups.scss";

interface ISubscriptionBoxProps {
  data: ISubscriptionUserInfoData;
  listingData: ProductInList[];
  isLoading: boolean;
  hasError: boolean;
}

// TODO: determine behaviour on query error
const SubscriptionBox: FC<ISubscriptionBoxProps> = ({
  data,
  listingData,
  isLoading,
  hasError,
}) => {
  const { isMobile } = useAppContext();
  const finalDates =
    data &&
    getWindowDateFromBenefitGroups(
      data.selectionWindowType,
      data.selectionWindowOpenToDate,
      data.subscriptionThresholdDay
    );

  const listElement =
    listingData?.length > 0 ? (
      <ul className={styles.list}>
        {listingData.map((eachBenefit) => {
          return (
            <li key={eachBenefit.id} className={styles.listItem}>
              {eachBenefit.name}
            </li>
          );
        })}
      </ul>
    ) : null;

  return (
    <div className={isMobile ? styles.groupsMobile : styles.groups}>
      <img src={data?.image} alt="woman" />
      <div className={styles.contentBox}>
        <h2>
          {__("Grupa")} {convertToRomanNumeral(data?.group)}
        </h2>
        <div className={styles.finalDatesBox}>
          {finalDates && <SubscriptionsDatesInfo finalDates={finalDates} />}
        </div>
        {isLoading ? <Loading className={styles.loader} /> : listElement}
        {hasError ? __("Błąd pobierania danych.") : null}
        <div className={styles.buttons}>
          <SubscriptionLink
            disabled={!data.isWindowOpen}
            type={ChoiceButtonType.CHANGE_SUBSCRIPTIONS}
            group={data?.group}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBox;
