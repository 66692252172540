import { useFormikContext } from "formik";

import { radioValues } from "../../constants";
import NewBenefitForm from "./newBenefitForm";
import ProductInformationForm from "./productInformationForm";

const ContactFields = () => {
  const { values } = useFormikContext<{ statement: string }>();
  const { statement } = values;
  const isNewBenefitForm = statement === radioValues.newBenefit;
  const isProductInformationForm = statement === radioValues.productInformation;

  return (
    <>
      {isNewBenefitForm && <NewBenefitForm />}
      {isProductInformationForm && <ProductInformationForm />}
    </>
  );
};

export default ContactFields;
