/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ICartItem, ICartSummaryItem } from "web/types/Cart";
import { ReducerPaymentMethod } from "web/types/Payment";
import { Nullable } from "web/types/Utils";

export interface IBuyNowState {
  cartId: Nullable<string>;
  paymentMethod: Nullable<string>;
  paymentMethods: Nullable<ReducerPaymentMethod[]>;
  total: number;
  totalCount: number;
  items: ICartItem[];
  summary: ICartSummaryItem[];
}

export const initialState: IBuyNowState = {
  cartId: null,
  paymentMethod: null,
  paymentMethods: null,
  total: 0,
  totalCount: 0,
  items: [],
  summary: [],
};

export const buyNowSlice = createSlice({
  name: "buyNow",
  initialState,
  reducers: {
    setBuyNowDetails: (state, action: PayloadAction<IBuyNowState>) => {
      return {
        ...state,
        ...action.payload,
        isDetailsGotten: true,
      };
    },
    setBuyNowCartId: (state, action: PayloadAction<string>) => {
      state.cartId = action.payload;
    },
    resetBuyNow: () => initialState,
  },
});

export const { setBuyNowDetails, resetBuyNow, setBuyNowCartId } =
  buyNowSlice.actions;

export default buyNowSlice.reducer;
