import { PureComponent } from "react";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

interface ISetScriptProps {
  js: string;
}

class SetScript extends PureComponent<ISetScriptProps> {
  safetyTimer: unknown = null;

  fireScript = () => {
    const { js } = this.props;

    if (!js) return;
    /* eslint-disable */
    try {
      const strictJs = "'use strict'; ".concat(js);
      const functionFromText = new Function(strictJs);

      functionFromText();
    } catch (error) {
      newRelicErrorReport(
        error,
        "web-app/web/Pages/PageGenerator/utils/setScript.js - 20"
      );
      console.log(error);
    }
    /* eslint-enable */
  };

  render = () => null;

  componentDidMount = () => {
    /**
     * Prevent multiple script calls
     * if CMS page is remounted several times
     */
    this.safetyTimer = setTimeout(this.fireScript, 200);
  };

  componentWillUnmount = () => {
    /**
     * Prevent multiple script calls
     * if CMS page is remounted several times
     */
    clearTimeout(this.safetyTimer as number);
  };
}

export default SetScript;
