import { FacetsStats } from "web/types/Product";

import { Identifiers } from "../useDataCached";

export interface ICachedItem<T> {
  id: string | number;
  timeExpiration: number;
  value: T;
  isShortProduct?: boolean;
  isIdsFacetsStatsData?: boolean;
}

const addTimeExpiration = <T extends Identifiers | FacetsStats>(
  items: T[],
  identifier: keyof Identifiers,
  isShortProduct?: boolean,
  TTL?: number
): ICachedItem<T>[] => {
  const ttl = TTL ?? +(process.env.CACHE_TTL || 0);
  const timeExpiration = Date.now() + ttl;

  return items.map((item) => ({
    id: item[identifier],
    timeExpiration,
    value: item,
    ...(isShortProduct ? { isShortProduct: true } : {}),
  }));
};

export default addTimeExpiration;
