import campaignGraphicsQuery from "web/queries/default/campaignGraphics.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { magentoUrlKey } from "web/constants/graphqlUrls";

import { ICampaignGraphics } from "web/types/CampaignGraphics";

import {
  addCampaignGraphicsEntries,
  removeCampaignGraphicsEntries,
  selectEntities,
} from "../campaignGraphics/campaignGraphicsSlice";
import useDataCached from "./useDataCached";

const mapData = new MapWrapper();

const useDataCachedCampaignGraphics = ({ ids }: { ids: number[] }) =>
  useDataCached<ICampaignGraphics>({
    ids,
    query: campaignGraphicsQuery,
    endpoint: magentoUrlKey,
    identifier: "graphic_id",
    addToCacheAction: addCampaignGraphicsEntries,
    removeFromCacheAction: removeCampaignGraphicsEntries,
    selectEntities,
    mapData,
  });

export default useDataCachedCampaignGraphics;
