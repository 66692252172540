import newRelicErrorReport from "../web/utils/system/essentials/newRelicErrorReport";

const logLocalStorageAvailability = () => {
  try {
    const testKey = "__test__";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
  } catch (e) {
    newRelicErrorReport(e, "no local storage");
  }
};

export default logLocalStorageAvailability;
