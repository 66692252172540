import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

const ASSETS_MANIFEST_FILENAME = "assets-manifest.json";

const fetchAssetsManifest = async (appName) => {
  return fetch(
    `${process.env.COMPONENTS_URL}/${appName}/${ASSETS_MANIFEST_FILENAME}`,
    {
      cache: "no-cache",
    }
  ).then((response) => response.json());
};

const getEntrypointFiles = (manifest) => {
  const { css = [], js = [] } = manifest.entrypoints.main.assets;

  return {
    css,
    js,
  };
};

export const generateCssLinks = (appName, css = []) => {
  if (!isArrayHasItems(css)) {
    return [];
  }

  return css.map((cssFile) => (
    <link
      key={cssFile}
      href={`${process.env.COMPONENTS_URL}/${appName}/${cssFile}`}
      rel="stylesheet"
    />
  ));
};

export const generateJsScripts = (appName, js = []) => {
  if (!isArrayHasItems(js)) {
    return [];
  }

  return js.map((jsFile) => (
    <script
      key={jsFile}
      src={`${process.env.COMPONENTS_URL}/${appName}/${jsFile}`}
      type="text/javascript"
    />
  ));
};

export const loadAppData = async (appName) => {
  const manifest = await fetchAssetsManifest(appName);
  const entrypoints = getEntrypointFiles(manifest);

  return { appName, entrypoints };
};

export default loadAppData;
