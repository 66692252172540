import { FC, useEffect, useState } from "react";

import MinusIcon from "web/assets/icons/minus.svg";
import PlusIcon from "web/assets/icons/plus.svg";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./plusMinusInput.scss";

interface IProductPlusMinusInputMobileProps {
  currentValue?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (val: number) => void;
}

const ProductPlusMinusInputMobile: FC<
  PropsWithClasses<IProductPlusMinusInputMobileProps>
> = ({
  currentValue = 1,
  minValue = 1,
  maxValue = 10,
  onChange = () => {},
  classes = {},
}) => {
  const [current, setCurrent] = useState(currentValue);

  const buttonClassName = `${classes.button} ${
    classes.buttonVariant ? classes.buttonVariant : ""
  }`;

  useEffect(() => {
    onChange(current);
  }, [current]);

  return (
    <div className={classes.root}>
      <button
        type="button"
        className={buttonClassName}
        disabled={current <= minValue}
        onClick={() => {
          setCurrent(current - 1);
        }}
        aria-label="Decrease"
      >
        <MinusIcon className={classes.icon} />
      </button>
      <input className={classes.input} value={current} readOnly />
      <button
        type="button"
        className={buttonClassName}
        disabled={current >= maxValue}
        onClick={() => {
          setCurrent(current + 1);
        }}
        aria-label="Increase"
      >
        <PlusIcon className={classes.icon} />
      </button>
    </div>
  );
};

export default classify<PropsWithClasses<IProductPlusMinusInputMobileProps>>(
  defaultClasses
)(ProductPlusMinusInputMobile);
