/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import { DOMESTIC_TOURISM } from "web/Pages/Customer/Content/OrderDetails/ProductDetails/ProductDetails.const";

import __ from "web/Layout/Translations";

import PlaceInfo from "web/Components/Common/PlaceInfo";

import getProvinceLabel from "web/utils/page/product/domesticTourism/getProvinceLabel";
import type { GroupedProduct } from "web/utils/page/product/universal/groupProducts";

import { IAttribute } from "web/types/Attributes";
import type { DomesticTourismProduct, MBProductType } from "web/types/Product";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import classes from "./tourismLocation.scss";

interface IProductTourismLocationProps {
  className: string;
  product:
    | GroupedProduct<MBProductType.ANIXE_TOURISM>["full"]
    | DomesticTourismProduct;
  short?: boolean;
}

const ProductTourismLocation: FC<IProductTourismLocationProps> = ({
  className,
  product,
  short = false,
}) => {
  let extractedValues = {};

  const { data: attributesData } = useDataCachedAttributes({
    ids: ["location_province"],
    // skip fetching attributes on success page, where province comes as label
    // @ts-ignore
    skip: typeof product.location_province === "string",
  }) as { data: IAttribute[] };

  // @ts-ignore
  if (product.mb_product_type === DOMESTIC_TOURISM) {
    const { offer_location } = product;
    const {
      city,
      street,
      house_number: houseNumber,
      flat_number: flatNumber,
      voivodeship,
      "e-mail": email,
      phone_number,
    } = offer_location || {};

    const composedStreet = `${street || ""}${
      street && houseNumber ? ` ${houseNumber}` : ""
    }${street && houseNumber && flatNumber ? `/${flatNumber}` : ""}`;

    const coposedVoivodeship = voivodeship
      ? `${__("woj.")} ${voivodeship}`
      : "";

    extractedValues = {
      city,
      street: composedStreet,
      voivodeship: coposedVoivodeship,
      phone_number,
      email,
    };
  } else {
    const {
      tourism_street: street,
      tourism_city: city,
      tourism_postal_code: postalCode,
      // @ts-ignore
      location_province: provinceCode,
    } = product;

    const provinceLabel = getProvinceLabel(attributesData, provinceCode);

    extractedValues = {
      street,
      city,
      postalCode,
      province: provinceLabel,
    };
  }

  const infoArray = Object.values(extractedValues).filter(
    (element) => !!element
  );

  switch (true) {
    // @ts-ignore
    case short && !!extractedValues.city: {
      // @ts-ignore
      const { city, province } = extractedValues || {};

      return (
        <span className={className || classes.root}>
          {city}
          {province && `, ${__(province)}`}
        </span>
      );
    }
    default: {
      return infoArray.length ? (
        <PlaceInfo
          address={infoArray.join(", ")}
          className={className || classes.root}
        />
      ) : null;
    }
  }
};

export default ProductTourismLocation;
