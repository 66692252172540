// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateBox-date-kJS{display:flex}.dateBox-date-kJS p{border:1px solid #93b5b5;border-radius:6px;padding:4px 11px;font-size:14px}.dateBox-date-kJS span{font-weight:bold}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard/Aside/PrepaidCardAside/DateBox/dateBox.scss"],"names":[],"mappings":"AAIuB,kBACrB,YAAA,CAEA,oBACE,wBAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CAGF,uBACE,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .date {\n  display: flex;\n\n  p {\n    border: 1px solid $cyan-light-4;\n    border-radius: 6px;\n    padding: 4px 11px;\n    font-size: 14px;\n  }\n\n  span {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `dateBox-date-kJS`
};
export default ___CSS_LOADER_EXPORT___;
