import DOMPurify from "dompurify";
import { FC, PropsWithChildren } from "react";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultStyles from "./description.scss";

interface IDescriptionProps {
  text: string;
  label: string;
  id: string;
  dataT1: string;
}

const Description: FC<
  PropsWithChildren<PropsWithClasses<IDescriptionProps>>
> = ({
  text,
  label,
  classes = {},
  id = "",
  dataT1 = "description",
  children,
}) => {
  const textClass = `reset-global-styles ${classes.text}`;

  const description = DOMPurify.sanitize(text, { ADD_ATTR: ["target"] });

  return (
    <div id={id} className={classes.root}>
      <span className={classes.label}>{label}</span>
      <div>{children}</div>
      <div
        className={textClass}
        data-t1={dataT1}
        data-t2={description}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </div>
  );
};

export default classify<PropsWithChildren<PropsWithClasses<IDescriptionProps>>>(
  defaultStyles
)(Description);
export { Description };
