import { Link } from "react-router-dom";

import Button from "web/Pages/PageGenerator/Button";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import classify from "web/classify";

import { FC, SyntheticEvent } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultClasses from "./element.scss";

interface IElementProps {
  imageSrc: string;
  url: string;
  button: string;
  header: string;
  description: string;
  buttonFontColor: string;
  buttonBackgroundColor: string;
  backgroundColor: string;
  descriptionFontColor: string;
  headerFontColor: string;
  isLarge?: boolean;
  isMobile: boolean;
  onClick?: (prop: SyntheticEvent) => void;
}

const PageGeneratorTripleElement: FC<PropsWithClasses<IElementProps>> = ({
  classes = {},
  imageSrc,
  url,
  button,
  header,
  description,
  buttonFontColor,
  buttonBackgroundColor,
  backgroundColor,
  descriptionFontColor,
  headerFontColor,
  isLarge = false,
  isMobile,
  onClick = () => {},
}) => {
  const rootStyles = {
    color: descriptionFontColor,
    backgroundColor,
  };

  const headerStyles = {
    color: headerFontColor,
  };

  const imageWidth = 750;
  const imageHeight = isLarge ? 650 : 300;

  return (
    <Link
      to={url}
      className={classes.root}
      style={rootStyles}
      data-t1="page_generator_triple_large"
      onClick={onClick}
    >
      {imageSrc && (
        <LazyLoadImage
          className={classes.image}
          src={imageSrc}
          alt={header}
          width={imageWidth}
          height={imageHeight}
        />
      )}
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {header && (
            <h2
              className={classes.title}
              style={headerStyles}
              data-t1="page_generator_triple_large_title"
            >
              {header}
            </h2>
          )}
          {!isMobile && description && (
            <div
              className={classes.description}
              data-t1="page_generator_triple_large_description"
            >
              {description}
            </div>
          )}
        </div>
        <Button
          onClick={() => null}
          className={classes.button}
          buttonFontColor={buttonFontColor}
          buttonBackgroundColor={buttonBackgroundColor}
        >
          {button}
        </Button>
      </div>
    </Link>
  );
};

export default classify<PropsWithClasses<IElementProps>>(defaultClasses)(PageGeneratorTripleElement);
