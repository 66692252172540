import { FC } from "react";
import { Link } from "react-router-dom";

import { IHelpCenterSpecificCategoryCategoryArticle } from "web/types/HelpCenter";
import { IStoreConfig } from "web/types/StoreConfig";

import classes from "./articleCard.scss";

export type HelpCenterCategoryArticleCardLink =
  `/${IStoreConfig["help_center_prefix"]}/${IHelpCenterSpecificCategoryCategoryArticle["url_key"]}.html${string}`;

interface IHelpCenterCategoryArticleCardProps {
  title: IHelpCenterSpecificCategoryCategoryArticle["title"];
  description: IHelpCenterSpecificCategoryCategoryArticle["short_description"];
  link: HelpCenterCategoryArticleCardLink;
}

const HelpCenterCategoryArticleCard: FC<
  IHelpCenterCategoryArticleCardProps
> = ({ title, description, link }) => {
  return (
    <Link className={classes.root} to={link}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.description}>{description}</div>
    </Link>
  );
};

export default HelpCenterCategoryArticleCard;
