import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import ArrowRightIcon from "web/assets/icons/arrow-right.svg";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import urls from "web/constants/urls";

import { BankItems } from "web/types/Banks";
import { FC } from "react";
import PointsEqualIcon from "../../../../../Components/Common/PointsEqualIcon";
import BanksList from "../Desktop/BanksList";
import banksListStyles from "../Desktop/BanksList/banksListMobile.scss";
import styles from "./myPoints.scss";

const webpStatus = canUseWebp();
const banksContainerClass = webpStatus
  ? styles.banksContainerWebp
  : styles.banksContainer;

interface IPageGeneratorMyPointsMobileProps {
  banks?: BankItems;
}

const PageGeneratorMyPointsMobile: FC<IPageGeneratorMyPointsMobileProps> = ({ banks = [] }) => {
  return (
    <div className={styles.root}>
      <div className={banksContainerClass}>
        <BanksList pointsBankList={banks} size={3} classes={banksListStyles} />
        <div className={styles.wrapper}>
          <Link to={urls.customer} className={styles.navLink}>
            {__("Przejdź do panelu")}
            <ArrowRightIcon className={styles.icon} />
          </Link>
          <PointsEqualIcon />
        </div>
      </div>
    </div>
  );
};

export default PageGeneratorMyPointsMobile;
