
import Banner from "web/Pages/PageGenerator/TousrismBanner/Banner";
import DomesticTourismForm, {
  DOMESTIC_TOURISM_FORM_VARIANTS,
} from "web/Pages/Tourism/DomesticTourismForm";

import CampaignGraphicsProvider from "web/Layout/CampaignGraphics/campaignGraphics";

import placements from "web/constants/campaignGraphics";

import { useAppContext } from "web/context/app";

import { FC } from "react";
import styles from "./tourismBanner.scss";

interface IPageGeneratorTourismBannerProps {
  description?: string;
  url?: string;
  imageSrc?: string;
  categoryUrl?: string;
  buttonTitle?: string;
  isButtonVisible?: boolean;
}

const PageGeneratorTourismBanner: FC<IPageGeneratorTourismBannerProps> = ({
  imageSrc = "",
  description = "",
  url = "",
  categoryUrl = "",
  buttonTitle = "",
  isButtonVisible = true,
}) => {
  const { isMobile } = useAppContext();

  return (
    <div
      className={
        isMobile
          ? styles.tourismContainerMobile
          : styles.tourismContainerDesktop
      }
      data-t1="page_generator_tourism_banner"
    >
      <div className={styles.wrapper}>
        <CampaignGraphicsProvider placement={placements.tourismBanner}>
          <DomesticTourismForm
            variant={DOMESTIC_TOURISM_FORM_VARIANTS.OFFER}
            categoryUrl={categoryUrl}
          />
        </CampaignGraphicsProvider>

        {!isMobile && (
          <Banner
            className={styles.banner}
            imageSrc={imageSrc}
            url={url}
            alt={description}
            buttonTitle={buttonTitle}
            isButtonVisible={isButtonVisible}
          />
        )}
      </div>
    </div>
  );
};

export default PageGeneratorTourismBanner;
