/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Field, useFormikContext } from "formik";
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import __ from "web/Layout/Translations";

import ClearInputFieldWrapper from "web/Components/Common/ClearInputFieldWrapper/clearInputFielWrapper";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { Nullable } from "web/types/Utils";

import { useGoogleContext } from "web/context/google.context";

import classes from "./googleSearch.scss";

interface IProductGoogleSearchMobileProps {
  disabled?: boolean;
  name: string;
  placeholder: string;
  initialValue: { placeId: string };
}

const ProductGoogleSearchMobile: FC<IProductGoogleSearchMobileProps> = ({
  disabled = false,
  name,
  placeholder = "",
  initialValue,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [predictionValues, setPredictionValues] = useState<
    Nullable<google.maps.places.AutocompletePrediction[]>
  >([]);
  const { setFieldValue, values } = useFormikContext();
  const timeoutRef = useRef<Nullable<NodeJS.Timeout>>(null);
  const { autocompleteService, geocoderService } = useGoogleContext();
  const onChangeHandler = (
    syntheticEvent: SyntheticEvent<HTMLInputElement>
  ) => {
    syntheticEvent.preventDefault();
    const nextValue =
      syntheticEvent &&
      syntheticEvent.currentTarget &&
      syntheticEvent.currentTarget.value;
    clearTimeout(timeoutRef.current!);
    setInputValue(nextValue);
    timeoutRef.current = setTimeout(() => {
      if (nextValue) {
        autocompleteService?.getPlacePredictions(
          {
            input: nextValue,
            componentRestrictions: {
              country: "pl",
            },
          },
          (predictions, status) => {
            if (status === "OK") {
              setOpenStatus(true);
              setPredictionValues(predictions);
            } else {
              setOpenStatus(true);
              setPredictionValues([]);
            }
          }
        );
      } else {
        setFieldValue(name, null);
        setOpenStatus(false);
      }
    }, 500);
  };
  const onKeyPressHandler = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === "Enter" &&
        openStatus &&
        isArrayHasItems(predictionValues) &&
        predictionValues[0] &&
        predictionValues[0].place_id
      ) {
        event.preventDefault();
        geocoderService?.geocode(
          {
            placeId: predictionValues[0].place_id,
          },
          (result, status) => {
            if (status === "OK" && isArrayHasItems(result) && result[0]) {
              setInputValue(result[0].formatted_address);
              setFieldValue(name, {
                lat: result[0].geometry.location.lat(),
                lng: result[0].geometry.location.lng(),
                placeId: predictionValues[0].place_id,
              });
              setOpenStatus(false);
            }
          }
        );
      }
    },
    [setInputValue, setFieldValue, setOpenStatus, openStatus, predictionValues]
  );
  const keypressEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpenStatus(false);
      }
    },
    [setOpenStatus]
  );
  const clickOutsideHandler = useCallback(
    (event: Event) => {
      if (
        event &&
        event.target &&
        typeof (event.target as HTMLElement).closest === "function" &&
        !(event.target as HTMLElement).closest(`.${classes.root}`)
      ) {
        setOpenStatus(false);
      }
    },
    [setOpenStatus]
  );

  useEffect(() => {
    if (openStatus) {
      document.addEventListener("keydown", keypressEscapeHandler);
      document.addEventListener("click", clickOutsideHandler);
    } else {
      document.removeEventListener("keydown", keypressEscapeHandler);
      document.removeEventListener("click", clickOutsideHandler);
    }

    return () => {
      document.removeEventListener("keydown", keypressEscapeHandler);
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [openStatus, clickOutsideHandler, keypressEscapeHandler]);

  useEffect(() => {
    if (initialValue && initialValue.placeId) {
      geocoderService?.geocode(
        {
          placeId: initialValue.placeId,
        },
        (result, status) => {
          if (
            status === "OK" &&
            isArrayHasItems(result) &&
            result[0] &&
            result[0].formatted_address
          ) {
            setInputValue(result[0].formatted_address);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!values[name]) {
      setInputValue("");
      setOpenStatus(false);
    }
    // @ts-ignore
  }, [values[name]]);

  const clearField = () => {
    setInputValue("");
    setFieldValue(name, null);
    setOpenStatus(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <ClearInputFieldWrapper value={inputValue} clearField={clearField}>
          <input
            className={classes.input}
            data-t1="geolocation_search_input"
            data-t2={name}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            value={inputValue}
            onChange={onChangeHandler}
            // @ts-ignore
            onKeyPress={onKeyPressHandler}
          />
        </ClearInputFieldWrapper>
      </div>
      {openStatus ? (
        <div className={classes.autocomplete}>
          {isArrayHasItems(predictionValues) ? (
            <ul className={classes.list}>
              {predictionValues.map((predictionValue) => {
                return (
                  <li className={classes.item} key={predictionValue.place_id}>
                    <button
                      className={classes.button}
                      type="button"
                      onClick={() => {
                        geocoderService?.geocode(
                          {
                            placeId: predictionValue.place_id,
                          },
                          (result, status) => {
                            if (
                              status === "OK" &&
                              isArrayHasItems(result) &&
                              result[0]
                            ) {
                              setInputValue(result[0].formatted_address);
                              setFieldValue(name, {
                                lat: result[0].geometry.location.lat(),
                                lng: result[0].geometry.location.lng(),
                                placeId: predictionValue.place_id,
                              });
                              setOpenStatus(false);
                            }
                          }
                        );
                      }}
                    >
                      {predictionValue.description}
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={classes.empty}>
              {__("Brak wyników wyszukiwania")}
            </div>
          )}
        </div>
      ) : null}
      <Field className="visually-hidden" type="hidden" name={name} />
    </div>
  );
};

export default ProductGoogleSearchMobile;
