// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aside-root-Xuc{height:100%;margin:0 0 60px}.aside-asideContainer-dVF{position:sticky;top:150px}@media(max-width: 1024px){.aside-asideContainer-dVF{padding:0 15px}}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard/Aside/aside.scss"],"names":[],"mappings":"AAIuB,gBACrB,WAAA,CACA,eAAA,CAGF,0BACE,eAAA,CACA,SAAA,CAEA,0BAJF,0BAKI,cAAA,CAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  height: 100%;\n  margin: 0 0 60px;\n}\n\n.asideContainer {\n  position: sticky;\n  top: 150px;\n\n  @media (max-width: 1024px) {\n    padding: 0 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `aside-root-Xuc`,
	"asideContainer": `aside-asideContainer-dVF`
};
export default ___CSS_LOADER_EXPORT___;
