import __ from "web/Layout/Translations";

import classes from "./subscriptions.scss";

const ProductQuicklistSubscriptions = () => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <strong className={classes.title}>{__("W trakcie realizacji")}</strong>
      </div>
    </div>
  );
};

export default ProductQuicklistSubscriptions;
