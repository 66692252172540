import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import WalletIcon from "web/assets/icons/wallet.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classify from "web/classify";

import { BankItems } from "web/types/Banks";
import { FC } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultStyles from "./banksListDesktop.scss";

interface IPageGeneratorBanksListDesktopProps {
  pointsBankList: BankItems;
  size: number;
}

const PageGeneratorBanksListDesktop: FC<PropsWithClasses<IPageGeneratorBanksListDesktopProps>> = ({ pointsBankList, size, classes = {} }) => {
  return isArrayHasItems(pointsBankList) ? (
    <ul className={classes.banksWrapper}>
      {pointsBankList?.slice(0, size).map((bankItem) => (
        <li key={bankItem.points_bank_id} className={classes.bankItemWrapper}>
          <div className={classes.wallet}>
            <WalletIcon className={classes.wallet__icon} />
          </div>
          <div className={classes.badge}>
            <strong>{bankItem.points_bank_name}</strong>
            <Price value={bankItem.balance} currency={__("pkt")} />
          </div>
        </li>
      ))}
    </ul>
  ) : null;
};

export default classify<PropsWithClasses<IPageGeneratorBanksListDesktopProps>>(defaultStyles)(PageGeneratorBanksListDesktop);
