import msAttributesQuery from "web/queries/ms/attributesByCodes.graphql";

import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { IAttribute } from "web/types/Attributes";

import {
  addAttributesEntries,
  removeAttributesEntries,
  selectEntities,
} from "../attributes/attributes";
import useDataCached from "./useDataCached";

const mapData = new MapWrapper();

const useDataCachedAttributes = ({
  ids,
  skip,
}: {
  ids: string[];
  skip?: boolean;
}) =>
  useDataCached<IAttribute>({
    ids,
    query: msAttributesQuery,
    addToCacheAction: addAttributesEntries,
    removeFromCacheAction: removeAttributesEntries,
    selectEntities,
    skip,
    identifier: "attribute_code",
    mapData,
  });

export default useDataCachedAttributes;
