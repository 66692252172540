// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-list-s6f{position:relative;flex-shrink:0;z-index:3;align-self:center;display:flex;align-items:center;justify-content:space-between}.products-item-VtN{width:376px;flex-shrink:0}.products-item-VtN:not(:first-child){margin-left:30px}.products-product-rkk{display:block;width:100%}.products-button-qO0{display:none}`, "",{"version":3,"sources":["webpack://./web/Pages/PageGenerator/TwoProducts/Products/products.scss"],"names":[],"mappings":"AAIuB,mBACrB,iBAAA,CACA,aAAA,CACA,SAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,mBACE,WAAA,CACA,aAAA,CAEA,qCACE,gBAAA,CAIJ,sBACE,aAAA,CACA,UAAA,CAGF,qBACE,YAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .list {\n  position: relative;\n  flex-shrink: 0;\n  z-index: 3;\n  align-self: center;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.item {\n  width: 376px;\n  flex-shrink: 0;\n\n  &:not(:first-child) {\n    margin-left: 30px;\n  }\n}\n\n.product {\n  display: block;\n  width: 100%;\n}\n\n.button {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `products-list-s6f`,
	"item": `products-item-VtN`,
	"product": `products-product-rkk`,
	"button": `products-button-qO0`
};
export default ___CSS_LOADER_EXPORT___;
