/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading/loading";
import ErrorBoundary from "web/Layout/ErrorBoundary";

import jsonParse from "web/utils/data/parser/string/jsonParse";

import { IParsedParameter } from "web/types/PageGenerator";
import { IProduct } from "web/types/Product";

import useDataCachedProductsDomesticTourism from "web/features/useDataCached/useDataCachedProductsDomesticTourism";
import useDataCachedProductsInList from "web/features/useDataCached/useDataCachedProductsInList";

import useSetGtmPromoItemsByPathname from "web/hooks/useSetGtmPromoItemsByPathname";
import TwoProducts from "./twoProducts";

interface IPageGeneratorTwoProductsContainerWithProductsProps {
  params: string;
  ids: number[];
  isAnixe: boolean;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorTwoProductsContainerWithProducts: FC<
  IPageGeneratorTwoProductsContainerWithProductsProps
> = ({
  params,
  ids,
  isMobile = false,
  listingPosition,
  listingAmount,
  isAnixe = false,
}) => {
  const getProductsCache = isAnixe
    ? useDataCachedProductsDomesticTourism
    : useDataCachedProductsInList;
  // @ts-ignore
  const { loading, error, data } = getProductsCache({
    ids,
  });

  useSetGtmPromoItemsByPathname(ids);

  const dataTruncatedToTwoProducts = data?.slice(0, 2);

  const {
    button,
    color_background: backgroundColor,
    color_font_header: headerFontColor,
    color_font_description: descriptionFontColor,
    color_font_button: buttonFontColor,
    color_background_button: buttonBackgroundColor,
    graphic_file: imageSrc,
    description,
    header,
    layout,
    url,
  } = useMemo<IParsedParameter>(() => jsonParse(params), [params]);

  if (loading) {
    return (
      <div style={{ height: 830 }}>
        <Loading />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  return (
    <TwoProducts
      isMobile={isMobile}
      backgroundColor={backgroundColor}
      descriptionFontColor={descriptionFontColor}
      imageSrc={imageSrc}
      header={header}
      description={description}
      button={button}
      url={url}
      layout={layout}
      headerFontColor={headerFontColor}
      buttonFontColor={buttonFontColor}
      buttonBackgroundColor={buttonBackgroundColor}
      data={dataTruncatedToTwoProducts as IProduct[]}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
    />
  );
};

interface IPageGeneratorTwoProductsContainerProps {
  params: string;
  isMobile: boolean;
  listingPosition: number;
  listingAmount: number;
}

const PageGeneratorTwoProductsContainer: FC<
  IPageGeneratorTwoProductsContainerProps
> = ({ params, isMobile, listingPosition, listingAmount }) => {
  const { product_id: productIds, anixe_product_id: anixeProductIds } =
    useMemo<IParsedParameter>(() => jsonParse(params), [params]);

  const ids = useMemo(() => {
    const productIdsSplitted = productIds?.length
      ? productIds.split(",").map((id) => +id)
      : [];
    const anixeIds = anixeProductIds?.length
      ? anixeProductIds.split(",").map((id) => +id)
      : [];

    return [...productIdsSplitted, ...anixeIds];
  }, [productIds, anixeProductIds]);

  return (
    <PageGeneratorTwoProductsContainerWithProducts
      params={params}
      isMobile={isMobile}
      ids={ids}
      isAnixe={!!anixeProductIds?.length}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
    />
  );
};

interface IPageGeneratorTwoProductsContainerErrorProps {
  params: string;
  isMobile: boolean;
  listingPosition?: number;
  listingAmount?: number;
}

const PageGeneratorTwoProductsContainerError: FC<
  IPageGeneratorTwoProductsContainerErrorProps
> = ({
  params,
  isMobile = false,
  listingPosition = null,
  listingAmount = null,
}) => {
  return (
    <ErrorBoundary>
      <PageGeneratorTwoProductsContainer
        params={params}
        isMobile={isMobile}
        listingPosition={listingPosition!}
        listingAmount={listingAmount!}
      />
    </ErrorBoundary>
  );
};

export default PageGeneratorTwoProductsContainerError;
