import { FC } from "react";

import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import ArticlesSection from "../../Shared/ArticlesSection";
import { IHelpCenterCategoryMobileProps } from "../mobile";
import classes from "./content.scss";

const HelpCenterCategoryMobileContent: FC<IHelpCenterCategoryMobileProps> = ({
  name,
  articles,
  prefix,
}) => {
  const { isParamLangEn } = useLangFromParams();
  return (
    <div className={classes.root}>
      <span className={classes.text}>
        {isParamLangEn
          ? "Questions in this category"
          : __("Pytania w kategorii:")}
      </span>
      <h1 className={classes.title}>{name}</h1>
      <ArticlesSection articles={articles} prefix={prefix} />
    </div>
  );
};

export default HelpCenterCategoryMobileContent;
