/* eslint-disable */

/**
 * replaceAll
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function replaceAllPolyfill(str, newStr) {
    // regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }

    // string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}

/**
 * matchAll
 */
if (!String.prototype.matchAll) {
  String.prototype.matchAll = function matchAllPolyfill(rx) {
    if (typeof rx === "string") rx = new RegExp(rx, "g");
    rx = new RegExp(rx);
    let cap = [];
    let all = [];
    while ((cap = rx.exec(this)) !== null) all.push(cap);
    return all;
  };
}
