import { number, oneOfType, shape, string } from "prop-types";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

import classify from "web/classify";

import defaultClasses from "./points.scss";

const Points = ({ classes, points, dataT1, currency }) => {
  const pointsText = currency || __("pkt");
  const pointsFormatted = formatNumber(points);

  return pointsFormatted ? (
    <strong
      className={classes.root}
      data-t1={`${dataT1}_value`}
      data-t2={pointsFormatted}
    >
      {pointsFormatted}
      <sup
        className={classes.text}
        data-t1={`${dataT1}_text`}
        data-t2={pointsText}
      >
        {pointsText}
      </sup>
    </strong>
  ) : (
    <strong className={classes.root}>
      <span style={{ fontSize: 29 }}>--,--</span>
      <sup className={classes.text}>{pointsText}</sup>
    </strong>
  );
};

Points.propTypes = {
  classes: shape({
    root: string,
    text: string,
  }).isRequired,
  points: oneOfType([string, number]),
  dataT1: string,
  currency: string,
};

Points.defaultProps = {
  points: 0,
  dataT1: "points_points_text",
  currency: "",
};

export default classify(defaultClasses)(Points);
