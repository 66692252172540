import { Redirect, useParams } from "react-router-dom";

import urls from "web/constants/urls";

import CheckoutPendingDomesticTourism from "./checkoutPendingDomesticTourism";

type Params = {
  orderId: string;
};

const CheckoutPendingDomesticTourismContainer = () => {
  const { orderId } = useParams<Params>();

  return orderId ? (
    <>
      <CheckoutPendingDomesticTourism orderId={orderId} />
    </>
  ) : (
    <Redirect to={urls.home} />
  );
};

export default CheckoutPendingDomesticTourismContainer;
