import { FC } from "react";

import { StringNumber } from "web/types/Utils";

import classes from "./totals.scss";

interface ICheckoutProductTotalsProps {
  className: string;
  main: StringNumber | JSX.Element;
  additional?: string | JSX.Element;
}

const CheckoutProductTotals: FC<ICheckoutProductTotalsProps> = ({
  className = "",
  main = "",
  additional = "",
}) => {
  return main || additional ? (
    <div className={className || classes.root} data-testid="totals-root">
      {main && (
        <div className={classes.main} data-t1="totals_main" data-t2={main}>
          {main}
        </div>
      )}
      {additional && <div className={classes.additional}>{additional}</div>}
    </div>
  ) : null;
};

export default CheckoutProductTotals;
