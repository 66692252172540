import { Button } from "@benefit-systems/common-components";
import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Reservation from "web/Pages/Checkout/TourismInternational/MobileSteps/Common/Reservation";

import Loading from "web/Layout/Common/Loading";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import PrintButton from "web/Components/Common/PrintButton";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import useWebViewInfo from "web/hooks/useWebViewInfo/useWebViewInfo";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { ITourismInternationalValues } from "web/utils/page/product/universal/groupProducts";
import parseOrderDetailResult from "web/utils/page/product/universal/parseOrderDetailResult";
import canUseWebp from "web/utils/system/DOM/media/canUseWebp";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";
import urls from "web/constants/urls";

import { IOrder, IOrderDetails, IParsedOrder } from "web/types/Order";
import { MBProductType } from "web/types/Product";
import { ITourismOrderItem } from "web/types/Tourism";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { useAppContext } from "web/context/app";

import OrderSummary from "./OrderSummary/index";
import Products from "./Products";
import TourismSuccess from "./TourismSuccess";
import classes from "./success.scss";

const getBannerClassName = (webpStatus: boolean, currentLanguage: string) => {
  if (currentLanguage === "en") {
    return webpStatus ? classes.bannerEngWebp : classes.bannerEng;
  }

  return webpStatus ? classes.bannerWebp : classes.banner;
};

const webpStatus = canUseWebp();
const currentLanguage = getLanguageCode();
const bannerClassName = getBannerClassName(webpStatus, currentLanguage);

const bannerReservationClassName = webpStatus
  ? classes.bannerReservation
  : classes.bannerReservationWebp;

const Success = memo(({ orderId }: { orderId: string }) => {
  const { isMobile } = useAppContext();
  const { askForReview } = useWebViewInfo();
  const [order, setOrder] =
    useState<Nullable<IParsedOrder<MBProductType>>>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderDetail = (await request(
          restUrls.successOrderDetails.replace(":orderId", orderId)
        )) as IOrderDetails<MBProductType> | IOrder<MBProductType>;
        setOrder(
          parseOrderDetailResult(orderDetail) as IParsedOrder<MBProductType>
        );
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/Pages/Checkout/Success/success.js - 68"
        );
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      if (window.ReactNativeWebView) askForReview();
    };
  }, []);

  const isAnixeTourism =
    isArrayHasItems(order?.items) &&
    order?.items?.some(
      (item) => item && item.mb_product_type === "anixe_tourism"
    );

  const isInternationalTourism =
    isArrayHasItems(order?.items) &&
    order?.items.some(
      (item) =>
        (item &&
          item.mb_product_type === "international_tourism_travelplanet") ||
        item.mb_product_type === "international_tourism_other"
    );

  const tourismOfferData = (
    order?.items[0] as ITourismOrderItem & {
      tourism_offer_data: ITourismInternationalValues;
    }
  )?.tourism_offer_data;

  return order ? (
    <div className={classes.root}>
      <Link to={urls.home}>
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className={classes.closeButton}
          value="Wróć do strony głównej"
        />
      </Link>

      <div className={classes.order}>
        <div
          className={
            isAnixeTourism ? bannerReservationClassName : bannerClassName
          }
        />
        <h1 className={classes.orderHeader}>{__("Dziękujemy!")}</h1>
        <ErrorBoundary>
          <OrderSummary
            order={order}
            isAnixeTourism={isAnixeTourism as boolean}
          />
        </ErrorBoundary>
      </div>
      <div className={classes.wrapper}>
        <div className="col col-9">
          {isAnixeTourism ? (
            <div className={classes.headerWrapper}>
              <h2
                className={classes.header}
                data-t1="success_page_details_header"
              >
                {__("Szczegóły zamówienia")}
              </h2>
              <PrintButton
                className={classes.print}
                label={__("Drukuj stronę")}
              />
            </div>
          ) : (
            <h2
              className={classes.header}
              data-t1="success_page_details_header"
            >
              {__("Szczegóły zamówienia")}
            </h2>
          )}
          <ErrorBoundary>
            <Products items={order.items} />
          </ErrorBoundary>
          {isInternationalTourism && isMobile && tourismOfferData && (
            <Reservation
              values={tourismOfferData as ITourismInternationalValues}
              removeBg
            />
          )}
          {isAnixeTourism ? (
            <ErrorBoundary>
              <TourismSuccess order={order} />
            </ErrorBoundary>
          ) : null}
          <div className={`${classes.back} nonPrintable`}>
            <Button
              to={urls.customerOrders}
              dataT1="success_page__button_continue_2"
            >
              {__("Przejdź do Moich zamówień")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
});

export default withScrollToTop(Success);
