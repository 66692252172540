import { Redirect, useLocation, useParams } from "react-router-dom";

import GtmEvent from "web/Layout/Gtm/GtmEvent";

import urls from "web/constants/urls";

import getPageReferrer from "web/utils/system/GTM/getPageReferrer";
import { useMemo } from "react";
import Success from "./success";

type Params = {
  orderId: string;
};

const SuccessContainer = () => {
  const { orderId } = useParams<Params>();
  const location = useLocation();

  const gtmOptions = useMemo(() => (
    { cgroup1: "Koszyk", cgroup2: "Złożono zamówienie", page_referrer: getPageReferrer(location) }
  ), [location, getPageReferrer]);

  return orderId ? (
    <>
      <Success orderId={orderId} />
      <GtmEvent options={gtmOptions} withUrl />
    </>
  ) : (
    <Redirect to={urls.home} />
  );
};

export default SuccessContainer;
