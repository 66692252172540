import { FC, ReactNode } from "react";

import classes from "./asideContainer.scss";

interface AsideContainerProps {
  children: [ReactNode, ReactNode];
}

const AsideContainer: FC<AsideContainerProps> = ({ children }) => {
  return (
    <div className={classes.root}>
      <div className={classes.top}>{children[0]}</div>
      <div className={classes.bottom}>{children[1]}</div>
    </div>
  );
};

export default AsideContainer;
