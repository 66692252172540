import { Button } from "@benefit-systems/common-components";
import { FC } from "react";

import __ from "web/Layout/Translations";

import urls from "web/constants/urls";

import classes from "./reservationPending.scss";

const ReservationPending: FC = () => {
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{__("Status")}:</h2>
      <p className={classes.subtitle}>
        {__("Oczekuje na potwierdzenie rezerwacji")}
      </p>
      <p className={classes.paragraph}>
        {__("Twoja rezerwacja oczekuje na potwierdzenie z obiektu.")}
        <br />
        {__("O potwierdzeniu rezerwacji poinformujemy Cię mailowo. ")}
        <br />
        {__(
          "Status rezerwacji możesz również sprawdzić przechodząc do Moich Zamówień."
        )}
        <br />
      </p>

      <div className={`${classes.buttonWrapper} nonPrintable`}>
        <Button to={urls.customerOrders} variant="primary">
          {__("Moje zamówienia")}
        </Button>
      </div>
    </div>
  );
};

export default ReservationPending;
