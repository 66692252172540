/* eslint-disable import/no-extraneous-dependencies, prefer-destructuring  */
import {
  Middleware,
  configureStore,
  isRejected,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";

import middleware from "web/middleware";

import api from "./features/api/apiSlice";
import graphQLApi from "./features/api/graphQLApiSlice";
import { setNotificationError } from "./features/app/appSlice";
import persistedReducer from "./reducers/persist";
import newRelicErrorReport from "./utils/system/essentials/newRelicErrorReport";
import isErrorException from "./utils/system/redux/isErrorException";

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) && !isErrorException(action)) {
    console.error("REJECTED QUERY WITH VALUE", action);
    const errorData = {
      endpointName: action.meta.arg.endpointName || "",
      error: action.payload,
      status: action.payload?.status,
      args: action.meta.arg?.originalArgs,
    };
    let message =
      errorData?.error?.data?.message ||
      errorData?.error?.message ||
      "Coś poszło nie tak";

    if (message.includes("Message:\n\n  ")) {
      message = message.split("Message:\n\n  ")[1];
    }

    store.dispatch(
      setNotificationError({
        message,
      })
    );
    newRelicErrorReport(
      errorData.error,
      `endpointName: ${errorData.endpointName} - (REJECTED WITH VALUE), status: ${errorData.status}, args: ${errorData.args}`
    );
  } else if (isRejected(action) && !isErrorException(action)) {
    console.error("REJECTED QUERY WITHOUT VALUE", action);
    const errorData = {
      endpointName: action?.meta?.arg?.endpointName || "",
      error: action?.error || "no error provided",
      status: action.payload?.status,
      args: action.meta.arg?.originalArgs,
    };

    let message = errorData.error?.message || "Coś poszło nie tak";

    if (message.includes("Message:\n\n  ")) {
      message = message.split("Message:\n\n  ")[1];
    }

    store.dispatch(
      setNotificationError({
        message,
      })
    );
    newRelicErrorReport(
      errorData.error,
      `endpointName: ${errorData.endpointName} - (REJECTED WITHOUT VALUE), status: ${errorData.status}, args: ${errorData.args}`
    );
  }

  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(api.middleware)
      .concat(graphQLApi.middleware)
      .concat(rtkQueryErrorLogger),
  enhancers: [middleware],
});

setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;
declare module "react-redux" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends RootState {}
}

export type AppDispatch = typeof store.dispatch;

export type Store = typeof store;

export const persistor = persistStore(store);

export const flushReduxPersistCache = async () => {
  persistor.pause();
  await persistor.flush();
  return persistor.purge();
};
