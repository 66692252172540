import { Field, useFormikContext } from "formik";
import { FC } from "react";

import __ from "web/Layout/Translations";

import ClearInputFieldWrapper from "web/Components/Common/ClearInputFieldWrapper";
import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import { tourismObjectField } from "../domesticTourismFormFields";
import { IDomesticTourismFormValues } from "../domesticTourismFormTypes";
import styles from "./tourismObject.scss";

interface IDomesticTourismFormTourismObjectProps {
  field: typeof tourismObjectField;
}

const DomesticTourismFormTourismObject: FC<
  IDomesticTourismFormTourismObjectProps
> = ({ field }) => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<IDomesticTourismFormValues>();
  const objectValue = values && values[field.name as keyof typeof values];
  const isError =
    errors[field.name as keyof typeof errors] &&
    touched[field.name as keyof typeof touched];

  const clearField = () => {
    if (field.name) {
      setFieldValue(field.name, "");
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.fieldset}>
        <strong className={styles.legend}>{__("Szukaj po obiektach")}</strong>
        <ClearInputFieldWrapper
          value={objectValue as string}
          clearField={clearField}
        >
          <Field
            className={`${styles.input} ${isError ? styles.error : ""}`}
            name={field.name}
            type={field.type}
            placeholder={field.placeholder}
            value={objectValue || ""}
          />
        </ClearInputFieldWrapper>
      </div>
      {isError && <ValidationErrorMessage name={field.name} />}
    </div>
  );
};

export default DomesticTourismFormTourismObject;
