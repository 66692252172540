import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { FacetsStats } from "web/types/Product";

import { RootState } from "web/store";

import { ICachedItem } from "../useDataCached/utils/addTimeExpiration";

const facetsStatsAdapter = createEntityAdapter<ICachedItem<FacetsStats>>();

const facetsStatsSlice = createSlice({
  name: "facetsStats",
  initialState: facetsStatsAdapter.getInitialState(),
  reducers: {
    addFacetsStatsEntries: facetsStatsAdapter.addMany,
    removeFacetsStatsEntries: facetsStatsAdapter.removeMany,
  },
});

export const { addFacetsStatsEntries, removeFacetsStatsEntries } =
  facetsStatsSlice.actions;

export const { selectEntities } = facetsStatsAdapter.getSelectors<RootState>(
  (state) => state.facetsStats
);

export const facetsStatsSliceName = facetsStatsSlice.name;

export default facetsStatsSlice.reducer;
