import { useEffect } from "react";

import type { Nullable } from "web/types/Utils";

interface IUseScrollToSecondRoomArgs {
  peopleWrapperElement: Nullable<HTMLDivElement>;
  tourismRoomsLength: number;
  modalElement: Nullable<HTMLDivElement>;
}

const useScrollToSecondRoom = ({
  peopleWrapperElement,
  tourismRoomsLength,
  modalElement,
}: IUseScrollToSecondRoomArgs) => {
  useEffect(() => {
    if (tourismRoomsLength !== 2) return;
    const { bottom } = peopleWrapperElement?.getBoundingClientRect() || {};
    const height = window.ReactNativeWebView
      ? window.screen.availHeight
      : window.innerHeight;

    if (modalElement) {
      modalElement.scrollTo({
        top: (bottom as number) + modalElement.scrollTop - height - 80,
        behavior: "smooth",
      });
    }
  }, [peopleWrapperElement, modalElement, tourismRoomsLength]);
};

export default useScrollToSecondRoom;
