// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-root-FWd{display:flex;flex-wrap:wrap;margin:-25px -15px}.list-item-rhb{width:33.333%;padding:25px 15px;display:flex;flex-direction:column}.list-item-rhb>*{flex-grow:1}@media print{.list-root-FWd{display:none}}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/CodeList/Desktop/List/list.scss"],"names":[],"mappings":"AAIuB,eACrB,YAAA,CACA,cAAA,CACA,kBAAA,CAGF,eACE,aAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CAEA,iBACE,WAAA,CAIJ,aACE,eACE,YAAA,CAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: flex;\n  flex-wrap: wrap;\n  margin: -25px -15px;\n}\n\n.item {\n  width: 33.333%;\n  padding: 25px 15px;\n  display: flex;\n  flex-direction: column;\n\n  & > * {\n    flex-grow: 1;\n  }\n}\n\n@media print {\n  .root, {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `list-root-FWd`,
	"item": `list-item-rhb`
};
export default ___CSS_LOADER_EXPORT___;
