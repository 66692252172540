// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldContainer-fieldContainer-h8b{display:grid;grid-template-columns:1fr auto}.fieldContainer-fieldContainer-h8b p{margin-bottom:0;font-weight:bold;font-size:14px}.fieldContainer-label-xwU{margin-top:15px}.fieldContainer-fieldInnerContainer-eaP{display:flex}.fieldContainer-currency-dFx{margin-top:15px;padding-left:10px}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard/Aside/PrepaidCardAside/FieldContainer/fieldContainer.scss"],"names":[],"mappings":"AAIuB,mCACrB,YAAA,CACA,8BAAA,CAEA,qCACE,eAAA,CACA,gBAAA,CACA,cAAA,CAIJ,0BACE,eAAA,CAGF,wCACE,YAAA,CAGF,6BACE,eAAA,CACA,iBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .fieldContainer {\n  display: grid;\n  grid-template-columns: 1fr auto;\n\n  p {\n    margin-bottom: 0;\n    font-weight: bold;\n    font-size: 14px;\n  }\n}\n\n.label {\n  margin-top: 15px;\n}\n\n.fieldInnerContainer {\n  display: flex;\n}\n\n.currency {\n  margin-top: 15px;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldContainer": `fieldContainer-fieldContainer-h8b`,
	"label": `fieldContainer-label-xwU`,
	"fieldInnerContainer": `fieldContainer-fieldInnerContainer-eaP`,
	"currency": `fieldContainer-currency-dFx`
};
export default ___CSS_LOADER_EXPORT___;
