import { array, func, node, oneOf, oneOfType, shape, string } from "prop-types";
import { createContext } from "react";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

export const TourismObjectContext = createContext(null);

const OrderDetailsAnixeProvider = ({ children }) => {
  const { loading, error, data } = useDataCachedAttributes({
    ids: ["tourism_object_type", "tourism_hotel_category"],
  });

  return (
    <TourismObjectContext.Provider value={{ loading, error, data }}>
      {children}
    </TourismObjectContext.Provider>
  );
};

OrderDetailsAnixeProvider.propTypes = {
  children: oneOfType([node, string, array, func, shape({}), oneOf([null])])
    .isRequired,
};

export default OrderDetailsAnixeProvider;
