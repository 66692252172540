import { FC, PropsWithChildren } from "react";
import InfiniteScroll from "./infiniteScroll";

type Fn = () => unknown;

interface IInfiniteScrollWrapperProps {
  next: Fn;
  hasMore: boolean;
  scrollThreshold?: number | string;
  dataLength: number;
  isHomePage: boolean;
}

const InfiniteScrollWrapper: FC<PropsWithChildren<IInfiniteScrollWrapperProps>> = ({
  dataLength,
  next,
  scrollThreshold,
  hasMore,
  children,
  isHomePage,
}) => {
  return isHomePage ? (
    <InfiniteScroll
      dataLength={dataLength}
      next={next}
      style={{
        overflow: "hidden",
      }}
      scrollThreshold={scrollThreshold}
      hasMore={hasMore}
      loader={null}
    >
      {children}
    </InfiniteScroll>
  ) : (
    <>{children}</>
  );
};

export default InfiniteScrollWrapper;
