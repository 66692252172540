/* eslint-disable import/prefer-default-export */
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IEmployeeConfig } from "web/types/Employee";
import { Nullable } from "web/types/Utils";

/**
 * Function that returns adequate data depending on subscription's group.
 *
 * @param {string | string[]} fieldNames - fieldNames to return from config object
 * @param {string} benefitGroupType - subscription's group.
 * @param {IEmployeeConfig?} employeeConfig - empployee configuration object.
 */

export const getEmployeeFieldsByGroupType = (
  fieldNames: string | string[],
  benefitGroupType: string,
  employeeConfig?: IEmployeeConfig
) => {
  if (typeof employeeConfig === "undefined") return {};

  if (typeof fieldNames === "string") {
    const field = benefitGroupType === "2" ? `${fieldNames}UMS` : fieldNames;
    return employeeConfig[field as keyof typeof employeeConfig];
  }

  const result: { [key: string]: Nullable<string> } = {};

  if (isArrayHasItems(fieldNames)) {
    fieldNames.forEach((fieldName) => {
      const field = benefitGroupType === "2" ? `${fieldName}UMS` : fieldName;
      result[fieldName] = employeeConfig[
        field as keyof typeof employeeConfig
      ] as Nullable<string>;
    });
  }
  return result;
};
