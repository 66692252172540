import { motion } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";

import Button from "web/Pages/PageGenerator/Button";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import __ from "web/Layout/Translations";

import DropArrow from "web/Components/Common/DropArrow/dropArrow";

import { Nullable } from "web/types/Utils";
import classesDesktop from "./foldableDesktop.scss";
import classesMobile from "./foldableMobile.scss";

const transitionConfig = { duration: 0.2 };
const titleVariants = {
  hide: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: "auto",
    opacity: 1,
  },
};

interface IPageGeneratorSingleFoldableProps {
  headerFontColor: string;
  backgroundColor: string;
  descriptionFontColor: string;
  buttonFontColor: string;
  buttonBackgroundColor: string;
  imageSrc: string;
  height: Nullable<string>;
  header: string;
  url: string;
  layout: string;
  button: string;
  description: string;
  isMobile: boolean;
}

const PageGeneratorSingleFoldable: FC<IPageGeneratorSingleFoldableProps> = ({
  isMobile = false,
  headerFontColor,
  backgroundColor,
  descriptionFontColor,
  buttonFontColor,
  buttonBackgroundColor,
  imageSrc,
  height,
  header,
  url,
  layout,
  button,
  description,
}) => {
  const [isOpen, toggleOpen] = useState(true);
  const classes = isMobile ? classesMobile : classesDesktop;
  const sectionClassName = isMobile
    ? classes.root
    : `${classes.root} container`.trim();
  const blockStyles = {
    color: descriptionFontColor,
    backgroundColor,
  };
  const imageBoxClassName = height ? classes.imageBoxLimited : classes.imageBox;
  const wrapperClassName =
    layout === "right_hand" ? classes.wrapper : classes.wrapperReverse;
  const headerClassName = isOpen ? classes.header : classes.headerClosed;
  const buttonText = isOpen ? __("Schowaj") : __("Czytaj więcej");
  const toggleHandler = useCallback(() => {
    toggleOpen((status) => !status);
  }, [toggleOpen]);
  const wrapperVariants = useMemo(
    () => ({
      open: {
        height: height ? `${height}px` : "auto",
        opacity: 1,
      },
      closed: {
        height: 0,
        opacity: 0,
      },
    }),
    [height]
  );

  const titleStyles = {
    color: headerFontColor,
  };

  return (
    <section className={sectionClassName} data-t1="pageGeneratorSingle">
      <div className={classes.row} style={blockStyles}>
        <header className={headerClassName}>
          <motion.strong
            animate={isOpen ? "hide" : "visible"}
            initial="hide"
            variants={titleVariants}
            transition={transitionConfig}
            className={classes.title}
            style={titleStyles}
            data-t1="pageGeneratorSingle__header"
          >
            {header}
          </motion.strong>
          <div className={classes.actions}>
            <Button
              data-t1="pageGeneratorSingle__buttonToggle"
              className={classes.toggleButton}
              buttonFontColor={buttonFontColor}
              buttonBackgroundColor={buttonBackgroundColor}
              title={buttonText}
              onClick={toggleHandler}
            >
              <span className={classes.toggleButtonText}>{buttonText}</span>
              <DropArrow
                className={classes.toggleButtonArrow}
                open={!isOpen}
                size="medium"
              />
            </Button>
            <Button
              data-t1="pageGeneratorSingle__buttonToggle"
              className={classes.arrow}
              type="button"
              onClick={toggleHandler}
            >
              <DropArrow open={!isOpen} size="large" />
            </Button>
          </div>
        </header>
        <motion.div
          className={wrapperClassName}
          animate={isOpen ? "open" : "closed"}
          initial="open"
          variants={wrapperVariants}
          transition={transitionConfig}
        >
          <div className={classes.content}>
            <h2
              className={classes.title}
              style={titleStyles}
              data-t1="pageGeneratorSingle__title"
            >
              {header}
            </h2>
            <div
              className={classes.description}
              data-t1="pageGeneratorSingle__description"
            >
              {description}
            </div>
            <Button
              data-t1="pageGeneratorSingle__url"
              to={url}
              className={classes.button}
              buttonFontColor={buttonFontColor}
              buttonBackgroundColor={buttonBackgroundColor}
            >
              {button}
            </Button>
          </div>
          <div
            className={imageBoxClassName}
            data-t1="pageGeneratorSingle__image"
          >
            {imageSrc && (
              <LazyLoadImage
                className={classes.image}
                src={imageSrc}
                alt={header}
              />
            )}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default PageGeneratorSingleFoldable;
