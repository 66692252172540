import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

declare global {
  interface Window {
    featureFlags: IFeatureFlag[];
  }
}

type FeatureFlagsProviderProps = {
  options: {
    apiUrl: string;
  };
};

export interface IFeatureFlag {
  env: string;
  name: string;
  active: boolean;
}

const defaultFeatureFlags: IFeatureFlag[] = [];

const FeatureFlagsContext = createContext(defaultFeatureFlags);

const FeatureFlagsProvider: FC<
  PropsWithChildren<FeatureFlagsProviderProps>
> = ({ children, options }) => {
  const [features, setFeatures] = useState<IFeatureFlag[]>(defaultFeatureFlags);
  useEffect(() => {
    if (options?.apiUrl) {
      const fetchFeatures = async () => {
        const res = await fetch(`${options.apiUrl}`);
        const data = await res.json();
        setFeatures(data);
        window.featureFlags = data;
      };

      fetchFeatures();
    }
  }, [options.apiUrl]);

  return (
    <FeatureFlagsContext.Provider value={features}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlagsContext = () =>
  useContext(FeatureFlagsContext) as IFeatureFlag[];

export { FeatureFlagsProvider, useFeatureFlagsContext };
