import { useQuery } from "@apollo/client";
import { FC } from "react";
import { Link } from "react-router-dom";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import helpCenterCategory from "web/queries/default/helpCenterCategory.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IHelpCenterArticleArticle } from "web/types/HelpCenter";

import { IHelpCenterDesktopArticleAsideProps } from "../aside";
import classes from "./linksList.scss";

interface IHelpCenterArticleDesktopAsideLinksListContainerProps {
  prefix: IHelpCenterDesktopArticleAsideProps["prefix"];
  links: IHelpCenterArticleArticle[];
}

const HelpCenterArticleDesktopAsideLinksList: FC<
  IHelpCenterArticleDesktopAsideLinksListContainerProps
> = ({ links, prefix }) => {
  const { langLinkSuffix } = useLangFromParams();

  return (
    <ul>
      {links?.map(({ id, title, url_key: url }) => {
        const to = `/${prefix}/${url}.html${langLinkSuffix}`;
        return (
          <li key={id} className={classes.item}>
            <Link className={classes.link} to={to}>
              {title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const HelpCenterArticleDesktopAsideLinksListContainer: FC<
  IHelpCenterDesktopArticleAsideProps
> = ({ categories, prefix }) => {
  const { languageFromParam } = useLangFromParams();

  const { error, loading, data } = useQuery(helpCenterCategory, {
    variables: {
      categoryId: categories,
      lang: languageFromParam || getLanguageCode(),
    },
  });

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <Loading />;
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Pages/HelpCenterArticle/Desktop/Aside/LinksList/linksList.js - 51"
      );
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    default: {
      return data &&
        data.helpCenterCategory &&
        data.helpCenterCategory.category[0].articles[0] ? (
        <HelpCenterArticleDesktopAsideLinksList
          links={data.helpCenterCategory.category[0].articles}
          prefix={prefix}
        />
      ) : null;
    }
  }
};

export default HelpCenterArticleDesktopAsideLinksListContainer;
