import { FC, PropsWithChildren } from "react";

import { PropsWithClasses } from "web/types/Common";

import { useAppContext } from "web/context/app";

import Desktop from "./Desktop/description.expanded";
import Mobile from "./Mobile/description";

interface IProductDescriptionContainerProps {
  text: string;
  label: string;
  dataT1?: string;
  id?: string;
  isSmall?: boolean;
  visibleHeight?: number;
  closeHeight?: number;
}

const ProductDescriptionContainer: FC<
  PropsWithChildren<PropsWithClasses<IProductDescriptionContainerProps>>
> = ({
  text,
  label,
  classes = {},
  isSmall = false,
  id = "",
  dataT1 = "description",
  children = "",
  visibleHeight = 75,
  closeHeight = 0,
}) => {
  const { isMobile } = useAppContext();
  return (
    <>
      {isMobile ? (
        <Mobile
          text={text}
          label={label}
          classes={classes}
          isSmall={isSmall}
          id={id}
          dataT1={dataT1}
          visibleHeight={visibleHeight}
          closeHeight={closeHeight}
        >
          {children}
        </Mobile>
      ) : (
        <Desktop
          text={text}
          label={label}
          classes={classes}
          id={id}
          dataT1={dataT1}
        >
          {children}
        </Desktop>
      )}
    </>
  );
};

export default ProductDescriptionContainer;
