// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpCenterArticles-container-SCL{margin-top:40px}.helpCenterArticles-header-Xpk{font-size:44px;margin-bottom:30px}.helpCenterArticles-title-I5Z{margin-bottom:32px;font-size:44px}.helpCenterArticles-wrapper-ljX{width:60%;margin:0 auto;min-width:800px}.helpCenterArticles-wrapper-ljX strong{top:-10px;margin-left:15px}`, "",{"version":3,"sources":["webpack://./web/Pages/HelpCenterCategory/Desktop/helpCenterArticles.scss"],"names":[],"mappings":"AAIuB,kCACrB,eAAA,CAEF,+BACE,cAAA,CACA,kBAAA,CAGF,8BACE,kBAAA,CACA,cAAA,CAGF,gCACE,SAAA,CACA,aAAA,CACA,eAAA,CACA,uCACE,SAAA,CACA,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .container {\n  margin-top: 40px;\n}\n.header {\n  font-size: 44px;\n  margin-bottom: 30px;\n}\n\n.title {\n  margin-bottom: 32px;\n  font-size: 44px;\n}\n\n.wrapper {\n  width: 60%;\n  margin: 0 auto;\n  min-width: 800px;\n  :global(strong) {\n    top: -10px;\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `helpCenterArticles-container-SCL`,
	"header": `helpCenterArticles-header-Xpk`,
	"title": `helpCenterArticles-title-I5Z`,
	"wrapper": `helpCenterArticles-wrapper-ljX`
};
export default ___CSS_LOADER_EXPORT___;
