import { FC, PropsWithChildren } from "react";

import HelpCenterArticleContent from "../../Shared/Content/content";
import HelpCenterArticleHeader from "../../Shared/Header/header";
import { IHelpCenterArticleWithId } from "../../helpCenterArticle";
import classes from "./content.scss";

interface IHelpCenterArticleMobileContentProps {
  title: IHelpCenterArticleWithId["title"];
  content: IHelpCenterArticleWithId["content"];
}

const HelpCenterArticleMobileContent: FC<
  PropsWithChildren<IHelpCenterArticleMobileContentProps>
> = ({ content, title, children }) => (
  <article className={classes.root}>
    <HelpCenterArticleHeader>{title}</HelpCenterArticleHeader>
    <HelpCenterArticleContent content={content} />
    {children}
  </article>
);

export default HelpCenterArticleMobileContent;
