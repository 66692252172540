import { Button } from "@benefit-systems/common-components";

import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import classes from "./searchSection.scss";

const HelpCenterMobileSearchSection = () => {
  const webpStatus = canUseWebp();
  const imageClass = webpStatus ? classes.imageWebp : classes.image;
  const { isParamLangEn } = useLangFromParams();

  return (
    <div className={classes.root}>
      <div className={imageClass} />
      <div className={classes.wrapper}>
        <div className={classes.section}>
          <div className={classes.title}>
            {isParamLangEn
              ? "You didn't find the answer"
              : __("Nie znalazłeś odpowiedzi")}
            ?
          </div>
          <Button size="thin" to="/contact-form">
            {isParamLangEn ? "Contact us" : __("Skontaktuj się")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterMobileSearchSection;
