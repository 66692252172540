/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Field } from "formik";
import { FC } from "react";

import __ from "web/Layout/Translations";

import { names } from "../../domesticTourismFormFields";
import classes from "./child.scss";

interface IDomesticTourismFormRoomChildProps {
  childAge: string;
  roomIndex: number;
  childIndex: number;
}

const DomesticTourismFormRoomChild: FC<IDomesticTourismFormRoomChildProps> = ({
  childAge,
  roomIndex,
  childIndex,
}) => {
  return (
    <div className={classes.root}>
      <span className={classes.label}>
        {__("Dziecko")} {childIndex + 1}
      </span>
      <div className={classes.field}>
        <Field
          name={`${names.rooms}[${roomIndex}].children[${childIndex}].age`}
        >
          {/* @ts-ignore */}
          {({ field, form }) => {
            return (
              <>
                <select
                  value={childAge}
                  className={classes.select}
                  onChange={(e) => {
                    form.setFieldValue(field.name, Number(e.target.value));
                  }}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                </select>
              </>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default DomesticTourismFormRoomChild;
