/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Formik } from "formik";
import { FC } from "react";

import __ from "web/Layout/Translations";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";
import {
  getInitialValues,
  validateFields,
} from "web/utils/system/formValidator/validation";

import Form from "./Form";
import formClassesDesktop from "./Form/formDesktop.scss";
import formClassesMobile from "./Form/formMobile.scss";
import classesDesktop from "./newsletterDesktop.scss";
import classesMobile from "./newsletterMobile.scss";

const submitHandler = () => {};
const getFields = () => [
  {
    name: "email",
    type: "email",
    label: __("Twój email"),
    placeholder: __("Podaj swój email"),
    initialValue: "",
    rules: ["required", "email"],
  },
  {
    name: "agreement",
    type: "checkbox",
    label: __(
      "Zapoznałam/em się z informacjami dotyczącymi bezpieczeństwa moich danych osobowych i wyrażam zgodę na \n  ich przetwarzanie w celach marketingowych przez MyBenefit Sp. z o.o."
    ),
    initialValue: false,
    rules: ["required"],
  },
];
const initialValues = () => getInitialValues(getFields());
const getValidate = () => validateFields(getFields());
const webpStatus = canUseWebp();

interface IPageGeneratorNewsletterProps {
  isMobile?: boolean;
}

const PageGeneratorNewsletter: FC<IPageGeneratorNewsletterProps> = ({
  isMobile,
}) => {
  const titleText = __("Zapisz się do Newslettera");
  const contentText = __(
    "Bądź na bieżąco z wyjątkowymi promocjami oraz najnowszymi produktami."
  );
  const classes = isMobile ? classesMobile : classesDesktop;
  const formClasses = isMobile ? formClassesMobile : formClassesDesktop;
  const rootClassName = isMobile
    ? classes.root
    : `container ${classes.root || ""}`.trim();
  const wrapperClassName = webpStatus ? classes.wrapperWebp : classes.wrapper;
  return (
    <section className={rootClassName} data-t1="pageGeneratorNewsletter">
      <div className={wrapperClassName}>
        <div className={classes.content}>
          <strong
            className={classes.title}
            data-t1="pageGeneratorNewsletter__title"
          >
            {titleText}
          </strong>
          <p className={classes.text} data-t1="pageGeneratorNewsletter__text">
            {contentText}
          </p>
          <Formik
            // @ts-ignore
            initialValues={initialValues()}
            onSubmit={submitHandler}
            validate={getValidate()}
          >
            <Form classes={formClasses} fields={getFields()} />
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default PageGeneratorNewsletter;
