import { motion } from "framer-motion";
import { arrayOf, number, shape, string } from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { LazyLoadImage as ThumbnailImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import ArrowLeftIcon from "web/assets/icons/arrow-left.svg";
import ArrowRightIcon from "web/assets/icons/arrow-right.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classes from "./galleryPrepaidFullscreen.scss";

const variants = {
  top: { y: "-30px" },
  normal: { y: "0px" },
};

const GalleryPrepaidFullscreen = ({ images, activeIndex }) => {
  const initialActiveIndex = typeof activeIndex === "number" ? activeIndex : 0;
  const [currentIndex, setCurrent] = useState(initialActiveIndex);
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        history.goForward();
      }
    });
  }, [history]);

  const sliderScrollPostion = useCallback(
    (thumbIndex) => {
      const thumbnailsContainer = document.querySelector(
        `.${classes.thumbnails}`
      );
      const activeThumbnail = document.querySelector(
        `.${classes.thumbnail}-${thumbIndex || currentIndex}`
      );

      const thumbnailsContainerWidth = thumbnailsContainer.offsetWidth;
      const activeThumbnailFromLeft = activeThumbnail.offsetLeft;
      const activeThumbnailWidth = activeThumbnail.offsetWidth;

      if (
        activeThumbnailFromLeft <
        thumbnailsContainerWidth - activeThumbnailWidth
      )
        thumbnailsContainer.scrollLeft = 0;

      if (
        activeThumbnailFromLeft >
        thumbnailsContainerWidth - activeThumbnailWidth
      )
        thumbnailsContainer.scrollLeft =
          activeThumbnailFromLeft -
          (thumbnailsContainerWidth / 2 - activeThumbnailWidth / 2);
    },
    [currentIndex]
  );

  const slideHandler = useCallback(
    (direction) => {
      sliderScrollPostion();
      setCurrent((state) => state + parseInt(direction, 10));
    },
    [setCurrent, sliderScrollPostion]
  );

  const thumbnailClickHandler = (thumbIndex) => {
    setCurrent(thumbIndex);
    sliderScrollPostion(thumbIndex);
  };

  return (
    isArrayHasItems(images) && (
      <div className={classes.root}>
        <div className={classes.current}>
          {currentIndex + 1} / {images.length}
        </div>

        <div className={classes.full}>
          {images.length > 1 && (
            <>
              <button
                type="button"
                className={classes.prev}
                onClick={() => slideHandler(-1)}
                disabled={!currentIndex}
                data-t1="gallery_full_screen_prev_button"
              >
                <ArrowLeftIcon width={16} />
              </button>

              <button
                type="button"
                className={classes.next}
                onClick={() => slideHandler(1)}
                data-t1="gallery_full_screen_next_button"
                disabled={currentIndex === images.length - 1}
              >
                <ArrowRightIcon width={16} />
              </button>
            </>
          )}
          <div className={classes.fullBox}>
            <LazyLoadImage
              src={images[currentIndex].media_url}
              alt={images[currentIndex].label}
              className={classes.image}
              isAutoresized={false}
              notProcessed
            />
          </div>
        </div>

        <div className={classes.thumbnails}>
          {images.map((image, index) => (
            <motion.div
              key={image.media_url}
              className={`${classes.thumbnail} ${classes.thumbnail}-${index}`}
              animate={currentIndex === index ? variants.top : variants.normal}
            >
              <button
                type="button"
                onClick={() => thumbnailClickHandler(index)}
                className={classes.thumbnailTrigger}
                data-t1="gallery_full_screen_thumbnail_trigger_button"
              >
                <ThumbnailImage
                  src={image.media_url}
                  alt={image.label}
                  className={classes.image}
                  width={324}
                  height={216}
                />
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    )
  );
};

GalleryPrepaidFullscreen.propTypes = {
  images: arrayOf(
    shape({
      thumbnail: string.isRequired,
      full: string.isRequired,
      description: string,
    })
  ).isRequired,
  activeIndex: number,
};

GalleryPrepaidFullscreen.defaultProps = {
  activeIndex: 0,
};

export default GalleryPrepaidFullscreen;
