import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";

import { ICachedItem } from "./addTimeExpiration";

interface IGetCachedDataFromReduxArgs<T> {
  ids: (number | string)[];
  dataFromStore: Record<string, ICachedItem<T>>;
  mapData: MapWrapper;
  isFullProduct?: boolean;
  hasAdditionalParameters?: boolean;
  isIdsFacetsStatsData?: boolean;
}

const getCachedDataFromRedux = <T>({
  ids,
  dataFromStore,
  mapData,
  isFullProduct,
  hasAdditionalParameters,
  isIdsFacetsStatsData,
}: IGetCachedDataFromReduxArgs<T>) => {
  const lackIds: Set<string | number> = new Set();
  const idsWaiting: Set<string | number> = new Set();
  const idsToRemoveFromCache: Set<string | number> = new Set();
  const data: T[] = [];

  ids.forEach((id: string | number) => {
    if (hasAdditionalParameters) {
      lackIds.add(id);
      return;
    }
    const dataFromCache = dataFromStore[id as keyof typeof dataFromStore];

    if (dataFromCache?.isShortProduct && isFullProduct) {
      lackIds.add(id);
      return;
    }

    if (mapData.has(id) && !isIdsFacetsStatsData) {
      idsWaiting.add(id);
      return;
    }

    if (!dataFromCache) {
      lackIds.add(id);
      return;
    }
    if (
      Date.now() > dataFromCache.timeExpiration ||
      Number.isNaN(dataFromCache.timeExpiration)
    ) {
      idsToRemoveFromCache.add(id);

      lackIds.add(id);
      return;
    }
    data.push(dataFromCache.value);
  });
  return {
    dataFromRedux: data,
    lackIds: [...lackIds],
    idsWaiting,
    idsToRemoveFromCache,
  };
};

export default getCachedDataFromRedux;
