import { FC } from "react";

import TourismLocation from "web/Pages/Product/Shared/Tourism/TourismLocation";
import TourismObjectInfo from "web/Pages/Product/Shared/Tourism/TourismObjectInfo";

import { GroupedProduct } from "web/utils/page/product/universal/groupProducts";

import { MBProductType } from "web/types/Product";
import type { IProductData } from "web/types/ProductData";

import classes from "./objectInformation.scss";

interface ICheckoutTourismDomesticObjectInformationProps {
  product: GroupedProduct<MBProductType.ANIXE_TOURISM>["full"] | IProductData;
  className?: string;
}

const CheckoutTourismDomesticObjectInformation: FC<
  ICheckoutTourismDomesticObjectInformationProps
> = ({ product, className = "" }) => {
  return product ? (
    <div className={className || classes.root}>
      <TourismObjectInfo
        className={classes.item}
        product={product as GroupedProduct<MBProductType.ANIXE_TOURISM>["full"]}
      />
      <TourismLocation
        className={classes.item}
        product={product as GroupedProduct<MBProductType.ANIXE_TOURISM>["full"]}
      />
    </div>
  ) : null;
};

export default CheckoutTourismDomesticObjectInformation;
