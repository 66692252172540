import { FC, PropsWithChildren } from "react";

import HelpCenterArticleContent from "../../Shared/Content/content";
import HelpCenterArticleContentHeader from "../../Shared/Header/header";
import { IHelpCenterArticleWithId } from "../../helpCenterArticle";
import classes from "./content.scss";

interface IHelpCenterDesktopArticleContentProps {
  title: IHelpCenterArticleWithId["title"];
  content: IHelpCenterArticleWithId["content"];
}

const HelpCenterDesktopArticleContent: FC<
  PropsWithChildren<IHelpCenterDesktopArticleContentProps>
> = ({ title, content, children }) => (
  <article className={classes.root}>
    <HelpCenterArticleContentHeader>{title}</HelpCenterArticleContentHeader>
    <HelpCenterArticleContent content={content} />
    {children}
  </article>
);

export default HelpCenterDesktopArticleContent;
