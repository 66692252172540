import DOMPurify from "dompurify";
import { FC, PropsWithChildren } from "react";

import Accordion from "web/Components/Common/Accordion/accordion";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultStyles from "./description.scss";

interface IProductDescriptionMobileProps {
  text: string;
  label: string;
  isSmall: boolean;
  id: string;
  dataT1: string;
  visibleHeight: number;
  closeHeight: number;
}

const ProductDescriptionMobile: FC<
  PropsWithChildren<PropsWithClasses<IProductDescriptionMobileProps>>
> = ({
  text,
  label,
  classes = {},
  isSmall = false,
  id = "",
  dataT1 = "description",
  children = "",
  closeHeight = 250,
}) => {
  const textClass = isSmall
    ? `reset-global-styles ${classes.textSmall}`
    : `reset-global-styles ${classes.text}`;

  const checkIfHasDescription = (description: string) => {
    // Empty description has only <a class="clear"> inside
    return !description.includes('class="clear"');
  };

  return (
    <div>
      {checkIfHasDescription(text) && (
        <div id={id} className={classes.root}>
          <Accordion
            label={label}
            variant="secondary"
            arrow
            expanded
            dataT1={dataT1}
            seeMoreButton
            closeHeight={closeHeight}
          >
            {children}
            <div
              className={textClass}
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(text, { ADD_ATTR: ["target"] }),
              }}
            />
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default classify<
  PropsWithChildren<PropsWithClasses<IProductDescriptionMobileProps>>
>(defaultStyles)(ProductDescriptionMobile);
export { ProductDescriptionMobile };
