/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { ILocationItem } from "web/types/Geolocation";

export interface IAnixeFiltersRoom {
  adults: number;
  children: { age: number }[];
}

export interface IAnixeFiltersSelectedDates {
  dateFrom: number;
  dateTo: number;
}
interface IAnixeFiltersState {
  selectedLocation: ILocationItem;
  selectedRooms?: IAnixeFiltersRoom[];
  selectedDates?: IAnixeFiltersSelectedDates;
  selectedObject: string;
}

const initialState: IAnixeFiltersState = {
  selectedLocation: {} as ILocationItem,
  selectedObject: "",
};

export const anixeFiltesSlice = createSlice({
  name: "anixeFilters",
  initialState,
  reducers: {
    setSelectedLocation: (state, action: PayloadAction<ILocationItem>) => {
      state.selectedLocation = action.payload;
    },
    setSelectedDates: (
      state,
      action: PayloadAction<IAnixeFiltersSelectedDates>
    ) => {
      state.selectedDates = action.payload;
    },
    setSelectedRooms: (state, action: PayloadAction<IAnixeFiltersRoom[]>) => {
      state.selectedRooms = action.payload;
    },
    setSelectedObject: (state, action: PayloadAction<string>) => {
      state.selectedObject = action.payload;
    },
  },
});

export const {
  setSelectedLocation,
  setSelectedDates,
  setSelectedRooms,
  setSelectedObject,
} = anixeFiltesSlice.actions;

export default anixeFiltesSlice.reducer;
