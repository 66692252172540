import { Button } from "@benefit-systems/common-components";
import { FC } from "react";

import __ from "web/Layout/Translations";

import urls from "web/constants/urls";

import { IParsedOrder } from "web/types/Order";
import { MBProductType } from "web/types/Product";

import PaidByMethods from "../PaidByMethods";
import classes from "./orderSummary.scss";

interface ISuccessOrderSummaryMainProps {
  order: IParsedOrder<MBProductType>;
  isAnixeTourism?: boolean;
  isAnixePending?: boolean;
}

const SuccessOrderSummaryMain: FC<ISuccessOrderSummaryMainProps> = ({
  order,
  isAnixeTourism,
  isAnixePending,
}) => {
  const { order_id, tourism_data, payment } = order || {};
  const { anixeId, anixeProviderId } = tourism_data || {};
  return (
    <div className={classes.gridItem}>
      <div
        className={classes.orderNumber}
        data-t1="orderSummaryMain__orderNumber"
      >
        <h2>{__("Twój numer zamówienia to")}:</h2>
        <p
          className={classes.orderNumberValue}
          data-t1="orderSummaryMain__orderNumberValue"
          data-t2={order_id}
        >
          {order_id}
        </p>
      </div>

      {isAnixeTourism && !isAnixePending && (anixeProviderId || anixeId) && (
        <div className={classes.orderNumber}>
          {__("Numer rezerwacji")}:&nbsp;
          <span className={classes.reservationId}>
            {anixeProviderId || anixeId}
          </span>
        </div>
      )}

      <PaidByMethods payments={payment} />

      <div className={`${classes.buttonWrapper} nonPrintable`}>
        <Button
          to={urls.home}
          variant="tertiary"
          dataT1="success_page_button_continue"
        >
          {__("Kontynuuj zakupy")}
        </Button>
      </div>
    </div>
  );
};

export default SuccessOrderSummaryMain;
