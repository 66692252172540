import { FC, PropsWithChildren } from "react";

import __ from "web/Layout/Translations";

import PrintButton from "web/Components/Common/PrintButton";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { useAppContext } from "web/context/app";

import classes from "./header.scss";

const HelpCenterArticleHeader: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useAppContext();
  const { isParamLangEn } = useLangFromParams();

  return (
    <header className={classes.root}>
      <div className={classes.title}>{children}</div>
      {!isMobile && (
        <PrintButton label={isParamLangEn ? "Print" : __("Drukuj")} />
      )}
    </header>
  );
};

export default HelpCenterArticleHeader;
