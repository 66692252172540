import { useSelector } from "react-redux";

import __ from "web/Layout/Translations";

import getWindowDateFromBenefitGroups from "web/utils/page/product/subscription/getWindowDateFromBenefitGroups";

import { useGetEmployeeConfigurationQuery } from "web/features/employee/employeeApiSlice";

import styles from "./windowDatesInfo.scss";

const WindowDatesInfo = () => {
  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const { subscriptionThresholdDayUMS } = employeeConfig || {};
  const { config } = useSelector((state) => state.subscriptionQuote);

  const finalDates = getWindowDateFromBenefitGroups(
    config?.selectionWindowTypeUms,
    config?.selectionWindowOpenToDateUms,
    subscriptionThresholdDayUMS
  );

  return (
    <section className={styles.root}>
      <p>
        {__("Wybieraj i zmieniaj swoje abonamenty do")}:
        <span className={styles.highlight}>{finalDates?.fullDateToDate}</span>
        {__("aby zostać nimi objętym od")}:
        <span className={styles.highlight}>{finalDates?.fullDateFromDate}</span>
      </p>
    </section>
  );
};

export default WindowDatesInfo;
