import { useQuery } from "@apollo/client";

import Page404 from "web/Pages/Page404";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import HelmetHeader from "web/Layout/HelmetHeader";
import MobileHeader from "web/Layout/MobileHeader";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import cmsPageQuery from "web/queries/default/cmsPage.graphql";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import { FC } from "react";
import Cms from "./cms";

interface IContainerProps {
  id: string | number;
  metaDescription: string;
  metaKeywords: string;
  metaTitle: string;
}

const Container: FC<IContainerProps> = ({ id, metaDescription, metaKeywords, metaTitle }) => {
  const { isMobile } = useAppContext();

  const { loading, error, data } = useQuery(cmsPageQuery, {
    variables: {
      id: +id,
      lang: getLanguageCode(),
    },
  });

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      console.error(error);
      newRelicErrorReport(error, "web-app/web/Pages/Cms/container.js - 38");
      return <ErrorComponentWithContainer />;
    }
    default: {
      const dataCmsPage = data && data.cmsPage;
      return dataCmsPage &&
        (dataCmsPage.title ||
          dataCmsPage.content ||
          dataCmsPage.content_heading) ? (
        <>
          {isMobile && (
            <MobileHeader
              replacementPath={(!window.history?.state ? urls.customer : null) as string}
              back
            >
              {dataCmsPage.content_heading || dataCmsPage.title}
            </MobileHeader>
          )}
          <HelmetHeader
            title={metaTitle || dataCmsPage.title}
            description={metaDescription}
            keywords={metaKeywords}
          />
          <Cms
            content={dataCmsPage.content}
            title={dataCmsPage.title}
            contentTitle={dataCmsPage.content_heading}
          />
        </>
      ) : (
        <Page404 />
      );
    }
  }
};

export default Container;
