import { FC } from "react";

import HelpCenterBreadcrumbs from "web/Pages/HelpCenter/Shared/Breadcrumbs";

interface IBreadcrumbsProps {
  name: string;
}

const Breadcrumbs: FC<IBreadcrumbsProps> = ({ name }) => {
  const breadcrumbs = [
    {
      label: name,
      dataT1: "breadcrumbs_help_center_category_page",
    },
  ];

  return <HelpCenterBreadcrumbs breadcrumbs={breadcrumbs} />;
};

export default Breadcrumbs;
