import { Link } from "react-router-dom";

import processAnchorHref from "web/utils/system/url/processAnchorHref";

import classify from "web/classify";

import { FC, PropsWithChildren } from "react";
import { PropsWithClasses } from "web/types/Common";
import defaultClasses from "./button.scss";

interface IPageGeneratorButtonProps {
  to?: string;
  submit?: boolean;
  buttonFontColor?: string;
  buttonBackgroundColor?: string;
  title?: string;
  onClick?: () => void;
  dataT1?: string;
}

const PageGeneratorButton: FC<PropsWithChildren<PropsWithClasses<IPageGeneratorButtonProps>>> = ({
  to = null,
  submit,
  classes = {},
  buttonFontColor,
  buttonBackgroundColor,
  title,
  onClick,
  children,
  dataT1 = "button",
}) => {
  const newHref = to ? processAnchorHref(to) : "";

  const buttonStyles = {
    color: buttonFontColor,
    backgroundColor: buttonBackgroundColor,
  };
  const buttonClassName = `${classes.root} ${classes.button}`;
  const content = <span className={classes.content}>{children}</span>;
  const type = submit ? "submit" : "button";
  switch (true) {
    case typeof newHref === "string" && !!newHref.length: {
      return newHref.indexOf("http") === -1 ? (
        <Link
          to={newHref}
          data-t1={`page_generator_link_${dataT1}`}
          className={buttonClassName}
          style={buttonStyles}
          title={title}
        >
          {content}
        </Link>
      ) : (
        <a
          href={newHref}
          data-t1={`page_generator_link_${dataT1}`}
          className={buttonClassName}
          target="_blank"
          rel="noreferrer"
          style={buttonStyles}
          title={title}
        >
          {content}
        </a>
      );
    }
    case !!onClick: {
      return (
        <button
          /* eslint-disable-next-line react/button-has-type */
          type={type}
          data-t1={`page_generator_button_${dataT1}`}
          className={buttonClassName}
          style={buttonStyles}
          onClick={onClick}
          title={title}
        >
          {content}
        </button>
      );
    }
    default:
      return null;
  }
};

export default classify(defaultClasses)(PageGeneratorButton);
