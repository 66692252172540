import { Button } from "@benefit-systems/common-components";
import { Form, useFormikContext } from "formik";

import GtmEvent from "web/Layout/Gtm/GtmEvent";
import __ from "web/Layout/Translations";

import urls from "web/constants/urls";

import getPageReferrer from "web/utils/system/GTM/getPageReferrer";
import { useLocation } from "react-router";
import { useMemo } from "react";
import ContactFields from "../Common/Fields/contactFields";
import RadioGroupField from "../Common/Fields/radioGroupField";
import classes from "./contactForm.scss";

const ContactFormDesktop = () => {
  const { isSubmitting } = useFormikContext();
  const location = useLocation();

  const gtmOptions = useMemo(() => ({
    cgroup1: "Kontakt", page_referrer: getPageReferrer(location),
  }), [location, getPageReferrer]);

  return (
    <div className={classes.root}>
      <Form className={classes.form}>
        <div className={classes.title}>{__("Skontaktuj się z nami:")}</div>
        <RadioGroupField name="statement" classes={classes} />
        <ContactFields />
        <span className={classes.requiredLabel}>{__("Pole obowiązkowe")}</span>
        <div className={classes.buttonWrapper}>
          <div className={classes.back}>
            <Button to={urls.helpCenter} type="reset" variant="secondary">
              {__("Wróć")}
            </Button>
          </div>
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {__("Wyślij")}
          </Button>
        </div>
      </Form>
      <GtmEvent options={gtmOptions} withUrl />
    </div>
  );
};

export default ContactFormDesktop;
