import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { DocumentNode, print } from "graphql";

import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import deleteGraphqlExtraSpaces from "web/utils/system/query/deleteGraphqlExtraSpaces";
import graphQlAsGet from "web/utils/system/query/graphQlAsGet";
import {
  getFlattedData,
  getOperationName,
} from "web/utils/system/storage/storagesAsync/utils";

import { request } from "web/api";
import { RootState } from "web/store";

const graphQLBaseQuery: BaseQueryFn = async (
  {
    endpoint,
    id,
    query,
    selectionSetNames,
    variables,
  }: {
    endpoint: string;
    id?: string;
    query: DocumentNode;
    selectionSetNames?: string[];
    variables?: Record<string, unknown>;
  },
  { getState }
) => {
  const {
    app: { storeConfig },
  } = getState() as RootState;
  const { id: storeId, token } = storeConfig || {};

  const queryProcessed = print(query);
  const queryProcessedWithoutSpaces = deleteGraphqlExtraSpaces(queryProcessed);
  const operationName = getOperationName(query);

  const options = {
    method: IApiRequestMethods.GET,
    body: JSON.stringify({
      query: queryProcessedWithoutSpaces,
      operationName,
      variables: {
        ...(variables || {}),
        storeId,
        token,
        lang: getLanguageCode(),
        ...(id ? { id } : {}),
      },
    }),
  };

  try {
    const data = (await graphQlAsGet(endpoint, options, request)) as {
      data: Record<string, { edges: { node: unknown }[] }>;
    };

    const dataFlatted = isArrayHasItems(selectionSetNames)
      ? selectionSetNames?.reduce((acc, selectionSetName) => {
          return {
            ...acc,
            [selectionSetName]: getFlattedData(data, query, selectionSetName),
          };
        }, {})
      : getFlattedData(data, query);

    return { data: dataFlatted };
  } catch (error) {
    return { error };
  }
};

export default graphQLBaseQuery;
