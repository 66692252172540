import dayjs from "dayjs";

import { dayMilliseconds } from "web/constants/date";

const availabilityMap = {
  used: 1,
  use: 0,
};

export const enum FilterValues {
  USE = "use",
  USED = "used",
  ALL = "all",
}

export const getFilters = (value: FilterValues) => {
  return value
    ? [
        {
          ...(value !== FilterValues.ALL
            ? {
                filters: [
                  {
                    field: "product_user_used",
                    value: availabilityMap[value],
                  },
                ],
              }
            : {}),
        },
        {
          ...(value === FilterValues.USE
            ? {
                filters: [
                  {
                    field: "product_expiration_date",
                    value: dayjs(Date.now() + dayMilliseconds).format(
                      "YYYY-MM-DD"
                    ),
                    conditionType: "gt",
                  },
                ],
              }
            : {}),
        },
      ]
    : null;
};
