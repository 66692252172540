import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type Optional<T extends object, K extends keyof T = keyof T> = Omit<
  T,
  K
> &
  Partial<Pick<T, K>>;

export type Nullable<T> = T | null;

export type NumberBoolean = 0 | 1;

export type StringBoolean = "0" | "1";

export type Email = `${string}@${string}.${string}`;

export type ObjectWithNullableFields<T> = {
  [K in keyof T]: Nullable<T[K]>;
};

export type ObjectWithGivenNullableFields<T, Keys extends keyof T> = {
  [K in keyof T]: K extends Keys ? Nullable<T[K]> : T[K];
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type TupleToUnion<T extends unknown[]> = T[number];

export type WithOptional<T, K extends keyof T> = Pick<Partial<T>, K> &
  Omit<T, K>;

export type DateTypes = Date | string | number;

export type StringNumber = string | number;

export type HydraResponse<T> = {
  "hydra:member": T[];
  "hydra:totalItems": number;
};

export type ButtonTypes = "button" | "submit" | "reset";

export type ValueOf<T> = T[keyof T];

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}
