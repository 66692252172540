import { AdditionalInfoDataType, FeeType } from "../../../types";
import { getTotalAmount } from "../../../utils";
import Item from "./Item";

type AdditionalInfoProps = {
  data: AdditionalInfoDataType[];
};

const getBanksAndAmounts = (
  data: AdditionalInfoDataType[],
  dataType: FeeType
) => {
  return data
    ?.filter(({ type }) => type === dataType)
    .map(({ bankName, amount }) => ({
      bankName,
      amount,
    }));
};

const AdditionalInfo = ({ data }: AdditionalInfoProps) => {
  const isCharge =
    data?.length > 0 &&
    data.some((el) => el.type === "CHARGE" && el.payer === "EMPLOYEE");
  const chargeAmount = getTotalAmount(data, "CHARGE", "EMPLOYEE");
  const chargeBanks = getBanksAndAmounts(data, "CHARGE");

  const isIssuanceFee =
    data?.length > 0 &&
    data.some((el) => el.type === "ISSUANCE_FEE" && el.payer === "EMPLOYEE");
  const issuanceFeeAmount = getTotalAmount(data, "ISSUANCE_FEE", "EMPLOYEE");
  const issuanceFeeBanks = getBanksAndAmounts(data, "ISSUANCE_FEE");

  const isRechargeCommission =
    data?.length > 0 &&
    data.some(
      (el) => el.type === "RECHARGE_COMMISSION" && el.payer === "EMPLOYEE"
    );
  const rechargeCommissionAmount = getTotalAmount(
    data,
    "RECHARGE_COMMISSION",
    "EMPLOYEE"
  );
  const rechargeCommissionBanks = getBanksAndAmounts(
    data,
    "RECHARGE_COMMISSION"
  );

  return (
    <>
      {isCharge && chargeAmount > 0 && (
        <Item
          type="CHARGE"
          total={chargeAmount as number}
          financingSources={chargeBanks}
        />
      )}

      {isIssuanceFee && issuanceFeeAmount > 0 && (
        <Item
          type="ISSUANCE_FEE"
          total={issuanceFeeAmount as number}
          financingSources={issuanceFeeBanks}
        />
      )}

      {isRechargeCommission && rechargeCommissionAmount > 0 && (
        <Item
          type="RECHARGE_COMMISSION"
          total={rechargeCommissionAmount as number}
          financingSources={rechargeCommissionBanks}
        />
      )}
    </>
  );
};

export default AdditionalInfo;
