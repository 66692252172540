import { FC } from "react";

import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import Title from "web/Components/Common/Title";

import WalletIcon from "web/assets/icons/wallet.svg";

import { zlotyPerMonth } from "web/constants/benefits";

import { Classes } from "web/types/Common";

import classify from "web/classify";

import EmployeePrice from "../PaymentsShared/EmployeePrice";
import defaultClasses from "./paymentsSummary.scss";

const titleClasses = { header: defaultClasses.title };

interface IPaymentsSummaryProps {
  classes?: Classes;
  employeePrice: string;
  employerPrice: string;
  employerTaxAmount: string;
  title?: string;
  isFetching?: boolean;
}

const CheckoutSubscriptionPaymentsSummary: FC<IPaymentsSummaryProps> = ({
  classes = {},
  employeePrice = "0",
  employerPrice = "0",
  employerTaxAmount = "0",
  title,
  isFetching = false,
}) => {
  return (
    <section className={classes.root}>
      <Title
        name={title}
        dataT1="payments_sumary_label_1"
        classes={titleClasses}
        container="h2"
      />
      <div className={classes.box}>
        <div className={classes.box__employer}>
          <strong className={classes.label} data-t1="payments_sumary_label_2">
            {__("Abonamenty opłacane przez pracodawcę")}
          </strong>
          <div className={classes.price}>
            <div className={classes.icon}>
              <WalletIcon width={25} />
            </div>
            <Price value={employerPrice} currency={__(zlotyPerMonth)} />
          </div>
        </div>
        <div className={classes.box__employee}>
          <EmployeePrice
            isFetching={isFetching}
            employeePrice={employeePrice}
            employerTaxAmount={employerTaxAmount}
          />
        </div>
      </div>
    </section>
  );
};

export default classify<IPaymentsSummaryProps>(defaultClasses)(
  CheckoutSubscriptionPaymentsSummary
);
