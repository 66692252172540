import { Button } from "@benefit-systems/common-components";
import { useCallback } from "react";

import __ from "web/Layout/Translations";

import CodeModal from "web/Components/Common/CodeItem/CodeModal";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import { useAppContext } from "web/context/app";

interface ISuccessOrderSummaryShowCodeProps {
  name: string;
  itemId: number;
  isCanceled: boolean;
  htmlDataKey: string;
  orderPaid: boolean;
  sku: string;
}

function SuccessOrderSummaryShowCode({
  name,
  itemId,
  isCanceled,
  htmlDataKey,
  orderPaid,
  sku,
}: ISuccessOrderSummaryShowCodeProps) {
  const { modal } = useAppContext();
  const { dispatch } = modal;

  const showModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: isCanceled ? null : (
        <CodeModal
          productName={name}
          itemId={itemId}
          orderPaid={orderPaid}
          sku={sku}
        />
      ),
    });
  }, [name]);

  return (
    <Button
      onClick={showModal}
      dataT1={`${htmlDataKey}_show_code_button`}
      disabled={isCanceled}
      variant="secondary"
    >
      {isCanceled ? __("Kod anulowany") : __("Zobacz kod")}
    </Button>
  );
}

export default SuccessOrderSummaryShowCode;
