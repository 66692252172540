import { createAsyncThunk } from "@reduxjs/toolkit";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import parseObjectToGetParameters from "web/utils/data/parser/object/parseObjectToGetParameters";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import restUrls from "web/constants/restUrls";

import type { ICodes } from "web/types/Code";
import type { NumberBoolean } from "web/types/Utils";

import { request } from "web/api";
import {
  revertCodeUsed,
  setCodeUsedRequest,
} from "web/features/codes/codesSlice";
import { RootState } from "web/store";

import { FilterValues, getFilters } from "./utils/helpers";

interface IGetCodesArgs {
  page?: number;
  pageSize?: number;
  filterValue?: FilterValues;
}
export const getCodes = createAsyncThunk(
  "codes/getCodes",
  async (
    { page = 1, pageSize = 12, filterValue }: IGetCodesArgs,
    { getState }
  ) => {
    const valueSecured = filterValue ?? FilterValues.USE;

    const { codes } = getState() as RootState;
    const itemsFromState = codes?.items || [];
    /**
     * if user enters page > 1 from a direct link we should
     * fetch also items from previous pages
     */
    const shouldFetchPreviousItems =
      page > 1 && itemsFromState.length !== (page - 1) * pageSize;

    const cacheKey = JSON.stringify({
      page,
      pageSize,
      filterValue: valueSecured,
      shouldFetchPreviousItems,
    });

    //
    /**
     * check if data is already cached
     * if so - return cached data
     */
    const codesFromCache = codes.cache[cacheKey];
    if (codesFromCache) {
      const { toUseCount, usedCount, totalCount } = codes;
      return {
        codes: JSON.parse(codesFromCache),
        toUseCount,
        usedCount,
        totalCount,
        cacheKey,
      };
    }

    const filters = getFilters(valueSecured);

    const sortOrders = [
      {
        field: "created_at",
        direction: "desc",
      },
    ];

    const paramsString = parseObjectToGetParameters({
      searchCriteria: {
        filterGroups: filters,
        pageSize: shouldFetchPreviousItems ? page * pageSize : pageSize,
        currentPage: shouldFetchPreviousItems ? 1 : page,
        sortOrders,
      },
    });

    const response = (await request(
      `${restUrls.customerCodes}?${paramsString}`
    )) as ICodes;

    const codeItems =
      response && isArrayHasItems(response.items) ? response.items : [];
    const {
      to_use_count: toUseCount,
      total_count: totalCount,
      used_count: usedCount,
    } = response || {};

    const items =
      page > 1 && !shouldFetchPreviousItems
        ? [...itemsFromState, ...codeItems]
        : codeItems;

    return {
      codes: items,
      toUseCount,
      usedCount,
      totalCount,
      cacheKey,
    };
  }
);

export const setCodeUsed = createAsyncThunk(
  "codes/setCodeUsed",
  async (
    payload: { itemId: number; isUsed: NumberBoolean },
    { getState, dispatch }
  ) => {
    const { itemId, isUsed } = payload;
    const { codes } = getState() as RootState;
    const itemsIdsMarkedAsToUseInitial = codes
      ? codes.itemsIdsMarkedAsToUse
      : [];
    const itemsIdsMarkedAsUsedInitial = isArrayHasItems(
      codes?.itemsIdsMarkedAsUsed
    )
      ? codes.itemsIdsMarkedAsUsed
      : [];
    const settingMarkIds =
      codes && codes.settingMarkIds ? codes.settingMarkIds : [];
    const newState = isUsed
      ? {
          toUse: itemsIdsMarkedAsToUseInitial.filter((id) => id !== itemId),
          used: [...itemsIdsMarkedAsUsedInitial, itemId],
        }
      : {
          toUse: [...itemsIdsMarkedAsToUseInitial, itemId],
          used: itemsIdsMarkedAsUsedInitial.filter((id) => id !== itemId),
        };

    await dispatch(
      setCodeUsedRequest({
        ...newState,
        mark: [...settingMarkIds, itemId],
      })
    );
    try {
      await request(
        restUrls.customerCodesMark.replace(":itemId", itemId.toString()),
        {
          method: IApiRequestMethods.PUT,
          body: JSON.stringify({
            used: isUsed,
          }),
        }
      );

      const { codes: codesState } = getState() as RootState;
      const settingMarkIdsState = isArrayHasItems(codesState?.settingMarkIds)
        ? codesState.settingMarkIds
        : [];
      const settingMarkIdsFiltered = isArrayHasItems(settingMarkIdsState)
        ? settingMarkIdsState.filter((id) => id !== itemId)
        : [];
      return { mark: settingMarkIdsFiltered };
    } catch (error) {
      const { codes: codesState } = getState() as RootState;
      const settingMarkIdsState =
        codesState && codesState.settingMarkIds
          ? codesState.settingMarkIds
          : [];
      const settingMarkIdsFiltered = isArrayHasItems(settingMarkIdsState)
        ? settingMarkIdsState.filter((id) => id !== itemId)
        : [];
      dispatch(
        revertCodeUsed({
          mark: settingMarkIdsFiltered,
        })
      );

      throw error;
    }
  }
);
