// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailsAnixe-details-l9D{display:flex;flex-direction:row;align-items:center;flex-wrap:wrap}.detailsAnixe-details-l9D>*{margin:0 6px 6px 0;font-size:11px}`, "",{"version":3,"sources":["webpack://./web/Pages/Checkout/Success/Products/Product/detailsAnixe.scss"],"names":[],"mappings":"AAIuB,0BACrB,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CAEA,4BACE,kBAAA,CACA,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .details {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n\n  > * {\n    margin: 0 6px 6px 0;\n    font-size: 11px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": `detailsAnixe-details-l9D`
};
export default ___CSS_LOADER_EXPORT___;
