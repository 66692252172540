import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import Search from "web/Layout/Common/Search/";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import pageErrorImgPng from "web/assets/images/404BackgroundMobile.png";
import pageErrorImgWebp from "web/assets/images/404BackgroundMobile.webp";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import { searchName } from "web/constants/toolbar";
import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import classesDesktop from "./page404Desktop.scss";
import classesMobile from "./page404Mobile.scss";

const headerText = `Ta strona nie istnieje lub jest w trakcie prac serwisowych.`;

const Page404 = () => {
  const { isMobile } = useAppContext();
  const webpStatus = canUseWebp();
  const { push } = useHistory();
  const currLang = getLanguageCode();
  const classes = isMobile ? classesMobile : classesDesktop;
  const rootClassName = isMobile ? classes.root : `${classes.root} container`;
  const labelClasses = { label: classes.label };

  const wrapperClass = webpStatus ? classes.wrapperWebp : classes.wrapper;

  const submitHandler = useCallback(
    (values: string | number | boolean) => {
      const searchPhrase = values;
      const searchPhraseEncoded = encodeURIComponent(searchPhrase);
      if (searchPhrase) {
        push(`${urls.search}?${searchName}=${searchPhraseEncoded}`);
      }
    },
    [push]
  );

  const mobileImage = webpStatus ? pageErrorImgWebp : pageErrorImgPng;

  return (
    <section className={rootClassName}>
      <div className={wrapperClass}>
        <div className={classes.errorImg}>
          <img src={mobileImage} alt="404" />
        </div>
        <div className={classes.row}>
          <header className={classes.header} data-t1="page_404_header">
            {isMobile ? (
              <p className={classes.headerText} data-t1="page_404_header_text">
                <strong
                  className={classes.headerTitle}
                  data-t1="page_404_header_title"
                >
                  {__("Ups!")}
                </strong>{" "}
                {__(headerText)}
              </p>
            ) : (
              <>
                <strong
                  className={classes.headerTitle}
                  data-t1="page_404_header_title"
                >
                  {__("Ups,")}
                </strong>
                <p
                  className={classes.headerText}
                  data-t1="page_404_header_text"
                >
                  {__(headerText)}
                </p>
              </>
            )}
          </header>
          <div className={classes.search}>
            <Search
              className={classes.form}
              label={__("Wpisz ponownie, czego szukasz:")}
              classes={labelClasses}
              initialValue=""
              onSubmit={submitHandler}
              id="page-404"
              data-t1="page_404_search"
              placeholder={__("Czego szukasz?")}
            />
            <p className={classes.searchAfterText}>
              {__("lub przejdź do")}
              {currLang === "pl" ? ":" : ""}
              <strong>
                <Link to="/" className={classes.link}>
                  {__("Strona główna MyBenefit")}
                </Link>
              </strong>
            </p>
          </div>
          <div className={classes.content} data-t1="page_404_content">
            <strong
              className={classes.contentTitle}
              data-t1="page_404_content_title"
            >
              {__("A może czas na przerwę")}?
            </strong>
            <p className={classes.contentText}>
              <strong>
                {__("Już teraz możesz zaplanować najbliższy urlop!")}
              </strong>{" "}
              {__(`Zarezerwuj
              hotel online – do wyboru masz tysiące atrakcyjnych obiektów
              w całej Polsce. Sprawdź:`)}{" "}
              <strong>
                <Link to="/tourism" className={classes.link}>
                  {__("Turystyka online")}
                </Link>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withScrollToTop(Page404);
