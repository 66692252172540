import { FC } from "react";

import __ from "web/Layout/Translations";

import Title from "web/Components/Common/Title";

import { ISubscriptionPointsBank } from "web/types/Subscription";

import EmployeePrice from "../PaymentsShared/EmployeePrice";
import styles from "./paymentsSummaryExtended.scss";

interface IPaymentSummaryExtendedProps {
  title: string;
  employeePrice: string;
  employeePayUPrice: string;
  employerTaxAmount: string;
  pointsBanks?: ISubscriptionPointsBank[];
  isFetching?: boolean;
}

const PaymentSummaryExtended: FC<IPaymentSummaryExtendedProps> = ({
  employeePrice = "0",
  employeePayUPrice = "0",
  employerTaxAmount = "0",
  pointsBanks,
  title,
  isFetching = false,
}) => {
  if (!employeePrice && !employerTaxAmount) return null;

  return (
    <section className={styles.root}>
      <Title
        name={__(title)}
        dataT1="payments_sumary_label_1"
        classes={{ header: styles.title }}
        container="h2"
      />
      <div className={styles.box}>
        <EmployeePrice
          employeePrice={employeePrice}
          employeePayUPrice={employeePayUPrice}
          pointsBanks={pointsBanks}
          employerTaxAmount={employerTaxAmount}
          isFetching={isFetching}
        />
      </div>
    </section>
  );
};

export default PaymentSummaryExtended;
