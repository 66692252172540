/* eslint-disable react/prop-types */
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";

import HeaderSearch from "web/Layout/HeaderSearch";
import Listing from "web/Layout/Listing";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import { searchName } from "web/constants/toolbar";
import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";
import { openNavigation } from "web/features/app/appSlice";

import Breadcrumbs from "./Breadcrumbs";
import classes from "./search.scss";

const Search = () => {
  const { isMobile } = useAppContext();
  const { search } = useLocation();
  const params = new URLSearchParams(decodeURIComponent(search));
  const paramsSearch = params.get(searchName);
  switch (true) {
    case typeof paramsSearch !== "string" || !paramsSearch.length: {
      return <Redirect to={urls.home} />;
    }
    case isMobile: {
      return (
        <div>
          <HeaderSearch backCb={openNavigation} />
          <Listing type="search" />
        </div>
      );
    }
    default: {
      return (
        <div className={`container ${classes.body}`}>
          <Breadcrumbs />
          <Listing type="search" />
        </div>
      );
    }
  }
};

export default withScrollToTop(Search);
