import { Button } from "@benefit-systems/common-components";
import { Form, useFormikContext } from "formik";

import MobileHeader from "web/Layout/MobileHeader";
import __ from "web/Layout/Translations";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import urls from "web/constants/urls";

import ContactFields from "../Common/Fields/contactFields";
import RadioGroupField from "../Common/Fields/radioGroupField";
import classes from "./contactFormMobile.scss";

const ContactFormMobile = () => {
  const bannerClassName = canUseWebp() ? classes.banner : classes.banner_webp;
  const { isSubmitting } = useFormikContext();

  return (
    <div className={classes.root}>
      <MobileHeader replacementPath={urls.helpCenter}>
        {__("Centrum pomocy")}
      </MobileHeader>
      <Form className={classes.form}>
        <div className={bannerClassName}>
          <div className={classes.title}>{__("Skontaktuj się")}</div>
        </div>
        <RadioGroupField name="statement" classes={classes} />
        <ContactFields />

        <span className={classes.requiredLabel}>{__("Pole obowiązkowe")}</span>

        <div className={classes.buttonWrapper}>
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {__("Wyślij")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactFormMobile;
