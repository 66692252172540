import { useField } from "formik";
import { bool, func, oneOf, oneOfType, shape, string } from "prop-types";

import __ from "web/Layout/Translations";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import classify from "web/classify";

import defaultClasses from "./field.scss";

const Field = ({
  classes,
  label,
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  disabled,
  dataT1,
  isDataT1NotChanged,
  showError,
  ...restProps
}) => {
  const [field, meta] = useField({
    name,
  });

  const { value, ...fieldRest } = field;
  const valueWithoutWhitespace = String(value).trimStart();

  const isError = meta.error && meta.touched && showError;
  const inputDisabledClass = disabled ? classes.inputDisabled : classes.input;
  const inputClassName = isError ? classes.inputError : inputDisabledClass;
  const customEvents = {};
  if (typeof onChange === "function")
    customEvents.onChange = (e) => {
      fieldRest.onChange(e);
      onChange(valueWithoutWhitespace);
    };
  if (typeof onBlur === "function")
    customEvents.onBlur = (e) => {
      fieldRest.onBlur(e);
      onBlur(valueWithoutWhitespace);
    };

  /* eslint-disable react/jsx-props-no-spreading */
  const input = (
    <input
      type={type}
      className={inputClassName}
      placeholder={placeholder}
      disabled={disabled}
      data-t1={isDataT1NotChanged ? dataT1 : `${dataT1}_${name}`}
      data-t2={valueWithoutWhitespace}
      value={valueWithoutWhitespace}
      {...fieldRest}
      {...customEvents}
      {...restProps}
    />
  );

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <div className={classes.root}>
      {label ? (
        <label className={classes.label} data-t1={`${dataT1}_label`}>
          <span className={classes.labelText} data-t1={`${dataT1}_label_span`}>
            {__(label)}
          </span>
          {input}
        </label>
      ) : (
        input
      )}
      {isError && <ValidationErrorMessage name={name} />}
    </div>
  );
};

Field.propTypes = {
  classes: shape({
    root: string,
    label: string,
    labelText: string,
    input: string,
    inputError: string,
    inputDisabled: string,
    error: string,
    errorTextArea: string,
  }).isRequired,
  label: oneOfType([string, oneOf([null])]),
  type: string,
  name: string.isRequired,
  placeholder: oneOfType([string, oneOf([null])]),
  onChange: oneOfType([func, oneOf([null])]),
  onBlur: oneOfType([func, oneOf([null])]),
  disabled: bool,
  dataT1: string,
  isDataT1NotChanged: bool,
  showError: bool,
};

Field.defaultProps = {
  label: null,
  placeholder: null,
  onChange: null,
  onBlur: null,
  disabled: false,
  type: "",
  dataT1: "field",
  isDataT1NotChanged: false,
  showError: true,
};

export default classify(defaultClasses)(Field);
