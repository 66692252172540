// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-root-x_s{display:block}.section-title-dE1{font-size:17px;font-weight:700;display:block;margin:0 0 20px}.section-list-AWx{font-size:17px;font-weight:700;margin:0 0 20px}.section-item-b34{position:relative;padding-left:14px;margin:0 0 10px}.section-item-b34:last-child{margin:0}.section-item-b34::before{content:"";position:absolute;left:0;top:calc(50% - 6px);width:8px;height:8px;border-radius:50%;background-color:currentColor}`, "",{"version":3,"sources":["webpack://./web/Pages/Product/Shared/Amenities/section.scss"],"names":[],"mappings":"AAIuB,kBACrB,aAAA,CAGF,mBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,eAAA,CAGF,kBACE,cAAA,CACA,eAAA,CACA,eAAA,CAGF,kBACE,iBAAA,CACA,iBAAA,CACA,eAAA,CAEA,6BACE,QAAA,CAGF,0BACE,UAAA,CACA,iBAAA,CACA,MAAA,CACA,mBAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,6BAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n}\n\n.title {\n  font-size: 17px;\n  font-weight: 700;\n  display: block;\n  margin: 0 0 20px;\n}\n\n.list {\n  font-size: 17px;\n  font-weight: 700;\n  margin: 0 0 20px;\n}\n\n.item {\n  position: relative;\n  padding-left: 14px;\n  margin: 0 0 10px;\n\n  &:last-child {\n    margin: 0;\n  }\n\n  &::before {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    top: calc(50% - 6px);\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    background-color: currentColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `section-root-x_s`,
	"title": `section-title-dE1`,
	"list": `section-list-AWx`,
	"item": `section-item-b34`
};
export default ___CSS_LOADER_EXPORT___;
