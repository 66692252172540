import { Button } from "@benefit-systems/common-components";
import { FC, PropsWithChildren } from "react";

import __ from "web/Layout/Translations";

import classes from "./footer.scss";

const HelpCenterArticleFooter: FC<PropsWithChildren> = ({ children }) => {
  return (
    <footer>
      <div className={classes.content}>{children}</div>
      <Button variant="secondary" onClick={() => window.history.back()}>
        {__("Wróć")}
      </Button>
    </footer>
  );
};

export default HelpCenterArticleFooter;
